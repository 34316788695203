import qs from 'qs'

export function getQueryString(queryString: string) {
    return new URLSearchParams(window.location.search).get(queryString) ?? ''
}

export function getQueryParams<Q extends {}>(q: Q) {
    const queryParams = qs.stringify(q)
    return queryParams ? `?${queryParams}` : ''
}
