import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {navigationOffset} from '@product-web/navigation/constants'
import {getBreakpoint} from '@product-web/styles/theme'

export const NavigationSidebar = styled.aside<{$width: number}>`
    position: initial;
    width: 100%;
    flex: 0 0 ${({$width}) => `${$width}px`};
    z-index: ${tokens.zIndexNavigation};
    transition: ${`all ${tokens.smoothInOut}`};

    @media (max-width: ${getBreakpoint('tabletMedUp')}) {
        position: fixed;
        flex: 0 0 ${navigationOffset};
    }
`
