import useSWR from 'swr'

import type {EmployeeRole} from '@pleo-io/deimos'

import {request} from '@product-web/api'
import {RequestAuth} from '@product-web/api'
import config from '@product-web/config'

const baseUrl = config.endpoints.api

/*
 * TODO: Add to deimos client
 */
export type GetUserNavigationResponse = {
    id: string
    email: string
    firstName: string
    lastName: string
    isOrganizationOwner: boolean
    organizationId: string
    organizationName: string
    companies: {
        id: string
        name: string
        role: EmployeeRole | null
        isHomeEntity: boolean
        avatar: {
            url: string
        }
    }[]
}

/**
 * Fetches the company accounts a user has access to based on their permissions
 * Used in the global app navigation accounts switch
 * @param param.accessToken accessToken associated wwith a user
 * @param param.email email associated with user's account. Used to create a unique cache key for swr
 * @returns basic user information alongside a list of companies a user has access to
 */
export function useUserNavigation({
    accessToken,
    email,
}: {
    accessToken?: string | null
    email: string
}) {
    return useSWR<GetUserNavigationResponse, Error>(
        accessToken && email
            ? `${baseUrl}/rest/v1/frontend/web/user-navigation?email=${encodeURIComponent(email)}`
            : null,
        async (route: string) =>
            request(route, {
                auth: RequestAuth.USER,
                credentials: false,
                bearer: accessToken,
                method: 'GET',
            }),
        {
            revalidateOnFocus: false,
        },
    )
}
