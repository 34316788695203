import useSWR from 'swr'

import type {SuccessResponse} from '@pleo-io/deimos'

import type {RequestScope} from '@product-web/api'
import {callAuth} from '@product-web/api'
import {RequestAuth} from '@product-web/api'
import type {OtpType} from '@product-web/api-types/otp'
import config from '@product-web/config'
import type {SupportedLanguage} from '@product-web/i18n'

const clientId = config.auth.oauthClientId

// 10 minutes
export const OTP_EXPIRATION_TIME = 600000

type ScopeTokenResponse = {
    accessToken: string
}

/**
 * Method to request scoped passcode
 * @param params.passcode User passcode
 * @param params.scope Scope of token. Can be card or permission or card_details scope
 * @param params.payload object containing a public key
 * @returns accessToken This is a scoped token
 */
export async function getScopedToken(
    passcode: string | null,
    scope?: RequestScope,
    payload?: {
        publicKey: any
    } | null,
): Promise<ScopeTokenResponse> {
    return callAuth(`/api/v1/user/pin/login`, {
        auth: RequestAuth.USER,
        method: 'POST',
        body: {
            clientId,
            pin: passcode,
            ...(scope && {scope}),
            ...(payload?.publicKey && {payload}),
        },
    })
}

type TwoFactorEnabledCheckResponse = {
    enabled: boolean
    blocked: boolean
}

/**
 * Custom hook to check if logged in user is flagged for 2FA feature
 * @returns enabled true
 */
export function use2FAEnabled() {
    return useSWR<TwoFactorEnabledCheckResponse, Error>(`/sca/check/sca-enabled`, async (url) =>
        callAuth(url, {
            auth: RequestAuth.USER,
            method: 'GET',
        }),
    )
}

export type APIOtpValidateResponse = {
    phoneLastDigits?: string
    googleAuth?: boolean
    secret?: string
    qrcode?: string
}

/**
 * Method to request One-Time-Password at login
 * @param params.email Email of user requesting login
 * @param params.passcode Passcode of user requesting login
 * @param params.loginToken Token gotten from a redirect. This is used in place of the passcode and usually for Single-Sign-On (SSO) login
 * @returns phoneLastDigits
 */
export async function requestOtp({
    email,
    passcode,
    loginToken,
    phone,
    otpType,
    language,
}: {
    email: string
    passcode?: string | null
    loginToken?: string | null
    phone?: string | null
    otpType?: OtpType | string
    language?: SupportedLanguage
}): Promise<APIOtpValidateResponse> {
    return callAuth('/sca/otp', {
        method: 'POST',
        body: {
            email,
            ...(passcode && {passcode}),
            ...(loginToken && {loginToken}),
            ...(phone && {phone}),
            ...(otpType && {otpType}),
            ...(language && {language}),
        },
    })
}

type EmailCheckResponse = {
    legacy?: boolean
    googleAuth?: boolean
    ssoAuthUrl?: string
}

export async function checkEmail(email: string): Promise<EmailCheckResponse> {
    return callAuth('/sca/check', {
        method: 'POST',
        body: {
            email,
        },
    })
}

/**
 * Method to request phone verification.
 * This works for both logged in users (with card-scoped access token) and logged out users (with a valid token and associated email)
 * @param params.phone Phone number to verify.
 * @param params.email Email is gotten from reset email sent to the user.
 * @param params.token Token gotten from reset email sent to the user. This token is valid for 24hrs
 * @param params.accessToken Used when we need to manually add the bearer. This is usually when a user is logged in.
 * @param params.captchaToken A Google ReCaptcha token. This endpoint is protected by google Recaptcha. See ~/external/use-recaptcha for more details
 * @returns success true or false if the request was successful
 */
export async function request2FAVerification({
    phone,
    email,
    token,
    authType,
    accessToken,
    captchaToken,
    language,
}: {
    phone?: string
    email?: string | null
    token?: string | null
    authType?: OtpType
    accessToken?: string | null
    captchaToken: string
    language?: SupportedLanguage
}) {
    return callAuth('/sca/reset/tfa', {
        ...(accessToken && {
            bearer: accessToken,
        }),
        method: 'PUT',
        body: {
            ...(email && token && authType && {email, token, phone, authType}),
            ...(phone && {phone}),
            captchaToken,
            language,
        },
    })
}

/**
 * Method to verify a phone number.
 * This works for both logged in users (with card-scoped access token) and logged out users (with a valid token and associated email)
 * @param params.otp Code sent when requesting phone verification.
 * @param params.email Email is gotten from reset email sent to the user.
 * @param params.token Token gotten from reset email sent to the user. This token is valid for 24hrs
 * @param params.accessToken A card-scoped access token. This required to verify the number of a logged in user.
 * @returns success true or false
 */
export async function verifyPhoneNumber({
    otp,
    email,
    token,
    accessToken,
    authType,
}: {
    otp: string | null
    email?: string | null
    token?: string | null
    accessToken?: string | null
    authType?: string
}): Promise<SuccessResponse> {
    return callAuth('/sca/reset/tfa/verify', {
        ...(accessToken && {
            bearer: accessToken,
        }),
        method: 'PUT',
        body: {otp, ...(email && token && {email, token}), ...(authType && {authType})},
    })
}

interface UserEmailCheckResult {
    userExists: boolean
    unverifiedUserExists: boolean
    emailChangeExists: boolean
    inviteExists: boolean
    domainExists: boolean
}

export async function checkSignupEmail(email: string): Promise<UserEmailCheckResult> {
    return callAuth('/api/v1/email/check', {
        auth: 'user',
        method: 'POST',
        body: {email},
    })
}
