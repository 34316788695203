import {t} from '@lingui/macro'
import React from 'react'
import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'

import {createOpenBankingPayment} from '@product-web/api-deimos/wallet'
import {reportError} from '@product-web/error/report'
import {type ToasterAPI, useToaster} from '@product-web/toaster'
import {useCompanyUser} from '@product-web/user'

import {trackOpenBankingModal, TrackOpenBankingTopUpAction} from './open-banking-tracking'
import type {OpenBankingCallBackStatus, OpenBankingCallBackType} from './types/open-banking'
import {isValidCallBackStatus, isValidCallBackType} from './types/open-banking'
import {useUpdateStoredAutoTopUpSettings} from './use-update-stored-auto-top-up-settings'
import {useVrpAutoTopUpStorage} from './use-vrp-auto-top-up-storage'

export const useOpenBankingRedirectParams = () => {
    const {showToast} = useToaster()
    const navigate = useNavigate()

    const user = useCompanyUser()
    const [searchParams] = useSearchParams()
    const {clearOpenBankingSearchParams} = useClearOpenBankingSearchParams()

    // todo: remove this Ref when BE is ready with the new flow - https://linear.app/pleo/issue/WALLE-5059
    const isPaymentRequestInProgressRef = React.useRef(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const {cleanSavedVrpAutoTopUpSettings} = useVrpAutoTopUpStorage()
    const updateStoredAutoTopUpSettings = useUpdateStoredAutoTopUpSettings()

    React.useEffect(() => {
        if (isPaymentRequestInProgressRef.current) {
            return
        }

        async function handleConsentTokenFromSearchParams() {
            if (searchParams.has('callbackStatus') && searchParams.has('callbackType')) {
                // Common flow for any Open Banking payment type
                const callbackStatus = searchParams.get('callbackStatus')
                const callbackType = searchParams.get('callbackType')

                if (!isValidCallBackStatus(callbackStatus) || !isValidCallBackType(callbackType)) {
                    reportError(
                        `Error during Open Banking redirect handling - some of the params is wrong: "${JSON.stringify(
                            {
                                callbackStatus,
                                callbackType,
                            },
                        )}"`,
                    )
                    return
                }

                trackOpenBankingModal({
                    action: TrackOpenBankingTopUpAction.HANDLE_CONSENT_TOKEN_CALLBACK_STATUS,
                    callbackType,
                    callbackStatus,
                })
                showToastMessageWithStatus(showToast, callbackStatus, callbackType)
                clearOpenBankingSearchParams()

                if (callbackType === 'VRP') {
                    if (callbackStatus === 'SUCCESS') {
                        try {
                            setIsLoading(true)
                            await updateStoredAutoTopUpSettings({
                                onSuccess: () => {
                                    navigate('/wallet/auto-top-up-activation', {
                                        state: {vrpRedirectStatus: 'SUCCESS'},
                                    })
                                },
                            })
                        } catch (err: any) {
                            reportError(
                                err,
                                `Error while updating VRP Auto Top-up settings: "${err.message}"`,
                            )
                        } finally {
                            setIsLoading(false)
                        }
                    }
                    cleanSavedVrpAutoTopUpSettings()
                }
            } else if (searchParams.has('consent')) {
                // Instant Top-up flow (deprecated)
                // todo: remove this section when BE is ready with the new flow - https://linear.app/pleo/issue/WALLE-5059
                const consentToken = searchParams.get('consent')!
                const institutionId = searchParams.get('institution') || ''

                trackOpenBankingModal({
                    action: TrackOpenBankingTopUpAction.SEND_CONSENT_TOKEN,
                    tokenStage: 'initiate',
                    institutionId,
                })

                isPaymentRequestInProgressRef.current = true

                try {
                    await createOpenBankingPayment(user.companyId, consentToken)

                    trackOpenBankingModal({
                        action: TrackOpenBankingTopUpAction.SEND_CONSENT_TOKEN,
                        tokenStage: 'success',
                        institutionId,
                    })

                    showToastMessageWithStatus(showToast, 'SUCCESS', 'PAYMENT')
                } catch (err: any) {
                    reportError(err, `Error while creating Open Banking payment: "${err.message}"`)
                    showToastMessageWithStatus(showToast, 'ERROR', 'PAYMENT')

                    trackOpenBankingModal({
                        action: TrackOpenBankingTopUpAction.SEND_CONSENT_TOKEN,
                        tokenStage: 'fail',
                        institutionId,
                    })
                } finally {
                    clearOpenBankingSearchParams()
                    isPaymentRequestInProgressRef.current = false
                }
            }
        }

        handleConsentTokenFromSearchParams().catch(reportError)

        // don't provide showToast as dependency to eliminate a bunch of Effect calling
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, user.companyId, clearOpenBankingSearchParams])

    return {isLoading}
}

function useClearOpenBankingSearchParams() {
    const [searchParams, setSearchParams] = useSearchParams()

    return React.useMemo(
        () => ({
            clearOpenBankingSearchParams: () => {
                // Common flow for any Open Banking payment type
                searchParams.has('callbackStatus') && searchParams.delete('callbackStatus')
                searchParams.has('callbackType') && searchParams.delete('callbackType')

                // Instant Top-up
                searchParams.has('consent') && searchParams.delete('consent')

                searchParams.has('application-user-id') &&
                    searchParams.delete('application-user-id')
                searchParams.has('user-uuid') && searchParams.delete('user-uuid')
                searchParams.has('institution') && searchParams.delete('institution')

                // VRP flow
                searchParams.has('consentid') && searchParams.delete('consentid')
                searchParams.has('paymentType') && searchParams.delete('paymentType')

                setSearchParams(searchParams)
            },
        }),
        [searchParams, setSearchParams],
    )
}

function showToastMessageWithStatus(
    showToast: ToasterAPI['showToast'],
    status: OpenBankingCallBackStatus,
    type: OpenBankingCallBackType,
) {
    switch (type) {
        case 'PAYMENT': {
            if (status === 'SUCCESS') {
                showToast(t`Your Wallet Top-up was successful and will be ready for use soon`, {
                    title: t`Top-up Successful`,
                    level: 'success',
                })
            } else {
                showToast(
                    t`Your transfer didn't go through due to a timeout. Please try again later.`,
                    {
                        title: t`Your transfer was not successful`,
                        level: 'error',
                    },
                )
            }

            break
        }
        case 'VRP': {
            if (status === 'SUCCESS') {
                showToast(t`Your recurring Top-up was successfully authorized`, {
                    title: t`Success`,
                    level: 'success',
                })
            } else {
                showToast(t`There was an error while setting up your recurring Top-up`, {
                    title: t`VRP agreement failed`,
                    level: 'error',
                })
            }

            break
        }
    }
}
