import {Trans} from '@lingui/macro'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import type {FC} from 'react'
import React, {useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import type {DataAttributes} from 'styled-components'
import styled from 'styled-components'

import {
    Avatar,
    Box,
    Drawer,
    focusRing,
    Inline,
    NakedButton,
    Stack,
    Text,
    tokens,
    Tooltip,
} from '@pleo-io/telescope'
import {ChevronRight, CirclePlus, CreditCard, Logout, Swap, User} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import type {SwitchAccountPayload} from '@product-web/auth--accounts'
import {useLoggedInAccounts} from '@product-web/auth--accounts'
import {useAccessToken, useLogout} from '@product-web/auth--session/context'
import {getUniqueConnectedAccountEmails} from '@product-web/navigation/helpers'
import {customColorSchemeTokens} from '@product-web/styles/custom-tokens'
import {breakpoints} from '@product-web/styles/theme'
import {getCompany, getIsCompanyOwner, useUser} from '@product-web/user'
import {getFullName} from '@product-web/utils'
import {Keys, onEnterKey} from '@product-web/web-platform/keyboard'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {useAccountSwitchModal} from '@product-web-features/multi-entity/add-account/add-account'
import {
    CardReadyPopover,
    useCardReadyPopover,
} from '@product-web-features/onboarding/increase-awareness/card-ready-popover'

import NavigationAccountItem from './navigation-account-item'
import {Dropdown} from './navigation-dropdown'
import {MOBILE_NAVIGATION_DRAWER_ID, NavigationAvatarMobile} from './navigation-mobile'

import {useHasConnectedEmails} from '../helpers'
import {useInternalNavigationContext} from '../navigation-internal-provider'

export const NavigationAvatar: FC = () => {
    const user = useUser()
    const company = getCompany(user)
    const employee = user?.employee ?? user?.partnerEmployee
    const fullName = getFullName(employee)

    const {setIsEntitySwitcherVisible} = useInternalNavigationContext()
    const {accounts, switchAccount} = useLoggedInAccounts()

    const hasConnectedEmails = useHasConnectedEmails(accounts)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
    const currentAccessToken = useAccessToken()
    const activeAccount = accounts?.find((account) => account.accessToken === currentAccessToken)
    const isOwner = getIsCompanyOwner(user)
    const {open: openSwitchAccountModal} = useAccountSwitchModal()
    const navigate = useNavigate()
    const logout = useLogout()
    const {close: closeCardReadyPopover} = useCardReadyPopover()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const isMediumScreen = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    const textRef = useRef<HTMLInputElement | null>(null)
    const [isTextTruncated, setIsTextTruncated] = useState<boolean | null>(false)

    const [switchAccountTabIndex, setSwitchAccountTabIndex] = useState<number>(-1)
    const switchAccountMenuItemRef = useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        const isTextRefTruncated =
            !!textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth

        setIsTextTruncated(isTextRefTruncated)
    }, [])

    const navigateAndClosePopover = (path: string): void => {
        navigate(path)
        setIsOpen(false)
    }

    const onOpen = () => {
        setIsOpen(!isOpen)
        setIsOpenSubMenu(false)
        tracking.toggleAccountsMenu({
            variant: 'desktop',
        })
    }

    const openSubMenu = (event: unknown) => {
        const e = event as Event
        e.preventDefault()
        setIsOpenSubMenu(!isOpenSubMenu)

        if (!isOpenSubMenu) {
            // check so we aren't tracking when the user closes the menu
            tracking.switchAccountClicked({
                numberOfActiveAccounts: accounts?.length ?? 0,
            })
        }
    }
    const preventDefault = (event: unknown) => {
        const e = event as Event
        e.preventDefault()
    }

    const handleSwitchAccount = async (payload: SwitchAccountPayload) => {
        // when we are switching between accounts we have to close entity switcher panel
        // since we do not know yet whether we can show it for new account
        setIsEntitySwitcherVisible(false)
        await switchAccount(payload)
    }

    const uniqueConnectedAccountEmails = getUniqueConnectedAccountEmails(accounts)

    const loggedOutAccountList = uniqueConnectedAccountEmails.map((account) => {
        return (
            <React.Fragment key={account.email}>
                <NavigationAccountItem
                    email={account.email}
                    loggedOutAccount={account}
                    activeAccount={activeAccount}
                    switchAccount={handleSwitchAccount}
                />
            </React.Fragment>
        )
    })

    const accountsList = accounts.map((account) => {
        const isCurrentLoggedInAccount = account.accessToken === currentAccessToken
        return (
            <React.Fragment key={account.email}>
                <NavigationAccountItem
                    email={account.email}
                    accessToken={account.accessToken}
                    activeAccount={activeAccount}
                    isCurrentLoggedInAccount={isCurrentLoggedInAccount}
                    numberOfActiveAccounts={accounts?.length}
                    switchAccount={handleSwitchAccount}
                />
            </React.Fragment>
        )
    })

    const label = (
        <LabelWrapper tabIndex={0} open={isOpen}>
            <Box my={16} mr={8}>
                <Avatar size={24} name={fullName} src={user?.employee?.avatar?.url} />
            </Box>
            <Stack>
                <NoWrapFontWeightMedium>{employee?.firstName}</NoWrapFontWeightMedium>
                {hasConnectedEmails && (
                    <>
                        {isTextTruncated ? (
                            <Tooltip content={user?.organization?.name ?? company?.name}>
                                <NoWrap
                                    css={{color: tokens.colorContentInteractiveQuiet}}
                                    variant="small-subtle"
                                >
                                    {user?.organization?.name ?? company?.name}
                                </NoWrap>
                            </Tooltip>
                        ) : (
                            <NoWrap
                                ref={textRef}
                                css={{color: tokens.colorContentInteractiveQuiet}}
                                variant="small-subtle"
                            >
                                {user?.organization?.name ?? company?.name}
                            </NoWrap>
                        )}
                    </>
                )}
            </Stack>
        </LabelWrapper>
    )

    if (isMediumScreen) {
        return (
            <>
                <Drawer.Trigger drawerId={MOBILE_NAVIGATION_DRAWER_ID}>
                    <NavTrigger
                        onClick={async () => {
                            tracking.toggleAccountsMenu({
                                variant: 'mobile',
                            })
                            setIsDrawerOpen(true)
                        }}
                    >
                        <Avatar
                            size={40}
                            name={fullName}
                            src={user?.employee?.avatar?.url ?? undefined}
                        />
                    </NavTrigger>
                </Drawer.Trigger>
                <NavigationAvatarMobile
                    isOpen={isDrawerOpen}
                    onDismiss={() => setIsDrawerOpen(false)}
                    employee={employee}
                    company={company}
                    accounts={accounts}
                    hasConnectedEmails={hasConnectedEmails}
                    activeAccount={activeAccount}
                    isOwner={isOwner}
                    switchAccount={handleSwitchAccount}
                    onLogout={logout}
                />
            </>
        )
    }

    const handleSwitchAccountFocus = () => {
        setSwitchAccountTabIndex(0)
        setIsOpenSubMenu(false)
    }

    const handleSwitchAccountBlur = () => {
        setSwitchAccountTabIndex(-1)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            event.key === Keys.ENTER ||
            event.key === Keys.SPACE ||
            event.key === Keys.ARROW_RIGHT
        ) {
            openSubMenu(event)
        }

        const keyToPropertyMap: {[key: string]: 'nextElementSibling' | 'previousElementSibling'} = {
            [Keys.ARROW_DOWN]: 'nextElementSibling',
            [Keys.ARROW_UP]: 'previousElementSibling',
        }

        if ([Keys.ARROW_DOWN, Keys.ARROW_UP].includes(event.key)) {
            const getSiblingProperty = keyToPropertyMap[event.key]

            let el: HTMLElement = switchAccountMenuItemRef.current?.[
                getSiblingProperty
            ] as HTMLElement
            while (el && el.getAttribute('role') !== 'menuitem') {
                el = el[getSiblingProperty] as HTMLElement
            }

            if (el) {
                el.setAttribute('tabindex', '0')
                el.focus()
                event.preventDefault()
            }
        }
    }

    const switchAccountSubMenu = (
        <DropdownMenu.Root open={isOpenSubMenu}>
            <DropdownMenu.TriggerItem
                asChild
                onPointerMove={preventDefault}
                onPointerLeave={preventDefault}
                onClick={(event) => openSubMenu(event)}
                tabIndex={switchAccountTabIndex}
                onFocus={handleSwitchAccountFocus}
                onBlur={handleSwitchAccountBlur}
                onKeyDown={handleKeyDown}
            >
                <MenuItem
                    ref={switchAccountMenuItemRef}
                    data-testid="e2e-avatar-menu-switch-account"
                >
                    <Inline alignY={'center'} space={12}>
                        <Swap size={16} color={tokens.colorContentInteractiveQuiet} />
                        <MenutItemText>
                            <Trans>Switch account</Trans>
                        </MenutItemText>
                    </Inline>
                    <span>
                        <ChevronRight size={16} color={tokens.colorContentInteractiveQuiet} />
                    </span>
                </MenuItem>
            </DropdownMenu.TriggerItem>
            <SubMenuWrapper alignOffset={-76}>
                <SubMenuContent>
                    <AccountListWrapper>
                        {accountsList}
                        {loggedOutAccountList}
                    </AccountListWrapper>
                    <BoxWithTopBorder py={2} />
                    <MenuItem
                        onClick={() => {
                            tracking.addAccountClicked({
                                numberOfActiveAccounts: accounts?.length ?? 0,
                                context: 'account-menu',
                            })
                            openSwitchAccountModal()
                        }}
                    >
                        <Inline alignY={'center'} space={12}>
                            <CirclePlus size={16} color={tokens.colorContentInteractiveQuiet} />
                            <MenutItemText>
                                <Trans>Add account</Trans>
                            </MenutItemText>
                        </Inline>
                    </MenuItem>
                    <Box py={4} />
                </SubMenuContent>
            </SubMenuWrapper>
        </DropdownMenu.Root>
    )

    return (
        <Dropdown open={isOpen} onOpenChange={onOpen}>
            <CardReadyPopover enabled={isOwner && !isOpen && !isMediumScreen}>
                <Dropdown.Trigger asChild>{label}</Dropdown.Trigger>
            </CardReadyPopover>
            <Dropdown.Content side={'top'}>
                <MenuWrapper data-testid="avatar-menu">
                    <Box py={4} />
                    {hasConnectedEmails ? (
                        <>
                            <MenuItem
                                data-testid="logout-link"
                                onClick={async () => {
                                    tracking.logOutClicked({
                                        numberOfActiveAccounts: accounts?.length ?? 0,
                                    })
                                    await logout()
                                }}
                            >
                                <Inline alignY={'center'} space={10}>
                                    <Logout size={16} color={tokens.colorContentInteractiveQuiet} />
                                    <MenutItemText>
                                        <Trans>Logout of all accounts</Trans>
                                    </MenutItemText>
                                </Inline>
                            </MenuItem>

                            {switchAccountSubMenu}
                        </>
                    ) : (
                        <>
                            <MenuItem
                                data-testid="logout-link"
                                onClick={async () => {
                                    tracking.logOutClicked({
                                        numberOfActiveAccounts: accounts?.length ?? 0,
                                    })
                                    await logout()
                                }}
                            >
                                <Inline alignY={'center'} space={10}>
                                    <Logout size={16} color={tokens.colorContentInteractiveQuiet} />
                                    <MenutItemText>
                                        <Trans>Logout</Trans>
                                    </MenutItemText>
                                </Inline>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    tracking.addAccountClicked({
                                        numberOfActiveAccounts: accounts?.length ?? 0,
                                        context: 'account-menu',
                                    })
                                    openSwitchAccountModal()
                                }}
                                onKeyDown={onEnterKey(() => openSwitchAccountModal())}
                                data-testid="e2e-avatar-menu-add-account"
                            >
                                <Inline alignY={'center'} space={10}>
                                    <CirclePlus
                                        size={16}
                                        color={tokens.colorContentInteractiveQuiet}
                                    />
                                    <MenutItemText>
                                        <Trans>Add account</Trans>
                                    </MenutItemText>
                                </Inline>
                            </MenuItem>
                        </>
                    )}

                    <Box py={2} />
                    <BoxWithTopBorder py={2} />
                    {isOwner && (
                        <CardReadyPopover enabled={isOpen && !isMediumScreen}>
                            <MenuItem
                                onClick={() => {
                                    closeCardReadyPopover()
                                    tracking.myCardsClicked({
                                        variant: 'desktop',
                                    })
                                    navigateAndClosePopover('/account/cards')
                                }}
                            >
                                <Inline alignY={'center'} space={10}>
                                    <CreditCard
                                        size={16}
                                        color={tokens.colorContentInteractiveQuiet}
                                    />
                                    <MenutItemText>
                                        <Trans>My cards</Trans>
                                    </MenutItemText>
                                </Inline>
                            </MenuItem>
                        </CardReadyPopover>
                    )}

                    <MenuItem
                        onClick={() => {
                            tracking.myAccountClicked({
                                variant: 'desktop',
                            })
                            navigateAndClosePopover('/account/profile')
                        }}
                    >
                        <Inline alignY={'center'} space={10}>
                            <User size={16} color={tokens.colorContentInteractiveQuiet} />
                            <MenutItemText>
                                <Trans>My account</Trans>
                            </MenutItemText>
                        </Inline>
                    </MenuItem>
                    <Box py={4} />
                </MenuWrapper>
            </Dropdown.Content>
        </Dropdown>
    )
}

interface LabelWrapperProps {
    open: boolean
}

const LabelWrapper = styled(NakedButton).attrs<DataAttributes>({
    'data-testid': 'nav-avatar',
})<LabelWrapperProps>`
    text-align: left;
    display: flex;
    align-items: center;
    width: 228px;
    padding: 0 ${tokens.spacing16};
    margin: 0;
    box-sizing: border-box;
    border-radius: ${tokens.arc8};
    background-color: ${(props) =>
        props.open
            ? tokens.colorBackgroundInteractive
            : customColorSchemeTokens.colorBackgroundEntitySwitcher};
    ${focusRing('regular')}

    &:hover {
        background-color: ${tokens.colorBackgroundInteractive};
    }
`

const NavTrigger = styled(NakedButton)`
    margin: ${tokens.spacing16} ${tokens.spacing8} ${tokens.spacing16} 0;
`

export const NoWrapFontWeightMedium = styled(Text).attrs({
    variant: 'medium-default',
    color: 'colorContentInteractive',
    as: 'p',
    truncate: true,
})`
    font-weight: ${tokens.fontWeightMedium};
    width: 168px;
`

export const NoWrap = styled(Text).attrs({as: 'p', truncate: true})`
    width: 168px;
`

const MenuWrapper = styled.div`
    box-shadow: ${tokens.shadowElevate};
    border-radius: ${tokens.arc8};
    width: 228px;
`
const SubMenuWrapper = styled(Dropdown.Content)`
    margin-left: ${tokens.spacing2};
    box-shadow: ${tokens.shadowElevate};
`
const SubMenuContent = styled.div`
    border-radius: ${tokens.arc8};
    width: 280px;
    background-color: ${tokens.colorBackgroundStatic};
`

const AccountListWrapper = styled.div`
    padding-top: ${tokens.spacing8};
    padding-bottom: ${tokens.spacing4};
    max-height: 309px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
`

interface MenuItemProps {
    focus?: boolean
    $topBorder?: boolean
    $topPadding?: boolean
    $bottomPadding?: boolean
}

const MenuItem = styled(Dropdown.Item)<MenuItemProps>`
    ${focusRing('inset')}
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight3};
    padding: ${tokens.spacing8} ${tokens.spacing12} ${tokens.spacing8} ${tokens.spacing20};
    background: ${(props) =>
        props.focus ? tokens.colorBackgroundInteractiveHover : tokens.colorBackgroundInteractive};
    border-top: ${(props) => (props.$topBorder ? tokens.borderInteractiveQuiet : '')};
    color: ${tokens.colorContentInteractive};
    text-align: left;
    box-sizing: border-box;
    background-color: transparent;
    transition-property: background-color;

    span {
        transition: ${tokens.smoothInOut};
    }

    &:hover {
        background: ${tokens.colorBackgroundInteractiveQuietHover};
        color: ${tokens.colorContentInteractiveHover};

        svg {
            fill: ${tokens.colorContentInteractiveHover};
        }

        span {
            transform: translateX(5px);
        }
    }

    cursor: pointer;
    outline: none;
`
const MenutItemText = styled.div`
    width: auto;
    overflow: hidden;
`

const BoxWithTopBorder = styled(Box)`
    border-top: ${tokens.borderStatic};
`
