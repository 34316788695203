import {useFeatureCountryConfig} from '@product-web/country-configuration/configuration'
import {Feature} from '@product-web/country-configuration/types'
import {useCompanyEntitlementsLimits} from '@product-web/entitlement/use-entitlement-limits'
import {useCompanyFeatureEntitlement} from '@product-web/entitlement/use-feature-entitlement'
import {useFlags, useFlagsLoaded} from '@product-web/flags'

export const useVendorCard = () => {
    const {subsPricing30} = useFlags()
    const isFlagLoaded = useFlagsLoaded()

    const vendorCardEntitlement = subsPricing30 ? 'VENDOR_CARDS' : 'DEDICATED_CARDS'
    const {isEntitled, isLoading: isEntitlementLoading} =
        useCompanyFeatureEntitlement(vendorCardEntitlement)

    const {limits: {maxAllowedVendorCards} = {}} = useCompanyEntitlementsLimits()

    // Check the country config to see if the feature is enabled for the country of the company
    // Vendor cards is not available for the USA
    const {canUseVendorCards: canUseVendorCardInUserCountry} = useFeatureCountryConfig(
        Feature.VendorCards,
    )

    const {isEntitled: isVendorLockEnabled} =
        useCompanyFeatureEntitlement('VENDOR_CARD_VENDOR_LOCK')

    const {isEntitled: isVendorCardDefaultAccountingEnabled} = useCompanyFeatureEntitlement(
        'VENDOR_CARD_DEFAULT_ACCOUNTING_VALUES',
    )

    const canUseVendorCards = isEntitled && canUseVendorCardInUserCountry

    const isEntitledToVendorLockFeature = subsPricing30 ? isVendorLockEnabled : true
    const isEntitledToVendorCardDefaultAccountingFeature = subsPricing30
        ? isVendorCardDefaultAccountingEnabled
        : true

    const isLoading = isEntitlementLoading && !isFlagLoaded

    return {
        isLoading,
        isEntitled,
        canUseVendorCards,
        maxAllowedVendorCards,
        canUseVendorCardInUserCountry,
        isEntitledToVendorLockFeature,
        isEntitledToVendorCardDefaultAccountingFeature,
    }
}
