import {Trans} from '@lingui/macro'

import {Badge, Box, Button, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {CheckCircleFilled} from '@pleo-io/telescope-icons'

import {PlanTypeName} from '@product-web/api-types/billing'
import type {CpqBillingPeriodType, CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'

export interface RecommendedPlanInfoProps {
    billingPeriodType: CpqBillingPeriodType
    recommendedPlan: CpqRatePlanType
    onClickComparePlans: () => void
    benefits: string[]
}

export const RecommendedPlanInfo = ({
    billingPeriodType,
    recommendedPlan,
    onClickComparePlans,
    benefits,
}: RecommendedPlanInfoProps) => {
    // flag for annual billing text if recommended plan is not STARTER.
    const shouldDisplayBillingPeriodText = recommendedPlan !== 'STARTER'

    return (
        <Card padding={32} space={24}>
            <Inline justifyContent="space-between">
                <Stack space={24}>
                    <Badge variant="discover">
                        <Trans>Recommendation</Trans>
                    </Badge>
                    <Stack space={8}>
                        <Text weight="regular" variant="4xlarge-accent">
                            {PlanTypeName[recommendedPlan]}
                        </Text>
                        {shouldDisplayBillingPeriodText && (
                            <Text weight="regular" variant="large-accent">
                                {billingPeriodType === 'YEAR' ? (
                                    <Trans>Annual billing</Trans>
                                ) : (
                                    <Trans>Monthly billing</Trans>
                                )}
                            </Text>
                        )}
                    </Stack>
                </Stack>
                <Box>
                    <Button variant="secondary" onClick={onClickComparePlans}>
                        <Trans>Compare all plans</Trans>
                    </Button>
                </Box>
            </Inline>
            <Card.Divider />
            <Stack>
                {benefits.map((benefit) => (
                    <Inline key={benefit} space={8}>
                        <CheckCircleFilled color={tokens.colorContentPositive} />
                        <Text weight="medium" color="colorContentPositive">
                            {benefit}
                        </Text>
                    </Inline>
                ))}
            </Stack>
        </Card>
    )
}
