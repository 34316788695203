import Locale from '@product-web/locale/locale'

import type {ICountryConfiguration} from './types'

import {Terms} from '../features/terms/types'
import {Feature} from '../types'

const IE: ICountryConfiguration = {
    [Feature.Terms]: {
        [Terms.Documents]: {
            masterServiceAgreement: {
                [Locale.en_GB]: 'pleo-master-service-agreement-uk.pdf',
            },
        },
    },
}

export default IE
