import type {Option} from 'react-select'

import type {AccountingAccount} from '@pleo-io/deimos'

import type {SupportedLanguage} from '@product-web/i18n'
import type Country from '@product-web/locale/country'
import type {HelpCentreArticle, HelpCentreFolder} from '@product-web/locale/helpers'

export interface TaxCodeRequest {
    name: string
    rate: number
    enabled?: boolean
    type?: 'reverse' | 'normal'
    code?: string
    ingoingTaxAccount?: string
    outgoingTaxAccount?: string
}

export interface TaxCode extends TaxCodeRequest {
    companyId: string
    id: string
    type?: 'reverse' | 'normal'
    ingoingTaxAccount?: string
    outgoingTaxAccount?: string
    createdAt: string
    updatedAt: string
}

export interface TaxCodeOption {
    hidden: boolean
    disabled: boolean
    value: string
    label: string
    groupId?: string
    groupLabel: boolean
}

/**
 * When updating the AccountingSystem below, make sure to also update
 * features/bill-invoices/backend/invoices.bff.ts
 *
 * It can't be imported due to do-not-use-client-imports-in-server-context rule
 */
export enum AccountingSystem {
    ACD = 'acd',
    BILLY_DIRECT = 'billy_direct',
    BILLY = 'billy',
    BMD = 'bmd',
    BUSINESSCENTRAL = 'businesscentral',
    BUSINESSCENTRAL_NEW = 'businesscentral_new',
    CEGIDLOOP = 'cegidloop',
    CUSTOM = 'custom',
    CUSTOM_API_INTEGRATION = 'custom_api_integration',
    DATEV = 'datev',
    DATEV_DIRECT = 'datev_direct',
    DINERO_DIRECT = 'dinero_direct',
    DYNAMICS_365_FINANCE = 'dynamics_365_finance',
    ECONOMIC = 'economic',
    ELO_DIGITAL_OFFICE = 'elo_digital_office',
    EXACT = 'exact',
    FORTNOX = 'fortnox',
    GENERIC = 'generic',
    HOLDED = 'holded',
    HOLDED_DIRECT = 'holded_direct',
    INVENTIO = 'inventio',
    LEXOFFICE = 'lexoffice',
    MYUNISOFT = 'myunisoft',
    NETSUITE = 'netsuite',
    NETVISOR = 'netvisor',
    NONE = 'none',
    ODOO = 'odoo',
    PENNYLANE = 'pennylane',
    PROCOUNTOR = 'procountor',
    QUICKBOOKS = 'quickbooks',
    QUICKBOOKS_DIRECT = 'quickbooks_direct',
    SAGE_ACTIVE = 'sage_active',
    SAGE_FIFTY = 'sage50',
    SAGE_ONE = 'sage_one',
    SAGE_100_FRANCE = 'sage_100_france',
    SAGE_INTACCT = 'sage_intacct',
    SAP_BUSINESS_ONE = 'sap_business_one',
    SAP_S4HANA = 'sap_s4hana',
    SIE = 'sie',
    SIE_MARATHON = 'sie_marathon',
    TEST_INTEGRATION = 'test_integration',
    TWINFIELD = 'twinfield',
    UNICONTA = 'uniconta',
    VISMA = 'visma',
    ECONOMIC_V2 = 'economic_v2',
    VISMADOTNET = 'vismadotnet',
    XERO = 'xero',
    XLEDGER = 'xledger',
}

export enum SystemName {
    ACD = 'ACD',
    BILLY = 'Billy CSV',
    BILLY_DIRECT = 'Billy Online',
    BMD = 'BMD CSV',
    BUSINESSCENTRAL = 'Microsoft Dynamics 365 Business Central',
    BUSINESSCENTRAL_NEW = 'Microsoft Business Central (New)',
    CEGIDLOOP = 'Cegid Loop',
    CUSTOM = 'Custom format',
    CUSTOM_API_INTEGRATION = 'Custom API Integration',
    DATEV = 'Datev CSV',
    DATEV_DIRECT = 'Datev Unternehmen online',
    DINERO = 'Dinero CSV',
    DINERO_DIRECT = 'Dinero Online',
    DYNAMICS_365_FINANCE = 'Microsoft Dynamics 365 Finance',
    ECONOMIC = 'Visma e-conomic',
    ELO_DIGITAL_OFFICE = 'Elo ECM',
    EXACT = 'ExactOnline.nl',
    FORTNOX = 'Fortnox',
    FREEAGENT = 'Freeagent',
    GENERIC = 'Generic format',
    HOLDED = 'Holded CSV',
    HOLDED_DIRECT = 'Holded Online',
    INVENTIO = 'Inventio',
    LEXOFFICE = 'lexoffice',
    MYUNISOFT = 'MyUnisoft',
    NAVISION = 'Dynamics NAV',
    NETSUITE = 'NetSuite',
    NETVISOR = 'Netvisor',
    ODOO = 'Odoo',
    PENNYLANE = 'Pennylane',
    PROCOUNTOR = 'Procountor',
    QUICKBOOKS = 'QuickBooks CSV',
    QUICKBOOKS_DIRECT = 'QuickBooks Online',
    SAGE_FIFTY = 'Sage 50 Desktop (UK)',
    SAGE_ONE = 'Sage Accounting (UK)',
    SAGE_100_FRANCE = 'Sage 100 (FR)',
    SAGE_TWO_HUNDRED = 'Sage 200 Cloud (UK)',
    SAGE_ACTIVE = 'Sage Active',
    SAGE_INTACCT = 'Sage Intacct',
    SAP_BUSINESS_ONE = 'SAP Business One',
    SAP_S4HANA = 'SAP S/4HANA',
    SIE = 'SIE',
    SIE_MARATHON = 'Marathon',
    TIMELOG = 'TimeLog',
    TEST_INTEGRATION = 'Partner Integration',
    TWINFIELD = 'Twinfield',
    UNICONTA_LEGACY = 'Uniconta Legacy',
    UNICONTA = 'Uniconta',
    VISMA = 'Visma eEkonomi',
    VISMADOTNET = 'Visma.NET',
    ECONOMIC_V2 = 'Visma economic',
    XERO = 'Xero',
    XLEDGER = 'Xledger',
}

export enum ExportFeature {
    POCKET = 'pocket',
    REPRESENTATION = 'representation',
    REVERSE = 'reverse',
    BILL_INVOICE = 'billInvoice',
}

export enum RepresentationFeatureProperty {
    DEDUCTIBLE_COST_ACCOUNT = 'deductibleCostAccount',
    NON_DEDUCTIBLE_ACCOUNT = 'nonDeductibleAccount',
    INTERNAL_DEDUCTIBLE_COST_ACCOUNT = 'internalDeductibleCostAccount',
    INTERNAL_NON_DEDUCTIBLE_ACCOUNT = 'internalNonDeductibleAccount',
}

export enum ReverseFeatureProperty {
    DEFAULT_INGOING_TAX_ACCOUNT = 'defaultIngoingTaxAccount',
}

export enum PocketFeatureProperty {
    EMPLOYEE_CONTRA_ACCOUNT = 'employeeContraAccount',
    EMPLOYEE_CASHBOOK = 'employeeCashbook',
    TRANSITION_ACCOUNT = 'defaultContraAccount',
}

export enum BillInvoiceFeatureProperty {
    FULL_LIST_OF_ACCOUNTS = 'fullListOfAccounts',
    SUPPLIER = 'supplier',
    EXPORTABLE_ONLY_PAID = 'exportableOnlyPaid',
    SHOW_EXPORT_IN_BILLS_DASHBOARD = 'showExportInBillsDashboard',
    EXPORT_CAN_SYNC = 'exportCanSync',
}

export interface FeatureToggleProperty {
    name: string
    required: boolean
    countries?: Country[]
}

type FeatureToggleProperties = FeatureToggleProperty[]

type SupportedFeatures = {
    [key in ExportFeature]?: FeatureToggleProperties
}

type costCenterTagGroup = {
    title: () => string
    description: () => string
    helpArticleUrl?: string
    codeColumn: boolean
    nameColumn: boolean
}

export type AccountingSettingsCostCenterTagGroup = {
    tagGroupId: string
    codeColumnId: string
    nameColumnId: string
}
export type System = AccountingSystem | string

export function isAccountingPreferenceKey(
    preferences: AccountingPreferences,
    key: string,
): key is AccountingPreferenceKey {
    return Object.keys(preferences).includes(key)
}

type AccountingPreferenceKey = keyof AccountingPreferences

export interface AccountingPreferences {
    direct: boolean
    system: System
    defaultContraAccount?: string // Default reimbursement account
    reverse?: boolean
    cashbook?: string
    employeeCashbook?: string
    defaultIngoingTaxAccount?: string
    deductibleCostAccount?: string
    nonDeductibleAccount?: string
    internalDeductibleCostAccount?: string
    internalNonDeductibleAccount?: string
    contraAccount?: string
    employeeContraAccount?: string
    invoicesPaymentContraAccount?: string
    invoicesPaymentJournal?: string
    timelog?: boolean
    verificationSeries?: string | null
    pdfReceipts: boolean
    defaultOutgoingTaxAccount?: string
    bankFeed?: boolean
    error?: string
    exportFormatId?: string | null
    consultantNumber?: number | null
    clientNumber?: number | null
    fiscalYearMonthStart?: number | null
    exportManualTransfers?: boolean
    // Deimos code that shows which systems includeLoads https://github.com/pleo-io/deimos/blob/master/src/lib/services/settings/company/reducers/include-loads-reducer.ts#L7
    includeLoads?: boolean
    // Used to maintain the state of the accounting system, this is maintained by the call to the checks endpoint on the microservice
    accountingSystemSetupIsValid?: boolean
    holdedPocketContactId?: string
    useCarriageReturn: boolean | null
    costCenter1TagGroup?: AccountingSettingsCostCenterTagGroup
    costCenter2TagGroup?: AccountingSettingsCostCenterTagGroup
    booksymbol?: string
    extendedAccountingSystemSettings?: Record<string, string | null>
}

export interface AccountingSystemDetails {
    value: string
    name: string
    csv?: boolean
    accessLevel?: 'format' | 'minimal' | 'read' | 'readwrite'
    contraAccount?: boolean
    employeeContraAccount?: boolean
    invoicesPaymentContraAccount?: boolean
    hidden?: boolean
    cashbook?: boolean
    employeeCashbook?: boolean
    direct?: boolean
    custom?: boolean
    reverse?: boolean
    reverseWithCode?: boolean
    doesNotSupportTaxCodes?: boolean
    supportsGenericVat?: boolean
    featureFlagged?: boolean
    countries?: Country[]
    noReverse?: boolean
    verificationSeries?: boolean
    hasAccountDefaultTaxCode?: boolean
    consultantNumber?: boolean
    clientNumber?: boolean
    fiscalYear?: boolean
    hasExternalAccountId?: boolean
    hasExternalAccounts?: boolean
    hasCashbookAccounts?: boolean
    useCashbooksOptionsAsEmployeeContraAccount?: boolean
    supportedFeatures?: SupportedFeatures
    directSystemValue?: string
    /** If this integration uses API key authentication */
    apiKeyIntegration?: boolean
    /**
     * Some integrations refer to this as something different, might not be API Key, might be Ref ID or token value.
     * This field allows us to specify the name that we can contextually use in the UI
     */
    apiKeySystemContextName?: string
    /** List of UI elements that specify the instructions on how to get the API key. */
    apiKeyIntegrationHelpInstructions?: JSX.Element[]
    /**
     * Help link that displayed in API key modal footer
     * */
    apiKeyIntegrationHelpFooter?: JSX.Element
    /** Show normal tax table with tax type column */
    normalTaxTableWithType?: boolean | Country[]
    costCenter1TagGroup?: costCenterTagGroup
    costCenter2TagGroup?: costCenterTagGroup
    /** Set to true if this integration supports tags */
    supportsTags?: boolean
    /** This is used in combination with the supportsTags flag. This is the description of tag segments/groups,
     * e.g. Departments and Cost Types OR Classes and Projects, etc.
     */
    tagsPromptDescription?: string
    booksymbol?: boolean
    helpCentre?: {
        /**
         * Collection ID to the Help centre collection that holds the troubleshooting info
         * e.g., '3434763-troubleshooting-xero' points to
         * https://help.pleo.io/en/collections/3434763-troubleshooting-xero
         */
        folderId?: HelpCentreFolder
        /**
         * Article ID to the Help centre that holds the troubleshooting info
         * e.g., '8662142-troubleshooting-guide-to-netsuite' points to
         * https://help.pleo.io/en/articles/8662142-troubleshooting-guide-to-netsuite
         */
        articleId?: HelpCentreArticle
        /**
         * List of languages in which the help centre article is available
         * defaults to en (which can be skipped in the list)
         */
        supportedLanguages?: SupportedLanguage[]
    }
    /** If this integration is an External API */
    isExternalApi?: boolean
    /** If this integration uses the Export Dispatcher */
    isExportDispatcherIntegration?: boolean
    /** Text to go under the system name on the accounting system selection banner */
    subtitle?: string
    /** Configuration of the system residing externally like Netsuite, Test integration */
    externalConfig?: {
        oauthUrl?: string
        configUrl?: string
    }
}

export type AccountingSystemOption = Option & {
    label: string
    value: string
    data: Partial<AccountingSystemDetails>
}

export interface ExportFormat {
    value: string
    name: string
    label: string
    formats: any[]
    subtitle?: string
}

export type ExportFormatOption = Option & {
    name: string
    label: string
    value: string
    subtitle?: string
    data: Partial<ExportFormat>
}

export interface AccountOption {
    label: string
    value: string
    data: AccountingAccount
}

export interface ExternalCompany {
    id: string
    name: string
    selected: boolean
}

export interface ExternalCompanyOption {
    label: string
    value: string
    data: ExternalCompany
}

export enum Flows {
    ONBOARDING = 'onboarding',
    SETTINGS = 'settings',
}
