import * as React from 'react'
import {Route} from 'react-router-dom'

const DownloadMobile = React.lazy(
    async () => import('./screens/download-app-mobile/download.mobile'),
)
const DownloadDesktop = React.lazy(
    async () => import('./screens/download-app-desktop/download.desktop'),
)
const GetStartedPage = React.lazy(async () => import('./screens/get-started/startup'))

export const routes = (
    <>
        <Route
            index
            element={<GetStartedPage />}
            handle={{auth: true, allowedRoles: ['member', 'owner']}}
        />
    </>
)

export const downloadRoutes = (
    <>
        <Route index element={<DownloadDesktop />} />
        <Route path="mobile" element={<DownloadMobile />} />
    </>
)
