import React from 'react'

export function useKeyPress(
    targetKey: string | null,
    callback: (ev: WindowEventMap['keydown']) => any,
) {
    const handler = (event: WindowEventMap['keydown']) => {
        if (targetKey === null || event.key === targetKey) {
            callback(event)
        }
    }
    React.useEffect(() => {
        window.addEventListener('keydown', handler)
        return () => {
            window.removeEventListener('keydown', handler)
        }
    }, [handler])
}
