import {openClientSession} from '@product-web/auth--client-session'
import {forceSessionRefresh} from '@product-web/auth--session/operations'
import {useUserMutations} from '@product-web/user'

/**
 * This hook returns a method that updates the underlying session with everything needed to access the selected company.
 * After having called `await switchClient({companyId})` the user can be safely navigated to the selected client's Pleo account.
 */

export function usePartnerClientSession() {
    const {forceRefetchUser} = useUserMutations()

    const switchClient = async (companyId: string) => {
        openClientSession(companyId)
        await forceSessionRefresh()
        await forceRefetchUser({companyId})
    }

    return {switchClient}
}
