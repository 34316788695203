import {t, Trans} from '@lingui/macro'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {Button, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, CreditCard} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {reportError} from '@product-web/error/report'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {isNonNullable} from '@product-web/utils'

import {DownloadMobileAppCard} from './download-mobile-app-card'
import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import {getMilestoneProgress} from '../lib/get-milestone-progress'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

const MOBILE_BREAKPOINT = pxBreakpoints.mediumTabletUp

type TryCardsMilestoneAccordionItemProps = {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
}

export const TryCardsMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
}: TryCardsMilestoneAccordionItemProps) => {
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()

    const tryCardsTasks: React.ComponentProps<typeof MilestoneAccordion.Tasks>['tasks'] = tasks
        .map((task) => {
            switch (task.name) {
                case 'ACTIVATE_CARD':
                    return {
                        heading: t`Activate your virtual card`,
                        status: task.status,
                        cta: (
                            <Button
                                variant="primary"
                                as={RouterLink}
                                to="/account/cards"
                                IconRight={ArrowRight}
                                onClick={() => {
                                    startTask(task.name)
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone: milestoneName,
                                        task: task.name,
                                    })
                                }}
                            >
                                <Trans>Continue</Trans>
                            </Button>
                        ),
                    }

                case 'DOWNLOAD_APP':
                    return {
                        heading: t`Get the mobile app`,
                        children: (
                            <Stack mt={12}>
                                <DownloadMobileAppCard />
                            </Stack>
                        ),
                        status: task.status,
                        stretch: true,
                    }

                case 'BUY_SOMETHING':
                    return {
                        heading: t`Buy something for work`,
                        children: (
                            <Stack space={16} mt={12}>
                                <InstructionSteps>
                                    <InstructionStep>
                                        <Text as="span" weight="medium">
                                            1.
                                        </Text>

                                        <Text as="span">
                                            <Trans>
                                                Add your card to Apple or Google Wallet to pay
                                                contactless.
                                            </Trans>
                                        </Text>
                                    </InstructionStep>

                                    <InstructionStep>
                                        <Text as="span" weight="medium">
                                            2.
                                        </Text>

                                        <Text as="span">
                                            <Trans>
                                                Use the app to snap a picture of your receipt.
                                            </Trans>
                                        </Text>
                                    </InstructionStep>
                                </InstructionSteps>

                                <Text>
                                    <Trans>
                                        If you like, you can{' '}
                                        <Link as={RouterLink} to="/people/cards/order">
                                            order a physical card
                                        </Link>{' '}
                                        instead.
                                    </Trans>
                                </Text>
                            </Stack>
                        ),
                        status: task.status,
                        stretch: true,
                    }

                default: {
                    reportError('Invalid milestone task name', null, {
                        milestoneName,
                        taskName: task.name,
                    })

                    return null
                }
            }
        })
        .filter(isNonNullable)

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Make a card payment`}
                Icon={CreditCard}
                progress={getMilestoneProgress(tryCardsTasks)}
            />

            <MilestoneAccordion.Content>
                <MilestoneAccordion.Tasks tasks={tryCardsTasks} />
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}

const InstructionSteps = styled.ol`
    display: flex;
    flex-direction: column;
    row-gap: ${tokens.spacing16};
    width: 100%;
`

const InstructionStep = styled.li`
    display: flex;
    column-gap: ${tokens.spacing8};
    padding: ${tokens.spacing16};
    border-radius: ${tokens.arc8};
    border: ${tokens.borderPrimary};

    ${containerQuery(
        {name: 'main', minWidth: MOBILE_BREAKPOINT},
        css`
            column-gap: ${tokens.spacing16};
            padding: ${tokens.spacing24};
        `,
    )}
`
