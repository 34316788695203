import type * as RadixAccordion from '@radix-ui/react-accordion'
import {useMemo, useState} from 'react'

import type {AccordionItemProps} from './accordion'
import {Accordion} from './accordion'

type AccordionStateReturn = {
    expanded: boolean
    toggleExpanded: () => void
}

export const useSingleAccordionState = (defaultExpanded = false): AccordionStateReturn => {
    const [expanded, setExpanded] = useState(defaultExpanded)

    return useMemo(
        () => ({
            expanded,
            toggleExpanded: () => {
                setExpanded((value) => !value)
            },
        }),
        [expanded],
    )
}

type SingleAccordionProps = Omit<RadixAccordion.AccordionSingleProps, 'type'> &
    Pick<AccordionItemProps, 'disabled'> &
    Partial<AccordionStateReturn> & {
        // Only used in uncontrolled mode
        defaultExpanded?: boolean
    }

/**
 * Custom Accordion component with simplified API and support for useAccordionState hook
 * The component is used in places where only a single accordion is being displayed
 */

/**
 * @deprecated Use '@pleo-io/telescope' Accordion
 */
export const SingleItemAccordion = ({
    disabled,
    defaultExpanded,
    ...props
}: SingleAccordionProps) => {
    // only used to control expanded state
    const itemId = 'single-item'

    // check whether to handle state via props or local state
    const defaultState = useSingleAccordionState(defaultExpanded)
    const {expanded, toggleExpanded} = useMemo(() => {
        if (props.expanded !== undefined) {
            return props
        }
        return defaultState
    }, [defaultState, props])

    return (
        <Accordion
            {...props}
            type="single"
            value={expanded ? itemId : ''}
            onValueChange={toggleExpanded}
        >
            <Accordion.Item value={itemId} disabled={disabled}>
                {props.children}
            </Accordion.Item>
        </Accordion>
    )
}
