import {useEffect, useState} from 'react'

import {useLoggedInUser} from '@product-web/user'

import {createBffHooks} from '../../../shared/bff-client'
import type {APIKeysRouter} from '../bff/index.bff'
import type {APIKeysAPIContext, APIKeysContext} from '../contexts/api-keys'
import {type APIKey, type APIKeyInput, fromApiKeyModel, intoCreateApiKeyInput} from '../models'

type FullContext = {
    readonly data: APIKeysContext
    readonly actions: APIKeysAPIContext
}

function useApiKeys() {
    const [apiKeys, setApiKeys] = useState<APIKey[]>([])

    const {companyId} = useLoggedInUser()
    const {data, isLoading} = bff.getAPIKeys.useQuery(companyId!)

    useEffect(() => {
        setApiKeys(data?.map(fromApiKeyModel) ?? [])
    }, [data])

    return {apiKeys, isLoading}
}

export function useAPIKeysBackend(): FullContext {
    const {apiKeys, isLoading} = useApiKeys()

    const createMutation = bff.createAPIKey.useMutation()
    const deleteMutation = bff.deleteAPIKey.useMutation()

    const create = async (apiKeyInput: APIKeyInput): Promise<APIKey> => {
        const res = await createMutation.mutateAsync(intoCreateApiKeyInput(apiKeyInput))
        return fromApiKeyModel(res)
    }

    const deleteKey = async (id: string) => {
        await deleteMutation.mutateAsync(id)
    }

    return {
        data: {
            apiKeys,
            isLoading,
            isSaving: createMutation.isLoading,
            isDeleting: deleteMutation.isLoading,
        },
        actions: {create, deleteKey},
    }
}

const bff = createBffHooks<APIKeysRouter>().apiKeys
