import {t} from '@lingui/macro'

import {sessionStorage} from '@product-web/web-platform/storage/session'

const key = 'promoMessageIndex'

function getNextMessageIndex() {
    const messageCount = getAllMessages().length
    const storedValue = sessionStorage.getItem(key)
    const currentIndex = Number(storedValue) || 0
    const hasExistingValue = storedValue !== null
    return hasExistingValue ? (currentIndex + 1) % messageCount : 0
}

function saveMessageIndex(index: number) {
    sessionStorage.setItem(key, index.toString())
}

export function getAllMessages() {
    return [
        t`Stay in control of your finances with analytics and spend limits.`,
        t`Simplify your reimbursements, mileage and more - all on Pleo.`,
        t`Save time on paperwork when more employees use Pleo.`,
    ]
}

function getMessageByIndex(index: number) {
    return getAllMessages()[index] || getAllMessages()[0]
}

export function getMessage() {
    const newIndex = getNextMessageIndex()
    saveMessageIndex(newIndex)
    return getMessageByIndex(newIndex)
}
