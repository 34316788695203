import {t} from '@lingui/macro'

import type {ApplicationWithInstallationResponse} from '@product-web/bff-moons/generated/endymion'

type Provider = Pick<ApplicationWithInstallationResponse, 'name' | 'description'>

export const getSamlSsoLocalisedProviderInfo = (id: string): Provider => {
    switch (id) {
        case 'okta':
            return {
                description: t`Identity management platform for streamlined integration`,
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                name: 'Okta',
            }
        case 'entra':
            return {
                description: t`Previously Azure Active Directory`,
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                name: 'Microsoft Entra',
            }
        case 'custom':
            return {
                description: t`Set up custom connection with the Identity provider of your choice`,
                name: t`Custom setup`,
            }
        default:
            throw new Error(`Unknown SAML SSO provider: ${id}`)
    }
}
