import qs from 'qs'
import useSWR from 'swr'

import type {ProviderName} from '@pleo-io/deimos'
import {CardStatus, CardType} from '@pleo-io/deimos'

import {callApi, request} from '@product-web/api'
import {RequestScope} from '@product-web/api'
import type {EncryptedCardDetails} from '@product-web/api-types/card-details'
import config from '@product-web/config'
import {useCompanyUser} from '@product-web/user'

import type {Employee} from './employee'
import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

export enum CanChangePin {
    NEVER = 'NEVER',
    ATM = 'ATM',
    APP = 'APP',
}

export interface CompanyCard {
    companyId: string
    embossedName: string | null
    employee: Employee | null
    employeeId: string | null
    enabled: boolean
    expiration: string
    id: string
    meta?: {
        disabledByLimit?: boolean
        blockedByTriesExceeded?: boolean
        storebox?: boolean
    }
    linked: boolean
    pan: string
    status: CardStatus
    storebox: boolean
    type: CardType
    velocity: string
    walletId: string
    disabledByAdmin: boolean
    pinTriesExceeded: boolean
    daysToExpiration: number
    aboutToExpire: boolean
    canChangePin: CanChangePin
    providerName: ProviderName
}

interface CompanyCardsFilters {
    employeeId?: string | null
    status?: CardStatus[] | null
    type?: CardType
}

export type UpdateCardRequest = {status: CardStatus}
interface UseCompanyCardsParams {
    spendingEntityId?: string
    filters?: CompanyCardsFilters
    bypassHook?: boolean
}

export async function updateCard(id: string, payload: UpdateCardRequest) {
    return request(`${baseUrl}/rest/v1/cards/${id}`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'PUT',
        body: payload,
        elevatedResourceIds: [id],
    })
}

export async function createCard(employeeId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'POST',
        body: {employeeId},
    })
}

export async function resetPinTries(cardId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards/${cardId}/pin/tries`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'DELETE',
    })
}

export async function pairCard(accessToken: string, employeeId: string, pan: string) {
    return request(`${baseUrl}/rest/v1/cards`, {
        bearer: accessToken,
        method: 'PUT',
        scope: RequestScope.CARD,
        body: {pan, employeeId},
    })
}

export async function getDetails(
    cardId: string,
    publicKey: string,
    token?: string,
): Promise<EncryptedCardDetails> {
    return callApi(`/rest/v1/cards/${cardId}/details`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD_DETAILS,
        method: 'POST',
        body: {publicKey},
    })
}

export async function getPin(cardId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards/${cardId}/pin`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'GET',
    })
}

export type CompanyCardsMutations = ReturnType<typeof useCompanyCards>['mutations']

export const useCompanyCards = ({
    spendingEntityId,
    filters,
    bypassHook = false,
}: UseCompanyCardsParams) => {
    const {companyId} = useCompanyUser()
    const resourceId = spendingEntityId || companyId
    const queryParams = qs.stringify(filters, {arrayFormat: 'comma', skipNulls: true})

    const query = queryParams ? `?${queryParams}` : ''

    const result = useSWR<CompanyCard[], Error>(
        resourceId && !bypassHook ? `/rest/v1/companies/${resourceId}/cards${query}` : null,
        getDeimos,
        {revalidateOnFocus: false, refreshInterval: 0},
    )

    async function update(id: string, payload: UpdateCardRequest) {
        const index = result.data?.findIndex((card) => card.id === id) ?? -1

        if (!result?.data || index === -1) {
            return
        }

        const response = await updateCard(id, payload)

        if ([CardStatus.LOST, CardStatus.STOLEN, CardStatus.DESTROYED].includes(payload.status)) {
            result.mutate(result.data.filter((card) => card.id !== id))
        } else {
            result.mutate([
                ...result.data.slice(0, index),
                {...result.data[index], ...response},
                ...result.data.slice(index + 1),
            ])
        }
    }

    async function create(id: string, token?: string) {
        if (!id) {
            return null
        }
        const response = await createCard(id, token)
        result.mutate([{...result?.data, ...response}])
        return response
    }

    async function pair(accessToken: string, employeeId: string, pan: string) {
        const response = await pairCard(accessToken, employeeId, pan)
        result.mutate([{...result?.data, ...response}])
        return response
    }

    async function resetPin(cardId?: string) {
        if (!cardId) {
            return
        }

        await resetPinTries(cardId)
        result.mutate()
    }

    return {
        ...result,
        isLoadingInitialData: !result.error && !result.data,
        mutations: {update, create, pair, resetPin},
    }
}

export function useCompanyPhysicalCards({
    filters,
    bypassHook = false,
}: {filters?: CompanyCardsFilters; bypassHook?: boolean} = {}) {
    const result = useCompanyCards({filters: {...filters, type: CardType.PHYSICAL}, bypassHook})

    return {
        ...result,
        data: result.data?.filter((card) => card.type === CardType.PHYSICAL),
    }
}
