import {useEffect} from 'react'

import {reportError} from '@product-web/error/report'

const setDocumentLang = (locale: string): void => {
    document.documentElement.lang = locale
}

function isInvalidLocale(locale: unknown): boolean {
    return !locale || locale === '' || locale === null || locale === undefined
}

export function useSetAppLang(locale: string): void {
    useEffect(() => {
        if (isInvalidLocale(locale)) {
            return reportError('Invalid locale provided')
        }

        return setDocumentLang(locale)
    }, [locale])
}
