import {Trans} from '@lingui/macro'
import {AnimatePresence, motion} from 'framer-motion'
import type {ReactNode} from 'react'
import {Children, isValidElement, useState} from 'react'
import styled from 'styled-components'

import {Button, Inline, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight} from '@pleo-io/telescope-icons'

const Group = styled(Stack).attrs({space: 8})``

const GroupTitle = styled(Text).attrs({variant: 'xlarge-accent', as: 'h3'})``

const GroupDescription = styled(Text).attrs({color: 'shade600'})``

const GroupContent = styled(Stack).attrs({space: 8, mt: 20})``

export const TasksCarousel = ({children}: {children: ReactNode}) => {
    const [groupIndex, setGroupIndex] = useState(0)
    const validChildren = Children.toArray(children).filter(isValidElement)
    const numberOfGroups = Children.count(validChildren)
    const canGoBack = groupIndex > 0 && numberOfGroups > 1
    const canGoForward = groupIndex < numberOfGroups - 1 && numberOfGroups > 1

    return (
        <Stack stretch>
            <AnimatePresence initial={false} exitBeforeEnter>
                <motion.div
                    key={groupIndex}
                    initial={{x: 1, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: 1, opacity: 0}}
                >
                    {validChildren[groupIndex]}
                </motion.div>
            </AnimatePresence>
            <Inline mt={20} justifyContent="space-between" alignSelf="flex-end">
                <NavButton visible={canGoBack}>
                    <Button
                        IconLeft={ArrowLeft}
                        variant="tertiary"
                        onClick={() => {
                            setGroupIndex(groupIndex - 1)
                        }}
                    >
                        <Trans>Back</Trans>
                    </Button>
                </NavButton>
                <NavButton visible={canGoForward}>
                    <Button
                        variant="tertiary"
                        onClick={() => {
                            setGroupIndex(groupIndex + 1)
                        }}
                        IconRight={ArrowRight}
                    >
                        <Trans>Next</Trans>
                    </Button>
                </NavButton>
            </Inline>
        </Stack>
    )
}

TasksCarousel.Group = Group
TasksCarousel.GroupTitle = GroupTitle
TasksCarousel.GroupDescription = GroupDescription
TasksCarousel.GroupContent = GroupContent

function NavButton({visible, children}: {visible: boolean; children: ReactNode}) {
    return (
        <div>
            <AnimatePresence>
                {visible && (
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
