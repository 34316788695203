import {getUrlMethods, useFilterState} from '@product-web/filters/use-filter-state'

import {useTagBudgetTracking} from './use-tag-budget-tracking'

export const ACTIVE_BUDGET_DETAILS_PARAMETER_NAME = 'id'

const activeBudgetDetailsUrlMethods = getUrlMethods(ACTIVE_BUDGET_DETAILS_PARAMETER_NAME, null)

function useActiveBudgetDetailsState() {
    const {getUrlValue, setUrlValue} = activeBudgetDetailsUrlMethods
    return useFilterState(getUrlValue, setUrlValue, null)
}

export function useActiveBudgetDrawerState(): {
    activeBudgetId: string
    isDrawerOpen: boolean
    openDrawer: (id: string) => void
    closeDrawer: () => void
} {
    const tracking = useTagBudgetTracking()

    const activeBudgetDetailsState = useActiveBudgetDetailsState()

    const activeBudgetId = activeBudgetDetailsState.interimValue ?? ''
    const isDrawerOpen = activeBudgetId !== ''

    const openDrawer = (nextActiveBudgetId: string) => {
        tracking.budgetOpened({budget_id: nextActiveBudgetId})
        activeBudgetDetailsState.directApply(nextActiveBudgetId)
    }

    const closeDrawer = () => {
        activeBudgetDetailsState.directApply(null)
    }

    return {activeBudgetId, isDrawerOpen, openDrawer, closeDrawer}
}
