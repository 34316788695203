export function getFileNamePrefix(budgetName: string): string {
    // Replace spaces with dashes and make it lowercase
    const withoutSpaces = budgetName.trim().replace(/ /g, '-').toLowerCase()

    // Remove any prefix or postfixed underscores
    const withoutUnderscores = withoutSpaces.replace(/^_+/, '').replace(/_+$/, '')

    // Replace invalid characters for file names with dashes
    const withoutInvalidCharacters = withoutUnderscores.replace(/[\\/:*?"<>|]/g, '-')

    // Remove any prefix or postfixed dashes
    const formattedName = withoutInvalidCharacters.replace(/^-+/, '').replace(/-+$/, '')

    // Return the formatted budget name
    return `budget_${formattedName}`
}
