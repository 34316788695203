import {createGlobalStyle} from 'styled-components'

import tokens, {__classNameColorSchemeDark} from '@pleo-io/telescope-tokens'

export type CustomColorSchemeToken = {light: string | number; dark: string | number}

export const customColorSchemeTokens = {
    colorBackgroundSwitchTrackOn: 'var(--colorBackgroundSwitchTrackOn)',
    colorBackgroundSwitchTrackOff: 'var(--colorBackgroundSwitchTrackOff)',
    colorBackgroundSwitchTrackDisabled: 'var(--colorBackgroundSwitchTrackDisabled)',
    colorBackgroundSwitchThumbDisabled: 'var(--colorBackgroundSwitchThumbDisabled)',
    colorContentSwitchThumbDisabled: 'var(--colorContentSwitchThumbDisabled)',
    colorBackgroundEntitySwitcher: 'var(--colorBackgroundEntitySwitcher)',
    colorBackgroundEntitySwitcherHover: 'var(--colorBackgroundEntitySwitcherHover)',
    colorBackgroundEntitySwitcherActive: 'var(--colorBackgroundEntitySwitcherActive)',
    colorBackgroundEntitySwitcherPanel: 'var(--colorBackgroundEntitySwitcherPanel)',
    colorContentPresentationalPink: 'var(--colorContentPresentationalPink)',
    colorExportStatusCellIconQueued: 'var(--colorExportStatusCellIconQueued)',
    colorBackgroundExpensesDot: 'var(--colorBackgroundExpensesDot)',
    colorBackgroundIndicator: 'var(--colorBackgroundIndicator)',
    colorBackgroundFileDropHover: 'var(--colorBackgroundFileDropHover)',
    colorBackgroundConfigurationSettingCompleted:
        'var(--colorBackgroundConfigurationSettingCompleted)',
    colorBorderEntityCardHover: 'var(--colorBorderEntityCardHover)',
    colorBackgroundSelfExpansionEntityCardHover:
        'var(--colorBackgroundSelfExpansionEntityCardHover)',
    // Invoice StatusIcon
    colorBackgroundInvoiceStatusIconPositive: 'var(--colorBackgroundInvoiceStatusIconPositive)',
    colorBackgroundInvoiceStatusIconPositiveHover:
        'var(--colorBackgroundInvoiceStatusIconPositiveHover)',
    colorBackgroundInvoiceStatusIconWarning: 'var(--colorBackgroundInvoiceStatusIconWarning)',
    colorBackgroundInvoiceStatusIconWarningHover:
        'var(--colorBackgroundInvoiceStatusIconWarningHover)',
    colorBackgroundInvoiceStatusIconNegative: 'var(--colorBackgroundInvoiceStatusIconNegative)',
    colorBackgroundInvoiceStatusIconNegativeHover:
        'var(--colorBackgroundInvoiceStatusIconNegativeHover)',
    colorBackgroundInvoiceStatusIconNeutral: 'var(--colorBackgroundInvoiceStatusIconNeutral)',
    colorBackgroundInvoiceStatusIconNeutralHover:
        'var(--colorBackgroundInvoiceStatusIconNeutralHover)',
} as const

type Data = Record<keyof typeof customColorSchemeTokens, CustomColorSchemeToken>

const customColorSchemeTokensData: Data = {
    colorBackgroundSwitchTrackOn: {light: tokens.green800, dark: tokens.green600},
    colorBackgroundSwitchTrackOff: {light: tokens.shade600, dark: tokens.shade300},
    colorBackgroundSwitchTrackDisabled: {light: tokens.shade300, dark: tokens.shade700},
    colorContentSwitchThumbDisabled: {light: tokens.shade000, dark: tokens.shade900},
    colorBackgroundSwitchThumbDisabled: {light: tokens.shade500, dark: tokens.shade600},
    colorBackgroundEntitySwitcher: {light: '#e4e4e4', dark: '#3c3c3c'},
    colorBackgroundEntitySwitcherHover: {light: tokens.shade000, dark: tokens.shade900},
    colorBackgroundEntitySwitcherActive: {light: tokens.shade200, dark: tokens.shade800},
    colorBackgroundEntitySwitcherPanel: {light: tokens.shade200, dark: tokens.shade800},
    colorContentPresentationalPink: {light: tokens.pink700, dark: tokens.pink600},
    colorExportStatusCellIconQueued: {light: tokens.yellow800, dark: tokens.yellow600},
    colorBackgroundExpensesDot: {light: tokens.pink500, dark: tokens.pink600},
    colorBackgroundIndicator: {light: tokens.shade300, dark: tokens.shade700},
    colorBackgroundFileDropHover: {light: tokens.pink400, dark: tokens.pink800},
    colorBackgroundConfigurationSettingCompleted: {light: tokens.purple400, dark: tokens.purple800},
    colorBorderEntityCardHover: {light: tokens.shade500, dark: tokens.shade500},
    colorBackgroundSelfExpansionEntityCardHover: {light: tokens.shade000, dark: tokens.shade900},
    // Invoice StatusIcon. Details on why these were made custom in Slack:
    // https://getpleo.slack.com/archives/CPCFSJK1R/p1700138814903639?thread_ts=1700062397.254629&cid=CPCFSJK1R
    colorBackgroundInvoiceStatusIconPositive: {light: tokens.green400, dark: tokens.green800},
    colorBackgroundInvoiceStatusIconPositiveHover: {light: tokens.green500, dark: tokens.green700},
    colorBackgroundInvoiceStatusIconWarning: {light: tokens.yellow500, dark: tokens.yellow900},
    colorBackgroundInvoiceStatusIconWarningHover: {light: tokens.yellow600, dark: tokens.yellow700},
    colorBackgroundInvoiceStatusIconNegative: {light: tokens.red400, dark: tokens.red800},
    colorBackgroundInvoiceStatusIconNegativeHover: {light: tokens.red500, dark: tokens.red700},
    colorBackgroundInvoiceStatusIconNeutral: {light: tokens.shade300, dark: tokens.shade700},
    colorBackgroundInvoiceStatusIconNeutralHover: {light: tokens.shade400, dark: tokens.shade600},
} as const

export const createCustomColorSchemeTokensGlobalStyle = (
    data: Record<string, CustomColorSchemeToken>,
) => createGlobalStyle`
:root {
    ${Object.keys(data).map((name) => `--${name}: ${data[name].light};`)}
    .${__classNameColorSchemeDark} {
        ${Object.keys(data).map((name) => `--${name}: ${data[name].dark};`)}
    }
}
`

export const CustomColorSchemeTokensGlobalStyle = createCustomColorSchemeTokensGlobalStyle(
    customColorSchemeTokensData,
)
