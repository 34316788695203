import Cookies from 'js-cookie'

import {checkStorageSupported, clearCookiesWithPrefix} from './shared'

const PREFIX = 'session@pleo/'
const isSupported = checkStorageSupported()

function setItem(key: string, value: string): void {
    if (isSupported) {
        window.sessionStorage.setItem(key, value)
    } else {
        Cookies.set(PREFIX + key, value, {path: ''})
    }
}

function getItem(key: string): string | null {
    if (isSupported) {
        return window.sessionStorage.getItem(key)
    }
    return Cookies.get(PREFIX + key) || null
}

function removeItem(key: string): void {
    if (isSupported) {
        window.sessionStorage.removeItem(key)
    } else {
        Cookies.remove(PREFIX + key, {path: '/'})
    }
}

function clear(): void {
    if (isSupported) {
        window.sessionStorage.clear()
    } else {
        clearCookiesWithPrefix(PREFIX)
    }
}

export const sessionStorage = {setItem, getItem, removeItem, clear}
