export type OpenBankingCallBackStatus = 'SUCCESS' | 'ERROR'
export const isValidCallBackStatus = (
    status: string | null,
): status is OpenBankingCallBackStatus => {
    return Boolean(status && ['SUCCESS', 'ERROR'].includes(status))
}

export type OpenBankingCallBackType = 'VRP' | 'PAYMENT'
export const isValidCallBackType = (type: string | null): type is OpenBankingCallBackType => {
    return Boolean(type && ['VRP', 'PAYMENT'].includes(type))
}
