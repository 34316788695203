import Country from '@product-web/locale/country'

export const VENDOR_CARDS_COUNTRIES_BLACKLIST: Country[] = [Country.US]

export const BILL_INVOICES_COUNTRIES_WHITELIST: Country[] = [Country.DE]

export const ANALYTICS_COUNTRIES_WHITELIST: Country[] = [
    Country.DE,
    Country.DK,
    Country.ES,
    Country.FR,
    Country.GB,
    Country.IE,
    Country.SE,
    Country.FI,
    Country.AT,
    Country.NL,
    Country.BE,
    Country.PT,
    Country.IT,
    Country.NO,
]
