import {useSearchParams} from 'react-router-dom'

export const usePageModal = (modalName: string) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const isOpen = !!searchParams.get(modalName)

    const openModal = () => setSearchParams({...searchParams, [modalName]: 'true'})
    const closeModal = () => {
        searchParams.delete(modalName)
        return setSearchParams(searchParams)
    }

    return {isOpen, openModal, closeModal}
}
