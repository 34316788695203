import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import styled from 'styled-components'

import {Badge, Button, Callout, Inline, Switch, Text, tokens} from '@pleo-io/telescope'

import {PlanTypeName} from '@product-web/api-types/billing'
import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import type {CpqBillingPeriodType, CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {useFlags} from '@product-web/flags'
import {breakpoints} from '@product-web/styles/theme'
import Wizard, {Step} from '@product-web/telescope-lab/wizard/wizard'
import {useToaster} from '@product-web/toaster'
import {useUser} from '@product-web/user'
import {exhaustiveCheck} from '@product-web/utils'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {FeaturesComparisonPanel} from './features-comparison-panel'
import {PlanChangeSummary} from './plan-change-summary'
import {DoneStep} from './pricing-modal-done-step'

import type {PlansData} from '../../index.bff'
import {getIsAlmostOver, getIsLastDay} from '../../manage-plan/choose-plan-container.helper'
import type {PageOrigin, PageSection} from '../../types'
import {PaymentConfirmationBreakdown} from '../payment-confirmation-breakdown'
import type {ModalVariant} from '../plan-change-clarification-modal'
import {PlanChangeClarificationModal} from '../plan-change-clarification-modal'
import {PlanColumn} from '../plan-column'
import type {LatestTrackingPropsType} from '../pricing-plan-modal'
import {trackPricingPlansModalActioned} from '../pricing-plans.helper'

export const MAX_NUMBER_USERS_IN_STARTER = 3

const Plans = styled.div`
    margin-top: ${tokens.spacing32};
    gap: ${tokens.spacing24};
    align-items: stretch;
    flex-wrap: wrap;
    display: grid;
    padding-bottom: ${tokens.spacing32};

    @media (min-width: ${breakpoints.mediumTabletUp}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${breakpoints.desktopMedUp}) {
        gap: ${tokens.spacing10};
        /* This precise minimum size prevents the columns overflowing the parent's right padding */
        grid-template-columns: repeat(4, minmax(267px, 1fr));
    }

    @media (min-width: ${breakpoints.desktopLrgUp}) {
        gap: ${tokens.spacing24};
    }
`

export const Heading = styled(Text).attrs({
    variant: '3xlarge-accent',
    align: 'center',
    as: 'h2',
})`
    flex: 1;
`

type WizardStep = 'plans' | 'changes' | 'done'

export type PlanSelectedType = {
    planType: CpqRatePlanType
    isUpgrade: boolean
    freeUserLimit: number
}

type RegularFlowWizardProps = {
    onDismiss: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    plansData?: PlansData
    setLatestTrackingProps?: (trackingData: LatestTrackingPropsType) => void
}

export const RegularFlowWizard = ({
    onDismiss,
    planUpgradeSource,
    pageOrigin,
    pageSection,
    plansData,
    setLatestTrackingProps,
}: RegularFlowWizardProps) => {
    const {pricing3GuidedMigration} = useFlags()
    const user = useUser()
    const [step, setStep] = useState<WizardStep>('plans')
    const [isBillingAnnual, setIsBillingAnnual] = useState(true)
    const [selectedPlanType, setSelectedPlanType] = useState<PlanSelectedType | null>(null)
    const [isWalletBalanceSufficient, setIsWalletBalanceSufficient] = useState(false)
    const {showToast} = useToaster()

    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const [clarificationModalVariant, setClarificationModalVariant] = useState<ModalVariant | null>(
        null,
    )

    const isCompanyNotVerified = plansData && !plansData.isFdd
    const targetBillingType = isBillingAnnual ? 'YEAR' : 'MONTH'

    const currency = plansData?.currency ?? 'EUR'
    const currentPlan = plansData?.currentPlan
    const isLegacyPlan = !!plansData?.isLegacyPlan

    const sortedAvailablePlansPricing = getSortedPlans({
        isSmallScreen,
        currentPlan,
        availableSubscriptions: plansData?.plansPricing,
    })

    const handleBillingTypeChange = (isAnnual: boolean) => {
        setIsBillingAnnual(isAnnual)

        trackPricingPlansModalActioned({
            action: 'annual_toggle_clicked',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlanType?.planType,
            interval: targetBillingType,
        })
    }

    const handleSelectPlan = (targetPlan: PlansData['plansPricing'][number]) => {
        const isTargetPlanStarter = targetPlan.planName === 'STARTER'

        const isBlockedByNumberOfUsers =
            isTargetPlanStarter && (user?.company?.numberOfUsers ?? 0) > MAX_NUMBER_USERS_IN_STARTER

        if (targetPlan.isTermed) {
            setClarificationModalVariant('CHANGE_FROM_CUSTOM')
            return
        }

        if (targetPlan.isPlanChangeBlocked && !targetPlan.isUpgrade) {
            setClarificationModalVariant('DOWNGRADE_FROM_ANNUAL')
            return
        }

        if (isBlockedByNumberOfUsers) {
            setClarificationModalVariant('DOWNGRADE_TO_STARTER_USER_LIMIT')
            return
        }

        if (isTargetPlanStarter) {
            setIsBillingAnnual(false)
        }

        setStep('changes')
        setSelectedPlanType({
            planType: targetPlan.planName,
            isUpgrade: targetPlan.isUpgrade,
            freeUserLimit: targetPlan.additionalUserBilling[targetBillingType].freeUserLimit ?? 0,
        })
        setLatestTrackingProps?.({
            step: getTrackingStep('changes'),
            plan: targetPlan.planName,
        })

        trackPricingPlansModalActioned({
            action: 'get_started',
            step: 'pricing_plan',
            origin: pageOrigin,
            section: pageSection,
            plan: targetPlan.planName,
        })
    }

    const handleContactUs = (planName: CpqRatePlanType) => {
        trackPricingPlansModalActioned({
            action: 'contact_us_clicked',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: planName,
        })
    }

    const handleOnDimissWizard = () => {
        onDismiss()

        // ask if we need to track this action here
        // because this is working in done step
        // but done step has its own tracking with done action
        trackPricingPlansModalActioned({
            action: 'abandoned',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlanType?.planType!,
        })

        if (step === 'done') {
            setStep('plans')
            setSelectedPlanType(null)
            setIsBillingAnnual(false)
            setLatestTrackingProps?.({
                step: getTrackingStep('plans'),
                plan: null,
            })
        }
    }

    const handleOnPaymentError = () => {
        showToast(t`An error occurred. Please try again later or contact support.`, {
            level: 'error',
        })
    }

    const trialEndDate = plansData?.trialEndDate ? new Date(plansData?.trialEndDate) : undefined
    const isTrialLastDay = getIsLastDay({trialEndDate})
    const isTrialAlmostOver = getIsAlmostOver({trialEndDate})
    const heading = getHeading({
        step,
        selectedPlanType: selectedPlanType!,
        isTrialLastDay,
        isTrialAlmostOver,
        isLegacyPlan,
    })

    const handleOnConfirmation = ({isWalletSufficient}: {isWalletSufficient: boolean}) => {
        setIsWalletBalanceSufficient(isWalletSufficient)
        setStep('done')
        setLatestTrackingProps?.({
            step: getTrackingStep('done'),
            plan: selectedPlanType?.planType!,
        })
    }

    const BackButton = () => (
        <Button
            variant="secondary"
            onClick={() => {
                setStep('plans')
                setSelectedPlanType(null)
                setLatestTrackingProps?.({
                    step: getTrackingStep('plans'),
                    plan: null,
                })
                trackPricingPlansModalActioned({
                    action: 'back',
                    step: 'payment_plan',
                    origin: pageOrigin,
                    section: pageSection,
                    plan: selectedPlanType?.planType,
                })
            }}
        >
            <Trans>Back</Trans>
        </Button>
    )

    return (
        <>
            {isCompanyNotVerified && (
                <Callout variant="warning">
                    <Callout.Text>
                        <Trans>
                            Company verification in progress. Please wait until you're fully
                            onboarded before changing plans.
                        </Trans>
                    </Callout.Text>
                </Callout>
            )}
            <Wizard step={step} isCurrentStepDone={step === 'done'}>
                <Step step={'plans'} label={t`Select plan`}>
                    <>
                        <WizardHeader
                            heading={heading}
                            isBillingAnnual={isBillingAnnual}
                            onBillingTypeChange={handleBillingTypeChange}
                        />
                        <Plans>
                            {sortedAvailablePlansPricing.map((availablePlanPricing) => (
                                <PlanColumn
                                    key={availablePlanPricing.planName}
                                    availablePlanPricing={availablePlanPricing}
                                    onSelectPlan={handleSelectPlan}
                                    onContactUs={handleContactUs}
                                    discountEnabled={isBillingAnnual}
                                    currency={currency}
                                    currentPlan={currentPlan!}
                                    isCompanyNotVerified={isCompanyNotVerified!}
                                    isLegacyPlan={isLegacyPlan}
                                    showRecommendedBadge={
                                        pricing3GuidedMigration &&
                                        plansData?.migrationRecommendation &&
                                        plansData?.migrationRecommendation.recommendedPlan ===
                                            availablePlanPricing.planName
                                    }
                                />
                            ))}
                        </Plans>
                        <FeaturesComparisonPanel
                            availablePlansPricing={sortedAvailablePlansPricing}
                            companyCurrency={currency}
                            pageOrigin={pageOrigin}
                            pageSection={pageSection}
                            billingType={targetBillingType}
                        />
                    </>
                </Step>
                <Step step={'changes'} label={t`Confirm changes`}>
                    <>
                        <WizardHeader
                            heading={heading}
                            isBillingAnnual={isBillingAnnual}
                            onBillingTypeChange={handleBillingTypeChange}
                            isSwitchVisible={selectedPlanType?.planType !== 'STARTER'}
                            showRecommendedBadge={
                                pricing3GuidedMigration &&
                                plansData?.migrationRecommendation &&
                                plansData?.migrationRecommendation.recommendedPlan ===
                                    selectedPlanType?.planType &&
                                (isBillingAnnual
                                    ? plansData?.migrationRecommendation
                                          .recommendedBillingPeriod === 'YEAR'
                                    : plansData?.migrationRecommendation
                                          .recommendedBillingPeriod === 'MONTH')
                            }
                        />

                        <Inline
                            paddingY={32}
                            alignItems="flex-start"
                            css={{
                                gap: tokens.spacing32,
                                flexDirection: isSmallScreen ? 'column' : 'row',
                            }}
                        >
                            <PlanChangeSummary
                                currentPlan={currentPlan!}
                                targetPlan={selectedPlanType!}
                                companyCurrency={currency}
                                onChatWithUs={handleContactUs}
                                isLegacyPlan={isLegacyPlan}
                            >
                                {!isSmallScreen && <BackButton />}
                            </PlanChangeSummary>
                            <PaymentConfirmationBreakdown
                                targetBillingType={targetBillingType}
                                targetPlan={selectedPlanType!}
                                onError={handleOnPaymentError}
                                onConfirmation={handleOnConfirmation}
                                currentPlan={currentPlan!}
                                currentBillingType={
                                    plansData?.currentBillingType as CpqBillingPeriodType
                                }
                                currentPlanEstimatedTotal={plansData?.currentPlanEstimatedTotal!}
                                isLegacyPlan={isLegacyPlan}
                                planUpgradeSource={planUpgradeSource}
                                pageOrigin={pageOrigin}
                                pageSection={pageSection}
                            />
                        </Inline>
                        {isSmallScreen && <BackButton />}
                    </>
                </Step>
                <Step step={'done'} label={t`Done`}>
                    <DoneStep
                        onClose={handleOnDimissWizard}
                        newPlan={selectedPlanType?.planType!}
                        isUpgrade={selectedPlanType?.isUpgrade!}
                        pageOrigin={pageOrigin}
                        pageSection={pageSection}
                        shouldTopUpWallet={!isWalletBalanceSufficient}
                    />
                </Step>
            </Wizard>
            {clarificationModalVariant && (
                <PlanChangeClarificationModal
                    isOpen={!!clarificationModalVariant}
                    onDismiss={() => setClarificationModalVariant(null)}
                    variant={clarificationModalVariant}
                    pageOrigin={pageOrigin}
                    pageSection={pageSection}
                />
            )}
        </>
    )
}

const WizardHeader = ({
    heading,
    isBillingAnnual,
    onBillingTypeChange,
    isSwitchVisible = true,
    showRecommendedBadge,
}: {
    heading: string
    isBillingAnnual: boolean
    onBillingTypeChange: (isAnual: boolean) => void
    isSwitchVisible?: boolean
    showRecommendedBadge?: boolean
}) => {
    const savePercentage = 10

    return (
        <>
            <Inline space={8} alignY="center" justifyContent="center">
                <Heading css={{flex: '0 1 auto'}}>{heading}</Heading>
                {showRecommendedBadge && (
                    <Badge variant="discover">
                        <Trans>Recommended</Trans>
                    </Badge>
                )}
            </Inline>
            {isSwitchVisible && (
                <Inline justifyContent="center" space={8} paddingTop={16}>
                    <Switch
                        checked={isBillingAnnual}
                        onCheckedChange={onBillingTypeChange}
                        data-testid="annual-billing-switch"
                    >
                        <Text color="colorContentInteractive">
                            <Trans>Annual billing (Up to {savePercentage}% discount)</Trans>
                        </Text>
                    </Switch>
                </Inline>
            )}
        </>
    )
}

type GetHeadingArgs = {
    step: WizardStep
    selectedPlanType: {planType: CpqRatePlanType; isUpgrade: boolean}
    isTrialLastDay: boolean
    isTrialAlmostOver: boolean
    isLegacyPlan: boolean
}

const getHeading = ({
    step,
    selectedPlanType,
    isTrialLastDay,
    isTrialAlmostOver,
    isLegacyPlan,
}: GetHeadingArgs): string => {
    switch (step) {
        case 'plans':
            if (isTrialLastDay) {
                return t`Your free trial has ended`
            } else if (isTrialAlmostOver) {
                return t`Your free trial is almost over...`
            }

            return t`Select a new plan`
        case 'changes':
            return selectedPlanType.isUpgrade || isLegacyPlan
                ? t`Upgrade to ${PlanTypeName[selectedPlanType.planType]}`
                : t`Downgrade to ${PlanTypeName[selectedPlanType.planType]}`
        case 'done':
            return t`Done`
        default:
            return exhaustiveCheck(step)
    }
}

type GetSortedPlansArgs = {
    isSmallScreen: boolean
    currentPlan?: PlansData['currentPlan']
    availableSubscriptions?: PlansData['plansPricing']
}

export const getSortedPlans = ({
    isSmallScreen,
    currentPlan,
    availableSubscriptions,
}: GetSortedPlansArgs) => {
    if (!availableSubscriptions?.length) {
        return []
    }

    if (!isSmallScreen || !currentPlan) {
        return availableSubscriptions
    }

    const currentPlanSubscription = availableSubscriptions.find(
        (subs) => subs.planName === currentPlan,
    )

    if (currentPlanSubscription) {
        return [
            ...availableSubscriptions.filter((subs) => subs.planName !== currentPlan),
            currentPlanSubscription,
        ]
    }

    return availableSubscriptions
}

const getTrackingStep = (step: WizardStep) => {
    switch (step) {
        case 'plans':
            return 'pricing_plan'
        case 'changes':
            return 'payment_plan'
        case 'done':
            return 'completed_plan_changes'
        default:
            return exhaustiveCheck(step)
    }
}
