import {useLocation} from 'react-router'

import {useCompanyGuides} from '@product-web/api-deimos/company-guides'
import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {useInvoiceAdoption} from '@product-web-features/adoption/invoice-adoption-prompt/use-invoice-adoption'
import {usePocketAdoption} from '@product-web-features/adoption/pocket-adoption-prompt/use-pocket-adoption'
import {getIsAdoptionCentreVisible} from '@product-web-features/help-centre/get-is-adoption-centre-visible'

export const useShowAdoptionCentre = () => {
    const {data: companyGuides, isLoading: isCompanyGuidesLoading} = useCompanyGuides()
    const {shouldShowPocketAdoptionPrompt} = usePocketAdoption()
    const {shouldShowInvoiceAdoptionPrompt} = useInvoiceAdoption()

    const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)

    const location = useLocation()

    return {
        shouldShowAdoptionCentre:
            !isCompanyGuidesLoading &&
            !shouldShowPocketAdoptionPrompt &&
            !shouldShowInvoiceAdoptionPrompt &&
            getIsAdoptionCentreVisible({
                isDesktop,
                companyGuides,
                pathname: location.pathname,
            }),
    }
}
