import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Accordion, Stack, tokens} from '@pleo-io/telescope'

import {helpCentreLinkButtonStyles} from '../../../help-centre-link-button'
import {usePleoLinks} from '../../adoption-centre-link-helpers'
import {trackHelpWidgetLinkClick} from '../../tracking'

const MoreItemLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    ${helpCentreLinkButtonStyles}
`

export const MORE_ITEM_ACCORDION_ID = 'MORE_ITEM'

export const MoreItem = () => {
    const {getBlogLink, getCommercialLink} = usePleoLinks()

    return (
        <Accordion.Item
            value={MORE_ITEM_ACCORDION_ID}
            data-testid="adoption-centre-more-item-accordion"
        >
            <Accordion.Header headingLevel="h2" heading={t`More`} />
            <Accordion.Content>
                <Stack space={12}>
                    <MoreItemLink
                        href={getBlogLink('/category/pleo-updates')}
                        onClick={() => trackHelpWidgetLinkClick('updates')}
                        data-testid="adoption-centre-new-link"
                    >
                        <span role="img" aria-hidden="true">
                            🎁
                        </span>
                        <Trans>What's new</Trans>
                    </MoreItemLink>
                    <MoreItemLink
                        href={getCommercialLink('/perks-internal')}
                        onClick={() => trackHelpWidgetLinkClick('perks')}
                        data-testid="adoption-centre-perks-link"
                    >
                        <span role="img" aria-hidden="true">
                            🔥
                        </span>
                        <Trans>Perks</Trans>
                    </MoreItemLink>
                    <MoreItemLink
                        css={{marginBottom: tokens.spacing12}}
                        href={getCommercialLink('/referral')}
                        onClick={() => trackHelpWidgetLinkClick('referral')}
                        data-testid="adoption-centre-refer-link"
                    >
                        <span role="img" aria-hidden="true">
                            🤝
                        </span>
                        <Trans>Refer a friend</Trans>
                    </MoreItemLink>
                    <MoreItemLink
                        href={getCommercialLink('/legal')}
                        onClick={() => trackHelpWidgetLinkClick('terms')}
                        data-testid="adoption-centre-terms-link"
                        css={{fontSize: tokens.fontSmall}}
                    >
                        <Trans>Terms</Trans>
                    </MoreItemLink>
                    <MoreItemLink
                        href="https://status.pleo.io"
                        onClick={() => trackHelpWidgetLinkClick('status')}
                        data-testid="adoption-centre-status-link"
                        css={{fontSize: tokens.fontSmall}}
                    >
                        <Trans>Service status</Trans>
                    </MoreItemLink>
                </Stack>
            </Accordion.Content>
        </Accordion.Item>
    )
}
