import React from 'react'
import {Route} from 'react-router'

import {roleSets} from '@product-web/user'

const TrustedBeneficiariesPage = React.lazy(
    async () => import('./screens/trusted-beneficiaries/trusted-beneficiaries'),
)
const OpenBankingConsentPage = React.lazy(async () => import('./screens/open-banking/open-banking'))

export const topLevelLayoutRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.company}}>
        <Route
            path="/trusted-beneficiaries"
            element={<TrustedBeneficiariesPage />}
            handle={{allowedRoles: ['owner']}}
        />
        <Route path="/openbanking/authenticate" element={<OpenBankingConsentPage />} />
    </Route>
)
