import type {ICountryConfiguration} from './types'

import {TravelInsurance} from '../features/travel-insurance/types'
import {Feature} from '../types'

const DK: ICountryConfiguration = {
    [Feature.TravelInsurance]: {
        [TravelInsurance.ClaimInformation]: {
            policyDocumentUrl: 'terms-pleo-travel-insurance-dk.pdf',
            phoneNumber: '+45 89 88 07 31',
        },
    },
    [Feature.Mileage]: {
        requirePurposeOfMileage: true,
    },
    [Feature.PerDiem]: {
        requirePurposeOfPerDiem: true,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleodk.aftership.com/',
    },
}

export default DK
