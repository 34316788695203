import {t} from '@lingui/macro'

import {fieldArrayValidation, validation, validationsToFormErrors} from './helper'
import {email as emailValidate, required, secureString} from './index'

interface User {
    email?: string
    firstName?: string
}

interface Values {
    users: User[]
}

const checkEmailUnique = (emailToFind = '', emails: string[]) => {
    const emailIndex = emails.findIndex((e) => {
        return e.toLowerCase() === emailToFind.toLowerCase()
    })

    return emailIndex !== -1
}

export const validateUser =
    (emails: string[]) =>
    ({email, firstName}: User, values?: Values) => {
        const errors: User =
            validationsToFormErrors([
                validation('email', (email || '').trim(), required, t`Email is required`),
                validation(
                    'email',
                    (email || '').trim(),
                    emailValidate,
                    t`Please enter a valid email`,
                ),
                validation('firstName', firstName, required, t`First name is required`),
                validation('firstName', firstName, secureString, t`First name is invalid`),
            ]) || {}

        if (
            email &&
            values &&
            (values.users || []).filter((user) => user.email === email).length > 1
        ) {
            errors.email = t`Email already exists`
        }

        if (email && checkEmailUnique((email || '').trim(), emails)) {
            errors.email = t`Email already exists`
        }

        return errors
    }

export const validateUsers = (emails: string[]) => (values: Values) =>
    fieldArrayValidation('users', values, (user: User) => validateUser(emails)(user, values))
