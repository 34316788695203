export enum RequestAuth {
    USER = 'user',
    ELEVATED = 'elevated',
}

export enum RequestScope {
    PERMISSION = 'permission',
    CARD = 'card',
    CARD_DETAILS = 'carddetails',
}

export interface RequestOptions {
    auth?: 'admin' | 'user' | 'elevated' | null
    scope?: RequestScope
    bearer?: string | null
    headers?: {[key: string]: string}
    credentials?: boolean
    forcePIN?: boolean
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    body?: any
    form?: {[key: string]: any}
    query?: any
    file?: File
    onProgress?: (number: number) => void
    timeout?: number
    responseType?: 'text' | 'document' | 'arraybuffer' | 'blob' | 'json' | 'stream' | undefined
    elevatedResourceIds?: string[]
}
