import {featureFundsManagementBff} from './bff-hooks'

// Temp hook to help all the teams to make moving to BFF smooth
// Once we all moved to BFF rails to call the API, we can remove this temp "useWalletBalance" hook
export const useWalletBalance = () => {
    const {data, error, isInitialLoading} =
        featureFundsManagementBff.utils.getWalletBalance.useQuery()

    return {
        walletBalance: data,
        error,
        isLoading: isInitialLoading,
    }
}
