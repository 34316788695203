import {SupportedLanguage} from '@product-web/i18n'

export const getDayjsLocales = async (language: SupportedLanguage) => {
    switch (language) {
        case SupportedLanguage.EN:
            return await import('dayjs/locale/en-gb')
        case SupportedLanguage.DA:
            return await import('dayjs/locale/da')
        case SupportedLanguage.DE:
            return await import('dayjs/locale/de')
        case SupportedLanguage.DE_AT:
            return await import('dayjs/locale/de-at')
        case SupportedLanguage.SV:
            return await import('dayjs/locale/sv')
        case SupportedLanguage.FR:
            return await import('dayjs/locale/fr')
        case SupportedLanguage.FR_BE:
            return await import('dayjs/locale/fr')
        case SupportedLanguage.ES:
            return await import('dayjs/locale/es')
        case SupportedLanguage.FI:
            return await import('dayjs/locale/fi')
        case SupportedLanguage.NL:
            return await import('dayjs/locale/nl')
        case SupportedLanguage.NL_BE:
            return await import('dayjs/locale/nl-be')
        case SupportedLanguage.PT:
            return await import('dayjs/locale/pt')
        case SupportedLanguage.IT:
            return await import('dayjs/locale/it')
        case SupportedLanguage.NO:
            return await import('dayjs/locale/nn')
        default:
            return exhaustiveGuard(language)
    }
}

const exhaustiveGuard = (value: never): never => value

export const dayjsLocaleMap = {
    da: 'da',
    sv: 'sv',
    en: 'en-gb',
    de: 'de',
    'de-AT': 'de-at',
    es: 'es',
    fr: 'fr',
    'fr-BE': 'fr',
    fi: 'fi',
    nl: 'nl',
    'nl-BE': 'nl-be',
    pt: 'pt',
    it: 'it',
    no: 'nn',
}
