import {featureFundsManagementBff} from './bff-hooks'

// Temp hook to help other teams to make moving to BFF more smooth
// Once they moved to BFF rails to call the API, we can remove this temp "useHasAWalletLoad" hook
export const useHasAWalletLoad = () => {
    const {data: hasWalletLoads, isInitialLoading} =
        featureFundsManagementBff.utils.getHasWalletLoads.useQuery()

    return {
        isLoading: isInitialLoading,
        hasAWalletLoad: Boolean(hasWalletLoads),
    }
}
