import type {PropsWithChildren} from 'react'

import {useFlags} from '@product-web/flags'

import {LandingPages, type LandingPagesProps} from './landing-pages'

export const LandingPagesWrapper = (
    props: PropsWithChildren<LandingPagesProps> & {
        skipExperiment?: boolean
    },
) => {
    const {newLandingPages} = useFlags()

    if (newLandingPages && !props.skipExperiment) {
        return <LandingPages {...props} />
    }

    return <>{props.children}</>
}
