import isEqual from 'lodash.isequal'
import * as React from 'react'
import {useSearchParams} from 'react-router-dom'

/**
 * Manages the state life-cycle of a filter with an interim state which can be persisted as a
 * URL search param.
 * @param getUrlValue The method to retrieve the current applied filter value from a URLSearchParams object
 * @param setUrlValue The method to apply the current filter value to a URLSearchParams object
 * @param defaultValue The value of the filter not rendered to the URL
 *
 * @example
 * const getUrlValue = (params) => params.get('player') ?? 'kobe'
 * const setUrlValue = (next, params) => {
 *    params.set('player', next)
 *    return params
 * },
 * const filter = useFilterState(getUrlValue, setUrlValue, 'kobe')
 * filter.setInterimValue('shaq')
 * filter.apply()
 *
 * @returns A object containing values and methods convenient for working with the filter state
 */
export function useFilterState<T>(
    getUrlValue: (searchParams: URLSearchParams) => T,
    setUrlValue: (nextValue: T, searchParams: URLSearchParams) => URLSearchParams,
    defaultValue: T,
) {
    const [searchParams, setSearchParams] = useSearchParams()
    const appliedValue = getUrlValue(searchParams)
    const [interimValue, setInterimValue] = React.useState<T>(appliedValue)

    const isActive = !isEqual(appliedValue, defaultValue)
    const canApply = !isEqual(interimValue, appliedValue)
    const canClear = !isEqual(interimValue, defaultValue)

    // Wrapping all methods in `useCallback` makes it easier to e.g.
    // wrap them with debouncing via useMemo at call site.

    const apply = React.useCallback(() => {
        return setSearchParams(setUrlValue(interimValue, searchParams))
    }, [setSearchParams, setUrlValue, interimValue, searchParams])

    const directApply = React.useCallback(
        (value: T) => {
            setInterimValue(value)
            setSearchParams(setUrlValue(value, searchParams))
        },
        [searchParams, setInterimValue, setSearchParams, setUrlValue],
    )

    const setDefaultParams = React.useCallback(
        (params: URLSearchParams) => setUrlValue(defaultValue, params),
        [defaultValue, setUrlValue],
    )

    const setParams = React.useCallback(
        (params: URLSearchParams) => setUrlValue(interimValue, params),
        [interimValue, setUrlValue],
    )

    const clear = React.useCallback(() => setInterimValue(defaultValue), [defaultValue])

    return {
        apply,
        setParams,
        setDefaultParams,
        clear,
        directApply,
        setInterimValue,
        appliedValue,
        interimValue,
        isActive,
        canApply,
        canClear,
    }
}

/**
 * Factory for the most common version of the getUrlValue/setUrlValue methods needed
 * by useFilterState. Allows single, string value (or null). If provided, the values
 * can be narrowed to only a specified set. Works for most single-value select filters.
 */
export function getUrlMethods<T extends string, D extends T | null>(
    paramName: string,
    defaultValue: D,
    allowedValues?: readonly T[],
) {
    function getUrlValue(searchParams: URLSearchParams) {
        const paramsValue = searchParams.get(paramName) as T

        if (allowedValues && paramsValue && !allowedValues.includes(paramsValue)) {
            return defaultValue
        }

        return paramsValue ?? defaultValue
    }

    function setUrlValue(value: ReturnType<typeof getUrlValue>, searchParams: URLSearchParams) {
        if (allowedValues && value && !allowedValues.includes(value)) {
            return searchParams
        }

        if (value && !isEqual(value, defaultValue)) {
            searchParams.set(paramName, value.toString())
            return searchParams
        }

        searchParams.delete(paramName)
        return searchParams
    }

    return {getUrlValue, setUrlValue}
}
/**
 * Factory for multi value version of the getUrlValue/setUrlValue methods needed
 * by useFilterState. Allows 0 or more string values (rendered as comma separated in the URL).
 * If provided, the values can be narrowed to only a specified set.
 * Works for most multi-value select filters.
 */
export function getMultiValueUrlMethods<T extends string>(
    paramName: string,
    allowedValues?: readonly T[],
) {
    const multiValuedParamName = `${paramName}[]`

    function getUrlValue(searchParams: URLSearchParams) {
        const defaultValue = [] as unknown as T[]
        const paramsValue = searchParams.getAll(multiValuedParamName) as T[]

        // If allowed values are provided, filter the URL values to only return the allowed ones
        // Had to give up trying to make this without `as` - I encourage you to try!
        if (allowedValues && paramsValue) {
            const allowedParamsValue = paramsValue.filter((value) =>
                allowedValues?.includes(value),
            ) as T[]
            return allowedParamsValue.length ? allowedParamsValue : defaultValue
        }

        return paramsValue ?? defaultValue
    }

    function setUrlValue(values: ReturnType<typeof getUrlValue>, searchParams: URLSearchParams) {
        searchParams.delete(multiValuedParamName)
        if (!isEqual(values, [])) {
            values.forEach((value) => {
                searchParams.append(multiValuedParamName, value)
            })
        }
        return searchParams
    }

    return {getUrlValue, setUrlValue}
}
