/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {Button, Input, Select, Skeleton, Stack, Text} from '@pleo-io/telescope'

export const PricingDetailsFormSkeleton = () => (
    <Stack space={24}>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>Your partner tier with icon</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Select />
            </Skeleton>
        </Stack>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>Pricing plan</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Select />
            </Skeleton>
        </Stack>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>Total number of users</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Input name="usersCount" />
            </Skeleton>
        </Stack>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>Billing frequency</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Text>Radio Monthly Radio Annually</Text>
            </Skeleton>
        </Stack>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>Currency</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Select />
            </Skeleton>
        </Stack>
        <Stack space={8}>
            <Skeleton loading borderRadius={4}>
                <Text>With cashback icon</Text>
            </Skeleton>
            <Skeleton loading borderRadius={8}>
                <Text>With cashback checkbox</Text>
            </Skeleton>
        </Stack>
        <Skeleton loading>
            <Button variant="primary">Calculate pricing</Button>
        </Skeleton>
    </Stack>
)
