import {useEffect} from 'react'

import type {
    SpendActivationProgress,
    SpendActivationTaskNames,
} from './bff/spend-activation-progress.bff'

import {bffHooks} from '../../bff-hooks'

const defaultValue: SpendActivationProgress = {
    allCompleted: false,
    percent: 0,
    tasks: {
        createVendorCards: {completed: false},
        configureSubWallets: {completed: false},
        activateVendorCards: {completed: false},
    },
}

const useSpendActivationGuideProgress = (
    {
        enabled,
        startGuideIfNotStarted,
    }: {
        enabled: boolean
        startGuideIfNotStarted?: boolean
    } = {enabled: true, startGuideIfNotStarted: false},
): {
    isLoading: boolean
    isUpdateLoading: boolean
    startGuideLoading: boolean
    progress: SpendActivationProgress
    isStarted?: boolean
    isSkipped?: boolean
    completeTask: (name: SpendActivationTaskNames) => Promise<string | null>
    skipGuide: () => Promise<void>
} => {
    const {data = defaultValue, isLoading: isProgressLoading} =
        bffHooks.recurringVendors.spendActivationGuide.progress.getSpendActivationProgress.useQuery(
            undefined,
            {
                enabled,
            },
        )
    const {data: guide, isLoading: isGuideLoading} =
        bffHooks.recurringVendors.spendActivationGuide.progress.getSpendActivationGuide.useQuery(
            undefined,
            {
                enabled,
            },
        )
    const {mutateAsync: startGuide, isLoading: startGuideLoading} =
        bffHooks.recurringVendors.spendActivationGuide.progress.startSpendActivationGuide.useMutation()
    const {mutateAsync: skipGuide, isLoading: skipGuideLoading} =
        bffHooks.recurringVendors.spendActivationGuide.progress.skipSpendActivationGuide.useMutation()
    const {mutateAsync: completeTask, isLoading: completeTaskLoading} =
        bffHooks.recurringVendors.spendActivationGuide.progress.completeSpendActivationTask.useMutation()
    const isMutationLoading = startGuideLoading || skipGuideLoading || completeTaskLoading

    const isLoading = isProgressLoading || isGuideLoading

    useEffect(() => {
        const shouldStartGuide =
            !(isLoading || isMutationLoading) &&
            guide &&
            !guide.isStarted &&
            enabled &&
            startGuideIfNotStarted
        if (shouldStartGuide) {
            startGuide()
        }
    }, [startGuideIfNotStarted, data, isLoading, isMutationLoading, enabled, guide, startGuide])

    return {
        isLoading: isGuideLoading || isProgressLoading,
        isUpdateLoading: isMutationLoading,
        startGuideLoading,
        progress: data,
        isStarted: guide?.isStarted,
        isSkipped: guide?.isSkipped,
        completeTask: async (taskName) => completeTask({taskName}),
        skipGuide: async () => {
            await skipGuide()
        },
    }
}

export default useSpendActivationGuideProgress
