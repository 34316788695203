import type {ICountryConfiguration} from './types'

import {Feature} from '../types'

const NO: ICountryConfiguration = {
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleono.aftership.com/',
    },
}

export default NO
