import Cookies from 'js-cookie'

import {checkStorageSupported, clearCookiesWithPrefix} from './shared'

const PREFIX = 'local@pleo/'
const TWENTY_YEARS = 20 * 365

const isSupported = checkStorageSupported()

function setItem(key: string, value: string): void {
    if (isSupported) {
        window.localStorage.setItem(key, value)
    } else {
        Cookies.set(PREFIX + key, value, {path: '', expires: TWENTY_YEARS})
    }
}

function getItem(key: string): string | null {
    if (isSupported) {
        return window.localStorage.getItem(key)
    }
    return Cookies.get(PREFIX + key) || null
}

function removeItem(key: string): void {
    if (isSupported) {
        window.localStorage.removeItem(key)
    } else {
        Cookies.remove(PREFIX + key, {path: '/'})
    }
}

function clear(): void {
    if (isSupported) {
        window.localStorage.clear()
    } else {
        clearCookiesWithPrefix(PREFIX)
    }
}

export const localStorage = {getItem, setItem, removeItem, clear}
