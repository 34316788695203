import type {FC} from 'react'

import type {Amount} from '@pleo-io/deimos'

import {ModalAccountSuspendedBasic} from './account-suspended-basic'
import ModalHelpScreen from './help-screen'
import {PaywallView, usePaywallContext} from './paywall-context'
import {ModalPendingTransfer} from './pending-transfer'
import ModalViewTransfer from './view-transfer'

export interface Props {
    amount: Amount
    hasPaidInvoice: boolean
    onClose: () => void
    isSuspended: boolean
    firstPaidInvoiceDate: string | undefined
}

export const ModalAccountSuspended: FC<Props> = ({
    amount,
    hasPaidInvoice,
    onClose,
    isSuspended,
    firstPaidInvoiceDate,
}) => {
    const {view, setView} = usePaywallContext()
    const isModalDismissible = !hasPaidInvoice && !isSuspended

    if (view === PaywallView.VIEW_TRANSFER) {
        return (
            <ModalViewTransfer
                onClose={onClose}
                onBack={() => setView(PaywallView.INITIAL)}
                onProceed={() => setView(PaywallView.PENDING_TRANSFER)}
            />
        )
    }

    if (view === PaywallView.PENDING_TRANSFER) {
        return <ModalPendingTransfer isDismissible={!isSuspended} onClose={onClose} />
    }

    if (view === PaywallView.GET_HELP) {
        return <ModalHelpScreen isDismissible={!isSuspended} onClose={onClose} />
    }
    return (
        <ModalAccountSuspendedBasic
            firstPaidInvoiceDate={firstPaidInvoiceDate}
            amount={amount}
            isSuspended={isSuspended}
            onNext={() => {
                setView(PaywallView.VIEW_TRANSFER)
            }}
            isDismissible={isModalDismissible}
            onClose={onClose}
        />
    )
}

export default ModalAccountSuspended
