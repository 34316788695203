import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'
import {Page} from '@product-web-features/ui-page'

import {useSideOverview} from './use-side-overview'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {SideOverview} from '../../components/side-overview'

export const Introduction = () => {
    const {country} = useAcademyContext()
    const {overviewSteps, usefulMaterials} = useSideOverview()
    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    id: 'wgpkfh02sz',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/0d361f76df39e0a078585a4f94780d74.jpg',
                }
            case 'DK':
            case 'GL':
            case 'SE':
                // TODO DK & SE video are in English, and same link
                return {
                    id: 'lo77obzms2',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/898ac265e94ab3007c1d29ecc693054dcc7ef4f3.jpg',
                }
            // TODO get missing content from Marketing
            case 'FR':
            case 'ES':
            case 'NL':
            default:
                // default is GB, IE, US
                return {
                    id: '449k2810qn',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/9ab8a4408b369aa9296c00314deedb2d.jpg',
                }
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`Introduction to Pleo`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Introduction to Pleo</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>3 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main flex={4}>
                        <Text>
                            <Trans>
                                Hi, we're Pleo, Europe's leading business spend management solution.
                                We help companies of all shapes and sizes spend smarter and safer.
                                This all starts with our Business Expense Cards.
                            </Trans>
                        </Text>
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Introduction</Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Beyond - The future of spend management`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({
                                    action,
                                    video: 'future_of_spend_management',
                                })
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../employee-experience"
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SideOverview overviewSteps={overviewSteps} usefulMaterials={usefulMaterials} />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
