import type {ReactElement} from 'react'

import {Inline, Stack, Text, tokens} from '@pleo-io/telescope'

import {pxBreakpoints} from '@product-web/styles/theme'
import {useContainerQuery} from '@product-web/telescope-lab/container-queries/container'

export type LandingPageKeyCardProps = {
    imageIcon: ReactElement
    getTitle: () => string
    getDescription: () => string
}

export const LandingPageKeyCard = ({
    getTitle,
    imageIcon,
    getDescription,
}: LandingPageKeyCardProps) => {
    const isDesktop = useContainerQuery({
        name: 'main',
        minWidth: pxBreakpoints.tabletMedUp,
    })

    return (
        <Inline
            flexDirection={isDesktop ? 'column' : 'row'}
            data-testid="landing-page-key-card"
            css={{gap: tokens.spacing24, flex: 1}}
        >
            <div css={{flexShrink: 0}}>{imageIcon}</div>
            <Stack space={4}>
                <Text variant="large-accent" as="h3" weight="medium">
                    {getTitle()}
                </Text>
                <Text variant="medium-default" as="p" color="colorContentStaticQuiet">
                    {getDescription()}
                </Text>
            </Stack>
        </Inline>
    )
}
