import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const GuidelineSettingsWrapper = React.lazy(
    async () => import('./screens/guidelines-settings-wrapper'),
)
const GuidelinesScreen = React.lazy(async () => import('./screens'))
const GuidelinesEditorWrapper = React.lazy(
    async () => import('./screens/guidelines-editor-wrapper'),
)
const GuidelineReadonlyScreen = React.lazy(
    async () => import('./screens/guideline-readonly-screen'),
)
const GuidelineEditorScreen = React.lazy(async () => import('./screens/guideline-editor-screen'))

export const settingsRoutes = (
    <Route
        handle={{auth: true, allowedRoles: roleSets.company}}
        element={<GuidelineSettingsWrapper />}
    >
        <Route element={<GuidelinesScreen />}>
            <Route index element={null} />
            <Route element={<GuidelinesEditorWrapper />}>
                <Route path=":guidelineId" element={<GuidelineReadonlyScreen />} />
                <Route path=":guidelineId/edit" element={<GuidelineEditorScreen />} />
            </Route>
        </Route>
    </Route>
)
