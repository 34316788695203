import {Plural, Trans} from '@lingui/macro'

import {Inline, Loading, Text} from '@pleo-io/telescope'

import {Accordion, SingleItemAccordion} from '@product-web/telescope-lab/accordion'

import {usePartnerProgramInfo} from '../../../api/partner-program-info'

export const PartnerOverviewCard = () => {
    const {data} = usePartnerProgramInfo()

    if (!data?.partnershipYear) {
        return <Loading />
    }

    const {paidUsers, freeUsers, newClients} = data.partnershipYear

    return (
        <SingleItemAccordion defaultExpanded>
            <Accordion.Header>
                <Text variant="xlarge-accent">
                    <Trans>Overview</Trans>
                </Text>
                <Accordion.Chevron />
            </Accordion.Header>
            <Accordion.Content>
                <Inline justifyContent="space-between">
                    <div>
                        <Text variant="xlarge-accent">{paidUsers}</Text>
                        <Text variant="small-subtle">
                            <Plural value={paidUsers} one="Paid user" other="Paid users" />
                        </Text>
                    </div>
                    <div>
                        <Text variant="xlarge-accent">{freeUsers}</Text>
                        <Text variant="small-subtle">
                            <Plural value={freeUsers} one="Free user" other="Free users" />
                        </Text>
                    </div>
                    <div>
                        <Text variant="xlarge-accent">{newClients}</Text>
                        <Text variant="small-subtle">
                            <Plural value={newClients} one="Client added" other="Clients added" />
                        </Text>
                    </div>
                </Inline>
            </Accordion.Content>
        </SingleItemAccordion>
    )
}

export default PartnerOverviewCard
