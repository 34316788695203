import type {SetStateAction} from 'react'
import {useCallback, useEffect, useState} from 'react'

type UsePersistedState<T> = [T, (value: T | ((previousState: T) => T)) => void]

function isFunction(value: unknown): value is Function {
    return toString.call(value) === '[object Function]' || typeof value === 'function'
}

const STORAGE_PREFIX = 'persisted_state_hook:'

export function createPersistedState(
    storageKey: string,
    storage: Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>,
): [
    <T>(key: string, initialValue: T) => UsePersistedState<T>,
    () => void,
    <T>(prop: string) => () => T,
] {
    const safeStorageKey = `${STORAGE_PREFIX}${storageKey}`
    const clear = (): void => {
        storage.removeItem(safeStorageKey)
        window.dispatchEvent(new StorageEvent('storage', {key: safeStorageKey}))
    }

    const get = (prop: string) => () => {
        const item = storage.getItem(safeStorageKey)
        return item ? JSON.parse(item)[prop] : {}
    }

    const usePersistedState = <T>(key: string, initialValue: T): UsePersistedState<T> => {
        let initialPersist: {[x: string]: unknown}

        try {
            const persist = storage.getItem(safeStorageKey)
            initialPersist = persist ? JSON.parse(persist) : {}
        } catch (ignore) {
            initialPersist = {}
        } // eslint-disable-line no-empty

        let initialOrPersistedValue = initialValue

        if (initialPersist && key in initialPersist) {
            initialOrPersistedValue = (initialPersist[key] as T) || initialValue
        }

        const [state, setState] = useState<T>(initialOrPersistedValue)

        const setPersistedState = useCallback(
            (newState: SetStateAction<T>): void => {
                let newValue: T

                if (isFunction(newState)) {
                    newValue = newState(state)
                    setState(newValue)
                } else {
                    setState(newState)
                    newValue = newState
                }

                const persistString = storage.getItem(safeStorageKey)
                const persist = persistString ? JSON.parse(persistString) : {}

                const newItem = JSON.stringify(
                    Object.assign(persist, {
                        [key]: newValue,
                    }),
                )

                storage.setItem(safeStorageKey, newItem)
                window.dispatchEvent(
                    new StorageEvent('storage', {key: safeStorageKey, newValue: newItem}),
                )
            },
            [key, state],
        )

        useEffect(() => {
            const handleStorage = (event: StorageEvent): void => {
                if (event.key === safeStorageKey) {
                    const newState = JSON.parse(event.newValue as string)
                    const newValue = newState && key in newState ? newState[key] : initialValue

                    setState(newValue)
                }
            }

            window.addEventListener('storage', handleStorage)
            return () => {
                window.removeEventListener('storage', handleStorage)
            }
        }, []) // eslint-disable-line react-hooks/exhaustive-deps

        return [state, setPersistedState]
    }

    return [usePersistedState, clear, get]
}
