import {Trans} from '@lingui/macro'

import {Button, Text, theme} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import type {DispatchActions, Step} from '@product-web-features/ui-guides/guide'

import {SingleEntityIntroduction} from './single-entity-introduction'
import {PlayVideoWithIcon} from './video'

import MultiEntityBuildingsConnected from '../../../images/multi-entity-buildings-connected.svg'

export const useIntroStep = (): Step => {
    const color = theme.colors.pink200
    return {
        key: 'single-entity-admin-introduction',
        color,
        illustrationBgColor: color,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Manage all your companies at once</Trans>
            </Text>
        ),
        sections: [
            {
                key: 'request-entities-form',
                content: <SingleEntityIntroduction />,
            },
        ],
        illustration: (
            <>
                <img src={MultiEntityBuildingsConnected} alt="" />
                <PlayVideoWithIcon />
            </>
        ),
        actions: [
            {
                key: 'cancel',
                action: ({onCompleted}: DispatchActions) => (
                    <Button variant="secondary" onClick={onCompleted}>
                        <Trans>Cancel</Trans>
                    </Button>
                ),
            },
            {
                key: 'next-step',
                action: ({onNextStep}: DispatchActions) => (
                    <Button variant="primary" onClick={onNextStep} IconRight={ArrowRight}>
                        <Trans>Continue</Trans>
                    </Button>
                ),
            },
        ],
    }
}
