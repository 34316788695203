import styled from 'styled-components'

import {Box, Text, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'
import CallToAction from '@product-web/telescope-lab/call-to-action/call-to-action'

export const SpanParagraph = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    display: inline-block;
`

export const TwoPanelContainer = styled.div`
    display: grid;
    height: 650px;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    padding: 0;

    @media (max-width: ${breakpoints.desktopUp}) {
        display: block;
        position: relative;
        padding: 0;
    }
`

export const IllustrationPanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${tokens.pink300};

    @media (max-width: ${breakpoints.desktopUp}) {
        display: none;
    }
`

export const IllustrationImage = styled.img`
    background-image: ${(props) => props.src};
`

export const LeftPanelContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const PaywallCallToAction = styled(CallToAction)`
    width: 100%;
    text-align: left;
    background-color: ${tokens.shade000};
`
