import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router'

import type {AgreementStatus} from '@product-web/api-types/direct-debit'
import type {AutoTopUpSettings} from '@product-web/api-types/wallet'

import type {Country} from './wallet.schema'

export interface AgreementCreationNavigationState {
    agreementStepIndex?: number
    preFilledAutoTopUpSettings?: AutoTopUpSettings
    preFilledReserveEnabled?: boolean
    preFilledReserveLimit?: number
    agreementCreationStatus?: AgreementStatus | null
    wasCreationSuccessful?: boolean
    wasReserveActivationSuccessful?: boolean
    backToUrl?: string
}

export function useDirectDebitNavigationState(country: Country): AgreementCreationNavigationState {
    const location = useLocation()

    const {
        wasCreationSuccessful = country === 'DK' || undefined,
        agreementCreationStatus = country === 'DK' ? 'PENDING' : null,
        agreementStepIndex,
        preFilledAutoTopUpSettings,
        preFilledReserveEnabled,
        preFilledReserveLimit,
    } = (location.state ?? {}) as AgreementCreationNavigationState

    return {
        wasCreationSuccessful,
        agreementCreationStatus,
        agreementStepIndex,
        preFilledAutoTopUpSettings,
        preFilledReserveEnabled,
        preFilledReserveLimit,
    }
}

export type DirectDebitProduct = 'auto-top-up' | 'overdraft'

export const useNavigateToDirectDebitCreation = (product: DirectDebitProduct = 'auto-top-up') => {
    const navigate = useNavigate()

    const navigateToDirectDebitCreation = useCallback(
        (state: AgreementCreationNavigationState) => {
            navigate(`/wallet/direct-debit/${product}`, {state})
        },
        [navigate],
    )

    return {
        navigateToDirectDebitCreation,
    }
}
