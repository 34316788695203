import {t, Trans} from '@lingui/macro'
import type {FC, ReactNode, Reducer} from 'react'
import {useEffect, useReducer, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import type {GetReceiptFinderIntegrationResponse} from '@pleo-io/deimos'
import {EmailProvider} from '@pleo-io/deimos'
import {Inline, Stack, Text, tokens} from '@pleo-io/telescope'

import * as tracking from '@product-web/analytics'
import {
    useOAuthStateToken,
    useOAuthUrls,
    useReceiptFinder,
} from '@product-web/api-deimos/receipt-finder'
import {reportError} from '@product-web/error/report'
import {ReactComponent as GoogleLogo} from '@product-web/images/google-logo.svg'
import {ReactComponent as FetchLogo} from '@product-web/images/integrations/fetch.svg'
import {ReactComponent as MicrosoftLogo} from '@product-web/images/microsoft-logo.svg'
import {invariant} from '@product-web/utils'

import {FetchDisableModal} from './fetch-disable-modal'
import SetupGuide from './fetch-guide/setup-modal'
import {trackProviderSelected} from './fetch-guide/setup-modal-tracking'
import {FetchGuideStep} from './fetch-guide/steps'

import {bff} from '../../bff-hooks'
import {IntegrationName} from '../constants'
import {Integration} from '../integration'

export type FetchInboxProviderInformation = {
    title: string
    subtitle?: string
    logo?: ReactNode
    onClick?: () => void
    href?: string
    redirectUri?: string
}

export type FetchInboxProviders = {
    [key: string]: FetchInboxProviderInformation
}

type FetchIntegrationLocationState =
    | {
          integration: IntegrationName
          state: string
          code: string
          provider: EmailProvider
          error: string
      }
    | undefined

export const useFetchProvidersBasics = (): FetchInboxProviders & any => {
    const oAuthUrls = useOAuthUrls()
    if (!oAuthUrls) {
        return null
    }
    return {
        [EmailProvider.GMAIL]: {
            logo: <GoogleLogo />,
            title: t`Google`,
            subtitle: t`Gmail, Google Workspace`,
            href: oAuthUrls[EmailProvider.GMAIL],
            onClick: () => {
                trackProviderSelected(EmailProvider.GMAIL)
            },
        },
        [EmailProvider.OFFICE365]: {
            logo: <MicrosoftLogo />,
            title: t`Microsoft`,
            subtitle: t`Microsoft 365, Office 365, Outlook or Hotmail`,
            href: oAuthUrls[EmailProvider.OFFICE365],
            onClick: () => {
                trackProviderSelected(EmailProvider.OFFICE365)
            },
        },
    }
}

interface GuideState {
    isVisible: boolean
    isConnecting: boolean
    activeStep: FetchGuideStep
    showError: boolean
    showHasMissingReceipts: boolean
}
const FetchGuideDefaultState = {
    isVisible: false,
    isConnecting: false,
    activeStep: FetchGuideStep.INTRO,
    showError: false,
    showHasMissingReceipts: false,
}
type GuideAction =
    | {type: 'SHOW'}
    | {type: 'RESET'}
    | {type: 'SHOW_ERROR'}
    | {type: 'SHOW_PROVIDER_CONNECTING'}
    | {type: 'HIDE_PROVIDER_CONNECTING'}
    | {type: 'SHOW_MISSING_RECEIPTS'; payload: {hasMissingReceipts: boolean}}

const guideReducer: Reducer<GuideState, GuideAction> = (state, action) => {
    switch (action.type) {
        case 'SHOW':
            return {
                ...state,
                isVisible: true,
            }
        case 'RESET':
            return {
                ...FetchGuideDefaultState,
            }
        case 'SHOW_ERROR':
            return {
                ...state,
                isVisible: true,
                showError: true,
                activeStep: FetchGuideStep.RESULT,
            }
        case 'SHOW_MISSING_RECEIPTS':
            return {
                ...state,
                showHasMissingReceipts: action.payload.hasMissingReceipts,
                activeStep: FetchGuideStep.RESULT,
            }
        case 'HIDE_PROVIDER_CONNECTING':
            return {
                ...state,
                isConnecting: false,
            }
        case 'SHOW_PROVIDER_CONNECTING':
            return {
                ...state,
                isVisible: true,
                isConnecting: true,
                activeStep: FetchGuideStep.PROVIDER,
            }
        default:
            return state
    }
}

const FetchIntegrationContainer: FC = () => {
    const [guideState, dispatch] = useReducer(guideReducer, FetchGuideDefaultState)
    const location = useLocation()

    const navigate = useNavigate()
    // const locationState = location.state as FetchIntegrationLocationState

    const [locationState, _ignoredSetLocationState] = useState<FetchIntegrationLocationState>(
        location.state as FetchIntegrationLocationState,
    )
    const authCallbackCode = (
        locationState?.integration === IntegrationName.FETCH ? locationState?.code ?? '' : ''
    ).trim()
    const authCallbackState = (
        locationState?.integration === IntegrationName.FETCH ? locationState?.state ?? '' : ''
    ).trim()
    const stateToken = useOAuthStateToken()

    const {
        mutations: {scheduleSearch},
    } = useReceiptFinder()

    const {data: activeIntegration, isLoading: isActiveIntegrationLoading} =
        bff.featureIntegrations.fetch.getActiveIntegration.useQuery()

    const {mutateAsync: disableIntegration} =
        bff.featureIntegrations.fetch.disableIntegration.useMutation()

    const {mutateAsync: setAuthCode} = bff.featureIntegrations.fetch.setAuthCode.useMutation()

    const onEnable = () => {
        tracking.fetchOnboardingActioned({
            step: 'settings',
            action: 'started',
        })
        dispatch({type: 'SHOW'})
    }

    const onDisable = async () => {
        const provider = activeIntegration?.provider
        invariant(provider, 'Expected provider to be defined while disabling the integration')

        await disableIntegration({provider})
        tracking.fetchOnboardingActioned({step: 'settings', action: 'disabled'})
    }

    useEffect(() => {
        const authCallbackProvider = locationState?.provider
        const authCallbackError = locationState?.error?.trim()
        if (authCallbackError) {
            dispatch({type: 'SHOW_ERROR'})
            return
        }
        if (authCallbackProvider && stateToken && stateToken !== authCallbackState) {
            dispatch({type: 'SHOW_ERROR'})
            return
        }

        if (!stateToken || !authCallbackProvider || !authCallbackCode || !authCallbackState) {
            return
        }

        const enableIntegration = async () => {
            try {
                await setAuthCode({
                    emailProvider: authCallbackProvider,
                    code: authCallbackCode,
                    location: window.location,
                })
                const hasMissingReceipts = await scheduleSearch()
                dispatch({type: 'SHOW_MISSING_RECEIPTS', payload: {hasMissingReceipts}})
            } catch (error) {
                dispatch({type: 'SHOW_ERROR'})
                reportError(error)
            } finally {
                dispatch({type: 'HIDE_PROVIDER_CONNECTING'})
                navigate(location.pathname, {replace: true})
            }
        }
        dispatch({type: 'SHOW_PROVIDER_CONNECTING'})
        enableIntegration()
    }, [stateToken, authCallbackState, authCallbackCode])

    const resetGuide = () => {
        dispatch({type: 'RESET'})
    }

    const guide = (
        <SetupGuide
            isOpen={guideState.isVisible}
            isConnecting={guideState.isConnecting}
            activeStep={guideState.activeStep}
            hasError={guideState.showError}
            hasMissingReceipts={guideState.showHasMissingReceipts}
            onClose={resetGuide}
            onDismiss={resetGuide}
        />
    )

    return (
        <FetchIntegration
            isEnabled={!!activeIntegration}
            isFetching={isActiveIntegrationLoading}
            onEnable={onEnable}
            onDisable={onDisable}
            enabledIntegration={activeIntegration?.integration}
            enabledIntegrationProvider={activeIntegration?.provider}
            guide={guide}
        />
    )
}

interface FetchIntegrationProps {
    isEnabled?: boolean
    isFetching: boolean
    enabledIntegration?: GetReceiptFinderIntegrationResponse
    enabledIntegrationProvider?: EmailProvider
    onEnable?: () => void
    onDisable?: () => void
    guide?: ReactNode
}

export const FetchIntegration: FC<FetchIntegrationProps> = ({
    isEnabled = false,
    isFetching,
    enabledIntegration,
    enabledIntegrationProvider,
    onEnable = () => {},
    onDisable = () => {},
    guide,
}) => {
    const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false)
    const providersInformation = useFetchProvidersBasics()

    const onDisableButtonClick = () => {
        tracking.fetchOnboardingActioned({step: 'settings', action: 'disable_click'})
        tracking.fetchOffboardingActioned({action: 'started'})
        setIsDisableModalOpen(true)
    }

    const onDisableConfirmButtonClick = () => {
        onDisable()
        setIsDisableModalOpen(false)
    }

    const onModalDismiss = () => {
        setIsDisableModalOpen(false)
    }

    return (
        <>
            <FetchDisableModal
                isOpen={isDisableModalOpen}
                onDisable={onDisableConfirmButtonClick}
                onDismiss={onModalDismiss}
            />
            <Integration
                enabled={isEnabled}
                fetching={isFetching}
                integration={IntegrationName.FETCH}
                logo={<FetchLogo width="100%" height="100%" />}
                onEnable={onEnable}
                onDisable={onDisableButtonClick}
                description={t`Automatically attaches receipts from your email inbox`}
                link="https://pleo.io/fetch"
                onLinkClick={() => {
                    tracking.fetchOnboardingActioned({
                        step: 'settings',
                        action: 'learn_more_clicked',
                    })
                }}
            >
                {enabledIntegration && providersInformation && (
                    <IntegrationInfoRow data-testid="fetch-enabled-integration-details">
                        <ImageWrapper>
                            {enabledIntegrationProvider &&
                                providersInformation[enabledIntegrationProvider].logo}
                        </ImageWrapper>
                        <Stack>
                            <Text as="p" weight="medium">
                                {enabledIntegration.emailAddress}
                            </Text>
                            {enabledIntegration.active ? (
                                <Text variant="small-subtle" color="shade700">
                                    {enabledIntegrationProvider &&
                                        providersInformation[enabledIntegrationProvider].subtitle}
                                </Text>
                            ) : (
                                <Text variant="small-subtle" color="red700">
                                    <Trans>Disconnected</Trans>
                                </Text>
                            )}
                        </Stack>
                    </IntegrationInfoRow>
                )}
                {guide}
            </Integration>
        </>
    )
}

const IntegrationInfoRow = styled(Inline)`
    margin-left: calc(${tokens.spacing56} + ${tokens.spacing24});
    margin-top: ${tokens.spacing12};
    padding: ${tokens.spacing12};
    background-color: ${tokens.shade200};
    border-radius: ${tokens.arc8};
    width: 320px;
`

const ImageWrapper = styled(Stack)`
    padding: ${tokens.spacing6};
    background-color: ${tokens.shade000};
    border-radius: ${tokens.arc8};
    max-width: ${tokens.spacing44};
    margin-right: ${tokens.spacing8};
`

export default FetchIntegrationContainer
