import {useState} from 'react'

type ModalType = 'closeEarly' | 'downloadExpenses' | 'deleteBudget'

type CloseEarlyModal = {
    isOpen: boolean
    open: () => void
    onConfirm: () => void
    onCancel: () => void
}

type DownloadExpensesModal = {
    isOpen: boolean
    open: () => void
    onCancel: () => void
    onConfirm: () => void
}

type DeleteBudgetModal = {
    isOpen: boolean
    open: () => void
    onCancel: () => void
    onConfirm: () => void
}

export type BudgetModals = {
    closeEarly: CloseEarlyModal
    downloadExpenses: DownloadExpensesModal
    deleteBudget: DeleteBudgetModal
}

export const useTagBudgetModals = (): {modals: BudgetModals} => {
    const [isCloseEarlyModalOpen, setIsCloseEarlyModalOpen] = useState<boolean>(false)
    const [isDownloadExpensesModalOpen, setIsDownloadExpensesModalOpen] = useState<boolean>(false)
    const [isDeleteBudgetModalOpen, setIsDeleteBudgetModalOpen] = useState<boolean>(false)

    const isModalOpen =
        isCloseEarlyModalOpen || isDownloadExpensesModalOpen || isDeleteBudgetModalOpen

    function changeModalState({modalType, isOpen}: {modalType: ModalType; isOpen: boolean}) {
        const modalMap = {
            closeEarly: () => setIsCloseEarlyModalOpen(isOpen),
            downloadExpenses: () => setIsDownloadExpensesModalOpen(isOpen),
            deleteBudget: () => setIsDeleteBudgetModalOpen(isOpen),
        }

        modalMap[modalType]?.()
    }

    /**
     * Opens a modal of the specified type.
     * Ensures we cannot open if there's a modal already open.
     *
     * @param {ModalType} modalType - The type of the modal to close.
     */
    function openModal(modalType: ModalType) {
        if (!isModalOpen) {
            changeModalState({modalType, isOpen: true})
        }
    }

    /**
     * Closes the specified modal.
     *
     * @param {ModalType} modalType - The type of the modal to close.
     */
    function closeModal(modalType: ModalType) {
        changeModalState({modalType, isOpen: false})
    }

    return {
        modals: {
            closeEarly: {
                isOpen: isCloseEarlyModalOpen,
                open: () => openModal('closeEarly'),
                onConfirm: () => closeModal('closeEarly'),
                onCancel: () => closeModal('closeEarly'),
            },
            downloadExpenses: {
                isOpen: isDownloadExpensesModalOpen,
                open: () => openModal('downloadExpenses'),
                onCancel: () => closeModal('downloadExpenses'),
                onConfirm: () => closeModal('downloadExpenses'),
            },
            deleteBudget: {
                isOpen: isDeleteBudgetModalOpen,
                open: () => openModal('deleteBudget'),
                onCancel: () => closeModal('deleteBudget'),
                onConfirm: () => closeModal('deleteBudget'),
            },
        },
    }
}
