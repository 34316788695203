/**
 * Checks if a passed value is an object with a provided key defined
 * Correctly narrows a type from unknown to allow type-safe value access
 * @param key Name of the key to check
 * @param obj Value to be checked
 * @returns a boolean indicating if the passed value is an object with a key
 */
export function hasKey<Key extends string>(key: Key, obj: unknown): obj is {[_ in Key]: unknown} {
    return typeof obj === 'object' && obj !== null && key in obj
}
