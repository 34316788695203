import type {ReactNode} from 'react'
import React, {useEffect, useState} from 'react'

export enum PaywallView {
    INITIAL = 'INITIAL',
    VIEW_TRANSFER = 'VIEW_TRANSFER',
    PENDING_TRANSFER = 'PENDING_TRANSFER',
    GET_HELP = 'GET_HELP',
}

interface PaywallContextValue {
    view: PaywallView
    setView: (view: PaywallView) => void
    didSubmitFeedback: boolean
    setDidSubmitFeedback: (didSubmitFeedback: boolean) => void
    didViewPendingTransfer?: boolean
}

const PaywallContext = React.createContext<PaywallContextValue>({
    view: PaywallView.INITIAL,
    setView: () => {
        throw new Error('No provider found, setView requires <PaywallContextProvider ... />')
    },
    didSubmitFeedback: false,
    setDidSubmitFeedback: () => {
        throw new Error(
            'No provider found, setDidSubmitFeedback requires <PaywallContextProvider ... />',
        )
    },
})

const PaywallContextProvider = ({
    children,
    initialView = PaywallView.INITIAL,
}: {
    children?: ReactNode
    initialView?: PaywallView
}) => {
    const [view, setView] = useState(initialView)
    const [didSubmitFeedback, setDidSubmitFeedback] = useState(false)
    const [didViewPendingTransfer, setDidViewPendingTransfer] = useState(false)
    useEffect(() => {
        if (view === PaywallView.PENDING_TRANSFER && !didViewPendingTransfer) {
            setDidViewPendingTransfer(true)
        }
    }, [view, didViewPendingTransfer])
    return (
        <PaywallContext.Provider
            value={{
                view,
                setView,
                didSubmitFeedback,
                setDidSubmitFeedback,
                didViewPendingTransfer,
            }}
        >
            {children}
        </PaywallContext.Provider>
    )
}

export const usePaywallContext = () => React.useContext(PaywallContext)

export default PaywallContextProvider
