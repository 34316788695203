import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import type {Amount} from '@pleo-io/deimos'
import {Box, Button, Inline, Modal, Stack, Text, tokens} from '@pleo-io/telescope'
import {CashSingle, Clock} from '@pleo-io/telescope-icons'

import {paywallActioned} from '@product-web/analytics'
import {dayjs} from '@product-web/dates/dayjs'
import {formatCurrency} from '@product-web/locale/currency'
import {breakpoints} from '@product-web/styles/theme'
import CallToAction from '@product-web/telescope-lab/call-to-action/call-to-action'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import GetHelpIcon from './images/get-help.svg'
import paywallBackground from './images/illustration-paywall-modal.svg'
import TopUpCallToActionIcon from './images/top-up-call-to-action.svg'
import {getPaywallTrackingView} from './paywall'
import {
    IllustrationImage,
    IllustrationPanel,
    LeftPanelContainer,
    SpanParagraph,
    TwoPanelContainer,
} from './paywall.styles'
import {PaywallView, usePaywallContext} from './paywall-context'
import PaywallLinkWithTracking from './paywall-link-with-tracking'

interface ModalAccountSuspendedBasicProps {
    isSuspended: boolean
    amount: Amount
    isDismissible: boolean
    onClose: () => void
    onNext: () => void
    firstPaidInvoiceDate: string | undefined
}

const StyledCallToAction = styled(CallToAction)`
    width: 100%;
    text-align: left;
    background-color: ${tokens.shade000};
`

const FlexContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const CloseButton = styled(Modal.Close)`
    margin: ${tokens.spacing20};
    border-radius: ${tokens.circle};
    background-color: ${tokens.shade000};
    padding: ${tokens.spacing10};

    @media (max-width: ${breakpoints.desktopUp}) {
        z-index: ${tokens.zIndexModal};
    }
`

export const ModalAccountSuspendedBasic: FC<ModalAccountSuspendedBasicProps> = ({
    isSuspended,
    amount,
    isDismissible,
    onNext,
    onClose,
    firstPaidInvoiceDate,
}) => {
    const navigate = useNavigate()
    const {view, setView} = usePaywallContext()
    const SuspendedModalFooter = () => (
        <Modal.Footer data-testid="suspended-modal-footer">
            <Stack align="center">
                {isSuspended ? (
                    <Text as="div" color="shade600">
                        <Trans>
                            Don't want to continue?{' '}
                            <PaywallLinkWithTracking
                                data-testid="export-data-link"
                                onClick={onClose}
                                as={RouterLink}
                                to="/export?exportLane=NOT_EXPORTED"
                                isDismissible={isDismissible}
                                trackingLabel="export_data"
                            >
                                Export data
                            </PaywallLinkWithTracking>{' '}
                            or{' '}
                            <PaywallLinkWithTracking
                                data-testid="close-account-link"
                                onClick={onClose}
                                as={RouterLink}
                                to={'/settings/general/billing?customerOffboarding'}
                                isDismissible={isDismissible}
                                trackingLabel="close_account"
                            >
                                Close account
                            </PaywallLinkWithTracking>
                        </Trans>
                    </Text>
                ) : (
                    <Text as="div" color="shade600" data-testid="chat-to-us-link">
                        <Trans>Need help?</Trans>{' '}
                        <ContactSupport
                            chatLabel={t`Chat to us`}
                            onClick={() => {
                                const trackingView = getPaywallTrackingView(view, !isDismissible)
                                if (trackingView) {
                                    paywallActioned({
                                        action: 'support_chat',
                                        is_dismissible: isDismissible,
                                        view: trackingView,
                                    })
                                }
                                if (!isDismissible) {
                                    navigate('/export')
                                } else {
                                    onClose()
                                }
                            }}
                        />
                    </Text>
                )}
            </Stack>
        </Modal.Footer>
    )

    return (
        <TwoPanelContainer>
            <LeftPanelContainer data-testid="modal-account-suspended">
                <Box marginTop={56}>
                    {isSuspended ? (
                        <>
                            <Box marginBottom={24}>
                                <Modal.Title>
                                    <Text
                                        align="left"
                                        variant="3xlarge-accent"
                                        data-testid="on-hold-account-title"
                                    >
                                        <Trans>Your account is on hold</Trans>
                                    </Text>
                                </Modal.Title>
                            </Box>
                            <Modal.Content>
                                <Box marginBottom={14}>
                                    <Text align="left" data-testid="wallet-pay-title">
                                        <Trans>
                                            There isn't enough money in your wallet to pay your Pleo
                                            subscription fees. The amount owed is{' '}
                                            <SpanParagraph weight="bold">
                                                {formatCurrency(amount.value, amount.currency)}
                                            </SpanParagraph>
                                            .{' '}
                                            <PaywallLinkWithTracking
                                                data-testid="invoice-link"
                                                onClick={onClose}
                                                as={RouterLink}
                                                to="/invoices"
                                                hidden={!isDismissible}
                                                isDismissible={isDismissible}
                                                trackingLabel="view_invoice"
                                            >
                                                View invoice
                                            </PaywallLinkWithTracking>
                                        </Trans>
                                    </Text>
                                </Box>

                                <Text align="left">
                                    <Trans>
                                        To reactivate your account, simply top up your wallet. We
                                        recommend adding enough funds to cover your team's expenses
                                        for the next month or two.{' '}
                                        <PaywallLinkWithTracking
                                            data-testid="wallet-link"
                                            onClick={onClose}
                                            as={RouterLink}
                                            to="/wallet"
                                            hidden={!isDismissible}
                                            isDismissible={isDismissible}
                                            trackingLabel="view_wallet"
                                        >
                                            View wallet
                                        </PaywallLinkWithTracking>
                                    </Trans>
                                </Text>

                                <Box marginTop={40} marginBottom={16}>
                                    <StyledCallToAction
                                        data-testid="top-up-wallet-cta"
                                        image={TopUpCallToActionIcon}
                                        onClick={() => setView(PaywallView.VIEW_TRANSFER)}
                                    >
                                        <Inline alignY="center" space={8}>
                                            <Text variant="large-accent">{t`Top up your wallet`}</Text>
                                        </Inline>
                                        <Text as="div" color="shade600">
                                            {t`To reactivate your Pleo account`}
                                        </Text>
                                    </StyledCallToAction>
                                </Box>

                                {/* Here should be handler for opening modal with Get Help modal */}
                                <StyledCallToAction
                                    data-testid="get-help-cta"
                                    image={GetHelpIcon}
                                    onClick={() => setView(PaywallView.GET_HELP)}
                                >
                                    <Inline alignY="center" space={8}>
                                        <Text variant="large-accent">{t`Get help`}</Text>
                                    </Inline>
                                    <Text as="div" color="shade600">
                                        {t`Talk to someone at Pleo`}
                                    </Text>
                                </StyledCallToAction>
                            </Modal.Content>
                        </>
                    ) : (
                        <Box>
                            <Box>
                                <Box marginTop={60}>
                                    <Modal.Title>
                                        <Text
                                            align="left"
                                            variant="3xlarge-accent"
                                            data-testid="wallet-balance-title"
                                        >
                                            <Trans>Your wallet balance is too low</Trans>
                                        </Text>
                                    </Modal.Title>
                                </Box>

                                <Modal.Content>
                                    <Box marginBottom={14}>
                                        <Text align="left" data-testid="wallet-balance-sub-title">
                                            <Trans>
                                                We weren't able to take payment for your Pleo
                                                subscription fees.
                                            </Trans>
                                        </Text>
                                    </Box>

                                    <Box marginBottom={14}>
                                        <Text align="left" data-testid="top-up-your-wallet">
                                            <Trans>
                                                Top up your wallet with at least{' '}
                                                <SpanParagraph weight="bold">
                                                    {formatCurrency(amount.value, amount.currency)}
                                                </SpanParagraph>{' '}
                                                by{' '}
                                                <SpanParagraph weight="bold">
                                                    {' '}
                                                    {dayjs(firstPaidInvoiceDate).format(
                                                        'Do MMMM YYYY',
                                                    )}
                                                </SpanParagraph>{' '}
                                                to keep your Pleo account active.
                                            </Trans>
                                        </Text>
                                    </Box>

                                    <FlexContainer>
                                        <Box marginTop={48} marginBottom={48}>
                                            <Button
                                                variant="primary"
                                                onClick={onNext}
                                                data-testid="top-up-wallet-button"
                                            >
                                                <Trans>Top up wallet</Trans>
                                            </Button>
                                        </Box>

                                        <Box marginBottom={16}>
                                            <Text>
                                                <CashSingle mr={8} />
                                                <Trans>
                                                    View the{' '}
                                                    <PaywallLinkWithTracking
                                                        data-testid="unpaid-invoices-link"
                                                        onClick={onClose}
                                                        as={RouterLink}
                                                        to="/settings/general/billing"
                                                        isDismissible={isDismissible}
                                                        trackingLabel="unpaid_invoice"
                                                    >
                                                        unpaid invoice
                                                    </PaywallLinkWithTracking>
                                                </Trans>
                                            </Text>
                                        </Box>

                                        <Box>
                                            <Text>
                                                <Clock mr={8} />
                                                <Trans>
                                                    Check your{' '}
                                                    <PaywallLinkWithTracking
                                                        data-testid="wallet-link"
                                                        onClick={onClose}
                                                        as={RouterLink}
                                                        to="/wallet"
                                                        color={tokens.pink900}
                                                        isDismissible={isDismissible}
                                                        trackingLabel="wallet"
                                                    >
                                                        wallet
                                                    </PaywallLinkWithTracking>
                                                </Trans>
                                            </Text>
                                        </Box>
                                    </FlexContainer>
                                </Modal.Content>
                            </Box>
                        </Box>
                    )}
                </Box>
                <SuspendedModalFooter />
            </LeftPanelContainer>
            <IllustrationPanel>
                <IllustrationImage
                    alt={t`Person with hands over their eyes`}
                    src={paywallBackground}
                />
            </IllustrationPanel>
            {isDismissible ? (
                <CloseButton
                    onClick={() => onClose()}
                    data-testid="modal-account-suspended-close-button"
                />
            ) : null}
        </TwoPanelContainer>
    )
}
