import React from 'react'
import {Route} from 'react-router-dom'

import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {roleSets} from '@product-web/user'

const OAuth2ErrorPage = React.lazy(async () => import('./screens/oauth2-error'))
const OAuth2AuthorizationPage = React.lazy(async () => import('./screens/oauth2-authorization'))

export const oauth2Routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.all}}>
        <Route path="error" element={<OAuth2ErrorPage />} />
        <Route path="authorize" element={<OAuth2AuthorizationPage />} />
        <Route index element={<PageNotFound />} />
    </Route>
)
