import type {DataAttributes} from 'styled-components'
import styled, {keyframes} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

const spin = keyframes`
    0% {
        animation-timing-function: cubic-bezier(0.6, 0.1, 0.4, 0.9);
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
`

/**
 *
 * @deprecated Use `@pleo-io/telescope` Spinner component instead
 */
export const SpinnerIcon = styled.div.attrs<DataAttributes>({'data-generic-ui': 'spinner'})`
    box-sizing: border-box;
    animation: ${spin} 1s infinite linear;
    width: 19px;
    height: 19px;
    border: ${`3px solid ${tokens.shade300}`};
    border-top: ${`3px solid ${tokens.shade600}`};
    border-radius: ${tokens.circle};
`
