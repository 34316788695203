import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

import {WalletRouteErrorBoundary} from './components/wallet-error-boundary'

const EditOverdraftPage = React.lazy(async () => import('./screens/overdraft/edit-overdraft'))

const AutoTopUpActivationPage = React.lazy(
    async () => import('./screens/auto-top-up-activation/auto-top-up-activation'),
)
const ChargebackPage = React.lazy(async () => import('./screens/chargeback/chargeback'))
const DirectDebitPage = React.lazy(async () => import('./screens/direct-debit/direct-debit'))
const ManageWalletPage = React.lazy(async () => import('./screens/manage-wallet/manage-wallet'))
const OverdraftStatementsPage = React.lazy(
    async () => import('./screens/overdraft/overdraft-statements'),
)
const ChangeOverdraftLimitPage = React.lazy(
    async () => import('./screens/overdraft/change-limit/change-overdraft-limit'),
)

const OverdraftEligibilityApplication = React.lazy(
    async () =>
        import(
            '@product-web-features/funds-management/credit/components/overdraft-eligibility-application/overdraft-eligibility-application'
        ),
)

const EligibilityApplicationSuccessPage = React.lazy(
    async () =>
        import(
            '@product-web-features/funds-management/credit/components/overdraft-eligibility-application/eligibility-application-success-page'
        ),
)
const OverdraftEligibilityApplicationInfoRequired = React.lazy(
    async () =>
        import(
            '@product-web-features/funds-management/credit/overdraft-eligibility-application-info-required'
        ),
)

const OverdraftApplicationRejectionInfo = React.lazy(
    async () =>
        import(
            '@product-web-features/funds-management/credit/overdraft-application-rejection-info'
        ),
)

const OverdraftApplicationApprovalInfo = React.lazy(
    async () =>
        import('@product-web-features/funds-management/credit/overdraft-application-approval-info'),
)

const OverdraftInfoRequiredSuccess = React.lazy(
    async () =>
        import('@product-web-features/funds-management/credit/overdraft-info-required-success'),
)

const HigherLimitApplication = React.lazy(
    async () => import('./screens/overdraft/higher-limit-application/higher-limit-application'),
)

const HigherLimitInfoRequired = React.lazy(
    async () => import('./screens/overdraft/higher-limit-info-required'),
)

const ApplicationSuccessPage = React.lazy(
    async () =>
        import(
            './screens/overdraft/higher-limit-application/higher-limit-application-success-page'
        ),
)
const UnloadPage = React.lazy(async () => import('./screens/unload/unload'))
const WalletHistoryPage = React.lazy(async () => import('./screens/wallet-history/wallet-history'))
const WalletPage = React.lazy(async () => import('./screens/wallet/wallet'))
const SubWalletCreationPage = React.lazy(
    async () => import('./screens/sub-wallet/sub-wallet-creation'),
)
const WalletWithdrawalPage = React.lazy(
    async () => import('./screens/wallet-withdrawal/wallet-withdrawal'),
)

const HigherLimitApplicationRejectionInfo = React.lazy(
    async () => import('./screens/overdraft/higher-limit-rejection-info'),
)

export const routes = (
    <Route element={<WalletRouteErrorBoundary />}>
        <Route
            path="chargeback"
            element={<ChargebackPage />}
            handle={{
                auth: true,
                allowedRoles: roleSets.company,
            }}
        />
        <Route
            handle={{
                auth: true,
                minCompanyStatus: 'sdd',
                allowedRoles: roleSets.ownerAndBookkeeper,
            }}
        >
            <Route index element={<WalletPage />} />
            <Route path="auto-top-up-activation" element={<AutoTopUpActivationPage />} />

            <Route path="manage/overdraft/statements" element={<OverdraftStatementsPage />} />
            <Route path="manage/overdraft/change-limit" element={<ChangeOverdraftLimitPage />} />
            <Route path="manage/:product?" element={<ManageWalletPage />} />

            <Route path="direct-debit" element={<DirectDebitPage />} />
            <Route path="direct-debit/:product" element={<DirectDebitPage />} />

            <Route path="create-sub-wallet" element={<SubWalletCreationPage />} />

            <Route path="withdrawal" element={<WalletWithdrawalPage />} />
            <Route path="history" element={<WalletHistoryPage />} />

            <Route path="unload" element={<UnloadPage />} />
            <Route path="unload/:account" element={<UnloadPage />} />

            <Route path="overdraft/activate" element={<EditOverdraftPage operation="activate" />} />
            <Route path="overdraft/renew" element={<EditOverdraftPage operation="renew" />} />

            <Route
                path="overdraft/eligibility-application"
                element={<OverdraftEligibilityApplication />}
            />
            <Route
                path="overdraft/eligibility-application/success"
                element={<EligibilityApplicationSuccessPage />}
            />
            <Route
                path="overdraft/eligibility-application/info-required"
                element={<OverdraftEligibilityApplicationInfoRequired />}
            />

            <Route
                path="overdraft/eligibility-application/info-required/success"
                element={<OverdraftInfoRequiredSuccess />}
            />

            <Route
                path="overdraft-application-rejection-info"
                element={<OverdraftApplicationRejectionInfo />}
            />

            <Route
                path="overdraft-application-approval-info"
                element={<OverdraftApplicationApprovalInfo />}
            />

            <Route path="overdraft/higher-limit-application" element={<HigherLimitApplication />} />
            <Route
                path="overdraft/higher-limit-application/info-required"
                element={<HigherLimitInfoRequired />}
            />
            <Route
                path="overdraft/higher-limit-info-required/success"
                element={<OverdraftInfoRequiredSuccess />}
            />
            <Route
                path="overdraft/higher-limit-application/success"
                element={<ApplicationSuccessPage />}
            />
            <Route
                path="higher-limit-rejection-info"
                element={<HigherLimitApplicationRejectionInfo />}
            />
            <Route
                path="higher-limit-approval-info"
                element={<OverdraftApplicationApprovalInfo />}
            />
        </Route>
    </Route>
)
