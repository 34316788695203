import {getExportUrlWithoutExpenseId} from '@product-web/navigation/helpers'

function getAccountCardsRedirect(
    location: {pathname: string},
    context: {isSpendingEntity?: boolean; isOrganizationAdmin?: boolean},
) {
    const isAccountCardsPathAndSpendingEntityWithoutOrgAdmin =
        location.pathname.includes('/account/cards') &&
        !!context.isSpendingEntity &&
        !context.isOrganizationAdmin

    if (isAccountCardsPathAndSpendingEntityWithoutOrgAdmin) {
        return '/expenses'
    }

    return null
}

function getPartnerRedirect(location: {pathname: string}) {
    if (new RegExp('/partner').test(location.pathname)) {
        return '/expenses'
    }

    return null
}

function getExportRedirect({pathname}: {pathname: string}) {
    if (new RegExp('/export').test(pathname)) {
        return getExportUrlWithoutExpenseId(pathname)
    }

    return null
}

function determineRedirectDestination(
    location: {pathname: string; search?: string},
    context: {isSpendingEntity?: boolean; isOrganizationAdmin?: boolean},
) {
    const redirectFunctions = [
        () => getAccountCardsRedirect(location, context),
        () => getPartnerRedirect(location),
        () => getExportRedirect(location),
    ]

    for (const getRedirect of redirectFunctions) {
        const redirectTo = getRedirect()
        if (redirectTo) {
            return redirectTo
        }
    }

    return null
}

function getStrippedLocation(location: {pathname: string}) {
    const locationsToStrip = [
        '/expenses',
        '/recurring-vendors',
        '/vendor-cards',
        '/people/teams',
        '/company-verification',
        '/budgets',
        '/review',
    ]

    return locationsToStrip.find((path) => new RegExp(`^${path}`).test(location.pathname))
}

export function getSwitchAccountRedirectLocation(
    location: {pathname: string; search?: string; hash?: string},
    context: {isSpendingEntity?: boolean; isOrganizationAdmin?: boolean},
) {
    const redirectDestination = determineRedirectDestination(location, context)
    if (redirectDestination) {
        return redirectDestination
    }

    const strippedLocation = getStrippedLocation(location)
    if (strippedLocation) {
        return strippedLocation
    }

    const currentLocation = `${location.pathname}${location.search ?? ''}${location.hash ?? ''}`
    return currentLocation
}
