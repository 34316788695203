import {SupportedLanguage, useAppLanguage} from '@product-web/i18n'

import bronzeBadgeDA from '../../../images/partner-tiers/bronze-da.png'
import bronzeBadgeDE from '../../../images/partner-tiers/bronze-de.png'
import bronzeBadgeEN from '../../../images/partner-tiers/bronze-en.png'
import bronzeBadgeFI from '../../../images/partner-tiers/bronze-fi.png'
import bronzeBadgeFR from '../../../images/partner-tiers/bronze-fr.png'
import bronzeBadgeIT from '../../../images/partner-tiers/bronze-it.png'
import goldBadgeDA from '../../../images/partner-tiers/gold-da.png'
import goldBadgeDE from '../../../images/partner-tiers/gold-de.png'
import goldBadgeEN from '../../../images/partner-tiers/gold-en.png'
import goldBadgeFI from '../../../images/partner-tiers/gold-fi.png'
import goldBadgeFR from '../../../images/partner-tiers/gold-fr.png'
import goldBadgeIT from '../../../images/partner-tiers/gold-it.png'
import newPartnerBadgeDA from '../../../images/partner-tiers/new-partner-da.png'
import newPartnerBadgeDE from '../../../images/partner-tiers/new-partner-de.png'
import newPartnerBadgeEN from '../../../images/partner-tiers/new-partner-en.png'
import newPartnerBadgeES from '../../../images/partner-tiers/new-partner-es.png'
import newPartnerBadgeFI from '../../../images/partner-tiers/new-partner-fi.png'
import newPartnerBadgeFR from '../../../images/partner-tiers/new-partner-fr.png'
import newPartnerBadgeIT from '../../../images/partner-tiers/new-partner-it.png'
import newPartnerBadgeNL from '../../../images/partner-tiers/new-partner-nl.png'
import newPartnerBadgePT from '../../../images/partner-tiers/new-partner-pt.png'
import newPartnerBadgeSV from '../../../images/partner-tiers/new-partner-sv.png'
import platinumBadgeDA from '../../../images/partner-tiers/platinum-da.png'
import platinumBadgeDE from '../../../images/partner-tiers/platinum-de.png'
import platinumBadgeEN from '../../../images/partner-tiers/platinum-en.png'
import platinumBadgeFI from '../../../images/partner-tiers/platinum-fi.png'
import platinumBadgeFR from '../../../images/partner-tiers/platinum-fr.png'
import platinumBadgeIT from '../../../images/partner-tiers/platinum-it.png'
import silverBadgeDA from '../../../images/partner-tiers/silver-da.png'
import silverBadgeDE from '../../../images/partner-tiers/silver-de.png'
import silverBadgeEN from '../../../images/partner-tiers/silver-en.png'
import silverBadgeFI from '../../../images/partner-tiers/silver-fi.png'
import silverBadgeFR from '../../../images/partner-tiers/silver-fr.png'
import silverBadgeIT from '../../../images/partner-tiers/silver-it.png'
import type {PartnerTierType} from '../../../lib/partner-tier'

export const PartnerTierBadge = ({tier}: {tier: PartnerTierType}) => {
    const language = useAppLanguage()
    return (
        <img
            data-testid="tier-badge-image"
            src={getTierBadgeImage(language)[tier]}
            alt=""
            width="52px"
        />
    )
}

function getTierBadgeImage(language: SupportedLanguage): Record<PartnerTierType, string> {
    switch (language) {
        case SupportedLanguage.DA:
            return {
                TRIAL: newPartnerBadgeDA,
                BRONZE: bronzeBadgeDA,
                SILVER: silverBadgeDA,
                GOLD: goldBadgeDA,
                GOLD_DACH: goldBadgeDA,
                PLATINUM: platinumBadgeDA,
                PLATINUM_DACH: platinumBadgeDA,
            }
        case SupportedLanguage.DE:
        case SupportedLanguage.DE_AT:
            return {
                TRIAL: newPartnerBadgeDE,
                BRONZE: bronzeBadgeDE,
                SILVER: silverBadgeDE,
                GOLD: goldBadgeDE,
                GOLD_DACH: goldBadgeDE,
                PLATINUM: platinumBadgeDE,
                PLATINUM_DACH: platinumBadgeDE,
            }
        case SupportedLanguage.ES:
            return {
                TRIAL: newPartnerBadgeES,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
        case SupportedLanguage.FI:
            return {
                TRIAL: newPartnerBadgeFI,
                BRONZE: bronzeBadgeFI,
                SILVER: silverBadgeFI,
                GOLD: goldBadgeFI,
                GOLD_DACH: goldBadgeFI,
                PLATINUM: platinumBadgeFI,
                PLATINUM_DACH: platinumBadgeFI,
            }
        case SupportedLanguage.FR:
        case SupportedLanguage.FR_BE:
            return {
                TRIAL: newPartnerBadgeFR,
                BRONZE: bronzeBadgeFR,
                SILVER: silverBadgeFR,
                GOLD: goldBadgeFR,
                GOLD_DACH: goldBadgeFR,
                PLATINUM: platinumBadgeFR,
                PLATINUM_DACH: platinumBadgeFR,
            }
        case SupportedLanguage.IT:
            return {
                TRIAL: newPartnerBadgeIT,
                BRONZE: bronzeBadgeIT,
                SILVER: silverBadgeIT,
                GOLD: goldBadgeIT,
                GOLD_DACH: goldBadgeIT,
                PLATINUM: platinumBadgeIT,
                PLATINUM_DACH: platinumBadgeIT,
            }
        case SupportedLanguage.NL:
        case SupportedLanguage.NL_BE:
            return {
                TRIAL: newPartnerBadgeNL,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
        case SupportedLanguage.NO:
            return {
                TRIAL: newPartnerBadgeSV,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
        case SupportedLanguage.PT:
            return {
                TRIAL: newPartnerBadgePT,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
        case SupportedLanguage.SV:
            return {
                TRIAL: newPartnerBadgeSV,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
        default:
            return {
                TRIAL: newPartnerBadgeEN,
                BRONZE: bronzeBadgeEN,
                SILVER: silverBadgeEN,
                GOLD: goldBadgeEN,
                GOLD_DACH: goldBadgeEN,
                PLATINUM: platinumBadgeEN,
                PLATINUM_DACH: platinumBadgeEN,
            }
    }
}
