import type {FC, ReactNode} from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {navigationOffset} from '@product-web/navigation/constants'
import {useNavigationWidth, useToggleNavigation} from '@product-web/navigation/navigation-context'
import {breakpoints} from '@product-web/styles/theme'

interface Props {
    id?: string
    children: ReactNode
    className?: string
    compact?: boolean
    contain?: boolean
    hidden?: boolean
    flex?: boolean
    relative?: boolean
    overflowHidden?: boolean
    showNav: boolean
}

/**
 * @deprecated Use '@product-web-features/ui-page/page' instead
 */
export const Page: FC<Props> = ({
    children,
    id,
    className,
    compact,
    contain,
    hidden,
    flex,
    relative,
    overflowHidden,
    showNav,
    ...restProps
}) => {
    useToggleNavigation({
        showNav,
    })
    const {width} = useNavigationWidth()
    return (
        <Wrapper
            id={id}
            className={className}
            $isHidden={hidden}
            $isFlex={flex}
            $isCompact={compact}
            $isContain={contain}
            $isRelative={relative}
            $isOverflowHidden={overflowHidden}
            $navigationWidth={width}
            data-generic-ui="page-legacy"
            {...restProps}
        >
            {children}
        </Wrapper>
    )
}

const flexStyles = css`
    display: flex;
    box-sizing: border-box;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    flex-direction: column;
    flex: 1 1 auto;
`

const hiddenStyles = css`
    opacity: 0;
`

const compactStyles = css`
    padding: 0;

    @media (min-width: ${breakpoints.mobileLrgUp}) {
        padding: 0;
    }

    @media (min-width: ${breakpoints.tabletUp}) {
        padding: 0;
    }
`

const containStyles = css`
    @media (min-width: ${breakpoints.desktopMedUp}) {
        max-width: 1200px;
        margin-left: 0;
    }
`

const overflowHiddenStyles = css`
    overflow: hidden;
`

const relativeStyles = css`
    position: relative;
`

const Wrapper = styled.div<{
    $isFlex?: boolean
    $isHidden?: boolean
    $isRelative?: boolean
    $isOverflowHidden?: boolean
    $isCompact?: boolean
    $isContain?: boolean
    $navigationWidth: number
}>`
    width: 100%;
    max-width: calc(100vw - ${(props) => props.$navigationWidth}px);
    margin: 0;
    box-sizing: border-box;
    padding: ${tokens.spacing16};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        max-width: 100%;
        max-height: calc(100vh - ${navigationOffset});
    }

    @media (min-width: ${breakpoints.mobileLrgUp}) {
        padding: ${tokens.spacing24};
    }

    @media (min-width: ${breakpoints.tabletMedUp}) {
        padding: ${tokens.spacing60};
    }

    ${({$isFlex}) => $isFlex && flexStyles}
    ${({$isHidden}) => $isHidden && hiddenStyles}
    ${({$isRelative}) => $isRelative && relativeStyles}
    ${({$isOverflowHidden}) => $isOverflowHidden && overflowHiddenStyles}
    ${({$isCompact}) => $isCompact && compactStyles}
    ${({$isContain}) => $isContain && containStyles}
`
