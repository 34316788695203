import {t} from '@lingui/macro'
import type React from 'react'

import {Button, Modal, ModalActions, ModalContent, ModalTitle, Text} from '@pleo-io/telescope'

import type {IModal} from '@product-web/shell/legacy/legacy-modals-bridge'
import {__legacyShowModal} from '@product-web/shell/legacy/legacy-modals-bridge'

interface ModalAlertProps extends IModal {
    title: React.ReactNode
    message: React.ReactNode
    confirmLabel: string
}

export function ModalAlert(props: ModalAlertProps) {
    return (
        <Modal onDismiss={() => props._modal?.promise.resolve()} data-generic-ui="alert-modal">
            <ModalTitle>{props.title}</ModalTitle>
            <ModalContent data-testid="modal-content">
                <Text as="p" color="shade600">
                    {props.message}
                </Text>
            </ModalContent>
            <ModalActions>
                <Button
                    variant="primary"
                    onClick={() => props._modal?.promise.resolve()}
                    data-testid="modal-confirm-button"
                >
                    {props.confirmLabel}
                </Button>
            </ModalActions>
        </Modal>
    )
}
type ShowAlertModalArgs = {
    title: React.ReactNode
    message: React.ReactNode
    confirmLabel?: string
}

/**
Shows an alert modal with the specified title and message.
@param args.title - The title of the alert modal.
@param args.message - The message to display in the alert modal.
@param [args.confirmLabel] - The label to display on the confirm button. Defaults to "Close".
*/
export function showAlertModal(args: ShowAlertModalArgs) {
    __legacyShowModal(
        <ModalAlert
            title={args.title}
            message={args.message}
            confirmLabel={args.confirmLabel ?? t`Close`}
        />,
    ).catch(() => null)
}
