import {Plural, plural, t, Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Link, Loading, ProgressBar, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import type {PartnerTierType} from '../../../../lib/partner-tier'
import {PartnerTierBadge} from '../partner-tier-badge'

export const PartnerTierCard = () => {
    const {data, isLoading, isError} = bff.partnerTierCard.getPartnerTierCard.useQuery()

    if (isLoading) {
        return <Loading />
    }

    if (isError) {
        return <GenericError />
    }

    return (
        <TierCard>
            <CardHeader partnerTier={data.isDuringOnboarding ? 'TRIAL' : data.currentTierType}>
                {data.isDuringOnboarding ? (
                    <>
                        <Text variant="large-accent" space={20}>
                            <Trans>Welcome to your onboarding period</Trans>
                        </Text>
                        <PartnerTierBadge tier={'TRIAL'} />
                    </>
                ) : (
                    <>
                        <Text variant="large-accent" space={20}>
                            <Trans>
                                You're currently a {partnerTiersNames()[data.currentTierType]}{' '}
                                partner
                            </Trans>
                        </Text>
                        <PartnerTierBadge tier={data.currentTierType} />
                    </>
                )}
            </CardHeader>
            <Inline>
                <Stack m={24} stretch>
                    <Text variant="large-accent" space={10}>
                        <Plural
                            one={`# paid user referred`}
                            other={`# paid users referred`}
                            value={data.paidUsersReferred}
                        />
                    </Text>
                    <Tooltip
                        content={getTooltipContent(
                            data.usersToNextTier,
                            data.clientsToNextTier,
                            data.nextTierType,
                        )}
                    >
                        <ProgressBar
                            data-testid="tier-progress-bar"
                            aria-label={t`progressbar depicting partner tier`}
                            variant="slim"
                            percent={data.tierProgressPercentage}
                            bgColor="shade300"
                            fillColor="shade900"
                        ></ProgressBar>
                    </Tooltip>
                    <Inline mt={8} justifyContent="space-between">
                        <Text variant="small-subtle">
                            {partnerTiersNames()[data.currentTierType]}
                        </Text>
                        {data.nextTierType && (
                            <Text variant="small-subtle">
                                {partnerTiersNames()[data.nextTierType]}
                            </Text>
                        )}
                    </Inline>
                </Stack>
            </Inline>
            <Inline mx={24} stretchChildren>
                <Footer>
                    {data.isDuringOnboarding ? (
                        <Trans>
                            Your new tier will be unlocked after your 6 month onboarding period.{' '}
                            <Link
                                data-testid="tier-card-link"
                                inherit={true}
                                as={RouterLink}
                                to="?showTiersModal=true"
                            >
                                Learn more
                            </Link>
                        </Trans>
                    ) : (
                        <Trans>
                            The more paid users and clients you refer, the more benefits you unlock
                            with each tier.{' '}
                            <Link
                                data-testid="tier-card-link"
                                inherit={true}
                                as={RouterLink}
                                to="?showTiersModal=true"
                            >
                                Learn more
                            </Link>
                        </Trans>
                    )}
                </Footer>
            </Inline>
        </TierCard>
    )
}

function partnerTiersNames(): Record<PartnerTierType, string> {
    return {
        TRIAL: t`New partner`,
        BRONZE: t`Bronze`,
        SILVER: t`Silver`,
        GOLD: t`Gold`,
        GOLD_DACH: t`Gold`,
        PLATINUM: t`Platinum`,
        PLATINUM_DACH: t`Platinum`,
    }
}

function getTooltipContent(
    usersToNextTier: number,
    clientsToNextTier: number,
    nextTier?: PartnerTierType,
) {
    if (nextTier) {
        const tierName = partnerTiersNames()[nextTier]
        if (usersToNextTier <= 0) {
            return plural(clientsToNextTier, {
                one: `Refer # more client to unlock your ${tierName} benefits`,
                other: `Refer # more clients to unlock your ${tierName} benefits`,
            })
        } else if (usersToNextTier > 0 && clientsToNextTier > 0) {
            return plural(clientsToNextTier, {
                one: plural(usersToNextTier, {
                    one: `Refer 1 paid user and 1 more client to unlock your ${tierName} benefits`,
                    other: `Refer ${usersToNextTier} paid users and 1 more client to unlock your ${tierName} benefits`,
                }),
                other: plural(usersToNextTier, {
                    one: `Refer 1 paid user and ${clientsToNextTier} more clients to unlock your ${tierName} benefits`,
                    other: `Refer ${usersToNextTier} paid users and ${clientsToNextTier} more clients to unlock your ${tierName} benefits`,
                }),
            })
        } else if (clientsToNextTier <= 0) {
            return plural(usersToNextTier, {
                one: `Refer # paid user to unlock your ${tierName} benefits`,
                other: `Refer # paid users to unlock your ${tierName} benefits`,
            })
        }
    }
    return t`Great job - you're officially top tier. Feels good, doesn't it?`
}

const tierColors: Record<PartnerTierType, string> = {
    TRIAL: tokens.pink200,
    BRONZE: 'rgba(245, 233, 230, 1)',
    SILVER: tokens.shade200,
    GOLD: tokens.yellow300,
    GOLD_DACH: tokens.yellow300,
    PLATINUM: 'rgba(226, 227, 229, 1)',
    PLATINUM_DACH: 'rgba(226, 227, 229, 1)',
}

const TierCard = styled(Stack).attrs({stretch: true})`
    background-color: ${tokens.shade000};
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc12};
    overflow: hidden;
`

const CardHeader = styled(Inline).attrs({
    stretch: true,
    alignItems: 'center',
    justifyContent: 'space-between',
})<{partnerTier: PartnerTierType}>`
    background-color: ${({partnerTier}) => tierColors[partnerTier]};
    padding: ${tokens.spacing24};
`

const Footer = styled(Text).attrs({align: 'center', variant: 'small-subtle'})`
    border-top: ${tokens.borderPrimary};
    padding: ${tokens.spacing16} 0;
`
