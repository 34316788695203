// src/shared/constants.ts
var CONFIG_GLOBAL_VAR = "___pleoConfig";

// src/runtime.ts
function getConfig() {
  const config = window[CONFIG_GLOBAL_VAR];
  delete window[CONFIG_GLOBAL_VAR];
  if (!config) {
    throw new Error("Configuration not found!");
  }
  return config;
}
export {
  getConfig
};
