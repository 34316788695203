import {Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'

import {Banner, Link} from '@pleo-io/telescope'

import {outstandingFeesBannerActioned} from '@product-web/analytics'

const OutstandingFeesBanner = () => {
    return (
        <Banner data-testid="outstanding-fees-banner" variant="warning" loud>
            <Banner.Content>
                <Banner.Text>
                    <Trans>
                        We weren't able to charge your Pleo subscription fees. To keep your account
                        active,{' '}
                        <Link
                            to="/wallet"
                            as={RouterLink}
                            onClick={() => outstandingFeesBannerActioned()}
                        >
                            top up your wallet
                        </Link>
                    </Trans>
                </Banner.Text>
            </Banner.Content>
        </Banner>
    )
}

export default OutstandingFeesBanner
