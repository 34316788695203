import {useEffect, useRef} from 'react'

/**
 * @deprecated  As per the new React 18 docs, it's recommended to **NOT** employ this hook or this behaviour.
 * See the [new docs](https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state)
 * for further details.
 *
 * Store a reference to the previous values for deep equality, value can be any type
 * Based on the implementation provided by
 * the official [older React 17 Docs](https://17.reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state)
 */
export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
