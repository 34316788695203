import {useLocation} from 'react-router'

import {useSessionUpdatedEventListener} from '@product-web/auth--session/store'
import {useHasAllowedRole, useUser} from '@product-web/user'
import {createPersistedState} from '@product-web/web-platform/persisted-state'
import {localStorage} from '@product-web/web-platform/storage/local'
import {ADOPTION_ESSENTIALS_PATHS} from '@product-web-features/help-centre/get-is-adoption-centre-visible'

import {bff} from '../bff-hooks'

export const INVOICE_ADOPTION_PROMPT_PERSISTED_STATE_KEY = 'invoice-adoption-prompt'
export const INVOICE_ADOPTION_PROMPT_VIEWED_LOCALSTORAGE_KEY = 'invoiceAdoptionPromptViewed'

const [usePersistedState, clearPersistedState] = createPersistedState(
    INVOICE_ADOPTION_PROMPT_PERSISTED_STATE_KEY,
    localStorage,
)

const INVOICE_ADOPTION_PATHS = ADOPTION_ESSENTIALS_PATHS.filter(
    (path) => path !== '/reimbursements',
)

export const useInvoiceAdoption = () => {
    const user = useUser()
    const isCompanySMB = (user?.company?.numberOfUsers || 0) < 50
    const isOwnerOrBookkeeper = useHasAllowedRole([
        'owner',
        'bookkeeper-extended',
        'bookkeeper-basic',
    ])

    const [isInvoiceAdoptionPromptViewed, setIsInvoiceAdoptionPromptViewed] =
        usePersistedState<boolean>(INVOICE_ADOPTION_PROMPT_VIEWED_LOCALSTORAGE_KEY, false)

    const {data: invoiceAdoptionPromptStatus} =
        bff.adoption.invoiceAdoptionPrompt.getInvoiceAdoptionPromptStatus.useQuery(undefined, {
            enabled: isOwnerOrBookkeeper && isCompanySMB && !isInvoiceAdoptionPromptViewed,
        })

    const {mutate: increaseInvoiceAdoptionPromptViewCount} =
        bff.adoption.invoiceAdoptionPrompt.increaseInvoiceAdoptionPromptViewCount.useMutation()

    const location = useLocation()
    const isVisibleLocation = INVOICE_ADOPTION_PATHS.some((p) => location.pathname.startsWith(p))

    useSessionUpdatedEventListener(() => {
        clearPersistedState()
    })

    const shouldShowInvoiceAdoptionPrompt =
        !!invoiceAdoptionPromptStatus?.isVisible &&
        isVisibleLocation &&
        !isInvoiceAdoptionPromptViewed

    const setInvoiceAdoptionPromptViewed = () => {
        setIsInvoiceAdoptionPromptViewed(true)
        increaseInvoiceAdoptionPromptViewCount()
    }

    return {
        shouldShowInvoiceAdoptionPrompt,
        setInvoiceAdoptionPromptViewed,
        clearPersistedState,
    }
}
