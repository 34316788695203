import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import {Box, Inline, Stack, Text} from '@pleo-io/telescope'

import * as tracking from '@product-web/analytics'
import {useFlags} from '@product-web/flags'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'
import {Page} from '@product-web-features/ui-page/page'

import {IntroductionToPleo} from './introduction-to-pleo'
import {OtherResources} from './other-resources'
import {ThePleoProduct} from './the-pleo-product'

import {useAcademyContext} from '../../components/academy-context/academy-context'

export const AcademyIndex = () => {
    const {partnerAcademy} = useFlags()
    const {country} = useAcademyContext()

    if (!partnerAcademy) {
        return <PageNotFound />
    }

    const getVideo = () => {
        switch (country) {
            case 'DK':
            case 'GL':
                return {
                    id: '4hsrfch7g2',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/aa599c6183713094cf5d52d46c061eca038adde5.jpg?video_still_time=0.7',
                }
            case 'GB':
            case 'UK':
            case 'IE':
                return {
                    id: 'hrhar1axa7',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/acc6ed6f8dc3ed146b588cf80d69f14f2c5ac0b5.jpg?video_still_time=8.48',
                }
            case 'SE':
                return {
                    id: 'odl01a1xqe',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/373bea886f5af5312d243bda260376b524c3417a.jpg',
                }
            case 'AT':
            case 'DE':
                return {
                    id: 'c7o0b8j2bg',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/f09d0db982e885ba017977ddccdcf27c3136c029.jpg?video_still_time=0.35',
                }
            case 'ES':
                return {
                    id: 'ehbo8cl1yo',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/93615c15585a7a8c9ade9023d9f9438b.jpg',
                }
            default:
                return {thumbnail: '', id: ''}
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`Partner Academy`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Partner Academy</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                {getVideo().id && (
                    <VideoWrapper>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Introduction`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({
                                    action,
                                    video: 'introduction',
                                })
                            }
                            autoPlay={false}
                            imgMinHeight={450}
                        />
                    </VideoWrapper>
                )}
                <Stack mt={24}>
                    <Text variant="2xlarge-accent" space={4}>
                        <Trans>Available courses</Trans>
                    </Text>
                    <Text color="colorContentStaticQuiet">
                        <Trans>Get up to speed quickly with our Partner Academy courses.</Trans>
                    </Text>
                </Stack>
            </Page.Header>
            <Inline mt={24} space={24} stretchChildren>
                <IntroductionToPleo />
                <ThePleoProduct />
                <div />
            </Inline>
            <OtherResources />
            <Box marginY={32} />
        </>
    )
}

const VideoWrapper = styled(Box).attrs({m: 24})`
    padding: 0 120px;
    max-height: 450px;
`
