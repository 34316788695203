import useSWR from 'swr'

import {checkExternalBookkeepersEntitlement} from '@product-web/api-deimos/entitlement'
import {useUser} from '@product-web/user'

export function useBookkeeperPermitted() {
    const user = useUser()

    const {data, error} = useSWR(user?.companyId || '', checkExternalBookkeepersEntitlement, {
        revalidateOnFocus: false,
    })

    if (error || !data) {
        return false
    }

    return data.janusJudgement === 'YES'
}
