import * as RadioGroup from '@radix-ui/react-radio-group'
import * as React from 'react'
import styled, {css} from 'styled-components'

import type {TagProps} from '@pleo-io/telescope'
import {Inline, Stack, Tag, Text, tokens} from '@pleo-io/telescope'

import {Container} from '../container-queries/container'
import {SkeletonLoader} from '../skeleton-loader/skeleton-loader'

export function ChoiceTiles({children, ...props}: RadioGroup.RadioGroupProps) {
    return (
        <ChoiceTilesRoot {...props} data-generic-ui="choice-tiles">
            <Container name="choiceTilesContainer">
                <Stack space={16}>{children}</Stack>
            </Container>
        </ChoiceTilesRoot>
    )
}

interface ChoiceTilesChoiceProps extends RadioGroup.RadioGroupItemProps {
    value: string
    id: string
    children: React.ReactNode
    className?: string
}

function ChoiceTilesChoice({value, id, children, className, ...rest}: ChoiceTilesChoiceProps) {
    return (
        <ChoiceTilesWrapper className={className}>
            <ChoiceTilesItem value={value} id={id} {...rest}>
                <ChoiceTilesIndicator />
            </ChoiceTilesItem>
            <ChoiceTilesLabel htmlFor={id}>{children}</ChoiceTilesLabel>
        </ChoiceTilesWrapper>
    )
}

interface ChoiceTilesTitleProps {
    children: React.ReactNode
    className?: string
}

function ChoiceTilesTitle({children, className}: ChoiceTilesTitleProps) {
    return (
        <Text as="span" variant="large-accent" weight="medium" className={className}>
            <TitleWrapper as="span" space={8} wrap>
                {children}
            </TitleWrapper>
        </Text>
    )
}

type ChoiceTilesTagProps = TagProps

function ChoiceTilesTag({children, ...props}: ChoiceTilesTagProps) {
    return <Tag {...props}>{children}</Tag>
}

interface ChoiceTileDescriptionProps {
    children: React.ReactNode
    className?: string
}

function ChoiceTileDescription({children, className}: ChoiceTileDescriptionProps) {
    return (
        <DescriptionWrapper align="left" as="span" color="shade600" className={className}>
            {children}
        </DescriptionWrapper>
    )
}

ChoiceTiles.Choice = ChoiceTilesChoice
ChoiceTiles.Title = ChoiceTilesTitle
ChoiceTiles.Description = ChoiceTileDescription
ChoiceTiles.Tag = ChoiceTilesTag

export function ChoiceTilesLoadingSkeleton({numberOfChoices = 2}: {numberOfChoices?: number}) {
    const generateRandomNumber = React.useCallback(
        (max: number, min: number) => Math.floor(Math.random() * (max - min + 1) + min),
        [],
    )

    return (
        <SkeletonWrapper>
            <Stack space={16}>
                {Array.from({length: numberOfChoices}).map((_, index) => (
                    <ChoiceTileSkeleton key={index}>
                        <SkeletonLoader height={57}>
                            <circle cx="12" cy="14" r="12" />
                            <rect
                                x="36"
                                y="4"
                                rx="3"
                                ry="3"
                                width={generateRandomNumber(300, 120)}
                                height="20"
                            />

                            <rect
                                x="36"
                                y="42"
                                rx="3"
                                ry="3"
                                width={generateRandomNumber(400, 120)}
                                height="17"
                            />
                        </SkeletonLoader>
                    </ChoiceTileSkeleton>
                ))}
            </Stack>
        </SkeletonWrapper>
    )
}

// #region Styled components
const borderBoxStyles = css`
    &,
    & *,
    & *::before,
    & *::after {
        box-sizing: border-box;
    }
`

const choiceTileLabelBaseStyles = css`
    background-color: ${tokens.colorBackgroundInteractive};
    border-radius: ${tokens.arc8};
    width: 100%;
`

const SkeletonWrapper = styled.div`
    ${borderBoxStyles}
`

const ChoiceTileSkeleton = styled.div`
    ${choiceTileLabelBaseStyles}

    border: ${tokens.borderInteractiveQuiet};
    padding: ${tokens.spacing24};
`

const ChoiceTilesRoot = styled(RadioGroup.Root)`
    ${borderBoxStyles}
`

const ChoiceTilesWrapper = styled.div`
    position: relative;
    width: 100%;
`

const ChoiceTilesLabel = styled.label`
    ${choiceTileLabelBaseStyles}

    border: ${tokens.borderInteractive};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing8};
    padding: ${tokens.spacing24} ${tokens.spacing24} ${tokens.spacing24} ${tokens.spacing56};
    transition: ${tokens.fastInOut};
    transition-property: background-color, border-color, box-shadow;

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
        border-color: ${tokens.colorBorderInteractiveHover};
    }
`

const indicatorSize = '8px'

const ChoiceTilesIndicator = styled(RadioGroup.Indicator)`
    align-items: center;
    border-radius: ${tokens.circle};
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    &::after {
        background-color: ${tokens.colorContentInteractiveSelected};
        border-radius: ${tokens.circle};
        content: '';
        display: block;
        height: ${indicatorSize};
        width: ${indicatorSize};
    }
`

const TitleWrapper = styled(Inline)`
    align-items: center;
    flex-direction: row;
    row-gap: ${tokens.spacing8};
`

const DescriptionWrapper = styled(Text)`
    margin-top: ${tokens.spacing8};
`

const sizeRadio = '20px'

const ChoiceTilesItem = styled(RadioGroup.Item)`
    all: unset;
    background-color: ${tokens.colorBackgroundInteractive};
    border: ${tokens.borderInteractive};
    border-radius: ${tokens.circle};
    box-sizing: border-box;
    cursor: pointer;
    content: '';
    flex-shrink: 0;
    height: ${sizeRadio};
    left: ${tokens.spacing24};
    position: absolute;
    top: ${tokens.spacing28};
    transition: ${tokens.fastInOut};
    transition-property: border-color, background-color;
    width: ${sizeRadio};

    &[aria-checked='true'] {
        background-color: ${tokens.colorBackgroundInteractiveSelected};
        border-color: ${tokens.colorBorderInteractiveSelected};

        & ~ ${ChoiceTilesLabel} {
            border-color: ${tokens.colorBorderInteractiveSelected};
        }
    }

    &.focus-visible ~ ${ChoiceTilesLabel} {
        box-shadow: 0 0 0 3px ${tokens.colorShadowFocus};
    }

    &:disabled {
        border-color: ${tokens.colorBorderInteractiveDisabled};
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        cursor: not-allowed;
    }

    &:disabled ${ChoiceTilesIndicator}::after {
        background-color: ${tokens.colorContentInteractiveDisabled};
    }

    &:disabled ~ ${ChoiceTilesLabel} {
        background-color: ${tokens.colorBackgroundInteractive};
        border-color: ${tokens.colorBorderInteractiveDisabled};
        cursor: not-allowed;

        &:hover {
            background-color: ${tokens.colorBackgroundInteractive};
            border-color: ${tokens.colorBorderInteractiveDisabled};
        }

        & ${TitleWrapper}, & ${DescriptionWrapper} {
            color: ${tokens.colorContentInteractiveDisabled};
        }
    }

    &:hover:enabled {
        border-color: ${tokens.colorBorderInteractiveHover};
    }
`
// #endregion
