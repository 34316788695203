import history from 'history/browser'
import mitt from 'mitt'
import React from 'react'

import {Modal, ModalClose} from '@pleo-io/telescope'

import {reportError} from '@product-web/error/report'

type EmitterEvents = {add: AddLegacyModalPayload; remove: string}
const legacyModalEmitter = mitt<EmitterEvents>()

// Internal type, used for emitting the "add" event and storing open modals in state
type AddLegacyModalPayload = {
    element: React.ReactElement
    options?: AddLegacyModalOptions
    id: string
}

// Options passed when calling __legacyShowModal method
type AddLegacyModalOptions = {
    'aria-label'?: string
    size?: 'auto'
    hideClose?: boolean
    onClose?(): void
    dangerouslyBypassFocusLock?: boolean
    dangerouslyBypassScrollLock?: boolean
}

/**
 * Legacy interface used to account for props injected to the modal
 * elements by the modal bridge
 * @deprecated use the Telescope modal instead
 */
export interface IModal<ResolveValue = void, RejectValue = void> {
    _modal?: LegacyModalInjectedProps<ResolveValue, RejectValue>
}

type LegacyModalInjectedProps<ResolveValue = void, RejectValue = void> = AddLegacyModalOptions & {
    id: string
    promise: {
        reject: (value: RejectValue) => void
        resolve: (value: ResolveValue) => void
    }
}

/**
 * A temporary component which acts as a bridge for migration away from Redux.
 * It allows to create a function that can be called outside of the React render tree
 * (e.g. from a Redux action) which renders a modal using our new declarative API.
 * It works by utilizing an event emitter - events can be triggered from outside of
 * React context, but listened to in a React useEffect hook.
 * It is only rendered once, at the top level of the app.
 */
export function LegacyModalsBridge({isLoggedOut = false}: {isLoggedOut?: boolean}) {
    const [modals, dispatch] = React.useReducer(modalsReducer, [])
    const onShowModal = React.useCallback(async (modal: AddLegacyModalPayload) => {
        dispatch({type: 'add', modal})
    }, [])
    const onRemoveModal = React.useCallback(async (id: string) => {
        dispatch({type: 'remove', id})
    }, [])

    React.useEffect(() => {
        legacyModalEmitter.on('add', onShowModal)
        legacyModalEmitter.on('remove', onRemoveModal)
        return () => {
            legacyModalEmitter.off('add', onShowModal)
            legacyModalEmitter.off('remove', onRemoveModal)
        }
    }, [onRemoveModal, onShowModal])

    // Whenever the user changes the url, we close all modals (e.g. when they press back)
    React.useEffect(() => {
        const cleanup = history.listen(() => dispatch({type: 'purge'}))
        return () => cleanup()
    }, [])

    // If the user gets logged out, we close all modals
    React.useEffect(() => {
        if (isLoggedOut) {
            dispatch({type: 'purge'})
        }
    }, [isLoggedOut])

    if (!modals.length) {
        return null
    }

    return (
        <>
            {modals.map((modal) => (
                <Modal
                    onDismiss={
                        modal.options?.hideClose
                            ? undefined
                            : () => {
                                  modal.options?.onClose?.()
                                  dispatch({type: 'remove', id: modal.id})
                              }
                    }
                    aria-label={modal.options?.['aria-label']}
                    key={modal.id}
                    size={modal.options?.size}
                    dangerouslyBypassFocusLock={modal.options?.dangerouslyBypassFocusLock}
                    dangerouslyBypassScrollLock={modal.options?.dangerouslyBypassScrollLock}
                >
                    {!modal.options?.hideClose && (
                        <ModalClose
                            onClick={() => {
                                modal.options?.onClose?.()
                                dispatch({type: 'remove', id: modal.id})
                            }}
                        />
                    )}
                    {modal.element}
                </Modal>
            ))}
        </>
    )
}

type State = AddLegacyModalPayload[]
type Action =
    | {type: 'add'; modal: AddLegacyModalPayload}
    | {type: 'remove'; id: string}
    | {type: 'purge'}

function modalsReducer(state: State, action: Action) {
    switch (action.type) {
        case 'add':
            return [action.modal, ...state]
        case 'remove':
            return state.filter((modal) => modal.id !== action.id)
        case 'purge':
            return []
        default:
            return assertUnreachable(action)
    }
}

/**
* @deprecated use the Telescope modal instead
* Opens a modal in a imperative way. Can be called from within and outside of React render tree.
* Injects _modal prop to the element passed, which includes _modal.promise property that can be used to
* close the modal (indicating "positive" action when resolved - like OK button, and "negative" action
* when rejecting - like Cancel button).
@template ResolveValue - The type of the value resolved by the promise returned by the modal.
@template RejectValue - The type of the value rejected by the promise returned by the modal.
@param element - The React element to render in the modal.
@param [options] - The options to use when rendering the modal.
@returns - A promise that resolves to an object containing the value and status of the modal. The status can either be "submitted" or "cancelled".
*/
export async function __legacyShowModal<ResolveValue = void, RejectValue = void>(
    element: React.ReactElement,
    options?: AddLegacyModalOptions,
) {
    const modalId = Math.random().toString(16).substr(2, 5)
    const promise = new Promise<ResolveValue>((resolve, reject) => {
        const injectedProps: LegacyModalInjectedProps<ResolveValue, RejectValue> = {
            id: modalId,
            promise: {resolve, reject},
            ...options,
        }
        const elementWithInjectedProps = React.cloneElement(element, {_modal: injectedProps})
        legacyModalEmitter.emit('add', {element: elementWithInjectedProps, options, id: modalId})
    })

    try {
        const value = await promise
        return {value, status: 'submitted' as const}
    } catch (error) {
        if (error instanceof Error) {
            reportError(error)
            throw error
        }
        return {value: error, status: 'cancelled' as const}
    } finally {
        legacyModalEmitter.emit('remove', modalId)
    }
}

function assertUnreachable(x: never): never {
    throw new Error(`assertUnreachable: ${x}`)
}
