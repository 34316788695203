/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {Box, Inline, Skeleton, Stack, Text} from '@pleo-io/telescope'

import {ViewDetails} from './pricing-results'

export const PricingResultsSkeleton = () => (
    <>
        <Stack mb={24} space={8}>
            <Text variant="3xlarge-accent" skeleton>
                Overview
            </Text>
            <Text skeleton>This is what will appear on your client's quote and invoice.</Text>
        </Stack>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Box pb={28} />
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Inline mt={48} space={24} alignItems="center">
            <ViewDetails>
                <Text variant="large-accent" skeleton>
                    Contract value
                </Text>
                <Text skeleton>Monthly: 280 DKK</Text>
                <Text skeleton>Annually: 1.280 DKK</Text>
            </ViewDetails>
            <ViewDetails>
                <Text variant="large-accent" skeleton>
                    Price per user
                </Text>
                <Text skeleton>Monthly: 56 DKK</Text>
                <Text skeleton>Annually: 102 DKK</Text>
            </ViewDetails>
        </Inline>
    </>
)
