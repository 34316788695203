import {useRef} from 'react'

import {useFlags} from '@product-web/flags'
import {useIsPartnerUser} from '@product-web/user'
import {useHelpCentre} from '@product-web-features/help-centre/help-centre-context'

import {AdoptionCentre} from './adoption-centre/adoption-centre'
import {useShowAdoptionCentre} from './adoption-centre/use-show-adoption-centre'
import {bff} from './bff-hooks'
import {HelpWidget} from './help-widget/help-widget'
import {PartnerSelfOnboarding} from './partner-self-onboarding/partner-self-onboarding'
import {ProductPanelsPortal} from './product-panels-portal'

const HelpCentrePanels = () => {
    const {isHelpCentreVisible} = useHelpCentre()
    const isPartnerUser = useIsPartnerUser()
    const {partnerAgreementCountries} = useFlags()
    const {data} = bff.helpCentrePanels.getPanelsInfo.useQuery(partnerAgreementCountries, {
        enabled: isHelpCentreVisible && isPartnerUser,
    })

    if (!isHelpCentreVisible) {
        return null
    }

    if (data?.showPartnerSelfOnboarding) {
        return <PartnerSelfOnboarding />
    }

    return <ProductPanels />
}

export default HelpCentrePanels

function ProductPanels() {
    const {shouldShowAdoptionCentre} = useShowAdoptionCentre()
    const ref = useRef<HTMLDivElement | null>(null)

    return (
        <ProductPanelsPortal ref={ref}>
            {shouldShowAdoptionCentre ? <AdoptionCentre /> : <HelpWidget portalRef={ref} />}
        </ProductPanelsPortal>
    )
}
