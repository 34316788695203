import type {ICountryConfiguration} from './types'

import {defaultLocale, Terms} from '../features/terms/types'
import {Feature} from '../types'

const US: ICountryConfiguration = {
    [Feature.Terms]: {
        [Terms.Documents]: {
            masterServiceAgreement: {
                [defaultLocale]: 'terms-and-conditions-us-2023-msa.pdf',
            },
            privacyPolicy: {
                [defaultLocale]: 'pleo-privacy-policy-us.pdf',
            },
            cardholderAgreement: {
                [defaultLocale]: 'pleo_prepaid_CHA_us.pdf',
            },
            authorisedUserTerms: {
                [defaultLocale]: 'pleo_authorized_user_disclosure_us.pdf',
            },
        },
    },
    [Feature.VendorCards]: {
        canUseVendorCards: false,
    },
}

export default US
