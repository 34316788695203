import type {ICountryConfiguration} from './types'

import {Feature} from '../types'

const FI: ICountryConfiguration = {
    [Feature.Paywall]: {
        noVATReclaim: true,
    },
}

export default FI
