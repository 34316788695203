import type {ComponentProps} from 'react'

import {PricingPlanModal} from './pricing-plan-modal'

export const PricingPlanModalWrapper = (props: ComponentProps<typeof PricingPlanModal>) => {
    if (!props.isOpen) {
        return null
    }
    return <PricingPlanModal {...props} />
}
