import {useFeatureFlag} from './use-feature-flag'
import {useIsEntitledToAPIKeys} from './use-is-entitled-to-api-keys'

export function useIsPaywalled() {
    const flag = useFeatureFlag()
    const entitlement = useIsEntitledToAPIKeys()

    return {
        isLoading: flag.isLoading || entitlement.isLoading,
        isOpen: flag.isEnabled && entitlement.isEntitled,
    }
}
