import * as tracking from '@product-web/analytics'
import type {PageOrigin} from '@product-web/paywall/types'

export const trackPocketAdoptionPromptActioned = ({
    action,
}: {
    action: 'viewed' | 'not_now' | 'explore_pocket'
}) => tracking.pocketAdoptionPromptActioned({action})

export const trackInvoiceAdoptionPromptActioned = ({
    action,
}: {
    action: 'viewed' | 'not_now' | 'try_invoices'
}) => tracking.invoiceAdoptionPromptActioned({action})

export const trackUpgradeYourPlanViewed = ({page}: {page: PageOrigin}) =>
    tracking.upgradeYourPlanViewed({page})

export const trackLandingPagesEligibleViewed = ({page}: {page: PageOrigin}) => {
    tracking.landingPagesEligibleViewed({page})
}

export const trackVismaMigrationAdoptionPromptActioned = ({
    type,
    companyId,
    pagePath,
    userId,
}: {
    type: 'accessed' | 'dismissed' | 'started' | 'exited' | 'failed' | 'completed'
    companyId?: string
    pagePath?: string
    userId?: string
}) => {
    tracking.vismaEconomicMigrationFlow({
        type,
        company_id: companyId,
        page_path: pagePath,
        user_id: userId,
    })
}

export const trackVismaMigrationInstructionsActioned = ({
    companyId,
    userId,
}: {
    companyId?: string
    userId?: string
}) => {
    tracking.vismaEconomicMigrationInstructionsActioned({company_id: companyId, user_id: userId})
}
