import type {FC} from 'react'
import styled from 'styled-components'

import {NavigationItemsLoader} from './navigation-items'
import {NavigationLogoLoader} from './navigation-logo'
import {NavigationSkeleton} from './navigation-skeleton'

export const NavigationLoading: FC = () => {
    return <NavigationSkeleton userMenu={<></>} main={<NavigationMainLoading />} />
}

const NavigationMainLoading: FC = () => {
    return (
        <>
            <NavigationLogoLoader />
            <NavigationItemsLoader />
            <EmptySpace />
        </>
    )
}

const EmptySpace = styled.div`
    flex-grow: 1;
`
