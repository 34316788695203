export enum SupportedLanguage {
    DA = 'da',
    SV = 'sv',
    EN = 'en',
    DE = 'de',
    DE_AT = 'de-AT',
    ES = 'es',
    FR = 'fr',
    FR_BE = 'fr-BE',
    FI = 'fi',
    NL = 'nl',
    NL_BE = 'nl-BE',
    PT = 'pt',
    IT = 'it',
    NO = 'no',
}

export function isSupportedLanguage(
    maybeSupportedLanguage: string,
): maybeSupportedLanguage is SupportedLanguage {
    return Object.values(SupportedLanguage).includes(maybeSupportedLanguage as SupportedLanguage)
}
