import type {CreateApiKeyInput} from '../bff/index.bff'

/** Request for creation of an API key */
export type APIKeyInput = {
    /** A human-readable name for identification purposes */
    name: string
    /** The expiration period of the API key */
    expiresIn: string
    /** The URN of the "subject" this API key grants access to: company or organization */
    subject: string
    /** A list of OAuthScope identifiers */
    scope: string[]
}

export function fromFormData(fd: FormData): APIKeyInput {
    return {
        name: fd.get('name') as string,
        expiresIn: fd.get('expiresIn') as string,
        subject: fd.get('subject') as string,
        scope: fd.getAll('scope') as string[],
    }
}

export function intoCreateApiKeyInput(input: APIKeyInput): CreateApiKeyInput {
    return {
        name: input.name,
        scope: input.scope,
        subject: input.subject,
        expiresAt: toJSON(expirationDate(input.expiresIn)),
    }
}

export function expirationDate(expiresIn: string): Date | undefined {
    const duration = parseInt(expiresIn, 10)
    if (Number.isNaN(duration)) {
        return undefined
    }
    return new Date(Date.now() + duration)
}

function toJSON<T extends {toJSON(): string}>(val: T | undefined): string | undefined {
    return val === undefined ? undefined : val.toJSON()
}
