import type {CompanyPermissions, EmployeePermission} from '@pleo-io/deimos'

import type {Address} from '@product-web/api-types/address'
import type {Company} from '@product-web/api-types/company'
import type {SupportedLanguage} from '@product-web/i18n'

export type Role =
    | 'member'
    | 'owner'
    | 'bookkeeper-basic'
    | 'bookkeeper-extended'
    | 'reviewer'
    | 'partner-owner'
    | 'partner-member'

type Reviewing = {
    teams: ReviewingEntry[]
}

export type ReviewingEntry = {
    id: string
    parent: ReviewingParentType
    parentId: string
}

export enum ReviewingParentType {
    COMPANY = 'company',
    ORGANIZATION = 'organization',
}

export type UserData = {
    id: string
    email: string
    role:
        | 'member'
        | 'owner'
        | 'bookkeeper'
        | 'bookkeeper-basic'
        | 'bookkeeper-extended'
        | 'partner-owner'
        | 'partner-member'
    hasPin: boolean
    language: SupportedLanguage | null
    phone?: string | null
    phoneVerified?: boolean | null
    company?: Company
    companies?: Company[] | null
    companyId?: string
    homeCompany?: Company
    intercomHash?: string
    partnerId?: string
    reviewer?: string[]
    departmentManager?: string[]
    status?: {
        verified: boolean
        acceptedTerms: boolean
        pending: boolean
        onboarded: boolean
        readyForExportSwitchDate: string | null
        isSignUpUser?: boolean
    }
    features?: {
        [id: string]: boolean
    }
    partnerEmployee?: {
        id: string
        firstName: string
        lastName: string
        role: 'owner' | 'member'
    }
    partner?: {
        id: string
        name: string
        registrationNumber: string
        address: Address
        ownCompanyId?: string
        tradingName: string
        createdAt: string
    }
    employee?: {
        id: string | null
        firstName: string
        lastName: string
        jobTitle: string | null
        phone: string | null
        email: string
        avatar: {url: string} | null
        status: {state?: Record<string, any>; createdByPartner?: boolean} & Record<string, any>
        company?: Company
        userId: string
        code: null | string
        role: 'member' | 'owner' | 'bookkeeper' | 'bookkeeper-basic' | 'bookkeeper-extended'
        permissions?: EmployeePermission[] | null
    }
    permissions?: CompanyPermissions
    isSpendingEntity?: boolean
    organization?: OrganizationData
    reviewing?: Reviewing
}

export type OrganizationData = {
    id: string
    name: string
}

export type PartnerUserData = UserData & {
    partnerId: string
    partnerEmployee: NonNullable<UserData['partnerEmployee']>
    partner: NonNullable<UserData['partner']>
}

export type CompanyUserData = UserData & {
    company: Company
    companies?: Company[]
    companyId: string
    employee: NonNullable<UserData['employee']>
}
