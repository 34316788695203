import React from 'react'

import config from '@product-web/config'
import type {SupportedLanguage} from '@product-web/i18n'

type OauthContextAPI = {oauthId: string}

export const OauthContext = React.createContext<OauthContextAPI>({
    oauthId: 'test-oauth-id',
})

/**
 * Custom React hook retrieving the oauth ID used for registration, verification
 * and login flows
 * @returns Currently saved Oauth ID
 */
export function useOauthId() {
    const {oauthId} = React.useContext(OauthContext)
    return oauthId
}

export enum ExternalProvider {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
}

type OauthRegisterAction = {
    action: 'register'
    state?: {
        inviteToken?: string
    }
}
type OauthVerifyAction = {
    action: 'verify'
    state: {
        userId: string
        verifyToken: string
    }
}
type OauthOtherAction = {
    action: 'login' | 'external'
    state?: {
        language?: SupportedLanguage
    }
}
type OauthExternalAction = {
    action: 'external'
    provider: ExternalProvider
    register?: boolean | string
    verify?: boolean
    responseType?: 'code' | 'token'
    codeChallenge?: string
    codeChallengeMethod?: 'S256' | 'plain'
}
type OauthExternalRegisterAction = OauthExternalAction & {
    register: true
    state?: {
        language?: SupportedLanguage
        inviteToken?: string
    }
}
type OauthExternalVerifyAction = OauthExternalAction & {
    verify: true
    state: {
        language?: SupportedLanguage
        verifyToken: string
    }
}
type OauthExternalLoginAction = OauthExternalAction & {
    register: undefined
    verify: undefined
}
type OauthAction =
    | OauthRegisterAction
    | OauthVerifyAction
    | OauthExternalVerifyAction
    | OauthOtherAction
    | OauthExternalRegisterAction
    | OauthExternalLoginAction
export type OauthHookParams = OauthAction & {
    scope: string[]
    redirectUri?: string
    state?: {}
}

const isExternalAction = (action: OauthAction): action is OauthExternalAction =>
    (action as OauthExternalAction).action === 'external' &&
    (action as OauthExternalAction).provider !== undefined

const isExternalRegisterAction = (action: OauthAction): action is OauthExternalRegisterAction =>
    isExternalAction(action) && (action as OauthExternalRegisterAction).register

const isExternalVerifyAction = (action: OauthAction): action is OauthExternalVerifyAction =>
    isExternalAction(action) &&
    (action as OauthExternalVerifyAction).verify &&
    (action as OauthExternalVerifyAction).state?.verifyToken !== undefined

/**
 * Custom React hook retrieving the full URL for Oauth form submission
 * @param params.action Determines which oauth action the form submission will perform
 * @param params.state Additional data, depends on the action chosen
 * @param params.scope Authentication scopes (e.g. ['resource:basic'])
 * @param params.provider Selects a 3rd party authentication provider if needed
 * @param params.register Used in tandem with provider to determine if user should be registered if not yet the case
 * @param params.redirectUri Overrides the default redirect URL (current page)
 * @param params.responseType Determines if the response should be in the form of a code token (preferred) or an access token.
 * @param params.codeChallenge Used when response_type is set to 'code'. A base 64 url encoded high-entropy cryptographic random STRING using the unreserved characters [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~" from Section 2.3 of [RFC3986], with a minimum length of 43 characters and a maximum length of 128 characters.
 * @param params.codeChallengeMethod Either 'plain' or 'S256'. If set to 'S256', the code_challenge needs to be hashed using S256 before base 64 encoding it.
 * @returns The Oauth form submission URL
 */
export function useOauthUrl(params: OauthHookParams) {
    const oauthId = useOauthId()

    const {protocol, host, pathname} = window.location
    const defaultRedirectUri = `${protocol}//${host}${pathname}`
    const baseUrl = config.endpoints.auth
    const url = new URL(`oauth/${params.action}`, baseUrl)

    // Shared params for all oauth calls
    url.searchParams.append('client_id', config.auth.oauthClientId)
    url.searchParams.append('scope', params.scope.join(','))
    url.searchParams.append('state', JSON.stringify({...params.state, id: oauthId}))
    url.searchParams.append('redirect_uri', params.redirectUri ?? defaultRedirectUri)

    // Shared params for all external provider oauth calls
    if (isExternalAction(params)) {
        url.searchParams.append('provider', params.provider)

        if (params.responseType) {
            url.searchParams.append('response_type', params.responseType)
        }

        if (params.codeChallenge) {
            url.searchParams.append('code_challenge', params.codeChallenge)
        }

        if (params.codeChallengeMethod) {
            url.searchParams.append('code_challenge_method', params.codeChallengeMethod)
        }
    }

    // Action specific call params
    if (params.action === 'login') {
        url.searchParams.append('response_type', 'token')
    }

    if (isExternalVerifyAction(params)) {
        url.searchParams.append('verify', 'true')
    }

    if (isExternalRegisterAction(params)) {
        url.searchParams.append('register', 'sca')
    }

    return url.toString()
}
