import {t, Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'
import {getQueryKey} from '@trpc/react-query'
import {AnimatePresence, motion} from 'framer-motion'
import type {ReactNode} from 'react'
import {useState} from 'react'
import {useMatch, useNavigate, useSearchParams} from 'react-router-dom'
import styled from 'styled-components'

import {
    Button,
    IconButton,
    Inline,
    Link,
    NakedButton,
    Popover,
    ProgressBar,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {Close, NewTab, SpeechBubble} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {useFlags} from '@product-web/flags'
import {useSupportChat} from '@product-web/freshchat/use-support-chat'
import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'
import {Drawer} from '@product-web/telescope-lab/drawer'

import {PartnerAgreement} from './components/partner-agreement/partner-agreement'
import {StandardTask} from './components/standard-task'
import LightBulb from './images/light-bulb.svg'
import OnboardingChecklist from './images/onboarding-checklist.svg'
import {TasksCarousel} from './tasks-carousel/tasks-carousel'

import {bff} from '../bff-hooks'

const LOCAL_STORAGE_KEY = '@pleo/partner/self-onboarding'

export const PartnerSelfOnboarding = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {partnerAgreementCountries} = useFlags()
    const [isOpen, setIsOpen] = useState(localStorage.getItem(LOCAL_STORAGE_KEY) !== 'dismissed')
    const supportChat = useSupportChat()
    const [searchParams, setSearchParams] = useSearchParams()
    const isOnPartnerPortal = useMatch('/partner/*')

    const {data, isLoading, isError} =
        bff.partnerSelfOnboarding.getOnboardingInfo.useQuery(partnerAgreementCountries)

    const queryKey = getQueryKey(
        bff.partnerSelfOnboarding.getOnboardingInfo,
        partnerAgreementCountries,
        'query',
    )

    const showOnboardingCompleteModal = () => {
        if (isOnPartnerPortal) {
            setSearchParams({...searchParams, showOnboardingCompleteModal: 'true'})
        } else {
            navigate('/partner?showOnboardingCompleteModal=true')
        }
    }

    const {mutateAsync: updateTask} = bff.partnerSelfOnboarding.updateTask.useMutation({
        onMutate: (taskName) =>
            queryClient.setQueryData(queryKey, (oldData: typeof data) => {
                if (oldData) {
                    const completedTasks = [...oldData.completedTasks, taskName]
                    const numberOfCompletedTasks = oldData.numberOfCompletedTasks + 1
                    const hasCompletedAllTasks = numberOfCompletedTasks === oldData.numberOfTasks
                    if (hasCompletedAllTasks) {
                        closeDrawer()
                        showOnboardingCompleteModal()
                    }
                    return {...oldData, numberOfCompletedTasks, completedTasks}
                }
                return undefined
            }),
    })

    if (isLoading || isError) {
        return null
    }

    /**
     * Progress percent is calculated in the frontend so that the progress bar moves as soon as a task is marked as completed.
     * If we were to calculate it in the BFF, the progress bar would stay behind until the query re-fetches.
     *
     * The `|| 0` is there to avoid a NaN error when there are no tasks.
     */
    const progressPercent =
        Math.round((data.numberOfCompletedTasks / data.numberOfTasks) * 100) || 0

    // If the user has not started the onboarding yet, when they click on the onboarding button we redirect them to the onboarding page.
    if (data.shouldStartOnboarding) {
        return (
            <OpenButton
                onClick={() => {
                    navigate('/partner/onboarding')
                }}
            >
                <Trans>Onboarding</Trans> | {progressPercent}%
            </OpenButton>
        )
    }

    const openDrawer = () => {
        setIsOpen(true)
        tracking.partnerSelfOnboardingDrawerActioned({action: 'open'})
    }

    const closeDrawer = () => {
        setIsOpen(false)
        tracking.partnerSelfOnboardingDrawerActioned({action: 'close'})
    }

    const getIsChecked = (taskName: string) => data.completedTasks.includes(taskName)
    const getToggleCheck = (taskName: string) => async () => await updateTask(taskName)

    return (
        <>
            <AnimatePresence>
                {isOpen ? (
                    <CloseButton onClick={closeDrawer} />
                ) : (
                    <OpenButton onClick={openDrawer}>
                        <Trans>Onboarding</Trans> | {progressPercent}%
                    </OpenButton>
                )}
            </AnimatePresence>
            <MainDrawer isOpen={isOpen} onDismiss={closeDrawer} noBackdrop>
                <DrawerContent>
                    <Stack mb={28} stretch>
                        <Text variant="2xlarge-accent" as="h2" space={20}>
                            <Trans>Your onboarding guide</Trans>
                        </Text>
                        <img src={OnboardingChecklist} alt="" />
                        <Inline mt={28} alignItems="center" space={12}>
                            <ProgressBar
                                aria-label={t`Onboarding progress`}
                                variant="tall"
                                percent={progressPercent}
                            />
                            <Text color="shade600">{progressPercent}%</Text>
                        </Inline>
                    </Stack>
                    <TasksCarousel>
                        <TasksCarousel.Group>
                            <TasksCarousel.GroupTitle>
                                <Trans>Set up your portal</Trans>
                            </TasksCarousel.GroupTitle>
                            <TasksCarousel.GroupDescription>
                                <Trans>Get started instantly by adding your first client.</Trans>
                            </TasksCarousel.GroupDescription>
                            <TasksCarousel.GroupContent>
                                <StandardTask
                                    name="add-your-first-client"
                                    label={t`Add your first client`}
                                    path="/partner/clients/my-clients"
                                    checked={getIsChecked('add-your-first-client')}
                                    toggleCheck={getToggleCheck('add-your-first-client')}
                                />
                                {data?.isPartnerOwner && (
                                    <StandardTask
                                        name="create-a-pleo-account"
                                        label={t`Create a Pleo account`}
                                        path="/partner/company/info"
                                        checked={getIsChecked('create-a-pleo-account')}
                                        toggleCheck={getToggleCheck('create-a-pleo-account')}
                                    />
                                )}
                            </TasksCarousel.GroupContent>
                        </TasksCarousel.Group>

                        {data?.shouldShowBookkeepingTasks && (
                            <TasksCarousel.Group>
                                <TasksCarousel.GroupTitle>
                                    <Trans>Set up your clients</Trans>
                                </TasksCarousel.GroupTitle>
                                <TasksCarousel.GroupDescription>
                                    <Trans>Help your clients start spending right away.</Trans>
                                </TasksCarousel.GroupDescription>
                                <TasksCarousel.GroupContent>
                                    <StandardTask
                                        name="access-client-through-portal"
                                        label={t`Access a client's account through the portal`}
                                        path="/partner/clients/my-clients"
                                        checked={getIsChecked('access-client-through-portal')}
                                        toggleCheck={getToggleCheck('access-client-through-portal')}
                                    />
                                    <StandardTask
                                        name="verify-your-client"
                                        label={t`Verify your client`}
                                        path="/partner/clients/onboarding"
                                        checked={getIsChecked('verify-your-client')}
                                        toggleCheck={getToggleCheck('verify-your-client')}
                                    />
                                    <StandardTask
                                        name="set-up-accounting"
                                        label={t`Set up accounting`}
                                        path="/partner/clients/my-clients"
                                        checked={getIsChecked('set-up-accounting')}
                                        toggleCheck={getToggleCheck('set-up-accounting')}
                                    />
                                </TasksCarousel.GroupContent>
                            </TasksCarousel.Group>
                        )}

                        {data?.isPartnerOwner && (
                            <TasksCarousel.Group>
                                <TasksCarousel.GroupTitle>
                                    <Trans>Set up your team</Trans>
                                </TasksCarousel.GroupTitle>
                                <TasksCarousel.GroupDescription>
                                    <Trans>
                                        Get the rest of your team up to speed in the portal.
                                    </Trans>
                                </TasksCarousel.GroupDescription>
                                <TasksCarousel.GroupContent>
                                    <StandardTask
                                        name="invite-your-employees"
                                        label={t`Invite your employees`}
                                        path="/partner/team/people"
                                        checked={getIsChecked('invite-your-employees')}
                                        toggleCheck={getToggleCheck('invite-your-employees')}
                                    />
                                    <StandardTask
                                        name="assign-bookkeepers"
                                        label={t`Assign bookkeepers`}
                                        path="/partner/clients/my-clients"
                                        checked={getIsChecked('assign-bookkeepers')}
                                        toggleCheck={getToggleCheck('assign-bookkeepers')}
                                    />
                                </TasksCarousel.GroupContent>
                            </TasksCarousel.Group>
                        )}

                        {data?.showPartnerAgreement && (
                            <TasksCarousel.Group>
                                <TasksCarousel.GroupTitle>
                                    <Trans>One last thing...</Trans>
                                </TasksCarousel.GroupTitle>
                                <TasksCarousel.GroupDescription>
                                    <Trans>
                                        Only one admin needs to accept this on behalf of the
                                        accountancy.
                                    </Trans>
                                </TasksCarousel.GroupDescription>
                                <TasksCarousel.GroupContent>
                                    <PartnerAgreement />
                                </TasksCarousel.GroupContent>
                            </TasksCarousel.Group>
                        )}
                    </TasksCarousel>
                </DrawerContent>
                <DrawerFooter>
                    <Link
                        href={getHelpCentreArticleLinkIntercom()}
                        onClick={() => {
                            tracking.partnerSelfOnboardingDrawerActioned({action: 'help-centre'})
                        }}
                        IconRight={NewTab}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Trans>Help centre</Trans>
                    </Link>
                    <Button
                        IconLeft={SpeechBubble}
                        variant="tertiary"
                        onClick={async () => {
                            await supportChat.show()
                            setIsOpen(false)
                            tracking.partnerSelfOnboardingDrawerActioned({action: 'open-chat'})
                        }}
                    >
                        <Trans>Chat to us</Trans>
                    </Button>
                </DrawerFooter>
            </MainDrawer>
        </>
    )
}

export const TipPopover = ({children}: {children: React.ReactNode}) => {
    const [isTipVisible, setIsTipVisible] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEY) !== 'dismissed',
    )

    const hideTip = () => {
        setIsTipVisible(false)
        localStorage.setItem(LOCAL_STORAGE_KEY, 'dismissed')
    }

    return (
        <Popover open={isTipVisible}>
            <Popover.Trigger>{children}</Popover.Trigger>
            <Popover.Content portalled={false} css={{maxWidth: 460, marginRight: tokens.spacing60}}>
                <Popover.Arrow />
                {isTipVisible && (
                    <Inline p={20}>
                        <Inline space={12}>
                            <img src={LightBulb} alt="" />
                            <Stack space={4}>
                                <Text as="h6" variant="2xlarge-accent">
                                    <Trans>Your onboarding guide</Trans>
                                </Text>
                                <Text as="p">
                                    <Trans>
                                        You can find all the useful information for getting started
                                        here.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Inline>
                        <DismissButton onClick={hideTip} aria-label={t`Dismiss tip`}>
                            <Close size={24} />
                        </DismissButton>
                    </Inline>
                )}
            </Popover.Content>
        </Popover>
    )
}

function OpenButton({children, onClick}: {children: ReactNode; onClick: () => void}) {
    return (
        <TipPopover>
            <ButtonOverlay
                key="open-button"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{opacity: tokens.slowOut}}
                p={20}
            >
                <Button variant="secondary" onClick={onClick}>
                    {children}
                </Button>
            </ButtonOverlay>
        </TipPopover>
    )
}

function CloseButton({onClick}: {onClick: () => void}) {
    return (
        <ButtonOverlay
            key="close-button"
            initial={{opacity: 0, translateX: 0}}
            animate={{opacity: 1, translateX: -416}}
            exit={{opacity: 0, translateX: 0}}
            transition={{opacity: tokens.slowOut, translateX: tokens.smoothIn}}
            py={20}
            px={16}
        >
            <IconButton
                aria-label={t`Close`}
                tooltipProps={{content: t`Close`}}
                variant={'primary'}
                Icon={Close}
                size="large"
                onClick={onClick}
            />
        </ButtonOverlay>
    )
}

const MainDrawer = styled(Drawer)`
    width: 416px;
    height: 75vh;
    min-height: 580px;
    box-shadow: ${tokens.shadowRaise};
    position: fixed;
    top: auto;
    bottom: 0;
`

const DrawerContent = styled(Stack).attrs({px: 28, pt: 20})`
    background-color: ${tokens.shade000};
    height: 100%;
    overflow-y: auto;
    text-align: left;
    /* to offset the height of the footer */
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    padding-bottom: 80px;
`

const DrawerFooter = styled(Inline).attrs({py: 16, px: 28, justifyContent: 'space-between'})`
    border-top: ${tokens.borderPrimary};
    background-color: ${tokens.shade000};
    position: fixed;
    bottom: 0;
    width: 100%;
`

const ButtonOverlay = motion(styled(Inline)`
    position: fixed;
    bottom: 0;
    right: 0;
`)

const DismissButton = styled(NakedButton)`
    margin-bottom: auto;
`
