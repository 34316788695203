import {Plural, Trans} from '@lingui/macro'
import type {FC} from 'react'
import type React from 'react'

import {Button, Link, ModalActions, ModalContent, ModalTitle, Text} from '@pleo-io/telescope'

import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'

import * as s from './add-result.styled'
import MemberLimitWarning from './member-limit-warning'

import {MemberType} from '../add-member-modal/add-member-modal'
import * as ps from '../add-member-modal/add-member-modal.styles'

interface AddResultModalProps {
    count: number
    existing: number
    memberType: MemberType
    onClose: () => void
    shouldShowMemberLimitWarning: boolean
}

interface AddResultModalFormHeader {
    count: number
    memberType: MemberType
}

interface AddResultModalFormDescription {
    existing: number
    memberType: MemberType
}

const AddResultModalFormHeader: React.FC<AddResultModalFormHeader> = ({count, memberType}) => {
    switch (memberType) {
        case MemberType.ADMIN:
            return <Plural value={count} one="# accountant added" other="# accountants added" />
        case MemberType.FIRST_ADMIN:
            return <Trans>Company admin invited</Trans>
        case MemberType.BOOKKEEPER:
            return <Plural value={count} one="# bookkeeper added" other="# bookkeepers added" />
    }
    return null
}

const AddResultModalFormDescription: React.FC<AddResultModalFormDescription> = ({
    existing,
    memberType,
}) => {
    switch (memberType) {
        case MemberType.ADMIN:
            return (
                <Plural
                    value={existing}
                    one="We had a problem inviting # accountant."
                    other="We had a problem inviting # accountants."
                />
            )
        case MemberType.BOOKKEEPER:
            return (
                <Plural
                    value={existing}
                    one="We had a problem inviting # bookkeeper."
                    other="We had a problem inviting # bookkeepers."
                />
            )
    }
    return null
}

export const AddResultModal: FC<AddResultModalProps> = ({
    memberType,
    count,
    existing,
    onClose,
    shouldShowMemberLimitWarning,
}) => {
    return (
        <>
            <ModalTitle>
                {count ? (
                    <AddResultModalFormHeader count={count} memberType={memberType} />
                ) : (
                    <Trans>No member added</Trans>
                )}{' '}
            </ModalTitle>
            <ModalContent>
                {!!count && (
                    <>
                        <ps.FormDescription>
                            <Trans>
                                We've sent them an email containing details on how to get started.
                            </Trans>
                        </ps.FormDescription>
                        <s.Sent />
                        {shouldShowMemberLimitWarning && (
                            <MemberLimitWarning onClickSeeBilling={onClose} />
                        )}
                    </>
                )}
                {!!existing && (
                    <ps.FormDescription>
                        <Text as="p" color="shade600">
                            <AddResultModalFormDescription
                                existing={existing}
                                memberType={memberType}
                            />
                        </Text>
                        <Link
                            href={getHelpCentreArticleLinkIntercom(
                                '3530116-add-users-who-have-existing-pleo-accounts',
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Trans>Learn more</Trans>
                        </Link>
                    </ps.FormDescription>
                )}
            </ModalContent>
            <ModalActions>
                <Button
                    type="button"
                    variant="primary"
                    id="add-member-done"
                    onClick={() => {
                        onClose()
                    }}
                >
                    <Trans>Done</Trans>
                </Button>
            </ModalActions>
        </>
    )
}

export default AddResultModal
