import type {FC} from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {useMobileNavigationVisibility} from '@product-web/navigation/navigation-context'
import {breakpoints} from '@product-web/styles/theme'
import {getIsPartnerUser, useUser} from '@product-web/user'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {NavigationItems} from './navigation-items'
import {NavigationAvatarWrapper as NavigationAvatarLegacy} from './navigation-legacy/navigation-avatar-wrapper'
import {NavigationLogo} from './navigation-logo'
import {NavigationSkeleton} from './navigation-skeleton'
import {NavigationAvatar} from './navigation-v2/navigation-avatar'

export const Navigation: FC = () => {
    const user = useUser()
    // We need to check if the user is a partner user because partners are still using the legacy navigation
    const isPartnerUser = getIsPartnerUser(user)

    return (
        <NavigationSkeleton
            userMenu={!isPartnerUser ? <NavigationAvatar /> : <NavigationAvatarLegacy />}
            main={<NavigationMain />}
        />
    )
}

const NavigationMain: FC = () => {
    const user = useUser()
    // We need to check if the user is a partner user because partners are still using the legacy navigation
    const isPartnerUser = getIsPartnerUser(user)

    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const {setIsMobileNavigationVisible} = useMobileNavigationVisibility()

    const handleNavigationClick = () => {
        setIsMobileNavigationVisible(false)
    }

    return (
        <>
            <NavigationLogo />
            <NavigationItems onClickItem={handleNavigationClick} showTopSpacing={true} />
            <EmptySpace />
            {!isTablet && (
                <NavigationAvatarWrapper>
                    {!isPartnerUser ? <NavigationAvatar /> : <NavigationAvatarLegacy />}
                </NavigationAvatarWrapper>
            )}
        </>
    )
}

const EmptySpace = styled.div`
    flex-grow: 1;
`
const NavigationAvatarWrapper = styled.div`
    margin-bottom: ${tokens.spacing12};
`
