import {t, Trans} from '@lingui/macro'
import {useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {Navigate, resolvePath, useLocation, useNavigate, useSearchParams} from 'react-router-dom'

import {LoadingPage, Tabs} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import {Page} from '@product-web-features/ui-page/page'

import {CompanyInfo} from './company-info'
import {CompanyReferrals} from './company-referrals'

import {bff} from '../../../bff-hooks'
import {GenericError} from '../../../components/generic-error'
import {AcceptPartnerAgreement} from '../components/accept-partner-agreement/accept-partner-agreement'

export const CompanyTab = {
    INFO: 'info',
    REFERRALS: 'referrals',
} as const

interface PartnerCompanyProps {
    tab: (typeof CompanyTab)[keyof typeof CompanyTab]
}

export const PartnerCompany = ({tab}: PartnerCompanyProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const acceptTerms = searchParams.get('acceptTerms')
    const [isModalOpen, setIsModalOpen] = useState(acceptTerms === 'true')
    const open = () => setIsModalOpen(true)
    const close = () => {
        setSearchParams({})
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (acceptTerms === 'true') {
            open()
        }
    }, [acceptTerms])

    const {partnerAgreementCountries} = useFlags()
    const {data, isLoading, isError} =
        bff.partnerCompany.getPartnerCompany.useQuery(partnerAgreementCountries)

    if (isError) {
        return <GenericError />
    }

    if (isLoading) {
        return <LoadingPage />
    }

    const {showPartnerAgreement, shouldShowReferrals} = data

    return (
        <>
            <Helmet>
                <title>{t`Company`}</title>
            </Helmet>
            <Tabs
                value={tab}
                onValueChange={(newTab) =>
                    // relative link issue should be fixed in 6.4.0 see issue: https://github.com/remix-run/react-router/issues/8350
                    navigate(resolvePath(`../${newTab}`, location.pathname), {
                        replace: true,
                    })
                }
            >
                <Page.Header>
                    <Page.Title>
                        <Trans>Company</Trans>
                    </Page.Title>
                </Page.Header>
                {shouldShowReferrals && (
                    <Tabs.TriggerList>
                        <Tabs.Trigger
                            key={CompanyTab.INFO}
                            aria-label={t`Information Tab`}
                            value={CompanyTab.INFO}
                        >
                            <Trans>Information</Trans>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            key={CompanyTab.REFERRALS}
                            aria-label={t`Referrals Tab`}
                            value={CompanyTab.REFERRALS}
                        >
                            <Trans>Referrals</Trans>
                        </Tabs.Trigger>
                    </Tabs.TriggerList>
                )}
                <Tabs.Content value={CompanyTab.INFO}>
                    <CompanyInfo />
                </Tabs.Content>
                <Tabs.Content value={CompanyTab.REFERRALS}>
                    {shouldShowReferrals ? (
                        <CompanyReferrals showPartnerAgreement={showPartnerAgreement} />
                    ) : (
                        <Navigate to={`../${CompanyTab.INFO}`} replace />
                    )}
                </Tabs.Content>
            </Tabs>
            {showPartnerAgreement && <AcceptPartnerAgreement isOpen={isModalOpen} close={close} />}
        </>
    )
}

export default PartnerCompany
