import {useCallback, useEffect} from 'react'

import {useCreditPersistedState} from './credit-persisted-state'

export enum PromptName {
    DECLINED_TRANSACTIONS = 'overdraft-declined-transactions',
    LOW_BALANCE = 'overdraft-low-balance',
    HIGH_OVERDRAFT_UTILIZATION = 'high-overdraft-utilization',
    HIGHER_OVERDRAFT_LIMIT = 'higher-overdraft-limit',
}

type PromptsPersistedState = {
    dismissedAt: Date | null
}

// Keep track of dismissed prompts
export const usePromptsPersistedState = () => {
    const [dismissedPrompts, setDismissedPrompts] = useCreditPersistedState<PromptsPersistedState>(
        'adoptionPrompts',
        {
            dismissedAt: null,
        },
    )

    const dismissPrompt = useCallback(() => {
        setDismissedPrompts({
            dismissedAt: new Date(),
        })
    }, [setDismissedPrompts])

    const resetPrompt = useCallback(() => {
        setDismissedPrompts({
            dismissedAt: null,
        })
    }, [setDismissedPrompts])

    return {
        dismissedPrompts,
        dismissPrompt,
        resetPrompt,
    }
}

const DISMISSED_MODAL_MAX_AGE = 7 * 24 * 60 * 60 * 1000 // 7 days

// Reset dismissed state after expiration period so that the prompts can be
// shown to the user again if the trigger event happens again
export const useResetPromptsPersistedState = () => {
    const {dismissedPrompts, resetPrompt} = usePromptsPersistedState()
    useEffect(() => {
        const {dismissedAt} = dismissedPrompts ?? {}
        if (!dismissedAt) {
            return
        }
        const dismissedTimeMs = new Date().getTime() - new Date(dismissedAt).getTime()
        if (dismissedTimeMs > DISMISSED_MODAL_MAX_AGE) {
            resetPrompt()
        }
    }, [dismissedPrompts, resetPrompt])
}
