import {useEffect, useState} from 'react'

import {useFlags} from '@product-web/flags'
import {PLEO_GUIDE_AWARENESS_COMPLETED_VALUE} from '@product-web-features/onboarding/increase-awareness/storage'

import {bff} from '../bff-hooks'

export const useDownloadMobileAppModal = () => {
    const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(false)
    const {bifrostNudgeToSpendExperiment} = useFlags()

    const {data: shouldShowDownloadModal} =
        bff.companyOnboarding.downloadAppModal.getDownloadAppModal.useQuery()
    const {mutate: setDownloadAppModal} =
        bff.companyOnboarding.downloadAppModal.setDownloadAppModal.useMutation()

    useEffect(() => {
        if (bifrostNudgeToSpendExperiment && shouldShowDownloadModal) {
            setIsDownloadAppModalOpen(true)
        }
    }, [bifrostNudgeToSpendExperiment, shouldShowDownloadModal])

    const onCloseDownloadAppModal = () => {
        setIsDownloadAppModalOpen(false)
        setDownloadAppModal(PLEO_GUIDE_AWARENESS_COMPLETED_VALUE)
    }

    return {isDownloadAppModalOpen, onCloseDownloadAppModal}
}
