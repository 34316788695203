import * as React from 'react'
import {Route} from 'react-router'

import {roleSets} from '@product-web/user'

const WellnessSettingsPage = React.lazy(async () => import('./screens/wellness'))

export const settingsRoutes = (
    <Route
        index
        element={<WellnessSettingsPage />}
        handle={{auth: true, allowedRoles: roleSets.company}}
    />
)
