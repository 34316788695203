import {useEmployeeSummary} from '@product-web/api-deimos/employee'
import {MAX_AMOUNT_OF_USERS_FOR_FREE_PLAN} from '@product-web/entitlement/helpers'
import {useFlags} from '@product-web/flags'
import {useCompanySubscription} from '@product-web/paywall/lib/company-subscription'

import {useNewPricingPlan} from '../add-modals/add-members/helpers'
import {bff} from '../bff-hooks'

export const useShouldShowMemberLimitWarning = () => {
    const {data: employeesCount} = useEmployeeSummary()
    const {data: companySubscriptionData} = useCompanySubscription()
    const canUseNewPricing = useNewPricingPlan(companySubscriptionData?.platformRatePlan?.type)
    const {trialBannerModals} = useFlags()

    const isInTrial = Boolean(companySubscriptionData?.isInTrialPeriod)

    const eligibleForFree =
        employeesCount &&
        employeesCount.owner + employeesCount.member <= MAX_AMOUNT_OF_USERS_FOR_FREE_PLAN

    const shouldFetchPricingPlan =
        trialBannerModals && !!employeesCount && !eligibleForFree && isInTrial && !canUseNewPricing

    const {data: pricingPlan} = bff.addMemberModal.getAddMemberModalPricingPlan.useQuery(
        undefined,
        {
            enabled: shouldFetchPricingPlan,
        },
    )

    return Boolean(
        shouldFetchPricingPlan && pricingPlan && ['free', 'starter'].includes(pricingPlan),
    )
}
