import {
    cleanupSession,
    forceSessionRefresh,
    loginSession,
    loginSessionWithPasscode,
    logoutSession,
    openSession,
    SESSION_TYPE_KEY,
    SessionType,
    verifiedLoginSession,
    verifyLoginAttempt,
} from './operations'
import {removeUserParams as removeParamsFromRegistration} from './register-state'
import {sessionStore} from './store'

export function getMutations(setIsLoggingOut: () => void) {
    /**
     * Method to log in the user during register flows
     * @param getToken
     */
    async function register(
        getToken: () => Promise<undefined | {accessToken: string; accessTokens?: string[]}>,
    ) {
        const response = await getToken()
        if (response) {
            // Cache the access token returned from Kerberos, this will be used
            // for any outgoing requests with the `auth: 'user'` option.
            const {accessToken, accessTokens} = response
            sessionStore.set({accessToken, accessTokens})

            // Cache session type
            sessionStorage.setItem(SESSION_TYPE_KEY, SessionType.Otp)

            // Clear localstorage params needed for registration
            removeParamsFromRegistration()

            // Force a refresh of the session, which will fetch a new access token
            // containing the newly created employee.
            await forceSessionRefresh()
        }
    }

    /**
     * Method to log user in and set the session type
     * @param email
     * @param passcode
     * @param otp
     * @param trust
     * @param loginToken
     */

    async function login({
        email,
        passcode,
        otp,
        trust,
        loginToken,
        otpType,
    }: Parameters<typeof loginSession>[0]) {
        const {accessToken, accessTokens, companyId, partnerId} = await loginSession({
            email,
            passcode,
            otp,
            trust,
            loginToken,
            otpType,
        })

        await openSession({companyId, accessToken, accessTokens, email, partnerId})
    }

    async function verifiedLogin({
        email,
        passcode,
        otp,
        trust,
        loginToken,
        otpType,
        codeChallenge,
        codeChallengeMethod,
    }: Parameters<typeof verifiedLoginSession>[0]) {
        const response = await verifiedLoginSession({
            email,
            passcode,
            otp,
            trust,
            loginToken,
            otpType,
            codeChallenge,
            codeChallengeMethod,
        })

        if (response.result === 'authenticated') {
            await openSession({email, ...response.authenticationData})
        }

        return response
    }

    /**
     * Method to log user on a trusted device
     * This is used for a second time login.
     * @param param.passcode
     * @param param.email
     */
    async function loginWithPasscode({
        passcode,
        email,
    }: Parameters<typeof loginSessionWithPasscode>[0]) {
        const {accessToken, accessTokens, companyId} = await loginSessionWithPasscode({
            passcode,
            email,
        })

        await openSession({companyId, accessToken, accessTokens, email})
    }

    /**
     * Method to log user on a trusted device
     * This is used for a second time login.
     * @param param.token
     */
    async function loginWithToken(token: string, codeVerifier: string) {
        const response = await verifyLoginAttempt(token, codeVerifier)

        await openSession(response)

        return response
    }

    /**
     * Ends the session of the currently logged in user and clears all the caches
     * and session-related local data. It also flushes the session token info in
     * cache to trigger the logout behavior
     * @param options.afterLogoutPath The page to redirect the user to after the page reload (defaults to `/login`)
     * @returns A promise that resolves when the user is logged out
     */
    async function logout(options?: Parameters<typeof cleanupSession>[0]) {
        await logoutSession()
        setIsLoggingOut()
        cleanupSession(options)
    }

    return {
        register,
        login,
        verifiedLogin,
        loginWithPasscode,
        loginWithToken,
        logout,
    }
}
