import type {FC} from 'react'
import {Link as RouterLink} from 'react-router-dom'

import * as s from './call-to-action.styles'

interface BaseProps {
    image: string
    actionIcon?: string
}

interface PropsWithOnClick extends BaseProps {
    href?: never
    target?: never
    to?: never
    onClick: (arg: any) => void
    disabled?: boolean
}

interface PropsWithHref extends BaseProps {
    href: string
    target?: string
    to?: never
    onClick?: (arg: any) => void
    disabled?: boolean
}

interface PropsWithTo extends BaseProps {
    href?: never
    target?: never
    to: string
    onClick?: (arg: any) => void
    disabled?: boolean
}

type Props = PropsWithOnClick | PropsWithHref | PropsWithTo

const getBaseComponent = (props: Props) => {
    if (props.href) {
        return 'a'
    } else if (props.to) {
        return RouterLink
    }

    return 'button'
}

const CallToAction: FC<Props> = (props) => {
    return (
        <s.Base data-generic-ui="call-to-action" {...props} as={getBaseComponent(props)}>
            <s.Image src={props.image} alt="" />
            <s.Content>{props.children}</s.Content>
            <s.ActionIcon />
        </s.Base>
    )
}

export default CallToAction
