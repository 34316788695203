import * as React from 'react'
import {Navigate, Outlet, Route, useLocation} from 'react-router-dom'
import {useExperiment} from 'statsig-react'

import {LoadingPage} from '@pleo-io/telescope'

import {GetStartedScreen} from './screens/get-started/get-started'

const OnboardTeamWizardRoute = React.lazy(
    async () => import('./screens/onboard-team-wizard-v2/onboard-team-wizard'),
)
const PleoGuidePage = React.lazy(async () => import('./screens/pleo-guide/pleo-guide-page'))
const PleoGuideSettingsPage = React.lazy(
    async () => import('./screens/settings-wizard/settings-wizard'),
)
const StartSpendingPage = React.lazy(async () => import('./screens/start-spending/start-spending'))
const TopupWalletPage = React.lazy(
    async () => import('./screens/topup-wallet/topup-wallet/topup-wallet'),
)

export const routes = (
    <Route handle={{auth: true, allowedRoles: ['owner']}} element={<RedirectWrapper />}>
        <Route index element={<LandingRedirect />} />
        <Route path="pleo-guide" element={<PleoGuidePage />} />
        <Route path="get-started" element={<GetStartedScreen />} />
        <Route handle={{minCompanyStatus: 'fdd'}}>
            <Route path="topup-wallet" element={<TopupWalletPage />} />
            <Route path="start-spending" element={<StartSpendingPage />} />
        </Route>
        <Route path="setup-accounting" element={<PleoGuideSettingsPage />} />
        <Route path="onboard-team" element={<OnboardTeamWizardRoute />} />
    </Route>
)

function RedirectWrapper() {
    const experiment = useExperiment('get_started_screen_variants_h1_2024')
    const location = useLocation()

    if (experiment.isLoading) {
        return <LoadingPage />
    }

    const experimentVariant: string = experiment.config.get('variant', 'control')

    if (experimentVariant === 'accordions' && location.pathname === '/onboarding/pleo-guide') {
        return <Navigate to="get-started" replace />
    }

    if (experimentVariant === 'control' && location.pathname === '/onboarding/get-started') {
        return <Navigate to="pleo-guide" replace />
    }

    return <Outlet />
}

function LandingRedirect() {
    const location = useLocation()
    const isPleoGuideRoute = location.pathname.includes('/pleo-guide')

    return (
        <Navigate
            to={isPleoGuideRoute ? `${location.pathname}${location.search}` : 'pleo-guide'}
            replace
        />
    )
}
