import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Badge, Box, Button, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {Bank, CashSingle, Check, Close} from '@pleo-io/telescope-icons'

import type {BillingInfoCurrency} from '@product-web/bff-moons/generated/beyond'
import type {CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/i18n'
import {breakpoints} from '@product-web/styles/theme'
import {useUser} from '@product-web/user'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import {
    getPlanContentConfiguration,
    isOverdraftAvailable,
    renderUpToCashbackLine,
    renderUpToCreditLine,
} from './pricing-plans.helper'

import type {PlansData} from '../index.bff'
import {formatMinorNumberToCurrency} from '../lib/currency-formatter'

type PlanColumnProps = {
    currentPlan: CpqRatePlanType | null
    onSelectPlan: (availablePlanPricing: PlansData['plansPricing'][number]) => void
    onContactUs: (planName: CpqRatePlanType) => void
    availablePlanPricing: PlansData['plansPricing'][number]
    discountEnabled: boolean
    currency: BillingInfoCurrency
    isCompanyNotVerified: boolean
    isLegacyPlan: boolean
    showRecommendedBadge?: boolean
}

const CurrentPlanBadge = styled(Badge)`
    height: 40px;
    border-radius: 0;
    justify-content: center;
    width: 100%;
    font-size: ${tokens.fontMedium};
`

export const PlanColumn = ({
    currentPlan,
    onSelectPlan,
    onContactUs,
    availablePlanPricing,
    discountEnabled,
    currency,
    isCompanyNotVerified,
    isLegacyPlan,
    showRecommendedBadge,
}: PlanColumnProps) => {
    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const planConfiguration = getPlanContentConfiguration(availablePlanPricing.planName)
    const isOverdraft = isOverdraftAvailable(currency)
    const columnPlanName = availablePlanPricing.planName

    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    const planPrice = formatMinorNumberToCurrency({
        value: discountEnabled ? (availablePlanPricing.YEAR ?? 0) / 12 : availablePlanPricing.MONTH,
        options: {currency},
        locale,
    })
    const isCurrentPlan = currentPlan === columnPlanName && !isLegacyPlan

    // The description should have a minimum height to align the buttons horizontally
    // when one of the plans has a longer description.
    const descriptionMinHeight = isSmallScreen ? '0' : '67px'

    return (
        <Card padding={24} data-testid={`plan-column${isCurrentPlan ? '-current' : ''}`}>
            <Stack space={24} stretch>
                <Stack space={8}>
                    <Inline space={8} alignY="center">
                        <Text variant="2xlarge-accent" weight="regular">
                            {planConfiguration?.planName}
                        </Text>
                        {showRecommendedBadge && (
                            <Badge variant="discover">
                                <Trans>Recommended</Trans>
                            </Badge>
                        )}
                    </Inline>
                    <Text
                        variant="medium-default"
                        color="colorContentStaticQuiet"
                        css={{minHeight: descriptionMinHeight}}
                    >
                        {planConfiguration?.description}
                    </Text>
                </Stack>
                <Card.Divider />
                <Box>
                    <Text color="colorContentStatic">
                        <Trans>Starting at</Trans>
                    </Text>
                    <Inline alignItems="baseline" space={4} css={{minWidth: 0}}>
                        {discountEnabled && columnPlanName !== 'STARTER' && (
                            <Text
                                variant="3xlarge-accent"
                                color="colorContentInteractiveDisabled"
                                css={{textDecoration: 'line-through'}}
                            >
                                {formatMinorNumberToCurrency({
                                    value: availablePlanPricing.MONTH,
                                    locale,
                                })}
                            </Text>
                        )}
                        <Trans>
                            <Text variant="3xlarge-accent" weight="medium">
                                {planPrice}
                            </Text>
                            <Text variant="medium-default" color="colorContentStatic">
                                /month
                            </Text>
                        </Trans>
                    </Inline>
                </Box>

                <Stack stretch>
                    <Inline
                        space={16}
                        justifyContent="center"
                        justifyItems="stretch"
                        alignItems="center"
                        css={{width: '100%'}}
                    >
                        {isCurrentPlan ? (
                            <CurrentPlanBadge variant="positive">
                                <Trans>Current plan</Trans>
                            </CurrentPlanBadge>
                        ) : (
                            <>
                                <Button
                                    variant={columnPlanName !== 'STARTER' ? 'primary' : 'secondary'}
                                    css={{width: '100%'}}
                                    disabled={isCompanyNotVerified || !currentPlan}
                                    aria-label={t`Select ${planConfiguration?.planName} plan`}
                                    onClick={() => onSelectPlan(availablePlanPricing)}
                                >
                                    <Trans>Select</Trans>
                                </Button>
                                {planConfiguration?.contactSalesEnabled && (
                                    <ContactSupport
                                        chatLabel={t`Contact us`}
                                        css={{whiteSpace: 'nowrap'}}
                                        onClick={() => {
                                            onContactUs(columnPlanName)
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Inline>
                </Stack>

                <Stack stretch space={8}>
                    <Inline space={8} alignItems="flex-start">
                        {planConfiguration?.cashback ? (
                            <>
                                <Bank size={16} css={{marginTop: '0.2em'}} />
                                <Text>{renderUpToCashbackLine(planConfiguration.cashback)}</Text>
                            </>
                        ) : (
                            <>
                                <Close
                                    size={16}
                                    color={tokens.colorContentStaticQuiet}
                                    css={{marginTop: '0.2em'}}
                                />{' '}
                                <Text color="colorContentStaticQuiet">
                                    <Trans>No cashback</Trans>
                                </Text>
                            </>
                        )}
                    </Inline>
                    {isOverdraft && (
                        <Inline space={8} alignItems="flex-start">
                            {planConfiguration?.overdraft ? (
                                <>
                                    <CashSingle size={16} css={{marginTop: '0.2em'}} />
                                    <Text>
                                        {renderUpToCreditLine({
                                            planConfiguration,
                                            currency,
                                            locale,
                                        })}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Close
                                        size={16}
                                        color={tokens.colorContentStaticQuiet}
                                        css={{marginTop: '0.2em'}}
                                    />
                                    <Text color="colorContentStaticQuiet">
                                        <Trans>No credit</Trans>
                                    </Text>
                                </>
                            )}
                        </Inline>
                    )}
                </Stack>

                <Stack space={8} stretch>
                    <Text weight="medium">{planConfiguration?.subTitle}</Text>

                    {planConfiguration?.entitlements.map((entitlement) => (
                        <Inline space={8} alignItems="flex-start" key={entitlement}>
                            <Check size={16} css={{marginTop: '0.2em'}} />
                            <Text variant="medium-default" color="colorContentStatic">
                                {entitlement}
                            </Text>
                        </Inline>
                    ))}
                </Stack>
            </Stack>
        </Card>
    )
}
