import {Trans} from '@lingui/macro'

import * as EmptySection from './empty-section/empty-section'

import detective from '../images/detective-yellow.svg'

export const GenericError = () => (
    <EmptySection.Root>
        <EmptySection.Content>
            <EmptySection.Image src={detective} />
            <EmptySection.Title>
                <Trans>Oops, we cannot find it</Trans>
            </EmptySection.Title>
            <EmptySection.Subtitle>
                <Trans>
                    There seems to be a problem on our end. We're working on a fix for this but in
                    the meantime, please be patient with us and try again later.
                </Trans>
            </EmptySection.Subtitle>
        </EmptySection.Content>
    </EmptySection.Root>
)
