import {Trans} from '@lingui/macro'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {Callout, Link} from '@pleo-io/telescope'

export const BillingCallout: React.FC<{
    onClickDescriptionAction?: () => void
    onClickTitleAction?: () => void
    title: string
    description?: string
    type?: 'info' | 'warn'
    billingCta?: string
}> = ({
    type = 'info',
    title,
    description,
    onClickDescriptionAction,
    onClickTitleAction,
    billingCta,
}) => {
    return (
        <StyledCallout variant={type === 'warn' ? 'warning' : 'info'}>
            <Callout.Content>
                <Callout.ContentHeading>
                    {title}{' '}
                    {onClickTitleAction && (
                        <Link
                            to="/settings/general/billing"
                            as={RouterLink}
                            onClick={onClickTitleAction} // e.g. close modal
                        >
                            {billingCta ? billingCta : <Trans>See plans</Trans>}
                        </Link>
                    )}
                </Callout.ContentHeading>
                <Callout.Text data-testid="billing-callout-description">
                    {description}{' '}
                    {onClickDescriptionAction && (
                        <Link
                            to="/settings/general/billing"
                            as={RouterLink}
                            onClick={onClickDescriptionAction} // e.g. close modal
                        >
                            {billingCta ? billingCta : <Trans>See plans</Trans>}
                        </Link>
                    )}
                </Callout.Text>
            </Callout.Content>
        </StyledCallout>
    )
}

const StyledCallout = styled(Callout)`
    width: 100%;
    max-width: 452px;
`
