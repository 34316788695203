import type {FC} from 'react'

import {useLoggedInAccounts} from '@product-web/auth--accounts'
import {useLogout} from '@product-web/auth--session/context'
import {
    getCompany,
    getFirstName,
    getIsBookkeeper,
    getIsCompanyOwner,
    getIsExtendedBookkeeper,
    useUser,
} from '@product-web/user'

import {NavigationAvatar} from './navigation-avatar'

export const NavigationAvatarWrapper: FC = () => {
    const {accounts, switchAccount} = useLoggedInAccounts()

    const user = useUser()
    const company = getCompany(user)
    const logout = useLogout()
    const firstName = getFirstName(user)
    const isBookkeeperExtended = getIsExtendedBookkeeper(user)
    const isBookkeeper = getIsBookkeeper(user)
    const isOwner = getIsCompanyOwner(user)

    let bookkeeperAccess: null | 'extended' | 'basic' = null
    if (isBookkeeperExtended) {
        bookkeeperAccess = 'extended'
    } else if (isBookkeeper) {
        bookkeeperAccess = 'basic'
    }

    return (
        <NavigationAvatar
            accounts={accounts}
            switchAccount={switchAccount}
            employee={user?.employee ?? user?.partnerEmployee}
            firstName={firstName}
            onLogout={logout}
            company={company}
            isOwnerOrBookkeeper={isOwner || isBookkeeper}
            bookkeeperAccess={bookkeeperAccess}
        />
    )
}
