import {t} from '@lingui/macro'
import * as ibanTools from 'ibantools'
// eslint-disable-next-line no-restricted-imports
import * as yup from 'yup'

import type {DateRange} from './helper'
import {isInDateRange, isValidDateFormat} from './helper'

const translatedMessages = {
    password: {
        capitalLetter: () => t`Password needs at least one capital letter`,
        lowercaseLetter: () => t`Password needs at least one lower-case letter`,
        maxCharacters: () => t`Password has a maximum of 255 characters`,
        minCharacters: () => t`Password needs minimum of 8 characters`,
    },
    address: {
        country: () => t`Country must be in the correct format`,
    },
    requiredAddress: {
        addressLine1: () => t`Address line 1 is required`,
        locality: () => t`Locality is required`,
        postalCode: () => t`Postal code is required`,
        countryRequired: () => t`Country code is required`,
        countryFormat: () => t`Country code must be in the correct format (for example 'DK')`,
    },
    personAddress: {
        postalCode: () => t`Postal code is required`,
        countryFormat: () => t`Country must be in the correct format`,
        countryRequired: () => t`Country must be in the correct format`,
    },
}

declare module 'yup' {
    export interface StringSchema {
        secure(message?: yup.TestOptionsMessage): StringSchema
        uuid(message?: yup.TestOptionsMessage): StringSchema
        greaterThanWithoutSpaces(maxChar: number, message?: yup.TestOptionsMessage): StringSchema
        isValidDateFormat(message?: yup.TestOptionsMessage): StringSchema
        isNotTooEarly(dateRange: DateRange, message: yup.TestOptionsMessage): StringSchema
        isNotTooLate(dateRange: DateRange, message: yup.TestOptionsMessage): StringSchema
        iban(message?: yup.TestOptionsMessage): StringSchema
        bic(message?: yup.TestOptionsMessage): StringSchema
    }
}

yup.addMethod(yup.string, 'secure', function (message?: yup.TestOptionsMessage) {
    return this.test({
        name: 'secure',
        exclusive: true,
        message: message || t`Value must only contain valid letters.`,
        test: (value) => {
            return yup
                .string()
                .matches(/^(?:[^<>"]+)?$/, {excludeEmptyString: true, message})
                .isValidSync(value)
        },
    })
})

yup.addMethod(yup.string, 'uuid', function (message?: yup.TestOptionsMessage) {
    return this.test({
        name: 'uuid',
        exclusive: true,
        message: message || t`Value must be a valid UUID`,
        test: (value) => {
            return yup
                .string()
                .matches(
                    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                    {excludeEmptyString: true, message},
                )
                .isValidSync(value)
        },
    })
})

yup.addMethod(
    yup.string,
    'greaterThanWithoutSpaces',
    function (maxChar: number, message?: yup.TestOptionsMessage) {
        return this.test({
            name: 'greaterThanWithoutSpaces',
            exclusive: true,
            message: message || t`This code should not be longer than ${maxChar} characters`,
            test: (value) => {
                const cleanedUpVal = value ? value.replace(/-|\s/g, '') : ''
                return cleanedUpVal.length < maxChar + 1
            },
        })
    },
)

yup.addMethod(yup.string, 'isValidDateFormat', function (message?: yup.TestOptionsMessage) {
    return this.test({
        name: 'isValidDateFormat',
        exclusive: true,
        message: message || t`A valid date is required`,
        test: isValidDateFormat,
    })
})

yup.addMethod(
    yup.string,
    'isNotTooEarly',
    function (dateRange: DateRange, message?: yup.TestOptionsMessage) {
        return this.test({
            name: 'isNotTooEarly',
            exclusive: true,
            message,
            test: (value) => {
                return isInDateRange(value, dateRange, 'min')
            },
        })
    },
)

yup.addMethod(
    yup.string,
    'isNotTooLate',
    function (dateRange: DateRange, message?: yup.TestOptionsMessage) {
        return this.test({
            name: 'isNotTooLate',
            exclusive: true,
            message,
            test: (value) => {
                return isInDateRange(value, dateRange, 'max')
            },
        })
    },
)

const ibanTestValuesForFakePayments = [
    'DK1111111111111111',
    'DK1111111111111112',
    'DK1111111111111113',
]

yup.addMethod(yup.string, 'iban', function (message?: yup.TestOptionsMessage) {
    return this.test({
        name: 'iban',
        exclusive: true,
        message: message || t`Invalid IBAN format`,
        test: (value) => {
            if (ibanTestValuesForFakePayments.includes(value)) {
                return true
            }
            const cleanedUpVal = value ? value.replace(/-|\s/g, '') : ''
            return ibanTools.isValidIBAN(cleanedUpVal)
        },
    })
})

yup.addMethod(yup.string, 'bic', function (message?: yup.TestOptionsMessage) {
    return this.test({
        name: 'bic',
        exclusive: true,
        message: message || t`Invalid BIC format`,
        test: (value) => {
            const cleanedUpVal = value ? value.replace(/-|\s/g, '') : ''
            return ibanTools.isValidBIC(cleanedUpVal)
        },
    })
})

export const password = yup
    .string()
    .matches(/[A-Z]/, {message: translatedMessages.password.capitalLetter()})
    .matches(/[a-z]/, {message: translatedMessages.password.lowercaseLetter()})
    .min(8, translatedMessages.password.minCharacters())
    .max(255, translatedMessages.password.maxCharacters())

// Use string.secure() instead
export const secureString = (message?: yup.TestOptionsMessage) => yup.string().secure(message)

export const email = (message?: string) => yup.string().email(message)
export const country = (message?: string) => yup.string().matches(/^[A-Z]{2}$/, {message})

export const requiredAddress = yup.object().shape({
    addressLine1: yup.string().secure().required(translatedMessages.requiredAddress.addressLine1),
    addressLine2: yup.string().secure(),
    postalCode: yup.string().secure().required(translatedMessages.requiredAddress.postalCode),
    locality: yup.string().secure().required(translatedMessages.requiredAddress.locality),
    region: yup.string().secure(),
    country: country(translatedMessages.requiredAddress.countryFormat()).required(
        translatedMessages.requiredAddress.countryRequired,
    ),
})

export default yup
