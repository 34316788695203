import {t} from '@lingui/macro'

import {reportError} from '@product-web/error/report'
import {useToaster} from '@product-web/toaster'

import {useSubmitPleoReserveChanges} from './credit/pleo-reserve-api'
import {useCompanyTopUpSettings} from './use-company-top-up-settings'
import {useVrpAutoTopUpStorage} from './use-vrp-auto-top-up-storage'

export function useUpdateStoredAutoTopUpSettings() {
    const {showToast} = useToaster()

    const {savedVrpAutoTopUpSettings} = useVrpAutoTopUpStorage()
    const {
        mutations: {updateTopUpSettings},
    } = useCompanyTopUpSettings()
    const {submitPleoReserveChanges} = useSubmitPleoReserveChanges()

    return async function (options?: {onSuccess: () => void}) {
        try {
            if (!savedVrpAutoTopUpSettings) {
                reportError(
                    'VRP Auto Top-up (ATU) creation: have no saved ATU settings after sending the consent token',
                )

                showToast(t`There was an error while setting up your recurring Top-up`, {
                    title: t`Have no saved Auto Top-up settings`,
                    level: 'error',
                })

                return
            }

            await updateTopUpSettings({
                autoTopupStatus: savedVrpAutoTopUpSettings.autoTopupStatus ?? 'PENDING',
                paymentRail: 'VRP',
                autoTopupType: savedVrpAutoTopUpSettings.autoTopupType,
                lowBalanceTopup: {
                    amount: savedVrpAutoTopUpSettings.autoTopUpAmount,
                    threshold: savedVrpAutoTopUpSettings.autoTopUpThreshold,
                },
            })
            if (typeof savedVrpAutoTopUpSettings.pleoReserveEnabled !== 'undefined') {
                // in case we're enabling both Pleo Reserve and ATU, we need to call the ATU endpoint first
                await submitPleoReserveChanges({
                    pleoReserveEnabled: savedVrpAutoTopUpSettings.pleoReserveEnabled,
                    autoTopUpAmount: savedVrpAutoTopUpSettings.autoTopUpAmount,
                    autoTopUpThreshold: savedVrpAutoTopUpSettings.autoTopUpThreshold,
                })
            }

            options?.onSuccess()
        } catch (err: any) {
            reportError(err, `Error while enabling VRP Auto Top-up: "${err.message}"`)

            showToast(t`There was an error while setting up your recurring Top-up`, {
                title: t`Saving Auto Top-up settings failed`,
                level: 'error',
            })
        }
    }
}
