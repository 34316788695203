import * as tracking from '@product-web/analytics'
import {useCompanyUser} from '@product-web/user'

import type {SubmitTagBudgetStepName as StepName} from '../screens/create-tag-budget/use-submit-tag-budget-stepper'

type DemoBudgetEventSource =
    | 'budget_actions_menu'
    | 'create_budget_button'
    | 'budget_expenses_link'
    | 'view_demo_budget_button'

export const TRACKING_EVENTS_FIELD_NAMES: Record<string, string> = {
    amount: 'amount',
    endDate: 'end_date',
    name: 'name',
    reviewers: 'reviewer',
    startDate: 'start_date',
    tag: 'tag',
}

const BUDGET_FORM_STEPS: StepName[] = ['details', 'tags', 'budgetOwners']

const getTargetStep = ({currentStep, delta}: {currentStep: StepName; delta: 1 | -1}) => {
    return BUDGET_FORM_STEPS[BUDGET_FORM_STEPS.indexOf(currentStep) + delta]
}

const getStepForward = ({currentStep}: {currentStep: StepName}) => {
    return getTargetStep({currentStep, delta: 1})
}

const getStepBackward = ({currentStep}: {currentStep: StepName}) => {
    return getTargetStep({currentStep, delta: -1})
}

export const useTagBudgetTracking = () => {
    const user = useCompanyUser()
    const actor_role = user.role

    return {
        overviewPageViewed: () => {
            tracking.tagBudgetsOverviewViewed({
                actor_role,
            })
        },
        filterActioned: ({
            action,
            filter_name,
            filter_value,
        }: {
            action: 'applied' | 'cleared'
            filter_name: 'status' | 'tag'
            filter_value?: unknown
        }) => {
            tracking.tagBudgetFilterActioned({
                actor_role,
                action,
                filter_name,
                filter_value,
            })
        },
        closedEarly: ({
            budget_id,
            end_date,
            source,
        }: {
            budget_id: string
            end_date: string
            source: 'edit' | 'overview'
        }) => {
            tracking.tagBudgetClosedEarly({
                actor_role,
                budget_id,
                end_date,
                source,
            })
        },
        unarchivedTag: ({budget_id, tag_id}: {budget_id: string; tag_id: string}) => {
            tracking.tagBudgetUnarchivedTag({
                actor_role,
                budget_id,
                tag_id,
            })
        },
        expenseDownloaded: ({budget_id}: {budget_id: string}) => {
            tracking.tagBudgetsExpenseDownloaded({
                actor_role,
                budget_id,
            })
        },
        budgetViewed: ({budget_id}: {budget_id: string}) => {
            tracking.tagBudgetViewed({
                actor_role,
                budget_id,
            })
        },
        budgetOpened: ({budget_id}: {budget_id: string}) => {
            tracking.tagBudgetOpened({
                actor_role,
                budget_id,
            })
        },
        creationStarted: ({field_name}: {field_name: string}) => {
            tracking.tagBudgetCreationStarted({
                actor_role,
                field_name,
            })
        },
        creationCompleted: ({
            budget_amount,
            budget_id,
            budget_name,
            start_date,
            end_date,
            reviewer_ids,
            tag_ids,
            tag_names,
        }: {
            budget_amount: number
            budget_id: string
            budget_name: string
            start_date: string
            end_date: string
            reviewer_ids: string[]
            tag_ids: string[]
            tag_names: string[]
        }) => {
            tracking.tagBudgetCreationCompleted({
                actor_role,
                budget_amount,
                budget_id,
                budget_name,
                start_date,
                end_date,
                reviewer_ids,
                tag_ids,
                tag_names,
            })
        },
        creationStepForward({currentStep}: {currentStep: StepName}): void {
            const step_name = getStepForward({currentStep})

            tracking.tagBudgetCreationStepForward({
                actor_role,
                step_name,
            })
        },
        creationStepBackward({currentStep}: {currentStep: StepName}): void {
            const step_name = getStepBackward({currentStep})

            tracking.tagBudgetCreationStepBackward({
                actor_role,
                step_name,
            })
        },
        creationDiscarded: ({action}: {action: 'completed' | 'started'}) => {
            tracking.tagBudgetDiscard({
                actor_role,
                action,
            })
        },
        edited: ({
            budget_amount,
            budget_id,
            budget_name,
            start_date,
            end_date,
            reviewer_ids,
        }: {
            budget_amount: number
            budget_id: string
            budget_name: string
            start_date: string
            end_date: string
            reviewer_ids: string[]
        }) => {
            tracking.tagBudgetEdited({
                actor_role,
                budget_amount,
                budget_id,
                budget_name,
                start_date,
                end_date,
                reviewer_ids,
            })
        },
        demoBudgetActioned: ({
            action,
            source,
        }: {
            action: 'hovered' | 'clicked'
            source: DemoBudgetEventSource
        }) => {
            tracking.demoBudgetActioned({action, source})
        },
        tagReviewOnboarding: ({
            action,
            source,
        }: {
            action: 'viewed' | 'closed' | 'clicked'
            source: 'budgets_list_modal' | 'budget_details_callout'
        }) => {
            tracking.tagReviewOnboarding({action, source})
        },
    }
}
