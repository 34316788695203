import {dayjs} from '@product-web/dates/dayjs'

// This is the default trial period users see before FDD
export const DEFAULT_TRIAL_LENGTH = 21

export const getDatesDiffInDays = (startDate?: string, endDate?: string): number => {
    if (!startDate || !endDate) {
        return 0
    }

    const start = dayjs.utc(startDate).startOf('date')
    const end = dayjs.utc(endDate).startOf('date')

    return end.diff(start, 'days')
}
