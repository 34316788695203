import type {ComponentProps, ReactNode} from 'react'
import styled from 'styled-components'

import {Inline, Stack} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'

const Main = styled(Inline).attrs({flexDirection: 'column'})``

const Content = styled(Inline).attrs({flex: 1, pb: 24, alignItems: 'center'})`
    width: 100%;
`

const Left = styled(Stack).attrs<ComponentProps<typeof Stack>>(({mt = 24}) => ({mt}))`
    flex: 1;
`

const Right = styled(Stack)`
    flex: 1;
    height: 100%;
    align-content: center;
    justify-content: center;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        display: none;
    }
`

const Actions = styled(Inline).attrs({mt: 24, space: 24, alignItems: 'center'})``
const Illustration = styled.img`
    align-self: 'center';
`

export const Layout = ({children}: {children: ReactNode}) => <Main>{children}</Main>

Layout.Content = Content
Layout.Right = Right
Layout.Left = Left
Layout.Actions = Actions
Layout.Illustration = Illustration
