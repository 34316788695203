import {Trans} from '@lingui/macro'
import type React from 'react'

import {Text} from '@pleo-io/telescope'

interface AddressProps {
    className?: string
    address?: {
        addressLine1: string
        addressLine2?: string
        postalCode: string
        locality: string
        region?: string
        country: string
        attention?: string
        company?: string
    }
}

const Address: React.FC<AddressProps> = ({address, className}) => {
    if (!address) {
        return null
    }
    return (
        <div className={className} data-testid="company-address" data-generic-ui="address">
            {address.company ? <Text as="p">{address.company}</Text> : null}
            {address.attention ? (
                <Text as="p">
                    <Trans>att/ {address.attention}</Trans>
                </Text>
            ) : null}
            <Text as="p">{address.addressLine1}</Text>
            {address.addressLine2 ? <Text as="p">{address.addressLine2}</Text> : null}
            <Text as="p">
                {address.postalCode} {address.locality}
            </Text>
            {address.region ? <Text as="p">{address.region}</Text> : null}
            <Text as="p">{address.country}</Text>
        </div>
    )
}

export default Address
