import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'
import {Page} from '@product-web-features/ui-page'

import {useSideOverview} from './use-side-overview'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {SideOverview} from '../../components/side-overview'

export const EmployeeExperience = () => {
    const {country} = useAcademyContext()
    const {overviewSteps, usefulMaterials} = useSideOverview()
    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    id: 's2v023ruri',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/e163a476e01c06fe6f664fb02bb8993181bcbc4b.jpg?video_still_time=10',
                }
            case 'DK':
            case 'GL':
                return {
                    id: 'v7wnx0qmvb',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/0161eb390923671b88db1c8da7092d14e86f4109.jpg?video_still_time=9',
                }
            case 'ES':
                return {
                    id: 'bzzvoqxqk4',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/7f8f55c72ce041f86d3d8b7fd504408b33c3feae.jpg?video_still_time=9',
                }
            case 'FR':
                return {
                    id: 'ukjv6hx225',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/45be57d13ceab4371f9a590265ba802e7dc19f8c.jpg?video_still_time=9',
                }
            case 'NL':
                return {
                    id: 'msnpv5b2tw',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/47b8d08ab9ae0be430e5d31bba028f7e9f566892.jpg?video_still_time=9',
                }
            case 'SE':
                return {
                    id: '9c9q7sq0sb',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/45643d73029190e576d2ce1aea9295672d6c7d4a.jpg?video_still_time=9',
                }
            default:
                // default is GB, IE, US
                return {
                    id: 'iyeqy6baie',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/9d429fe7c04ead7b483d71677ccc43a9de36e74b.jpg?video_still_time=9',
                }
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`Introduction to Pleo`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Introduction to Pleo</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main flex={4}>
                        <Text>
                            <Trans>What can Pleo do for you and your clients' employees?</Trans>
                        </Text>
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Employee experience</Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Employee Experience`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({action, video: 'employee_experience'})
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../introduction" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../pleo-for-your-clients"
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SideOverview overviewSteps={overviewSteps} usefulMaterials={usefulMaterials} />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
