import {formatCurrencyParts} from '@product-web/locale/currency'

export const formatWalletBalance = ({
    value,
    currency,
}: {value?: number; currency?: string} = {}) => {
    if (typeof value === 'undefined' || typeof currency === 'undefined') {
        return '-'
    }

    const formattedBalance = formatCurrencyParts(value, currency)
    return `${formattedBalance.value} ${formattedBalance.currency}`
}
