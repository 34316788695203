import {t, Trans} from '@lingui/macro'
import type {ChangeEventHandler} from 'react'
import React from 'react'

import {
    Button,
    FormControl,
    Inline,
    Input,
    ModalActions,
    ModalContent,
    ModalTitle,
    Stack,
} from '@pleo-io/telescope'

import {useToaster} from '@product-web/toaster'
import {luhnCheck} from '@product-web/validation/helper'

interface Props {
    onInformationCompleted(cardDetails: any): void
}

export const ModalStoreboxExtraInfo = (props: Props) => {
    const {showToast} = useToaster()
    const [pan, setPan] = React.useState('')
    const [year, setYear] = React.useState('')
    const [month, setMonth] = React.useState('')

    const handlePanChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPan(ccformat(e.target.value.toString()))
    }

    const handleYearChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
        setYear(e.target.value.toString())
    }

    const handleMonthChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMonth(e.target.value.toString())
    }

    const handleFinish = () => {
        const cardTrim = pan.replace(/\s/g, '')
        const issues: string[] = []

        if (!luhnCheck(cardTrim)) {
            issues.push('pan')
        }

        if (parseInt(month, 10) > 12) {
            issues.push('month')
        }

        if (parseInt(year, 10) < 18 || parseInt(year, 10) > 30) {
            issues.push('year')
        }

        let errorMessage
        if (issues.includes('pan') && (issues.includes('month') || issues.includes('year'))) {
            errorMessage = t`Please verify your card number and the expiry date.`
        } else if (
            issues.includes('pan') &&
            !(issues.includes('month') || issues.includes('year'))
        ) {
            errorMessage = t`Please verify your card number.`
        } else if (!(issues.includes('month') || issues.includes('year'))) {
            errorMessage = t`Please verify the expiry date.`
        }

        if (errorMessage) {
            showToast(errorMessage, {
                level: 'error',
                title: t`Oops, some card details are not valid`,
            })
            return
        }

        props.onInformationCompleted({year, month, pan: cardTrim})
    }

    return (
        <>
            <ModalTitle>
                <Trans>Please provide us with your physical card details</Trans>
            </ModalTitle>
            <ModalContent align="left" data-testid="storebox-extra-info-content">
                <Stack space={40} align="center" stretch>
                    <FormControl maxWidth="192px">
                        <FormControl.Label>{t`Card number`}</FormControl.Label>
                        <Input
                            name="pan"
                            value={pan}
                            onChange={handlePanChanged}
                            inputMode="numeric"
                            maxLength={19}
                            placeholder={t`Enter card number...`}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControl.Label>{t`Expiry date`}</FormControl.Label>
                        <Inline space={10} style={{width: '100%'}}>
                            <Input
                                name="expiry-month"
                                type="number"
                                placeholder="MM"
                                pattern="[0-9]*"
                                maxLength={2}
                                value={month}
                                onChange={handleMonthChanged}
                                inputMode="numeric"
                            />
                            <Input
                                name="expiry-year"
                                type="number"
                                placeholder="YY"
                                pattern="[0-9]*"
                                maxLength={2}
                                value={year}
                                onChange={handleYearChanged}
                                inputMode="numeric"
                            />
                        </Inline>
                    </FormControl>
                </Stack>
            </ModalContent>
            <ModalActions>
                <Button disabled={!pan || !month || !year} variant="primary" onClick={handleFinish}>
                    <Trans>Continue</Trans>
                </Button>
            </ModalActions>
        </>
    )
}

function ccformat(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    const matches = v.match(/\d{4,16}/g)
    const match: string = (matches && matches[0]) || ''
    const parts: string[] = []
    /* tslint:disable:one-variable-per-declaration */
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join(' ')
    }
    return value
}

export default ModalStoreboxExtraInfo
