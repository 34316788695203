// eslint-disable-next-line no-restricted-imports
import {createTRPCReact} from '@trpc/react-query'
import type {AnyRouter} from '@trpc/server'
import merge from 'lodash.merge'

type CreateTRPCReactOptions = Parameters<typeof createTRPCReact>[0]

declare module '@tanstack/react-query' {
    interface MutationMeta {
        // Invalidate all queries on successful mutation. Defaults to `true`
        invalidateAllQueries?: boolean
    }
}

export function createBffHooks<T extends AnyRouter>(overrides?: Partial<CreateTRPCReactOptions>) {
    const defaultOptions: CreateTRPCReactOptions = {
        /**
         * To prevent requesting invalid calls when users switch accounts/entities.
         * BFF will automatically re-fetch when the auth token changes,
         * but the user may change to a role that isn't authorised
         * or query params might make no sense for the new user/entity,
         * so this prevents unnecessary 404 or 403 calls (IN-1628, GAIA-663).
         */
        abortOnUnmount: true,
        overrides: {
            useMutation: {
                /**
                 * This function is called whenever a `.useMutation` succeeds
                 **/
                async onSuccess({originalFn, meta: mutationMeta, queryClient}) {
                    /**
                     * @note that order here matters:
                     * The order here allows route changes in `onSuccess` without
                     * having a flash of content change whilst redirecting.
                     **/
                    // Calls the `onSuccess` defined in the `useQuery()`-options:
                    await originalFn()

                    if (mutationMeta.dangerouslyPreventMutationInvalidatingAllQueries === true) {
                        return
                    }

                    // Invalidate all queries in the react-query cache:
                    await queryClient.invalidateQueries({
                        predicate: ({meta: queryMeta = {}}) => {
                            return (
                                queryMeta.dangerouslyPreventDefaultQueryInvalidationOnMutation !==
                                true
                            )
                        },
                    })
                },
            },
        },
    }
    const options = merge(defaultOptions, overrides)
    return createTRPCReact<T>(options)
}
