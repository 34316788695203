import {t} from '@lingui/macro'
import type {Reducer} from 'react'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {EmployeeType} from '@pleo-io/deimos'

import * as tracking from '@product-web/analytics'
import {RequestScope} from '@product-web/api'
import * as apiBookkeeper from '@product-web/api-deimos/bookkeeper'
import type {Employee} from '@product-web/api-deimos/employee'
import {
    create as createEmployee,
    update as updateEmployee,
    useCompanyEmployees,
} from '@product-web/api-deimos/employee'
import type {APIPartnerInfo} from '@product-web/api-deimos/partner'
import {isRequestError} from '@product-web/api-error'
import {ensureScopedTokens} from '@product-web/auth--scoped-tokens'
import {dayjs} from '@product-web/dates/dayjs'
import {reportError} from '@product-web/error/report'
import {useCompanySubscription} from '@product-web/paywall/lib/company-subscription'
import type {IModal} from '@product-web/shell/legacy/legacy-modals-bridge'
import {__legacyShowModal} from '@product-web/shell/legacy/legacy-modals-bridge'
import {useToaster} from '@product-web/toaster'
import {useCompanyUser} from '@product-web/user'
import {hasKey} from '@product-web/utils'
import {validateUser} from '@product-web/validation/user'
import type {SetupAccountingPersonaActionedExtended} from '@product-web-features/onboarding/tracking/types'

import {useBillingAddonsByType} from './hooks/use-billing-addons-by-type'
import {useBookkeeperPermitted} from './hooks/use-bookkeeper-permitted'
import {usePartnerInfoForEmail} from './hooks/use-partner-info-for-email'
import {useSortedInvites} from './hooks/use-sorted-invites'

import type {PersonWithEmail} from '../../helpers'
import {usePeopleEmails} from '../../helpers'
import {useShouldShowMemberLimitWarning} from '../../hooks/use-should-show-member-limit-warning'
import type {ModalFlowOrigin} from '../../lib/add-member-modal-context'
import {AddMemberModalContext} from '../../lib/add-member-modal-context'
import AddAdmin from '../add-admin/add-admin'
import AddBookkeeper from '../add-bookkeeper/add-bookkeeper'
import AddBookkeeperLegacy from '../add-bookkeeper/add-bookkeeper-legacy'
import AddInternal from '../add-internal/add-internal'
import {isOnFreePlan, useGetNewPricingPlan, useNewPricingPlan} from '../add-members/helpers'
import AddSuccess from '../add-result/add-result'

export enum AddMemberModalScreen {
    INTERNAL_BOOKKEEPER = 'INTERNAL_BOOKKEEPER',
    EXTERNAL_BOOKKEEPER = 'EXTERNAL_BOOKKEEPER',
    SUCCESS = 'SUCCESS',
    ADMIN = 'ADMIN',
}

export enum MemberType {
    ADMIN = 'admin',
    BOOKKEEPER = 'external_bookkeeper',
    FIRST_ADMIN = 'first_admin',
}

////////////////////////////////////////////////////////////////////////////////
//#region Main Component

interface AddMemberModalProps {
    initialScreen: AddMemberModalScreen
    data: PersonWithEmail[]
    onAddMember: () => void
    modalFlowOrigin?: ModalFlowOrigin
}

export const AddMemberModal = ({
    initialScreen,
    _modal,
    data,
    onAddMember,
    modalFlowOrigin,
}: AddMemberModalProps & IModal) => {
    const {modalState, showExternalBookkeeper, inviteMembers} = useAddMemberModalState(
        initialScreen,
        onAddMember,
    )

    const emails = usePeopleEmails(data)
    const user = useCompanyUser()
    const {data: companySubscriptionData, isLoading: isSubscriptionLoading} =
        useCompanySubscription()
    const subscriptionPlanType = companySubscriptionData?.platformRatePlan?.type
    const canUseNewPricing = useNewPricingPlan(subscriptionPlanType)

    const bookkeeperAddon = useBillingAddonsByType(MemberType.BOOKKEEPER.toUpperCase())

    const isAddBookkeeperPermitted = useBookkeeperPermitted()

    const shouldShowMemberLimitWarning = useShouldShowMemberLimitWarning()

    const seatPriceInfo =
        bookkeeperAddon && canUseNewPricing
            ? {
                  price: {
                      amount: bookkeeperAddon.pricing.price?.value
                          ? bookkeeperAddon.pricing.price?.value / 100
                          : undefined,
                      currency: bookkeeperAddon.pricing.price?.currency,
                  },
                  type: 'bookkeeper',
                  interval: companySubscriptionData?.billingPeriodType,
              }
            : undefined

    const billingInfo =
        bookkeeperAddon && canUseNewPricing
            ? {billingDate: dayjs(bookkeeperAddon?.billingCycleDay).format('ll')}
            : undefined

    useEffect(() => {
        tracking.addMemberStarted({
            actor_role: user.role,
        })
    }, [])

    const {
        isLoading: isFetchingPartner,
        partnerInfo: partnerInfoForEmail,
        setEmail: onEmailChange,
    } = usePartnerInfoForEmail(user.companyId)

    const closeModal = () => {
        const modal = _modal

        if (modal) {
            modal.promise.resolve()
        }

        if (modalFlowOrigin === 'onboarding') {
            tracking.setupAccountingPersonaActioned({
                action: 'completed',
            } as SetupAccountingPersonaActionedExtended)
        }
    }
    const planType = useGetNewPricingPlan(subscriptionPlanType)

    switch (modalState.currentScreen) {
        case AddMemberModalScreen.EXTERNAL_BOOKKEEPER:
            return canUseNewPricing ? (
                // Adding the Context here instead of the parent Component that opens the modal,
                // because of its legacy show() function
                <AddMemberModalContext.Provider value={{modalFlowOrigin}}>
                    <AddBookkeeper
                        validate={validateUser(emails)}
                        onSubmit={inviteMembers(MemberType.BOOKKEEPER)}
                        onEmailChange={onEmailChange}
                        isFetchingPartner={isFetchingPartner}
                        partnerInfo={partnerInfoForEmail}
                        seatPriceInfo={seatPriceInfo}
                        billingInfo={billingInfo}
                        isOnSubscriptionPlan={companySubscriptionData ? true : false}
                        isOnFreePlan={isOnFreePlan(planType ?? undefined)}
                        isLoading={isSubscriptionLoading}
                    />
                </AddMemberModalContext.Provider>
            ) : (
                <AddBookkeeperLegacy
                    validate={validateUser(emails)}
                    onSubmit={inviteMembers(MemberType.BOOKKEEPER)}
                    onEmailChange={onEmailChange}
                    isFetchingPartner={isFetchingPartner}
                    partnerInfo={partnerInfoForEmail}
                    isAddBookkeeperPermitted={isAddBookkeeperPermitted}
                    isLoading={isSubscriptionLoading}
                />
            )

        case AddMemberModalScreen.INTERNAL_BOOKKEEPER:
            return (
                <AddInternal
                    validate={validateUser(emails)}
                    onSubmit={inviteMembers(MemberType.ADMIN)}
                    onCancel={closeModal}
                    onAddExternalBookkeeper={showExternalBookkeeper}
                />
            )

        case AddMemberModalScreen.ADMIN:
            return (
                <AddAdmin onSubmit={inviteMembers(MemberType.FIRST_ADMIN)} onCancel={closeModal} />
            )

        case AddMemberModalScreen.SUCCESS:
            return (
                <AddSuccess
                    onClose={closeModal}
                    count={modalState.createdMembersCount || 0}
                    existing={modalState.existingMembersCount || 0}
                    memberType={modalState.createdMemberType || MemberType.ADMIN}
                    shouldShowMemberLimitWarning={shouldShowMemberLimitWarning}
                />
            )
        default:
            return null
    }
}

//#endregion Main Component
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Connected Component

const AddMemberModalWrapper = {
    show: async ({
        initialScreen,
        data,
        onAddMember,
        modalFlowOrigin = 'people',
    }: AddMemberModalProps) =>
        __legacyShowModal(
            <AddMemberModal
                initialScreen={initialScreen}
                data={data}
                onAddMember={onAddMember}
                modalFlowOrigin={modalFlowOrigin}
            />,
            {
                size: 'auto',
            },
        ),
}

export default AddMemberModalWrapper

//#endregion Connected Component
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Business Logic Custom Hook

interface State {
    currentScreen: AddMemberModalScreen
    previousScreen: AddMemberModalScreen | null
    createdMembersCount: number | null
    existingMembersCount: number | null
    createdMemberType: MemberType | null
}

enum MemberTrackingTypes {
    OWNER = 'owner',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
}

interface Bookkeeper {
    firstName: string
    email: string
    accessLevel: 'basic' | 'extended' | null
    partner: APIPartnerInfo | null | never
    parentResourceId?: string
    parentResource?: string
}

interface Admin {
    firstName: string
    email: string
    cardAccess?: boolean
}

interface User {
    email: string
    cardAccess?: boolean
}

type Action =
    | {type: 'SHOW_EXTERNAL_BOOKKEEPER'}
    | {type: 'MEMBERS_ADDED'; payload: {memberType: MemberType; count: number; existing: number}}

const modalReducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'SHOW_EXTERNAL_BOOKKEEPER':
            return {
                ...state,
                currentScreen: AddMemberModalScreen.EXTERNAL_BOOKKEEPER,
                previousScreen: state.currentScreen,
            }
        case 'MEMBERS_ADDED':
            return {
                ...state,
                currentScreen: AddMemberModalScreen.SUCCESS,
                previousScreen: state.currentScreen,
                createdMembersCount: action.payload.count,
                existingMembersCount: action.payload.existing,
                createdMemberType: action.payload.memberType,
            }
        default:
            return state
    }
}

export function useAddMemberModalState(
    initialScreen: AddMemberModalScreen,
    onAddMember?: (successCount: number) => void,
) {
    const [modalState, dispatch] = React.useReducer(modalReducer, {
        currentScreen: initialScreen,
        previousScreen: null,
        createdMembersCount: null,
        existingMembersCount: null,
        createdMemberType: null,
    })
    const {
        mutations: {add: addInvite},
    } = useSortedInvites()

    const {
        mutations: {add: addEmployee},
    } = useCompanyEmployees({
        includeLimits: true,
        type: EmployeeType.COMPANY,
    })
    const showExternalBookkeeper = () => dispatch({type: 'SHOW_EXTERNAL_BOOKKEEPER'})
    const user = useCompanyUser()
    const {showToast} = useToaster()
    function inviteMembers(memberType: MemberType.BOOKKEEPER): (values: Bookkeeper) => Promise<void>
    function inviteMembers(
        memberType: MemberType.ADMIN | MemberType.FIRST_ADMIN,
    ): (values: Admin) => Promise<void>
    function inviteMembers(memberType: MemberType) {
        return async (values: Bookkeeper | Admin) => {
            await ensureScopedTokens([RequestScope.PERMISSION, RequestScope.CARD])
            try {
                let result
                let trackingType: MemberTrackingTypes

                switch (memberType) {
                    case MemberType.BOOKKEEPER: {
                        const bookkeeperValues = values as Bookkeeper
                        result = await inviteBookkeeper(bookkeeperValues as Bookkeeper)
                        trackingType =
                            bookkeeperValues.accessLevel === 'extended'
                                ? MemberTrackingTypes.BOOKKEEPER_EXTENDED
                                : MemberTrackingTypes.BOOKKEEPER_BASIC
                        break
                    }
                    case MemberType.ADMIN:
                    case MemberType.FIRST_ADMIN:
                        result = await inviteAdmin(values as Admin)
                        trackingType = MemberTrackingTypes.OWNER
                        break
                    default:
                        return
                }
                const {successCount, failCount} = result

                tracking.addMemberCompleted({
                    actor_role: user.role,
                    type: trackingType,
                    no_of_users: successCount,
                    no_of_existing_users: failCount,
                })
                dispatch({
                    type: 'MEMBERS_ADDED',
                    payload: {count: successCount, existing: failCount, memberType},
                })

                onAddMember?.(successCount)
            } catch (error) {
                showToast(t`An error occurred while adding users. Please try again later.`, {
                    level: 'error',
                })
                reportError(error)
            }
        }
    }

    const createBookkeeperInvite = async (
        props: apiBookkeeper.CreateBookkeeperPayload,
    ): Promise<void> => {
        try {
            await apiBookkeeper.create(props)
            await addInvite()
        } catch (error) {
            const bookkeeperAlreadyInCompany =
                getRequestErrorPleoMetaMessage(error) === 'already_in_company'

            if (bookkeeperAlreadyInCompany) {
                showToast(t`A user with the email: ${props.email} already exists in system`, {
                    level: 'error',
                })
            } else {
                showToast(t`An error occurred. Please try again`, {
                    level: 'error',
                })
                reportError(error)
            }

            throw error
        }
    }

    const createUser = async (newUser: User): Promise<Employee> => {
        const companyId = user.companyId
        const {employee} = await createEmployee(companyId, newUser)
        await addEmployee(employee)

        return employee
    }

    const inviteBookkeeper = async ({
        email,
        firstName,
        accessLevel,
        partner,
        parentResource,
        parentResourceId,
    }: Bookkeeper) => {
        try {
            await createBookkeeperInvite({
                resourceId: user.companyId,
                resourceName: user.company.name,
                email,
                firstName,
                type: accessLevel === 'extended' ? 'bookkeeper-extended' : 'bookkeeper-basic',
                parentResourceId,
                parentResource,
                metadata: partner
                    ? {partnerName: partner.name, bookkeeperUserId: partner.bookkeeperUserId}
                    : undefined,
            })

            return {successCount: 1, failCount: 0}
        } catch (e) {
            return {successCount: 0, failCount: 1}
        }
    }

    const inviteAdmin = async (values: Admin) => {
        const employee = await createUser(values)
        await updateEmployee(employee.id!, {role: 'owner'})

        return {successCount: 1, failCount: 0}
    }

    return {
        modalState,
        showExternalBookkeeper,
        inviteMembers,
    }
}

//#endregion Business Logic Custom Hook
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Custom Hook for conditional modal opening

export function useAddMemberModalScreen(data: PersonWithEmail[], onAddMember: () => void) {
    const location = useLocation()

    useEffect(() => {
        async function showModals() {
            if (location.hash === '#invite-bookkeeper') {
                await AddMemberModalWrapper.show({
                    initialScreen: AddMemberModalScreen.INTERNAL_BOOKKEEPER,
                    data,
                    onAddMember,
                })
            }
            if (location.hash === '#invite-external-bookkeeper') {
                await AddMemberModalWrapper.show({
                    initialScreen: AddMemberModalScreen.EXTERNAL_BOOKKEEPER,
                    data,
                    onAddMember,
                })
            }
        }

        showModals().catch(reportError)
    }, [location.hash])
}

//#endregion Custom Hook for conditional modal opening
////////////////////////////////////////////////////////////////////////////////
function getRequestErrorPleoMetaMessage(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }

    const responseData = error?.response?.data
    if (
        hasKey('meta', responseData) &&
        hasKey('message', responseData.meta) &&
        typeof responseData.meta.message === 'string'
    ) {
        return responseData.meta.message
    }
    return null
}
