import {equals, isEmpty, isNil} from 'ramda'
import type {Dispatch, SetStateAction} from 'react'
import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import type {Address} from '@product-web/api-types/address'
import type {OtpType} from '@product-web/api-types/otp'
import {usePrevious} from '@product-web/react-utils'
import {createPersistedState} from '@product-web/web-platform/persisted-state'
import {localStorage} from '@product-web/web-platform/storage/local'

type PartnerBookkeeperClient = {
    companyId: string
    accessLevel: 'extended' | 'basic'
    companyName: string
    registrationNumber: string
}

type PartnerInviteMetadata = {
    source?: 'manual' | 'registry'
    address: Address
    lastName?: string
    registrationNumber?: string
}

type InviteUserInfo = {
    id: string
    phone: string
    phoneVerified: boolean
    scaBlocked: boolean
    scaEnabled: boolean
    verified: boolean
}

type PartnerInviteInfo = {
    email: string
    firstName: string
    partnerName: string
    partnerId: string | null
    userId: string | null
    type: 'owner' | 'member'
    clients: PartnerBookkeeperClient[]
    metadata: PartnerInviteMetadata
    user: InviteUserInfo | null
    verifyToken: string | null
}

export type RegisterParams = {
    accessToken?: string
    activeRegisterType?: 'admin' | 'bookkeeper' | 'employee' | 'partner'
    companyCountryCode?: string
    companyId?: string
    companyName?: string
    companySize?: string
    countryCode?: string
    email?: string
    employeeId?: string
    firstName?: string
    hasSeenAppDownloadPage?: boolean
    inviteToken?: string
    jobTitle?: string
    lastName?: string
    otp?: string
    otpType?: OtpType
    partnerInvite?: PartnerInviteInfo
    phone?: string
    phoneNumber?: string
    pin?: string
    promo?: string
    role?: string
    userId?: string
    verifyToken?: string
}

const [usePersistedState, clear] = createPersistedState('userParams', localStorage)

export const removeUserParams = () => clear()

/**
 * Method to store the params during registration in local storage
 * @param query RegisterParams
 * @param initialValue RegisterParams or empty value
 * @returns an array of values [current RegisterParams, function to update current RegisterParams, clear current RegisterParams]
 */

export const useRegisterState = (
    query?: RegisterParams,
    initialValue: RegisterParams = {},
): [RegisterParams, Dispatch<SetStateAction<RegisterParams>>, () => void] => {
    const [params, setParams] = usePersistedState<RegisterParams>('params', initialValue)
    const previousQuery = usePrevious(query)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (isEmpty(query) || isNil(query)) {
            return
        }

        if (!equals(previousQuery, query)) {
            setParams((values) => ({...values, ...query}))
            navigate({...location, search: undefined}, {replace: true})
        }
    })

    const clearParams = () => setParams({})

    return [params, setParams, clearParams]
}
