import {t, Trans} from '@lingui/macro'
import {Link as RouterLink, useSearchParams} from 'react-router-dom'

import {Button, Inline, Link, LoadingPage, Stack, Text, Tooltip} from '@pleo-io/telescope'
import {Info} from '@pleo-io/telescope-icons'

import {dayjs} from '@product-web/dates/dayjs'

import {bff} from '../../../bff-hooks'
import * as EmptySection from '../../../components/empty-section/empty-section'
import {GenericError} from '../../../components/generic-error'
import {SplitContent} from '../../../components/split-content'
import TruncateText from '../../../components/truncate-text/truncate-text'
import cat from '../../../images/no-clients.png'
import PartnerOverviewCard from '../components/partner-overview-card'
import {PartnerTierCard} from '../components/partner-tier-card/partner-tier-card'

export const CompanyReferrals = ({showPartnerAgreement}: {showPartnerAgreement: boolean}) => {
    const {
        data: currentReferrals,
        isLoading: isLoadingCurrentReferrals,
        isError: currentReferralsError,
        fetchNextPage: fetchNextPageCurrentReferrals,
        isFetchingNextPage: isFetchingNextPageCurrentReferrals,
        isFetching: isFetchingCurrentReferrals,
        hasNextPage: hasMoreCurrentReferrals,
        refetch: refetchCurrentReferrals,
        remove: removeCurrentReferrals,
    } = bff.companyReferrals.getReferralsList.useInfiniteQuery(
        {year: 'current'},
        {getNextPageParam: ({nextPage}) => nextPage, keepPreviousData: true},
    )
    const currentReferralsList = currentReferrals?.pages.flatMap((page) => page.list)
    const hasLoadedMoreCurrentReferrals =
        currentReferrals?.pages.length && currentReferrals?.pages.length > 1

    const {
        data: pastReferrals,
        isLoading: isLoadingPastReferrals,
        isError: pastReferralsError,
        fetchNextPage: fetchNextPagePastReferrals,
        isFetchingNextPage: isFetchingNextPagePastReferrals,
        isFetching: isFetchingPastReferrals,
        hasNextPage: hasMorePastReferrals,
        refetch: refetchPastReferrals,
        remove: removePastReferrals,
    } = bff.companyReferrals.getReferralsList.useInfiniteQuery(
        {year: 'previous'},
        {getNextPageParam: ({nextPage}) => nextPage, keepPreviousData: true},
    )

    const pastReferralsList = pastReferrals?.pages.flatMap((page) => page.list)
    const hasLoadedMorePastReferrals =
        pastReferrals?.pages.length && pastReferrals?.pages.length > 1

    const isLoading = isLoadingCurrentReferrals || isLoadingPastReferrals

    const noReferrals = currentReferralsList?.length === 0 && pastReferralsList?.length === 0

    if (currentReferralsError || pastReferralsError) {
        return <GenericError />
    }

    return (
        <Stack mt={32} stretch>
            <SplitContent reverse>
                <SplitContent.Main>
                    {isLoading ? (
                        <LoadingPage />
                    ) : noReferrals ? (
                        showPartnerAgreement ? (
                            <NotSignedAgreementSection />
                        ) : (
                            <NoReferralsSection />
                        )
                    ) : (
                        <>
                            {!!currentReferralsList?.length && (
                                <Stack stretch>
                                    <Text variant="large-accent" weight="semibold" as="h2">
                                        <Trans>Your referrals</Trans>
                                    </Text>
                                    <Stack space={24} mt={24} mb={60} stretch>
                                        {currentReferralsList.map((referral) => (
                                            <ReferralItem
                                                key={referral.companyId}
                                                companyName={referral.companyName}
                                                referredAt={referral.referredAt}
                                            />
                                        ))}
                                        <Inline>
                                            {hasMoreCurrentReferrals ? (
                                                <Button
                                                    variant="tertiary"
                                                    onClick={() => {
                                                        fetchNextPageCurrentReferrals()
                                                    }}
                                                    loading={isFetchingNextPageCurrentReferrals}
                                                >
                                                    <Trans>See more</Trans>
                                                </Button>
                                            ) : (
                                                hasLoadedMoreCurrentReferrals && (
                                                    <Button
                                                        variant="tertiary"
                                                        onClick={() => {
                                                            removeCurrentReferrals()
                                                            refetchCurrentReferrals({
                                                                refetchPage(_, index) {
                                                                    return index === 0
                                                                },
                                                            })
                                                        }}
                                                        loading={isFetchingCurrentReferrals}
                                                    >
                                                        <Trans>See less</Trans>
                                                    </Button>
                                                )
                                            )}
                                        </Inline>
                                    </Stack>
                                </Stack>
                            )}
                            {!!pastReferralsList?.length && (
                                <Stack stretch>
                                    <Text
                                        variant="large-accent"
                                        weight="semibold"
                                        as="h2"
                                        space={10}
                                    >
                                        <Trans>Previous referrals</Trans>
                                    </Text>
                                    <Text color="shade600">
                                        <Trans>
                                            These past referrals would not count towards your tier
                                            activity because they are not part of your current
                                            partnership year.
                                        </Trans>
                                    </Text>
                                    <Stack space={24} mt={24} mb={60} stretch>
                                        {pastReferralsList.map((referral) => (
                                            <ReferralItem
                                                key={referral.companyId}
                                                companyName={referral.companyName}
                                                referredAt={referral.referredAt}
                                            />
                                        ))}
                                        <Inline>
                                            {hasMorePastReferrals ? (
                                                <Button
                                                    variant="tertiary"
                                                    onClick={() => {
                                                        fetchNextPagePastReferrals()
                                                    }}
                                                    loading={isFetchingNextPagePastReferrals}
                                                >
                                                    <Trans>See more</Trans>
                                                </Button>
                                            ) : (
                                                hasLoadedMorePastReferrals && (
                                                    <Button
                                                        variant="tertiary"
                                                        onClick={() => {
                                                            removePastReferrals()
                                                            refetchPastReferrals({
                                                                refetchPage(_, index) {
                                                                    return index === 0
                                                                },
                                                            })
                                                        }}
                                                        loading={isFetchingPastReferrals}
                                                    >
                                                        <Trans>See less</Trans>
                                                    </Button>
                                                )
                                            )}
                                        </Inline>
                                    </Stack>
                                </Stack>
                            )}
                        </>
                    )}
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <Stack space={32} stretch>
                            <PartnerTierCard />
                            <PartnerOverviewCard />
                        </Stack>
                    </SplitContent.Sticky>
                </SplitContent.Right>
            </SplitContent>
        </Stack>
    )
}

function NoReferralsSection() {
    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>No referrals yet</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        You have not referred any users yet. To get started,{' '}
                        <Link as={RouterLink} to="/partner/onboard">
                            add your first client
                        </Link>
                        .
                    </Trans>
                </EmptySection.Subtitle>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function NotSignedAgreementSection() {
    const [searchParams, setSearchParams] = useSearchParams()
    const openAcceptPartnerAgreement = () => {
        searchParams.set('acceptTerms', 'true')
        setSearchParams(searchParams)
    }

    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>Don't miss out on your benefits</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        Accept your partner agreement to start onboarding new clients to the portal
                        right away.
                    </Trans>
                </EmptySection.Subtitle>
                <Button variant="primary" onClick={openAcceptPartnerAgreement}>
                    <Trans>Accept agreement</Trans>
                </Button>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function ReferralItem({companyName, referredAt}: {companyName: string | null; referredAt: string}) {
    return (
        <Inline justifyContent="space-between" space={24}>
            {companyName ? (
                <Stack flex={1}>
                    <TruncateText text={companyName} tooltip />
                </Stack>
            ) : (
                <Tooltip
                    content={t`We could not find the company name for this referral`}
                    side="right"
                >
                    <Inline space={8} alignItems="center">
                        <Text>
                            <Trans>Unknown company name</Trans>
                        </Text>
                        <Info size={16} />
                    </Inline>
                </Tooltip>
            )}
            <Inline>
                <Text>{dayjs(referredAt).format('DD MMMM YYYY')}</Text>
            </Inline>
        </Inline>
    )
}
