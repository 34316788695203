import type {MouseEvent} from 'react'
import styled, {css} from 'styled-components'

import type {ButtonProps} from '@pleo-io/telescope'
import {Checkbox, Inline, NakedButton, Stack, tokens} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

const ItemContent = styled(Stack).attrs({pl: 16, py: 20})`
    border-left: ${tokens.borderPrimary};
`
const ItemCheckbox = styled(Checkbox).attrs(({onClick}) => ({
    onClick: (event: MouseEvent<HTMLLabelElement>) => {
        event.stopPropagation()
        onClick?.(event)
    },
}))``

export const OnboardingItem = ({children, ...buttonProps}: Omit<ButtonProps, 'variant'>) => (
    <TaskButton {...buttonProps}>
        <Inline alignItems="center" space={16} px={16}>
            {children}
            <ArrowRight color={tokens.shade800} size={16} />
        </Inline>
    </TaskButton>
)

OnboardingItem.Content = ItemContent
OnboardingItem.Checkbox = ItemCheckbox

const TaskButton = styled(NakedButton)`
    width: 100%;
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};

    ${({disabled}) => {
        if (disabled) {
            return css`
                cursor: initial;
            `
        }

        return css`
            cursor: pointer;

            &:hover {
                box-shadow: ${tokens.shadowElevateQuiet};
                transform: scale(1.02);
            }
        `
    }};
`
