import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

import config from '@product-web/config'

/**
 * Checks for the latest version of the SPA by performing a HEAD request
 * towards the current index.html file and checking the custom header. We set
 * the header when serving the file via an Edge Lambda.
 *
 * This hook is used to implement behaviour that nudges users towards refreshing
 * the app to stay up to date with new versions.
 * @see https://github.com/pleo-io/spa-tools/tree/main/terraform-module
 */
export function useIsAppOutdated() {
    const {data: isOutdated} = useQuery({
        queryKey: ['app-version'],
        queryFn: async () => {
            const res = await axios.head('/')
            const latestVersion = res.headers['x-pleo-spa-version']
            return !!latestVersion && config.version !== latestVersion
        },
        refetchInterval: (currentIsOutdated) => {
            // If we've already confirmed the app is outdated, no need to continue polling
            if (currentIsOutdated) {
                return false
            }
            // We check for a new version every 5 minutes
            return 5 * 60 * 1000
        },
        enabled: config.environment !== 'local' && window.Cypress === undefined,
    })

    return isOutdated
}
