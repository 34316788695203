import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import type {CompanyMemberSettings, CurrencyType, WellnessSpendSetting} from '@pleo-io/deimos'

import {generateSystems} from '@product-web/accounting-systems'
import {request} from '@product-web/api'
import {RequestScope} from '@product-web/api'
import type {AccountingPreferences} from '@product-web/api-types/accounting'
import {
    BillInvoiceFeatureProperty,
    ExportFeature,
    isAccountingPreferenceKey,
} from '@product-web/api-types/accounting'
import type {AutoTopUp} from '@product-web/api-types/direct-debit'
import config from '@product-web/config'
import {getIsBookkeeper, hasFeatureFlag, useCompanyUser, useUser} from '@product-web/user'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

export interface GetCompanySettingsResponse {
    accounting: AccountingPreferences
    autoTopup: AutoTopUp
    walletDryThreshold: number
    limits: {
        defaultMonthlySpendingLimit: {[key in CurrencyType]?: string} | null
        defaultPerTransactionSpendingLimit: string
    }
    wellnessSpend: WellnessSpendSetting
}

export interface AccountingSystemValidityResponse {
    isValid: boolean
    accountingSystem: string
    accountingSystemName: string
    errorDetails?: AccountingSystemCheckError
    checkAccountingSystemIntegration?: () => void
}

interface AccountingSystemCheckError {
    type: string
    message: string
}

async function updateCompanySettings({
    companyId,
    scope,
    payload,
}: {
    companyId: string
    scope?: RequestScope
    payload: Partial<GetCompanySettingsResponse>
}) {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/settings`, {
        auth: 'user',
        scope,
        method: 'PUT',
        body: payload,
    })
}

export function useCompanySettings(scope?: RequestScope, swrConfig?: SWRConfiguration) {
    const user = useUser()
    const companyId = user?.companyId
    const canFetchSettings = Boolean(
        companyId && (user?.role === 'owner' || (user && getIsBookkeeper(user))),
    )
    const result = useSWR<GetCompanySettingsResponse | null, Error>(
        canFetchSettings ? `/rest/v1/companies/${companyId}/settings` : null,
        getDeimos,
        swrConfig,
    )

    async function updateSettings(payload: Partial<GetCompanySettingsResponse>) {
        if (!companyId) {
            return null
        }
        const response = await updateCompanySettings({
            companyId,
            payload,
            scope,
        })
        await result.mutate(response, {
            optimisticData: {...result?.data, ...(payload as GetCompanySettingsResponse)},
            rollbackOnError: true,
            populateCache: true,
            revalidate: true,
        })
        return response
    }

    return {...result, canFetchSettings, mutations: {updateSettings}}
}

export function useCompanyMemberSettings() {
    const user = useUser()
    const companyId = user?.companyId
    return useSWR<CompanyMemberSettings | null, Error>(
        `/rest/v1/companies/${companyId}/settings/member`,
        getDeimos,
    )
}

export function useAccountingSettings(): AccountingPreferences | undefined {
    const preferences = useCompanySettings()
    return preferences?.data?.accounting
}

export function useCompanyAccountingSystemSupportsLoads() {
    const settings = useAccountingSettings()
    return !!settings?.includeLoads
}

export const useCompanyAccountingSystemDetails = () => {
    const {data: settings} = useCompanySettings()
    const accountingSystem = settings?.accounting?.system
    const accountingSystemName = accountingSystem?.includes(':custom') ? 'custom' : accountingSystem
    return generateSystems()[accountingSystemName ?? 'none']
}

export const useSupportsBills = () => {
    const user = useCompanyUser()
    return hasFeatureFlag(user, 'billInvoices')
}

export const systemSupportsExternalAccountsForBills = (system?: string | null) =>
    system &&
    generateSystems()[system]?.supportedFeatures?.[ExportFeature.BILL_INVOICE]?.find(
        (feature) => feature.name === BillInvoiceFeatureProperty.FULL_LIST_OF_ACCOUNTS,
    )

export function useSupportsExternalAccountsForBills() {
    const preferences = useCompanySettings()
    return !!systemSupportsExternalAccountsForBills(preferences?.data?.accounting?.system)
}

const systemSupportsSuppliersForBills = (system?: string | null) =>
    system &&
    generateSystems()[system]?.supportedFeatures?.[ExportFeature.BILL_INVOICE]?.find(
        (feature) => feature.name === BillInvoiceFeatureProperty.SUPPLIER,
    )

export function useSupportsSuppliersForBills() {
    const preferences = useCompanySettings()
    return systemSupportsSuppliersForBills(preferences?.data?.accounting?.system)
}

export const systemSupportsExportOnlyForPaidBills = (system?: string | null) =>
    system
        ? Boolean(
              generateSystems()[system]?.supportedFeatures?.[ExportFeature.BILL_INVOICE]?.find(
                  (feature) => feature.name === BillInvoiceFeatureProperty.EXPORTABLE_ONLY_PAID,
              ),
          )
        : undefined

export function useSupportsExportOnlyForPaidBills() {
    const preferences = useCompanySettings()
    return systemSupportsExportOnlyForPaidBills(preferences?.data?.accounting?.system)
}

const systemSupportsExportInBillsDashboard = (system?: string | null) =>
    Boolean(
        system &&
            generateSystems()[system]?.supportedFeatures?.[ExportFeature.BILL_INVOICE]?.find(
                (feature) =>
                    feature.name === BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD,
            ),
    )

const systemSupportsSyncAfterExport = (system?: string | null) =>
    Boolean(
        system &&
            generateSystems()[system]?.supportedFeatures?.[ExportFeature.BILL_INVOICE]?.find(
                (feature) => feature.name === BillInvoiceFeatureProperty.EXPORT_CAN_SYNC,
            ),
    )

export function useSystemBillsDashboardFeature() {
    const preferences = useCompanySettings()
    return {
        showExport: systemSupportsExportInBillsDashboard(preferences?.data?.accounting?.system),
        exportCanSync: systemSupportsSyncAfterExport(preferences?.data?.accounting?.system),
    }
}

export function useSupportsPocket() {
    const system = useCompanyAccountingSystemDetails()

    return !!system?.supportedFeatures?.[ExportFeature.POCKET]
}

export function useCompletedAccountingPocketSetup() {
    const preferences = useAccountingSettings()
    const system = useCompanyAccountingSystemDetails()
    if (!system) {
        return false
    }

    const pocketFields = system?.supportedFeatures?.[ExportFeature.POCKET]

    if (!pocketFields?.length) {
        return false
    }

    return pocketFields.every(({name, required}) => {
        if (required && preferences !== undefined && isAccountingPreferenceKey(preferences, name)) {
            const value = preferences?.[name]
            return typeof value === 'string' && value.trim() !== ''
        }
        return true
    })
}

interface CompanySettingsResponse {
    accounting: AccountingPreferences
    autoTopup: AutoTopUp
    walletDryThreshold: number
    limits: {
        defaultMonthlySpendingLimit: {[currencyKey: string]: string} | null
        defaultPerTransactionSpendingLimit: string
    }
    language: string | null
    wellnessSpend: WellnessSpendSetting
}

export async function get(companyId: string): Promise<CompanySettingsResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/settings`, {
        auth: 'user',
        method: 'GET',
    })
}

export async function update(
    companyId: string,
    params: any,
    elevated = false,
): Promise<CompanySettingsResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/settings`, {
        auth: elevated ? 'elevated' : 'user',
        scope: elevated ? RequestScope.CARD : undefined,
        method: 'PUT',
        body: params,
    })
}
