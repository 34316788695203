import {t} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useClipboard} from 'use-clipboard-copy'

import {
    StyledCopyableButton,
    StyledCopyableIcon,
    StyledCopyableInline,
    StyledCopyableText,
} from './copyable.styles'

export type TIsSuccess = {
    message: string
    isSuccess: boolean
}

export interface ICopyable {
    text?: string
    isInline?: boolean
    valueToCopy: string
    dataTestId?: string
    children?: ReactNode
    errorMessage?: string
    isWithoutIcon?: boolean
    successMessage?: string
    iconPosition?: 'left' | 'right'
    callback: ({isSuccess, message}: TIsSuccess) => void
}

/**
 * `Copyable` is a React component that takes in a value to copy, and copies it to the clipboard when clicked
 * @typedef {"left" | "right"} IconPosition
 *
 * @param {Object} CopyableProps
 * @param {boolean=} CopyableProps.isWithoutIcon: remove the Copy icon
 * @param {string=} CopyableProps.text: pass the text to display into a span
 * @param {string=} CopyableProps.dataTestId: change the data-testId value of the component
 * @param {string} CopyableProps.valueToCopy: pass the text to copy when the button is clicked
 * @param {ReactNode=} CopyableProps.children: allow you to render another element (ex: a Tooltip)
 * @param {boolean=} CopyableProps.isInline: change the component from a Button to an Inline element
 * @param {IconPosition=} CopyableProps.iconPosition: change the position of the Icon (default is left)
 * @param {string=} CopyableProps.errorMessage: overwrite the message displayed in the toast when the copy failed (default: Copy failed)
 * @param {string=} CopyableProps.successMessage: overwrite the message displayed in the toast when successfully copied (default: Copied
 * @param {(args): CopySuccess => void=} CopyableProps.callback : the callback triggered when clicked, 2 args available isSuccess (boolean that tells if the copy was a success) message (default error or success message)
 * @returns A styled button or Inline element with or without a copy icon and the children/text passed in.
 * */
export const Copyable = ({
    text,
    children,
    isInline,
    callback,
    dataTestId,
    valueToCopy,
    errorMessage,
    isWithoutIcon,
    successMessage,
    iconPosition = 'left',
}: ICopyable) => {
    const copyToClipBoard = t`Copy to clipboard`
    const clipboard = useClipboard({
        onSuccess() {
            callback({isSuccess: true, message: successMessage ?? t`Copied to clipboard`})
        },
        onError() {
            callback({isSuccess: false, message: errorMessage ?? t`Copy failed`})
        },
    })

    const CopyableContent = () => (
        <>
            {!isWithoutIcon && <StyledCopyableIcon size={16} iconPosition={iconPosition} />}
            {text ? <StyledCopyableText>{text}</StyledCopyableText> : <>{children}</>}
        </>
    )

    if (isInline) {
        return (
            <StyledCopyableInline
                tabIndex={0}
                aria-label={copyToClipBoard}
                iconPosition={iconPosition}
                data-testid={dataTestId ?? copyToClipBoard}
                onClick={() => {
                    clipboard.copy(valueToCopy)
                }}
                data-generic-ui="copyable"
            >
                <CopyableContent />
            </StyledCopyableInline>
        )
    }

    return (
        <StyledCopyableButton
            aria-label={copyToClipBoard}
            iconPosition={iconPosition}
            data-testid={dataTestId ?? copyToClipBoard}
            onClick={() => {
                clipboard.copy(valueToCopy)
            }}
            data-generic-ui="copyable"
        >
            <CopyableContent />
        </StyledCopyableButton>
    )
}
