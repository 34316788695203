import styled, {css} from 'styled-components'

import {focusRing, tokens} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

export const Image = styled.img`
    grid-area: image;
    height: auto;
    max-width: 100%;
`

export const Content = styled.div`
    grid-area: content;
    align-self: stretch;
`

export const ActionIcon = styled(ArrowRight)`
    grid-area: actionIcon;
    transition-duration: ${tokens.slow};
    transition-property: transform;
    color: ${tokens.shade500};
`

export const Base = styled.button`
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    text-align: start;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 40px 1fr 16px;
    grid-template-areas: 'image content actionIcon';
    grid-template-rows: auto;
    border: ${tokens.borderPrimary};
    padding: ${tokens.spacing24};
    grid-gap: ${tokens.spacing24};
    align-items: center;
    border-radius: ${tokens.arc8};
    cursor: pointer;
    transition: ${tokens.smoothInOut};
    transition-property: box-shadow, transform;
    ${focusRing('regular')}

    &:hover {
        box-shadow: ${tokens.shadowElevateQuiet};
        transform: scale(1.02);
    }

    &:hover ${ActionIcon} {
        transform: translateX(25%);
    }

    ${(props) =>
        props.disabled &&
        `
        background: ${tokens.shade100};
        pointer-events: none;
    `}

    ${(props) =>
        props.disabled &&
        css`
            & ${ActionIcon} {
                display: none;
            }
        `}
`
