import type {Amount} from '@pleo-io/deimos'

import type {ActivePlanType} from './plan'
import {PlanType} from './plan'

interface ProductResponse {
    id: string
    name?: string
    description?: string
    plans?: PlanResponse[]
}

// Pricing plan names should not be translated
/* eslint-disable string-to-lingui/missing-lingui-transformation */
export const PlanTypeName: Record<ActivePlanType, string> = {
    [PlanType.STARTER]: 'Starter',
    [PlanType.ESSENTIAL]: 'Essential',
    [PlanType.ADVANCED]: 'Advanced',
    [PlanType.BEYOND]: 'Beyond',
}
/* eslint-enable string-to-lingui/missing-lingui-transformation */

enum Interval {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

type PlanResponseReason = 'AVAILABLE' | 'TOO_MANY_EMPLOYEES'

interface PlanResponse {
    id: string
    nickname: string
    product: ProductResponse
    type: PlanType
    amountDecimal?: number
    billingScheme?: string
    currency?: string
    interval?: Interval
    intervalCount?: number
    trialPeriodDays?: number
    eligibility: boolean
    reason: PlanResponseReason
    country: string
    userCommitment?: number
    nextBillingDate?: string
}

interface CurrentPeriodResponse {
    start: Date
    end: Date
}

interface Trial {
    start: string
    end: string
}

interface BreakDownItem {
    amount: Amount
    description: string
    quantity: number
}

interface ProrationLine {
    amount: Amount
    description: string
    quantity: number
}

interface BillingBreakdown {
    additionalUsersCost: Amount
    baseUsersCost: Amount
    discountAmount: Amount
    prorationLines?: ProrationLine[]
    subtotal: Amount
    total: Amount
    totalProration: Amount
    totalBeforeVat: Amount
    vatAmount: Amount
    pendingCards?: BreakDownItem[]
    pendingReimbursements?: BreakDownItem[]
    pendingUsers?: number
}

export interface SubscriptionResponse {
    id: string
    companyId: string
    status: SubscriptionStatus
    customerId: string
    planSubscriptionItem: SubscriptionItemResponse
    extraUsersSubscriptionItem?: SubscriptionItemResponse
    latestInvoiceId?: string
    currentPeriod?: CurrentPeriodResponse
    started: Date
    couponId?: string
    freeAdministrators: number
    isInTrial?: boolean
    trial?: Trial
    billingEmail: string
    billingBreakdownUpcoming: BillingBreakdown
    taxExemptStatus: string
    insuranceSubscriptionItem?: SubscriptionItemResponse
    contract?: boolean
    includeCashback?: boolean
    userCommitment?: number
}

enum SubscriptionStatus {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    INCOMPLETE = 'INCOMPLETE',
    INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
    TRIALING = 'TRIALING',
    PAST_DUE = 'PAST_DUE',
    UNPAID = 'UNPAID',
    STRIPE_SUBSCRIPTION = 'STRIPE_SUBSCRIPTION',
}

interface SubscriptionItemResponse {
    id: string
    quantity?: number
    freeAdministrators?: number
    plan: PlanResponse
    totalAmount?: Amount
}
