import styled from 'styled-components'

import {Box, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import type {Props as InlineProps} from '@pleo-io/telescope/dist/components/inline'
import type {StackProps} from '@pleo-io/telescope/dist/components/stack'

export const CourseSummary = ({steps}: {steps: string[]}) => (
    <StepsCard>
        {steps.map((step, index) => (
            <Box key={step}>
                <Step>
                    <StepCount>{index + 1}</StepCount>
                    <Text>{step}</Text>
                </Step>
                {index < steps.length - 1 && <Divider />}
            </Box>
        ))}
    </StepsCard>
)

const StepsCard = styled(Stack).attrs<StackProps>({justifyItems: 'stretch', my: 40})`
    width: 100%;
    background: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc12};
`

const Divider = styled.div`
    width: 100%;
    height: ${tokens.sizeBorderDefault};
    background-color: ${tokens.colorBorderStatic};
`

const StepCount = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0 ${tokens.spacing8};
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSmall};
    font-weight: ${tokens.fontWeightRegular};
    border-radius: ${tokens.arc99999};
    border: ${tokens.borderInteractiveSelected};
    white-space: nowrap;
    user-select: none;
`

const Step = styled(Inline).attrs<InlineProps>({space: 24, m: 24, alignItems: 'center'})``
