import {Trans} from '@lingui/macro'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router'
import styled from 'styled-components'

import {Avatar, Inline, NakedButton, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import type {GetUserNavigationResponse} from '@product-web/api-deimos/user-navigation'
import {useUserNavigation} from '@product-web/api-deimos/user-navigation'
import {OtpType} from '@product-web/api-types/otp'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/auth--accounts'
import {checkEmail} from '@product-web/auth--otp/api'
import {reportError} from '@product-web/error/report'
import {getIsOrganizationAdmin, useUser} from '@product-web/user'
import {getFullName} from '@product-web/utils'
import {AddAccountScreenName} from '@product-web-features/multi-entity/add-account-screen-name'

import {getSwitchAccountRedirectLocation} from '../get-switch-account-redirect-location'

interface LoggedOutAccount {
    email: string
    trused?: boolean
}
interface NavigationAccountItemProps {
    email: string
    accessToken?: string
    loggedOutAccount?: LoggedOutAccount
    isCurrentLoggedInAccount?: boolean
    item?: GetUserNavigationResponse | null
    activeAccount?: LoggedInAccount
    numberOfActiveAccounts?: number
    switchAccount: (payload: SwitchAccountPayload) => void
}

const NavigationAccountItemMobile = ({
    isCurrentLoggedInAccount,
    email,
    accessToken,
    loggedOutAccount,
    activeAccount,
    numberOfActiveAccounts,
    switchAccount,
}: NavigationAccountItemProps) => {
    const user = useUser()
    const isOrganizationAdmin = getIsOrganizationAdmin(user)
    const navigate = useNavigate()
    // TODO: move this to BFF, https://linear.app/pleo/issue/ME-4057/story-1-bff-user-navigation-does-not-loop-through-the-response
    const {data: item} = useUserNavigation({accessToken, email})
    const getItemLabel = (account?: GetUserNavigationResponse | null) => {
        if (loggedOutAccount) {
            return (
                <Text as="span" color="colorContentStaticQuiet" italic>
                    <Trans>Logged out</Trans>
                </Text>
            )
        }
        const isOrganization = account?.organizationId
        if (!isOrganization && account) {
            return account.companies[0].name
        }
        return account?.organizationName
    }

    const avatarData = {
        fullName: getFullName(item ?? undefined) === '' ? email : getFullName(item ?? undefined),
        organizationOrCompanyName: getItemLabel(item),
        url: item?.companies.find((company) => company.avatar !== null)?.avatar.url,
    }

    const [isTextTruncated, setIsTextTruncated] = useState<boolean | null>(false)
    const [isEmailTruncated, setIsEmailTruncated] = useState<boolean | null>(false)

    const textRef = useRef<HTMLInputElement | null>(null)
    const emailRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const isTextRefTruncated =
            !!textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth

        const isEmailRefTruncated =
            !!emailRef.current && emailRef.current.scrollWidth > emailRef.current.clientWidth

        setIsTextTruncated(isTextRefTruncated)
        setIsEmailTruncated(isEmailRefTruncated)
    }, [email])

    const onSwitchAccount = async (accountEmail: string, companyId?: string | null) => {
        if (loggedOutAccount) {
            await addAccount(loggedOutAccount)
        }
        tracking.accountSwitchToggleActioned({
            actor_role: user?.role,
        })

        // prevent trying to switch to current account
        if (activeAccount && activeAccount.email === accountEmail) {
            return
        }

        const redirectTo = getSwitchAccountRedirectLocation(location, {
            isSpendingEntity: user?.isSpendingEntity,
            isOrganizationAdmin,
        })

        tracking.switchAccount({
            numberOfActiveAccounts: numberOfActiveAccounts ?? 0,
        })
        switchAccount({email: accountEmail, companyId, location: redirectTo})
    }

    const addAccount = async (connectedAccount: {email: string; trusted?: boolean}) => {
        try {
            const response = await checkEmail(connectedAccount.email)
            const otpType = response.googleAuth ? OtpType.GOOGLE_AUTH : OtpType.PHONE
            const addAccountLocation = connectedAccount.trusted ? '/access' : '/add-account'

            navigate(addAccountLocation, {
                state: {
                    ...(connectedAccount.trusted ? {reAuthenticateAccount: true} : {}),
                    otpType,
                    email: connectedAccount.email,
                    currentScreen: AddAccountScreenName.PASSCODE,
                    redirect: getSwitchAccountRedirectLocation(location, {
                        isSpendingEntity: user?.isSpendingEntity,
                        isOrganizationAdmin,
                    }),
                },
            })
        } catch (err) {
            reportError(err)
        }
    }

    return (
        <Button key={email} onClick={async () => onSwitchAccount(email)}>
            <Inline alignY={'center'} pl={12} align={'left'}>
                <NavAvatar size={24} name={avatarData.fullName ?? email} src={avatarData.url} />
                <Stack>
                    {isTextTruncated ? (
                        <Tooltip content={avatarData.organizationOrCompanyName}>
                            <NoWrapFontWeightMedium align="left">
                                {avatarData.organizationOrCompanyName}
                            </NoWrapFontWeightMedium>
                        </Tooltip>
                    ) : (
                        <NoWrapFontWeightMedium ref={textRef} align="left">
                            {avatarData.organizationOrCompanyName}
                        </NoWrapFontWeightMedium>
                    )}
                    {isEmailTruncated ? (
                        <Tooltip content={email}>
                            <NoWrap
                                color="colorContentInteractive"
                                variant="small-subtle"
                                align="left"
                            >
                                {email}
                            </NoWrap>
                        </Tooltip>
                    ) : (
                        <NoWrap
                            ref={emailRef}
                            color="colorContentInteractive"
                            variant="small-subtle"
                            align="left"
                        >
                            {email}
                        </NoWrap>
                    )}
                </Stack>
                {isCurrentLoggedInAccount && (
                    <Check size={16} color={tokens.colorContentInteractiveQuiet} />
                )}
            </Inline>
        </Button>
    )
}

const NavAvatar = styled(Avatar)`
    margin: ${tokens.spacing16} ${tokens.spacing12} ${tokens.spacing16} 0;
`
export const NoWrapFontWeightMedium = styled(Text).attrs({
    variant: 'medium-default',
    as: 'p',
    truncate: true,
})`
    font-weight: ${tokens.fontWeightMedium};
    width: inherit;
`
const NoWrap = styled(Text).attrs({
    truncate: true,
})`
    width: inherit;
    text-align: left;
`

const Button = styled(NakedButton)`
    width: 100%;
`
export default NavigationAccountItemMobile
