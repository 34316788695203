import {t} from '@lingui/macro'

const PartnerTierTypes = [
    'TRIAL',
    'BRONZE',
    'SILVER',
    'GOLD',
    'GOLD_DACH',
    'PLATINUM',
    'PLATINUM_DACH',
] as const
export type PartnerTierType = (typeof PartnerTierTypes)[number]

const VisiblePartnerTierTypes = ['TRIAL', 'BRONZE', 'SILVER', 'GOLD', 'PLATINUM'] as const
export type VisiblePartnerTierType = (typeof VisiblePartnerTierTypes)[number]

export const partnerTierNamesMap = (): Record<PartnerTierType, string> => ({
    TRIAL: t`New partner`,
    BRONZE: t`Bronze`,
    SILVER: t`Silver`,
    GOLD: t`Gold`,
    GOLD_DACH: t`Gold`,
    PLATINUM: t`Platinum`,
    PLATINUM_DACH: t`Platinum`,
})

export const visibleTierTypesMap: Record<PartnerTierType, VisiblePartnerTierType> = {
    TRIAL: 'TRIAL',
    BRONZE: 'BRONZE',
    SILVER: 'SILVER',
    GOLD: 'GOLD',
    GOLD_DACH: 'GOLD',
    PLATINUM: 'PLATINUM',
    PLATINUM_DACH: 'PLATINUM',
}

export const partnerTierDiscountMap: Record<PartnerTierType, number> = {
    TRIAL: 30,
    BRONZE: 0,
    SILVER: 10,
    GOLD: 20,
    GOLD_DACH: 25,
    PLATINUM: 30,
    PLATINUM_DACH: 35,
}
