import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

import {RedirectWithParams} from './lib/redirect-with-params'

const PageNotFound = React.lazy(
    async () => import('@product-web/routes/page-not-found/page-not-found'),
)
const BusinesscentralCallbackPage = React.lazy(
    async () => import('./screens/export-callbacks/business-central-callback'),
)
const DatevCallbackPage = React.lazy(
    async () => import('./screens/export-callbacks/datev-callback'),
)
const VismaDotNetCallbackPage = React.lazy(
    async () => import('./screens/export-callbacks/visma-dot-net-callback'),
)
const ErpAuthPage = React.lazy(async () => import('./screens/erp/erp'))

const SetupPage = React.lazy(async () => import('./screens/setup/setup'))

// TODO: Move at least the page setup to the app (like for the setup page)
const CategoriesPage = React.lazy(
    async () => import('@product-web-features/accounting/categories/categories'),
)

const AccountingWrapper = React.lazy(async () => import('./components/settings-wrapper'))
const TagPage = React.lazy(async () => import('./screens/tag/tag'))
const TagsPage = React.lazy(async () => import('./screens/tags/tags'))
const IGatewayConnectPage = React.lazy(async () => import('./screens/integrations-gateway/connect'))

export const routes = (
    <Route
        handle={{
            auth: true,
            allowedRoles: roleSets.ownerAndBookkeeper,
        }}
    >
        <Route path="datev/oauth" element={<DatevCallbackPage />} />
        <Route
            // delete it. /erp/auth should be used instead
            path="businesscentral/oauth"
            element={<BusinesscentralCallbackPage />}
        />
        <Route
            // delete it. /erp/auth should be used instead
            path="vismadotnet/oauth"
            element={<VismaDotNetCallbackPage />}
        />
        <Route index element={<PageNotFound />} />
    </Route>
)

export const erpRoutes = (
    <>
        <Route path="auth" element={<ErpAuthPage />} />
    </>
)

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route path="tags/:id/" element={<TagPage />} />
        <Route element={<AccountingWrapper />}>
            <Route path="tags" element={<TagsPage />} />
            <Route path="setup" element={<SetupPage />} />
            <Route path="categories" element={<CategoriesPage />} />
            <Route index path="*" element={<RedirectWithParams to="setup" />} />
            <Route path="setup/connect" element={<IGatewayConnectPage />} />
        </Route>
    </Route>
)
