import {callAuth} from '@product-web/api'
import {RequestAuth, RequestScope} from '@product-web/api'
import {getClientSessionId} from '@product-web/auth--client-session'
import {clearScopedTokens} from '@product-web/auth--session/scoped-tokens'
import config from '@product-web/config'

const clientId = config.auth.oauthClientId

export async function login(
    pin: string,
    companyId: string | null,
    scope?: RequestScope,
    payload?: {
        publicKey: any
    } | null,
    elevatedResourceIds?: string[],
): Promise<{accessToken: string}> {
    const body: any = {pin, clientId}
    payload && (body.payload = payload)
    scope && (body.scope = scope)
    elevatedResourceIds && (body.elevatedResourceIds = elevatedResourceIds)
    return callAuth('/api/v1/user/pin/login', {
        auth: RequestAuth.USER,
        method: 'POST',
        query: {companyId},
        body,
    })
}

export async function create(pin: string): Promise<{success: boolean}> {
    return callAuth('/api/v1/user/pin', {
        auth: RequestAuth.USER,
        method: 'POST',
        body: {pin},
    })
}

export async function update(oldPin: string, passcode: string): Promise<{success: boolean}> {
    const companyId = getClientSessionId()
    const response = await login(oldPin, companyId, RequestScope.CARD, null)
    const accessToken = response.accessToken
    return callAuth('/sca/reset/passcode', {
        bearer: accessToken,
        method: 'PUT',
        body: {passcode},
    }).then(async (result): Promise<{success: boolean}> => {
        clearScopedTokens()
        return result
    })
}

export async function checkReset(resetToken: string): Promise<{success: boolean}> {
    return callAuth(`/api/v1/user/pin/reset/${resetToken}`, {
        auth: RequestAuth.USER,
        method: 'GET',
    })
}

export async function reset(resetToken: string, pin: string): Promise<{success: boolean}> {
    return callAuth(`/api/v1/user/pin/reset/${resetToken}`, {
        auth: RequestAuth.USER,
        method: 'PUT',
        body: {pin},
    })
}

export async function resetPasscode(
    accessToken: string,
    userId: string,
): Promise<{success: boolean}> {
    return callAuth(`sca/reset/${userId}/passcode`, {
        bearer: accessToken,
        auth: RequestAuth.ELEVATED,
        scope: RequestScope.PERMISSION,
        method: 'POST',
    })
}

export async function resetTwoFactorAuth(
    accessToken: string,
    userId: string,
): Promise<{success: boolean}> {
    return callAuth(`sca/reset/${userId}/tfa`, {
        bearer: accessToken,
        auth: RequestAuth.ELEVATED,
        scope: RequestScope.PERMISSION,
        method: 'POST',
    })
}
