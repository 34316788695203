import styled from 'styled-components'

import {Button, Modal, ModalSubtitle, Text, tokens} from '@pleo-io/telescope'

export const OauthButton = styled(Button).attrs({variant: 'secondary'})`
    width: 100%;
    margin: ${tokens.spacing24} 0 0 0;

    img {
        display: inline-block;
        margin-right: ${tokens.spacing8};
        margin-left: ${tokens.spacing8};
    }
`

export const Divider = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    margin: ${tokens.spacing20} 0;
    width: 100%;
    display: flex;

    &::before,
    &::after {
        content: '';
        border-bottom: ${tokens.borderPrimary};
        margin: auto;
    }

    &::before {
        flex: 4;
        margin-right: ${tokens.spacing12};
    }

    &::after {
        flex: 4;
        margin-left: ${tokens.spacing12};
    }
`
export const EmailInput = styled.div`
    text-align: left;
`

export const AddAccountModal = styled(Modal)<{enforceTrustedSession: boolean}>`
    & ${ModalSubtitle} {
        margin-bottom: ${(props) => (props?.enforceTrustedSession ? '0' : tokens.spacing24)};
    }
    & ${Divider} {
        margin-top: ${(props) =>
            props?.enforceTrustedSession ? tokens.spacing10 : tokens.spacing20};
        margin-bottom: ${(props) =>
            props?.enforceTrustedSession ? tokens.spacing24 : tokens.spacing20};
    }
`

export const AddMoreEntities = styled.div`
    margin-top: ${tokens.spacing24};
`
