/* eslint-disable string-to-lingui/missing-lingui-transformation */

declare global {
    interface Window {
        opera: any
    }
}

export enum MobilePlatform {
    WINDOWS_PHONE,
    ANDROID,
    IOS,
}

// https://stackoverflow.com/a/21742107
export const getMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return MobilePlatform.WINDOWS_PHONE
    }

    if (/android/i.test(userAgent)) {
        return MobilePlatform.ANDROID
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return MobilePlatform.IOS
    }

    return null
}

export const isMobile = !!getMobilePlatform()
