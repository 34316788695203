import {lazy, Suspense} from 'react'
import {Route} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {roleSets} from '@product-web/user'

const VendorCardPage = lazy(async () => import('./screens/vendor-cards'))
const VendorCardDetailsDrawer = lazy(
    async () => import('@product-web-features/vendor-cards/vendor-card-details-drawer'),
)
export const routes = (
    <>
        <Route
            path="/vendor-cards"
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
            element={<VendorCardPage />}
        >
            <Route
                path=":cardId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <VendorCardDetailsDrawer />
                    </Suspense>
                }
            >
                <Route
                    path=":expenseId"
                    element={
                        <Suspense fallback={<LoadingPage />}>
                            <VendorCardDetailsDrawer />
                        </Suspense>
                    }
                />
            </Route>
        </Route>
        <Route
            path="create"
            element={<VendorCardPage />}
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        />
    </>
)
