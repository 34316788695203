import {t, Trans} from '@lingui/macro'
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react'
import styled from 'styled-components'

import {Badge, focusRing, Spinner, tokens} from '@pleo-io/telescope'
import {Check, ChevronDown, Close} from '@pleo-io/telescope-icons'

import type {SelectOptions, SelectProps} from '@product-web/telescope-lab/select'
import {Select, useSelectContext} from '@product-web/telescope-lab/select'
import type {SaveState} from '@product-web/telescope-lab/spinner/types'

interface ExpensePropertyTriggerProps {
    icon: JSX.Element
    labelString: string
    selectedOption?: string | null
    isOpen: boolean
    disabled?: boolean
    showMissingLabel?: boolean
    saveState?: SaveState
}

export const ExpensePropertyTrigger = React.forwardRef(
    (
        {
            icon,
            labelString,
            selectedOption,
            isOpen,
            disabled,
            showMissingLabel,
            saveState,
            ...props
        }: ExpensePropertyTriggerProps,
        ref: React.Ref<HTMLButtonElement>,
    ) => {
        const renderSaveState = () => {
            switch (saveState) {
                case 'saving':
                    return (
                        <SaveStateWrapper key="saving">
                            <Spinner />
                        </SaveStateWrapper>
                    )
                case 'error':
                    return (
                        <SaveStateWrapper key="error">
                            <Close size={16} color={tokens.colorContentNegative} />
                        </SaveStateWrapper>
                    )
                case 'success':
                    return (
                        <SaveStateWrapper key="success">
                            <Check size={16} color={tokens.colorContentPositive} />
                        </SaveStateWrapper>
                    )
                case 'idle':
                default:
                    return (
                        <SaveStateWrapper key="idle">
                            <DropdownArrow $isOpen={isOpen} />
                        </SaveStateWrapper>
                    )
            }
        }

        return (
            <ExpenseButton
                $isOpen={isOpen}
                $hasValue={Boolean(selectedOption)}
                aria-haspopup="true"
                type="button"
                data-testid="expense-property-trigger"
                disabled={disabled}
                {...props}
                ref={ref}
            >
                <ExpenseButtonIconWrapper>{icon}</ExpenseButtonIconWrapper>
                {labelString}
                <AnimatePresence exitBeforeEnter>{renderSaveState()}</AnimatePresence>
                {showMissingLabel ? (
                    <LabelWrapper $isOpen={isOpen}>
                        <Badge loud variant="warning">
                            <Trans>Missing</Trans>
                        </Badge>
                    </LabelWrapper>
                ) : null}
            </ExpenseButton>
        )
    },
)

/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */
ExpensePropertyTrigger.displayName = 'ExpensePropertyTrigger'

interface DropdownArrowProps {
    $isOpen?: boolean
}

const DropdownArrow = styled(ChevronDown).attrs({size: 16})<DropdownArrowProps>`
    display: ${({$isOpen}) => ($isOpen ? 'block' : 'none')};
    margin-left: auto;
    transform: ${({$isOpen}) => ($isOpen ? 'rotateX(180deg)' : 'none')};
`

const SaveStateWrapper = styled(motion.div).attrs({
    initial: {
        scale: 0,
    },
    animate: {
        scale: 1,
    },
    exit: {
        scale: 0,
    },
    transition: {duration: 0.15},
})`
    align-items: center;
    display: flex;
    margin-left: auto;
`

const LabelWrapper = styled.div<{$isOpen: boolean}>`
    position: absolute;
    right: ${tokens.spacing12};
    top: 50%;
    transform: translate(${({$isOpen}) => ($isOpen ? '-28px' : 0)}, -50%);
    transition: transform ${tokens.fastIn};
`

interface ExpenseButtonProps {
    $isOpen?: boolean
    $hasValue?: boolean
}

const ExpenseButton = styled.button<ExpenseButtonProps>`
    text-align: left;
    align-items: center;
    background-color: ${({$isOpen}) =>
        $isOpen ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
    border: ${({$isOpen}) =>
        `${tokens.sizeBorderDefault} solid ${
            $isOpen ? tokens.colorBorderInteractiveQuiet : 'transparent'
        }`};
    border-radius: ${tokens.spacing8};
    color: ${({$hasValue}) =>
        $hasValue ? tokens.colorContentInteractive : tokens.colorContentInteractiveQuiet};
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: ${tokens.fontMedium};
    padding: ${tokens.spacing8} ${tokens.spacing12};
    position: relative;
    transition: ${`border ${tokens.smoothInOut}`};
    width: 100%;
    ${focusRing('regular')};

    &:hover {
        border: ${tokens.borderInteractiveHover};
    }

    &:hover ${DropdownArrow}, &:focus ${DropdownArrow} {
        display: block;
    }

    &:hover ${LabelWrapper}, &:focus ${LabelWrapper} {
        transform: translate(-28px, -50%);
    }

    &:disabled {
        pointer-events: none;
    }
`

const ExpenseButtonIconWrapper = styled.span`
    margin-right: ${tokens.spacing8};

    > svg {
        transition: ${`color ${tokens.fast}`};
    }
`
interface SelectTriggerButtonProps {
    icon: JSX.Element
    onOpen?: () => void
    disabled?: boolean
    showMissingLabel?: boolean
    saveState?: SaveState
}

const SelectTriggerButton = React.forwardRef(
    (
        {icon, onOpen, disabled, ...props}: SelectTriggerButtonProps,
        ref: React.Ref<HTMLButtonElement>,
    ) => {
        const {labelString, selectedOption, isOpen} = useSelectContext()

        useEffect(() => {
            if (isOpen && onOpen) {
                onOpen()
            }
        }, [isOpen, onOpen])

        return (
            <ExpensePropertyTrigger
                labelString={labelString}
                selectedOption={selectedOption?.value}
                isOpen={isOpen}
                icon={icon}
                disabled={disabled}
                {...props}
                ref={ref}
            />
        )
    },
)

/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */
SelectTriggerButton.displayName = 'SelectTriggerButton'

interface ReadyOnlyExpensePropertyEditorProps {
    icon: JSX.Element
    label: string
}

export const ReadyOnlyExpensePropertyEditor = ({
    icon,
    label,
}: ReadyOnlyExpensePropertyEditorProps) => {
    return (
        <FieldWrapper>
            <IconWrapper>{icon}</IconWrapper>
            {label}
        </FieldWrapper>
    )
}

export interface ExpensePropertyEditorProps extends SelectProps {
    value: string | null
    onChange: (newValue: string | null) => void
    icon: JSX.Element
    options: SelectOptions
    isReadOnly?: boolean
    readOnlyLabel?: string
    placeholder?: string
    'data-testid'?: string
    onOpen?: () => void
    disabled?: boolean
    showMissingLabel?: boolean
    saveState?: SaveState
}

const ExpensePropertyEditor = ({
    value,
    onChange,
    icon,
    options,
    placeholder,
    isReadOnly,
    readOnlyLabel,
    'data-testid': testId,
    onOpen,
    disabled,
    showMissingLabel,
    saveState,
    ...other
}: ExpensePropertyEditorProps) => {
    if (isReadOnly) {
        return <ReadyOnlyExpensePropertyEditor icon={icon} label={readOnlyLabel || ''} />
    }

    return (
        <Select
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            searchable
            searchInputPlaceholder={t`Search...`}
            {...other}
        >
            <SelectTriggerButton
                icon={icon}
                data-testid={testId}
                onOpen={onOpen}
                disabled={disabled}
                showMissingLabel={showMissingLabel}
                saveState={saveState}
            />
        </Select>
    )
}

export default ExpensePropertyEditor

const IconWrapper = styled.span`
    margin-right: ${tokens.spacing8};

    > svg {
        transition: ${`color ${tokens.fast}`};
    }
`

const FieldWrapper = styled.div`
    align-items: center;
    background-color: ${tokens.colorBackgroundStatic};
    color: ${tokens.colorContentStatic};
    border: 1px solid transparent;
    border-radius: ${tokens.spacing8};
    display: flex;
    font-family: inherit;
    font-size: ${tokens.fontMedium};
    padding: ${tokens.spacing8} ${tokens.spacing12};
    transition: ${`border ${tokens.smoothInOut}`};
    width: 100%;
`
