import type {ICountryConfiguration} from './types'

import {Feature} from '../types'

const ES: ICountryConfiguration = {
    [Feature.Paywall]: {
        isCashBackCountry: true,
    },
    [Feature.Mileage]: {
        requirePurposeOfMileage: true,
    },
    [Feature.PerDiem]: {
        requirePurposeOfPerDiem: true,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleoes.aftership.com/',
    },
}

export default ES
