import {useMemo} from 'react'

export interface PersonWithEmail {
    email?: string
}

export function usePeopleEmails(data: PersonWithEmail[]) {
    return useMemo(
        () => data.filter((d) => d?.email).map((user) => user.email) as string[],
        [data.length],
    )
}
