/**
 * Compare two arrays to see if they are the same
 * For the current use case, it only compares array of primitive values
 * @param a an array of string or number
 * @param b an array of string or number
 * @returns true or false if a and b are equal
 */
export const isSameArray = (a: Array<string | number>, b: Array<string | number>) => {
    return (
        a.every((item: string | number) => b.includes(item)) &&
        b.every((item: string | number) => a.includes(item))
    )
}
