import type React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import type {ColorTheme} from './context'
import {PageDrawerContext} from './context'
import * as NavigationButtons from './navigation-arrow-buttons'

/**
 * The PageDrawer exposes necessary components to create the basics of a drawer component.
 * The component is a compound component made of:
 * Header: The Header element wraps components related to navigation, drawer tabs, etc.
 * It receives a theme prop to change the background color.
 * NavigationCloseButton: Closes the drawer.
 * NavigationPreviousButton: Navigates to the previous panel.
 * NavigationNextButton: Navigates to the next panel.
 * NavigationBackButton: Navigates back.
 * All the components can forward a ref
 */

type HeaderProps = {
    children: React.ReactNode
    colorTheme?: ColorTheme
}

const PageDrawerHeader = (props: HeaderProps) => {
    return (
        <PageDrawerContext.Provider
            value={{colorTheme: props.colorTheme}}
            data-generic-ui="page-drawer"
        >
            <StyledDrawerHeader $colorTheme={props.colorTheme}>{props.children}</StyledDrawerHeader>
        </PageDrawerContext.Provider>
    )
}

type StyledDrawerHeaderProps = {
    $colorTheme?: ColorTheme
}

const getThemeBackgroundColor = ({$colorTheme}: StyledDrawerHeaderProps) => {
    if ($colorTheme === 'purple') {
        return tokens.purple300
    }

    if ($colorTheme === 'light') {
        return tokens.shade000
    }

    return tokens.shade200
}

const StyledDrawerHeader = styled.div<StyledDrawerHeaderProps>`
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: minmax(auto, 1fr) auto minmax(auto, 1fr);
    grid-template-rows: auto;
    min-height: 60px;
    padding: 0 ${tokens.spacing12};
    color: ${tokens.shade600};
    background-color: ${getThemeBackgroundColor};
    border-bottom: 1px solid transparent;
    align-items: center;
    z-index: ${tokens.zIndexNavigation};
`

export const PageDrawer = () => {}

PageDrawer.Header = PageDrawerHeader
PageDrawer.NavigationButton = NavigationButtons.NavigationButton
PageDrawer.NavigationButtons = NavigationButtons.NavigationButtons
PageDrawer.NavigationCloseButton = NavigationButtons.ButtonClose
PageDrawer.NavigationBackButton = NavigationButtons.ButtonLeft
PageDrawer.NavigationPreviousButton = NavigationButtons.ButtonUp
PageDrawer.NavigationNextButton = NavigationButtons.ButtonDown
