import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const PageAuthAnalytics = React.lazy(async () => import('./screens/analytics'))

export const routes = (
    <Route
        handle={{
            auth: true,
            allowedRoles: roleSets.company,
        }}
    >
        <Route index element={<PageAuthAnalytics />} />
    </Route>
)
