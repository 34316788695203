import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Stack, Text, tokens} from '@pleo-io/telescope'

interface CardProps {
    description: ReactNode
    icon: ReactNode
    title: string
}

const Card = ({description, icon, title}: CardProps) => (
    <CardWrapper>
        {icon}
        <Title>{title}</Title>
        <Text>{description}</Text>
    </CardWrapper>
)

const CardWrapper = styled(Stack).attrs({space: 12})`
    padding: ${tokens.spacing24} ${tokens.spacing16};
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: ${tokens.spacing24};
`
const Title = styled(Text).attrs({as: 'h5', variant: 'large-accent'})`
    font-weight: ${tokens.fontWeightSemibold};
`

interface SnapshotProps {
    children: ReactNode
}
export const Snapshot = (props: SnapshotProps) => <Container {...props} />

Snapshot.Card = Card
