import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Button, Inline, Stack, Text} from '@pleo-io/telescope'
import {Eye, Play, Swap, Team} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {stringToSupportedLanguage, useSupportedLanguageList} from '@product-web/i18n'

import type {VideoProps} from './video'
import {Video} from './video'

import {bff} from '../../../bff'
import {getVideoIdByLanguage} from '../../lib/helpers'

export const SingleEntityIntroduction = () => {
    const {data} = bff.featureMultiEntity.requestEntitiesIntro.introData.useQuery()
    const supportedLanguageList = useSupportedLanguageList()
    const userLanguage = data?.userLanguage
        ? stringToSupportedLanguage(data?.userLanguage, supportedLanguageList)
        : null

    const videoLink = getVideoIdByLanguage(userLanguage)

    const handleClick = () => {
        if (!data?.companyId || !data?.userId) {
            return
        }
        tracking.selfExpansionInfoVideoViewed({
            companyId: data.companyId,
            userId: data.userId,
            hasOrgAccess: false,
        })
    }

    return (
        <Stack space={24}>
            <PlayVideoLink onClick={handleClick} videoLink={videoLink} />
            <Text as="p" align="left">
                <Trans>
                    Multi-entity management lets you and your finance team control your entire
                    organisation from one place.
                </Trans>
            </Text>
            <Inline space={18} alignY="center">
                <Swap />
                <Stack>
                    <Text weight="medium">
                        <Trans>Switch between companies from one account</Trans>
                    </Text>
                    <Text as="p" variant="small-subtle" align="left">
                        <Trans>All your admins can manage all the companies</Trans>
                    </Text>
                </Stack>
            </Inline>
            <Inline space={18} alignY="center">
                <Eye />
                <Stack>
                    <Text weight="medium">
                        <Trans>Easily control all your finances</Trans>
                    </Text>
                    <Text as="p" variant="small-subtle" align="left">
                        <Trans>Quick overview of all entities and wallets</Trans>
                    </Text>
                </Stack>
            </Inline>
            <Inline space={18} alignY="center">
                <Team />
                <Stack>
                    <Text weight="medium">
                        <Trans>Create teams and add reviewers across entities</Trans>
                    </Text>
                    <Text as="p" variant="small-subtle" align="left">
                        <Trans>Combine team members from different entities</Trans>
                    </Text>
                </Stack>
            </Inline>
        </Stack>
    )
}

export const PlayVideoLink = ({onClick, videoLink}: VideoProps) => (
    <PlayButton variant="tertiary" IconLeft={Play} onClick={onClick}>
        <Video video={videoLink}>
            <Trans>Watch video (1 min)</Trans>
        </Video>
    </PlayButton>
)

const PlayButton = styled(Button)`
    width: 100%;
`
