import {localStorage} from '@product-web/web-platform/storage/local'

const FORM_COUNTRY_CODE = 'formCountryCode'
const FORM_COUNTRY_SIZE = 'formCompanySize'

export const getFormCompanyCountry = () => localStorage.getItem(FORM_COUNTRY_CODE)
export const getFormCompanySize = () => localStorage.getItem(FORM_COUNTRY_SIZE)

export const setFormCompanyCountry = (data: string) => localStorage.setItem(FORM_COUNTRY_CODE, data)
export const setFormCompanySize = (data: string) => localStorage.setItem(FORM_COUNTRY_SIZE, data)
