/* eslint-disable string-to-lingui/missing-lingui-transformation */

import {TRPCClientError} from '@trpc/client'
import type {AnyRouter} from '@trpc/server'

/**
 * The error messages that can be returned by the BFF.
 * Note that we do not use error classes here as all errors are tRPC errors.
 */
export const BffErrorMessages = {
    // The Pin Modal was cancelled by the user.
    PIN_MODAL_CANCELLED: 'PIN_MODAL_CANCELLED',
} as const

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AnyRouter> {
    return cause instanceof TRPCClientError
}
