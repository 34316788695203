// We've build this customly (with the radix dropdown menu), as it
// needs to be highly specialized. It used to be using the Telescope
// Popover which caused some issues. See this issue for details:
// https://linear.app/pleo/issue/DO-1110/account-switch-scrolls-out-of-view-on-several-features
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, {css, keyframes} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

const translateDistance = tokens.spacing10

const slideDownAndFade = keyframes({
    '0%': {transform: `translateY(-${translateDistance})`, opacity: 0},
    '100%': {transform: 'translateY(0)', opacity: 1},
})

const slideUpAndFade = keyframes({
    '0%': {transform: `translateY(${translateDistance})`, opacity: 0},
    '100%': {transform: 'translateY(0)', opacity: 1},
})

const fadeOut = keyframes({'0%': {opacity: 1}, '100%': {opacity: 0}})

const animationStyles = css`
    transform-origin: 'var(--radix-popover-content-transform-origin)';
    animation-duration: ${tokens.smooth};
    animation-timing-function: 'cubic-bezier(0.16, 1, 0.3, 1)';

    &[data-side='top'] {
        animation-name: ${slideDownAndFade};
    }

    &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
    }

    &[data-state='closed'] {
        animation-name: ${fadeOut};
    }
`

// These hacks are obviously not ideal. But it's none the less
// what Radix proposes for cases like these at this point:
// https://github.com/radix-ui/primitives/issues/1181#issuecomment-1049185802)
// This as [they don't really support non-portalled overlays yet.
// https://github.com/radix-ui/primitives/issues/367#issuecomment-754070415
// So if you're reading this and considering a refactor, might be worth checking up
// on the state of these issues...
const desktopHack = css`
    top: initial !important;
    right: 0 !important;
    bottom: calc(100% + ${tokens.spacing4}) !important;
    left: 0 !important;
`

const wrapperMargin = tokens.spacing16
const Wrapper = styled.div`
    position: relative;
    margin: 0 ${wrapperMargin};

    /*
        See https://github.com/radix-ui/primitives/issues/1181#issuecomment-1049185802
        and https://github.com/radix-ui/primitives/issues/367#issuecomment-756954835
    */
    [data-radix-popper-content-wrapper] {
        transform: none !important;
        ${desktopHack}
    }
`

type DropdownProps = Omit<DropdownMenu.DropdownMenuProps, 'modal'>
export const Dropdown = ({children, ...rest}: DropdownProps) => (
    <Wrapper>
        <DropdownMenu.Root {...rest}>{children}</DropdownMenu.Root>
    </Wrapper>
)

const Content = styled(DropdownMenu.DropdownMenuContent).attrs({portalled: false, loop: true})<{}>`
    ${animationStyles};
    box-shadow: ${tokens.shadowElevateQuiet};
    background-color: ${tokens.colorBackgroundStatic};
    border-radius: ${tokens.arc8};
    overflow: hidden;
`

Dropdown.Content = Content
Dropdown.Item = DropdownMenu.DropdownMenuItem
Dropdown.Trigger = DropdownMenu.Trigger
