import {t, Trans} from '@lingui/macro'
import {Link as RouterLink, useNavigate} from 'react-router-dom'

import {Button, ButtonGroup, Modal, Stack, Text} from '@pleo-io/telescope'

import onboardingCompleted from '../images/onboarding-completed.svg'

interface Props {
    isOpen: boolean
    nextCoursePath?: string
    upNext?: string
}

export const CourseCompleteModal = ({isOpen, nextCoursePath, upNext}: Props) => {
    const navigate = useNavigate()
    const handleClose = () => navigate('/partner/academy')
    const ariaLabel = upNext ? t`Course completed` : t`All courses completed`

    return (
        <Modal aria-label={ariaLabel} isOpen={isOpen}>
            <Modal.Close onClick={handleClose} />
            <Modal.Content>
                <Stack space={24} justifyItems="center">
                    <img src={onboardingCompleted} alt="" />
                    {upNext ? (
                        <>
                            <Text as="h4" variant="3xlarge-accent">
                                <Trans>You've completed a course</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Ready to help your clients save time and money? Onboard them so
                                    that they can start spending with Pleo today.
                                </Trans>
                            </Text>
                            <Text color="colorContentStaticQuiet">{upNext}</Text>
                            <ButtonGroup>
                                <Button
                                    variant="secondary"
                                    as={RouterLink}
                                    to={`/partner/academy/${nextCoursePath}`}
                                >
                                    <Trans>Let's go</Trans>
                                </Button>
                                <Button variant="primary" as={RouterLink} to="/partner/onboard">
                                    <Trans>Onboard a client</Trans>
                                </Button>
                            </ButtonGroup>
                        </>
                    ) : (
                        <>
                            <Text as="h4" variant="3xlarge-accent">
                                <Trans>You've completed all courses</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Got clients that would benefit with Pleo? Help them save time
                                    and money.
                                </Trans>
                            </Text>
                            <Button variant="primary" as={RouterLink} to="/partner/onboard">
                                <Trans>Onboard a client</Trans>
                            </Button>
                        </>
                    )}
                </Stack>
            </Modal.Content>
        </Modal>
    )
}
