import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import {useEffect, useState} from 'react'

import {
    Button,
    Inline,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalIllustration,
    ModalTitle,
} from '@pleo-io/telescope'

import * as tracking from '@product-web/analytics'
import {RequestScope} from '@product-web/api'
import type {CardDetails} from '@product-web/api-deimos/storebox'
import {linkCard, linkCardWithDetails} from '@product-web/api-deimos/storebox'
import {getKeyPair} from '@product-web/auth--keys/get-key-pair'
import ModalPinLogin from '@product-web/auth--pin-modal/pin-modal'
import {reportError} from '@product-web/error/report'
import receiptImage from '@product-web/images/receipts.svg'
import successImage from '@product-web/images/success.svg'
import {useUser} from '@product-web/user'

import {ModalImage} from './storebox.styles'
import StoreboxExtraInformation from './storebox-extra-info'

import {bff} from '../../../bff-hooks'

export interface Props {
    showRemindLater?: boolean
    onClose: (dismiss?: boolean) => void
}

export const StoreboxUserEnableModalContainer: FC<Props> = ({onClose, ...props}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isEnabled, setIsEnabled] = useState(false)
    const [needsExtraInfo, setNeedsExtraInfo] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [invalidCardId, setInvalidCardId] = useState<string | null>(null)
    const trackingStep = props.showRemindLater ? 'prompt' : 'intro'

    const user = useUser()

    const employeeId = user?.employee?.id ?? ''
    const {data: unlinkedCards = [], isLoading: isFetchingUnlinkedCards} =
        bff.featureIntegrations.storebox.getUnlinkedCards.useQuery(
            {employeeId},
            {enabled: Boolean(employeeId)},
        )
    const showAdminPowerupsPath =
        ['owner', 'bookkeeper-basic', 'bookkeeper-extended'].indexOf(user?.role ?? '') > -1
    const submit = async () => {
        tracking.storeboxOnboardingActioned({step: trackingStep, action: 'enable'})
        setIsLoading(true)
        try {
            const {publicKey} = await getKeyPair()
            const result = await ModalPinLogin.showScoped(RequestScope.CARD_DETAILS, publicKey)

            if (result.status === 'cancelled') {
                onClose(false)
                tracking.storeboxIntegrationActioned({
                    entity: 'employee',
                    action: 'abandoned',
                    flow: 'enable',
                })
                return
            }

            let invalidId
            for (const card of unlinkedCards) {
                const invalidCard = await linkCard(card.id, {token: result.value.accessToken})
                if (invalidCard) {
                    invalidId = invalidCard
                }
            }

            if (invalidId) {
                setNeedsExtraInfo(true)
                setInvalidCardId(invalidId)
            } else {
                setIsEnabled(true)
            }
            tracking.storeboxIntegrationActioned({
                entity: 'employee',
                action: 'completed',
                flow: 'enable',
            })
        } catch (err) {
            setHasError(true)
            reportError(err)
            tracking.storeboxIntegrationActioned({
                entity: 'employee',
                action: 'failed',
                flow: 'enable',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const onInformationCompleted = async (cardInfo: CardDetails) => {
        if (invalidCardId) {
            await linkCardWithDetails(invalidCardId, cardInfo)
        }
        setIsEnabled(true)
        setNeedsExtraInfo(false)
        setInvalidCardId(null)
    }

    return (
        <StoreboxUserEnableModal
            isEnabled={isEnabled}
            isLoading={isLoading}
            needsExtraInfo={needsExtraInfo}
            hasError={hasError}
            onEnable={submit}
            onInformationCompleted={onInformationCompleted}
            onClose={onClose}
            showAdminPowerupsPath={showAdminPowerupsPath}
            isFetchingUnlinkedCards={!!isFetchingUnlinkedCards}
            {...props}
        />
    )
}

interface StoreboxUserEnableModalProps {
    isEnabled?: boolean
    isLoading?: boolean
    needsExtraInfo?: boolean
    hasError?: boolean
    showRemindLater?: boolean
    showAdminPowerupsPath?: boolean
    onEnable: () => void
    onInformationCompleted: (cardInfo: CardDetails) => Promise<void>
    onClose: (dismiss?: boolean) => void
    isFetchingUnlinkedCards?: boolean
}

export const StoreboxUserEnableModal: FC<StoreboxUserEnableModalProps> = ({
    isEnabled = false,
    isLoading = false,
    needsExtraInfo = false,
    hasError = false,
    showRemindLater = false,
    showAdminPowerupsPath = false,
    onEnable,
    onInformationCompleted,
    onClose,
    isFetchingUnlinkedCards,
}) => {
    const trackingStep = showRemindLater ? 'prompt' : 'intro'

    useEffect(() => {
        if (!isLoading && !needsExtraInfo && !isEnabled && !hasError) {
            tracking.storeboxOnboardingActioned({
                step: trackingStep,
                action: 'viewed',
            })
        }
    }, [])

    if (isLoading) {
        return <Fetching />
    }

    if (needsExtraInfo) {
        return <StoreboxExtraInformation onInformationCompleted={onInformationCompleted} />
    }

    if (isEnabled) {
        return <Success close={onClose} />
    }

    if (hasError) {
        return <Error close={onClose} />
    }
    return (
        <Modal aria-labelledby="storebox-enable-title" isOpen onDismiss={() => onClose()}>
            <ModalClose onClick={() => onClose()} />
            <ModalIllustration>
                <ModalImage src={receiptImage} alt={t`digital receipt`} />
            </ModalIllustration>
            <ModalTitle id="storebox-enable-title">
                <Trans>Get receipts automatically</Trans>
            </ModalTitle>
            <ModalContent align="center" data-testid="storebox-user-enable-content">
                <Trans>
                    The next time you buy something at Netto, Føtex or +5,000 other stores – we'll
                    instantly grab the receipt.
                </Trans>{' '}
                {showRemindLater && showAdminPowerupsPath && (
                    <Trans>
                        You can enable this at anytime in <b>Settings → Apps</b>
                    </Trans>
                )}
                {showRemindLater && !showAdminPowerupsPath && (
                    <Trans>
                        You can enable this at anytime in <b>My Profile</b> under the{' '}
                        <b>My power-ups</b> tab.
                    </Trans>
                )}
            </ModalContent>
            <ModalActions>
                <Inline space={18}>
                    {showRemindLater && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                tracking.storeboxOnboardingActioned({
                                    step: 'prompt',
                                    action: 'remind_me_later',
                                })
                                onClose(false)
                            }}
                        >
                            <Trans>Remind me later</Trans>
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        loading={isLoading}
                        onClick={onEnable}
                        disabled={isFetchingUnlinkedCards}
                    >
                        {showRemindLater ? <Trans>Enable Storebox</Trans> : <Trans>Enable</Trans>}
                    </Button>
                </Inline>
            </ModalActions>
        </Modal>
    )
}

export const Fetching = () => (
    <Modal aria-labelledby="storebox-enable-fetching" isOpen>
        <ModalTitle id="storebox-enable-fetching" data-testid="storebox-user-enable-fetching">
            <Trans>One moment...</Trans>
        </ModalTitle>
        <ModalContent align="center">
            <Trans>We're linking your card with Storebox</Trans>
        </ModalContent>
        <ModalActions>
            <Button loading variant="primary" style={{width: 120}} />
        </ModalActions>
    </Modal>
)

export const Success = ({close}: {close: (dismiss?: boolean) => void}) => {
    useEffect(() => {
        tracking.storeboxOnboardingActioned({
            step: 'success',
            action: 'viewed',
        })
    }, [])
    return (
        <Modal aria-labelledby="storebox-enable-success" isOpen onDismiss={() => close()}>
            <ModalClose onClick={() => close()} />
            <ModalIllustration>
                <ModalImage src={successImage} alt={t`Storebox is enabled`} />
            </ModalIllustration>
            <ModalTitle id="storebox-enable-success">
                <Trans>Storebox is up and running</Trans>
            </ModalTitle>
            <ModalContent align="left" data-testid="storebox-user-enable-success">
                <Trans>
                    Remember, <b>not all stores support digital receipts yet</b>. We'll let you know
                    if we got the receipt instantly, or if you have to attach one.
                </Trans>
            </ModalContent>
            <ModalActions>
                <Button
                    variant="primary"
                    onClick={() => {
                        tracking.storeboxOnboardingActioned({step: 'success', action: 'completed'})
                        close()
                    }}
                >
                    <Trans>All done</Trans>
                </Button>
            </ModalActions>
        </Modal>
    )
}

export const Error = ({close}: {close: (dismiss?: boolean) => void}) => {
    useEffect(() => {
        tracking.storeboxOnboardingActioned({
            step: 'something_went_wrong',
            action: 'viewed',
        })
    }, [])
    return (
        <Modal aria-labelledby="storebox-enable-error" isOpen onDismiss={() => close(false)}>
            <ModalClose onClick={() => close()} />
            <ModalTitle id="storebox-enable-error">
                <Trans>Something went wrong</Trans>
            </ModalTitle>
            <ModalContent data-testid="storebox-user-enable-error">
                <Trans>An error occurred while enabling Storebox, try again later.</Trans>
            </ModalContent>
            <ModalActions>
                <Button
                    variant="primary"
                    onClick={() => {
                        tracking.storeboxOnboardingActioned({
                            step: 'something_went_wrong',
                            action: 'completed',
                        })
                        close(false)
                    }}
                >
                    <Trans>OK</Trans>
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default StoreboxUserEnableModalContainer
