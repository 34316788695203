import * as React from 'react'
import {Route} from 'react-router'

import {RegisterRedirect} from './register-redirect'

const RegisterFlowBookkeeper = React.lazy(
    async () => import('./screens/register-bookkeeper/register.bookkeeper'),
)
const RegisterFlowEmployee = React.lazy(
    async () => import('./screens/register-employee/register.employee'),
)
const RegisterInvitePage = React.lazy(async () => import('./screens/invite/invite'))
const RegisterVerifyPage = React.lazy(async () => import('./screens/verify/verify'))
const SignupError = React.lazy(async () => import('./screens/signup-error/signup-error'))
const TermsPage = React.lazy(async () => import('./screens/terms/terms'))
const SignupStatus = React.lazy(async () => import('./screens/signup-status'))

export const routes = (
    <>
        <Route index element={<RegisterRedirect />} />
        <Route path="status" element={<SignupStatus />} />
        <Route path="invite/:token" element={<RegisterInvitePage />} />
        <Route path="verify/:verifyToken" element={<RegisterVerifyPage />} />
        <Route path="user/not-allowed" element={<SignupError error="signup_not_allowed" />} />
        <Route path="user/sole-traders" element={<SignupError error="sole_trader" />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="bookkeeper/*" element={<RegisterFlowBookkeeper />} />
        <Route path="employee/*" element={<RegisterFlowEmployee />} />
    </>
)
