import Country from '@product-web/locale/country'

import AT from './at'
import BE from './be'
import DE from './de'
import Default from './default'
import DK from './dk'
import ES from './es'
import FI from './fi'
import FR from './fr'
import GB from './gb'
import IE from './ie'
import IT from './it'
import NL from './nl'
import NO from './no'
import PT from './pt'
import SE from './se'
import type {ICountryConfiguration} from './types'
import US from './us'

const countryConfigurationMap: Record<Country, ICountryConfiguration> = {
    [Country.AT]: AT,
    [Country.BE]: BE,
    [Country.BG]: Default,
    [Country.HR]: Default,
    [Country.CY]: Default,
    [Country.CZ]: Default,
    [Country.DK]: DK,
    [Country.EE]: Default,
    [Country.FI]: FI,
    [Country.FR]: FR,
    [Country.DE]: DE,
    [Country.GR]: Default,
    [Country.HU]: Default,
    [Country.IE]: IE,
    [Country.IT]: IT,
    [Country.LV]: Default,
    [Country.LT]: Default,
    [Country.LU]: Default,
    [Country.MT]: Default,
    [Country.NL]: NL,
    [Country.PL]: Default,
    [Country.PT]: PT,
    [Country.RO]: Default,
    [Country.SK]: Default,
    [Country.SI]: Default,
    [Country.ES]: ES,
    [Country.SE]: SE,
    [Country.NO]: NO,
    [Country.GB]: GB,
    [Country.US]: US,
}

export default countryConfigurationMap
