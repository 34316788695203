import {AdoptionCentreProvider} from './adoption-centre-context'
import {AdoptionCentrePrompt} from './adoption-centre-prompt'
import {AdoptionCentreWidget} from './adoption-centre-widget'
import {AdoptionDrawer} from './adoption-drawer'

export const AdoptionCentre = () => {
    return (
        <AdoptionCentreProvider>
            <AdoptionCentrePrompt />
            <AdoptionDrawer />
            <AdoptionCentreWidget />
        </AdoptionCentreProvider>
    )
}
