import {useLocation} from 'react-router'

import OutstandingFeesBanner from '@product-web/paywall/outstanding-fees-banner'
import usePaywallStatus from '@product-web/paywall/use-paywall-status'
import {getIsCompanyUser, useLoggedInUser} from '@product-web/user'
import {TrialBannerFlagContainer} from '@product-web-features/onboarding/trial/banner.container'

interface Props {
    hide?: boolean
}

const shownForPaths = [
    /^\/wallet/,
    /^\/analytics/,
    /^\/account/,
    /^\/people/,
    /^\/settings/,
    /^\/subscriptions/,
    /^\/invoices/,
]

/***
 * Displays a banner at the top of eligible pages (based on design / usability)
 * used for important account information or upselling
 */
export const AccountNotificationBanner = ({hide}: Props) => {
    const {pathname} = useLocation()
    const {paywallActive} = usePaywallStatus()
    const pathEligible = shownForPaths.find((path) => pathname.match(path))
    const user = useLoggedInUser()
    const isCompanyUser = getIsCompanyUser(user)

    if (hide || !pathEligible || !isCompanyUser) {
        return null
    }

    if (paywallActive) {
        return <OutstandingFeesBanner />
    }

    return <TrialBannerFlagContainer />
}
