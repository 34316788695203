import {Outlet, Route} from 'react-router-dom'

import {Box} from '@pleo-io/telescope'

import {useHideHelpCentre} from '@product-web-features/help-centre/help-centre-context'
import {Page} from '@product-web-features/ui-page/page'

import {AcademyContextProvider} from './components/academy-context/academy-context'
import {AcademyIndex} from './screens/academy-index/academy-index'
import {routes as introductionToPleoRoutes} from './screens/introduction-to-pleo/routes'
import {routes as thePleoProductRoutes} from './screens/the-pleo-product/routes'

export const routes = (
    <Route element={<AcademyRoutesWrapper />}>
        <Route index element={<AcademyIndex />} />
        <Route path="introduction-to-pleo">{introductionToPleoRoutes}</Route>
        <Route path="the-pleo-product">{thePleoProductRoutes}</Route>
    </Route>
)

function AcademyRoutesWrapper() {
    useHideHelpCentre()
    return (
        <AcademyContextProvider>
            <Page layout="wide" showNav>
                <Outlet />
                <Box pb={56} />
            </Page>
        </AcademyContextProvider>
    )
}
