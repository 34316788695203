import {t, Trans} from '@lingui/macro'
import qs from 'qs'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, NakedButton, Tabs, tokens, Tooltip} from '@pleo-io/telescope'
import {ArrowRight, Download} from '@pleo-io/telescope-icons'

import {dayjs} from '@product-web/dates/dayjs'
import {useFlags} from '@product-web/flags'
import {PageDrawer} from '@product-web/telescope-lab/page-drawer/page-drawer'
import {useIsDownloadEnabled} from '@product-web-features/budgets/budget-feature-flags'

import {TagBudgetMenu} from './tag-budget-menu'

import type {TagBudgetDetails} from '../../index.bff'
import {DATEPICKER_DATE_FORMAT} from '../../lib/constants'
import type {BudgetDrawerTabValues} from '../../lib/types'
import {useTagBudgetModals} from '../../lib/use-tag-budget-modals'
import {useTagBudgetTracking} from '../../lib/use-tag-budget-tracking'
import {TagBudgetDownloadExpensesModal} from '../tag-budget-download-expenses-modal'

const MAX_END_DATE = '2999-12-31'

export const TagBudgetDetailsHeader: React.FC<{
    budget: TagBudgetDetails
    tagGroups: string[]
    isDemoBudget: boolean
    hasOwnerAccess: boolean
    onClose?: () => void
    tabValue: BudgetDrawerTabValues
    setTabValue: React.Dispatch<React.SetStateAction<BudgetDrawerTabValues>>
}> = ({budget, tagGroups, hasOwnerAccess, isDemoBudget, onClose, tabValue, setTabValue}) => {
    const tracking = useTagBudgetTracking()
    const {modals} = useTagBudgetModals()
    const {isEnabled: hasDownloadsFlag, isLoading: isLoadingFeatureFlag} = useIsDownloadEnabled()

    const onDownloadExpenses = () => {
        modals.downloadExpenses.open()
    }

    if (isLoadingFeatureFlag) {
        return null
    }
    const shouldShowDownloadExpenses = hasDownloadsFlag && budget.sumSpent > 0

    return (
        <PageDrawer.Header>
            <PageDrawer.NavigationButtons>
                <PageDrawer.NavigationCloseButton onClick={onClose} />
            </PageDrawer.NavigationButtons>
            {!isDemoBudget ? (
                <HeaderContent {...{budget, tabValue, setTabValue, tagGroups}} />
            ) : (
                <Tooltip
                    content={t`After you create a budget, you will be able to see a list of its expenses`}
                >
                    <Button
                        variant="tertiary"
                        IconRight={ArrowRight}
                        onMouseEnter={() =>
                            tracking.demoBudgetActioned({
                                action: 'hovered',
                                source: 'budget_expenses_link',
                            })
                        }
                    >
                        <Trans>Budget Expenses</Trans>
                    </Button>
                </Tooltip>
            )}
            <Inline justifyContent="flex-end">
                {hasOwnerAccess && <TagBudgetMenu budget={budget} isDemoBudget={isDemoBudget} />}
                {!hasOwnerAccess && shouldShowDownloadExpenses && (
                    <Tooltip content={t`Download expenses`}>
                        <NakedButton onClick={onDownloadExpenses}>
                            <Download size={16} color={tokens.shade700} />
                        </NakedButton>
                    </Tooltip>
                )}
            </Inline>
            {shouldShowDownloadExpenses && (
                <TagBudgetDownloadExpensesModal
                    budgetId={budget.id!}
                    budgetName={budget.name!}
                    isOpen={modals.downloadExpenses.isOpen}
                    onCancel={modals.downloadExpenses.onCancel}
                    onClose={modals.downloadExpenses.onConfirm}
                />
            )}
        </PageDrawer.Header>
    )
}

const HeaderContent: React.FC<{
    budget: TagBudgetDetails
    tabValue: BudgetDrawerTabValues
    setTabValue: React.Dispatch<React.SetStateAction<BudgetDrawerTabValues>>
    tagGroups: string[]
}> = ({budget, tabValue, setTabValue, tagGroups}) => {
    const {invoicesInBudget} = useFlags()
    const endDate = budget.endDate

    const startDateFormatted = dayjs(budget.startDate).utc().format(DATEPICKER_DATE_FORMAT)
    const endDateFormatted = endDate
        ? dayjs(endDate).utc().format(DATEPICKER_DATE_FORMAT)
        : MAX_END_DATE
    const expensesNavParams = qs.stringify(
        {
            tagGroups,
            dateRange: {
                from: startDateFormatted,
                to: endDateFormatted,
            },
        },
        {arrayFormat: 'brackets', skipNulls: true},
    )

    if (invoicesInBudget) {
        return (
            <Tabs defaultValue={tabValue}>
                <Tabs.TriggerList aria-label={t`Budget overview`}>
                    <Tabs.Trigger value="general" onClick={() => setTabValue('general')}>
                        <Tabs.TriggerText text={t`General`} />
                    </Tabs.Trigger>
                    <Tabs.Trigger value="payments" onClick={() => setTabValue('payments')}>
                        <Tabs.TriggerText text={t`Payments`} />
                    </Tabs.Trigger>
                </Tabs.TriggerList>
            </Tabs>
        )
    }
    return (
        <Link
            as={RouterLink}
            to={{pathname: '/expenses', search: expensesNavParams}}
            IconRight={ArrowRight}
        >
            <Trans>Expenses</Trans>
        </Link>
    )
}
