import type {PostalAddress} from './styx'

export enum Status {
    created = 'CREATED',
    processing = 'PROCESSING',
    sent = 'SENT',
    received = 'RECEIVED',
}

export interface CardOrder {
    cardOwners?: Array<{employeeId: string | null; name: string}> | null
    address: PostalAddress
    amount: number
    companyId: string
    createdAt?: string
    deletedAt?: string | null
    deliveryEstimateMin?: string | null
    deliveryEstimateMax?: string | null
    embossLine: string
    employeeId: string
    id?: string
    shippingLabel?: string | null
    status: Status
    updatedAt?: string
    names?: string[]
    manufacturer?: Manufacturer
    trackingId?: string
}

export enum Manufacturer {
    MEGACARD = 'MEGACARD',
    NITECREST = 'NITECREST',
    IDEMIA = 'IDEMIA',
}
