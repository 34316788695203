import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Box, Text, tokens} from '@pleo-io/telescope'

import {PlanTypeName} from '@product-web/api-types/billing'
import * as Table from '@product-web/telescope-lab/table/table'

type FeatureComparisonTableProps = {
    label: string
    entries: ReactNode[][]
}

export const FeatureComparisonTable = ({label, entries}: FeatureComparisonTableProps) => (
    <>
        <Box paddingLeft={16} paddingBottom={24} paddingTop={48}>
            <Text variant="xlarge-accent">{label}</Text>
        </Box>
        <Table.Table
            caption={label}
            css={{outline: tokens.borderPrimary, borderRadius: tokens.arc8}}
        >
            <Table.Head>
                <Table.HeaderRow css={{borderTop: '0px'}}>
                    <Table.HeaderCell
                        scope="col"
                        css={{width: '300px', paddingLeft: tokens.spacing16}}
                    >
                        <Trans>Feature</Trans>
                    </Table.HeaderCell>
                    <CustomHeaderCell>{PlanTypeName.STARTER}</CustomHeaderCell>
                    <CustomHeaderCell>{PlanTypeName.ESSENTIAL}</CustomHeaderCell>
                    <CustomHeaderCell>{PlanTypeName.ADVANCED}</CustomHeaderCell>
                    <CustomHeaderCell>{PlanTypeName.BEYOND}</CustomHeaderCell>
                </Table.HeaderRow>
            </Table.Head>
            <Table.Body>
                {entries?.map((row, index) => (
                    <Table.Row key={`${label}+${index}`} css={{borderBottom: '0px'}}>
                        <Table.Cell css={{paddingLeft: tokens.spacing16}}>{row[0]}</Table.Cell>
                        <CustomTableCell>{row[1]}</CustomTableCell>
                        <CustomTableCell>{row[2]}</CustomTableCell>
                        <CustomTableCell>{row[3]}</CustomTableCell>
                        <CustomTableCell>{row[4]}</CustomTableCell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table.Table>
    </>
)

const CustomTableCell = styled(Table.Cell)`
    text-align: center;
`

const CustomHeaderCell = styled(Table.HeaderCell).attrs({scope: 'col'})`
    width: 15%;
    text-align: center;
`
