import Cookies from 'js-cookie'

import {stringToSupportedLanguage} from './string-to-supported-language'
import {SupportedLanguage} from './supported-language'

const ONE_YEAR = 365

export const SUPPORTED_LANGUAGE_LIST = [
    SupportedLanguage.EN,
    SupportedLanguage.DE,
    SupportedLanguage.DE_AT,
    SupportedLanguage.ES,
    SupportedLanguage.SV,
    SupportedLanguage.FR,
    SupportedLanguage.FR_BE,
    SupportedLanguage.DA,
    SupportedLanguage.FI,
    SupportedLanguage.NL,
    SupportedLanguage.NL_BE,
    SupportedLanguage.PT,
    SupportedLanguage.IT,
    SupportedLanguage.NO,
]

export const useSupportedLanguageList = (isEngSupported = true) =>
    SUPPORTED_LANGUAGE_LIST.filter((language) => {
        if (!isEngSupported) {
            return language !== SupportedLanguage.EN
        }
        return true
    })

export const i18nStorage = {
    set: (language: SupportedLanguage) =>
        Cookies.set('x-pleo-language', language, {path: '/', expires: ONE_YEAR}),
    get: () =>
        stringToSupportedLanguage(Cookies.get('x-pleo-language') ?? null, SUPPORTED_LANGUAGE_LIST),
}

export const getTranslationVersion = () => Cookies.get('translation-version') ?? null
