import {Outlet, Route} from 'react-router-dom'

import {useHideHelpCentre} from '@product-web-features/help-centre/help-centre-context'
import {Page} from '@product-web-features/ui-page/page'

import {OnboardingIndex} from './screens/onboarding-index/onboarding-index'
import {OnboardingType} from './screens/onboarding-type/onboarding-type'
import {PartnerSize} from './screens/partner-size/partner-size'
import {StartOnboarding} from './screens/start-onboarding/start-onboarding'

export const routes = (
    <>
        <Route element={<OnboardingRoutesWrapper />}>
            <Route index element={<OnboardingIndex />} />
            <Route path="start-onboarding" element={<StartOnboarding />} />
            <Route path="partner-size" element={<PartnerSize />} />
            <Route path="onboarding-type" element={<OnboardingType />} />
        </Route>
    </>
)

function OnboardingRoutesWrapper() {
    useHideHelpCentre()
    return (
        <Page layout="wide" showNav>
            <Outlet />
        </Page>
    )
}
