import {createRoutesFromElements, Route} from 'react-router-dom'

import {PageNotFound} from '@product-web/routes/page-not-found/page-not-found'
import {roleSets} from '@product-web/user'
import {
    erpRoutes,
    routes as accountingRoutes,
    settingsRoutes as accountingSettingsRoutes,
} from '@product-web-apps/accounting/routes'
import {topLevelLayoutRoutes as adminConsentTopLevelLayoutRoutes} from '@product-web-apps/admin-consent/routes'
import {routes as analyticsRoutes} from '@product-web-apps/analytics/routes'
import {routes as apiKeysSettingsRoutes} from '@product-web-apps/api-keys'
import {routes as billInvoicesRoutes} from '@product-web-apps/bill-invoices/routes'
import {settingsRoutes as billingSettingsRoutes} from '@product-web-apps/billing/routes'
import {routes as budgetRoutes} from '@product-web-apps/budgets/routes'
import {
    routes as cardsRoutes,
    settingsRoutes as cardsSettingsRoutes,
    suspiciousTransactionRoutes,
} from '@product-web-apps/cards/routes'
import {routes as companyOnboardingRoutes} from '@product-web-apps/company-onboarding/routes'
import {
    downloadRoutes as downloadAppsRoutes,
    routes as employeeOnboardingRoutes,
} from '@product-web-apps/employee-onboarding/routes'
import {routes as expensesRoutes} from '@product-web-apps/expenses/routes'
import {routes as exportRoutes} from '@product-web-apps/export/routes'
import {routes as fundsManagementRoutes} from '@product-web-apps/funds-management/routes'
import {settingsRoutes as guidelinesSettingsRoutes} from '@product-web-apps/guidelines/routes'
import {settingsRoutes as integrationsSettingsRoutes} from '@product-web-apps/integrations/routes'
import {
    routes as kycRoutes,
    settingsRoutes as kycSettingsRoutes,
} from '@product-web-apps/kyc/routes'
import {settingsRoutes as mileageSettingsRoutes} from '@product-web-apps/mileage/routes'
import {oauth2Routes} from '@product-web-apps/oauth/routes'
import {settingsRoutes as openApiSettingsRoutes} from '@product-web-apps/open-api/routes'
import {routes as partnerExperienceRoutes} from '@product-web-apps/partner-experience/routes'
import {routes as peopleRoutes} from '@product-web-apps/people/routes'
import {routes as purchaseOrdersRoutes} from '@product-web-apps/purchase-orders/routes'
import {routes as receiptDownloadRoutes} from '@product-web-apps/receipt-download/routes'
import {routes as recurringVendorsRoutes} from '@product-web-apps/recurring-vendors/routes'
import {routes as registerNewCompanyRoutes} from '@product-web-apps/register-new-company/routes'
import {routes as registerUserRoutes} from '@product-web-apps/register-user/routes'
import {
    ReimbursementsRoutesWrapper,
    routes as reimbursementsRoutes,
} from '@product-web-apps/reimbursements/routes'
import {settingsRoutes as reimbursementSettingsRoutes} from '@product-web-apps/reimbursements/settings-routes'
import {routes as requestsRoutes} from '@product-web-apps/requests/routes'
import {reviewsSettingsRoutes, routes as reviewRoutes} from '@product-web-apps/reviews/routes'
import {routes as settingsRoutes} from '@product-web-apps/settings/routes'
import {
    oauthRoutes as userAccountOauthRoutes,
    resetRoutes as userAccountResetRoutes,
    routes as userAccountManagementRoutes,
    topLevelLayoutRoutes as userAccountTopLevelLayoutRoutes,
    topLevelRoutes as userAccountTopLevelRoutes,
} from '@product-web-apps/user-account-management/routes'
import {routes as vendorCardsRoutes} from '@product-web-apps/vendor-cards/routes'
import {settingsRoutes as wellnessSettingsRoutes} from '@product-web-apps/wellness/routes'

import {Layout} from './layout'
import {Providers} from './providers'
import {redirectRoutes} from './static-redirects'
import TopLevelComponents from './top-level-components'
import TopLevelComponentsCompany from './top-level-components-company'

export const getRoutes = () =>
    createRoutesFromElements(
        <Route element={<Providers />}>
            <Route handle={{app: 'user-account-management'}}>{userAccountTopLevelRoutes}</Route>
            <Route element={<Layout />}>
                <Route element={<TopLevelComponents />}>
                    <Route handle={{app: 'user-account-management'}}>
                        {userAccountTopLevelLayoutRoutes}
                    </Route>
                    <Route path="/oauth" handle={{app: 'user-account-management'}}>
                        {userAccountOauthRoutes}
                    </Route>
                    <Route path="/reset" handle={{app: 'user-account-management'}}>
                        {userAccountResetRoutes}
                    </Route>
                    <Route path="/account" handle={{app: 'user-account-management'}}>
                        {userAccountManagementRoutes}
                    </Route>
                    <Route path="/partner" handle={{app: 'partner-experience'}}>
                        {partnerExperienceRoutes}
                    </Route>

                    <Route element={<TopLevelComponentsCompany />}>
                        <Route handle={{app: 'admin-consent'}}>
                            {adminConsentTopLevelLayoutRoutes}
                        </Route>
                        <Route path="/people" handle={{app: 'people'}}>
                            {peopleRoutes}
                        </Route>
                        <Route path="/oauth2" handle={{app: 'oauth2'}}>
                            {oauth2Routes}
                        </Route>
                        <Route path="/account/cards" handle={{app: 'cards'}}>
                            {cardsRoutes}
                        </Route>
                        <Route path="/receipts" handle={{app: 'receipt-download'}}>
                            {receiptDownloadRoutes}
                        </Route>
                        <Route path="/expenses" handle={{app: 'expenses'}}>
                            {expensesRoutes}
                        </Route>
                        <Route path="/review" handle={{app: 'review'}}>
                            {reviewRoutes}
                        </Route>
                        <Route path="/export" handle={{app: 'export'}}>
                            {exportRoutes}
                        </Route>
                        <Route path="/erp" handle={{app: 'accounting'}}>
                            {erpRoutes}
                        </Route>
                        <Route path="/callback" handle={{app: 'accounting'}}>
                            {accountingRoutes}
                        </Route>
                        <Route path="/analytics" handle={{app: 'analytics'}}>
                            {analyticsRoutes}
                        </Route>
                        <Route
                            path="/reimbursements"
                            handle={{app: 'reimbursements'}}
                            element={<ReimbursementsRoutesWrapper />}
                        >
                            {reimbursementsRoutes}
                        </Route>
                        <Route path="/requests" handle={{app: 'requests'}}>
                            {requestsRoutes}
                        </Route>
                        <Route path="/wallet" handle={{app: 'funds-management'}}>
                            {fundsManagementRoutes}
                        </Route>
                        <Route path="/budgets" handle={{app: 'budgets'}}>
                            {budgetRoutes}
                        </Route>
                        <Route path="/invoices" handle={{app: 'bill-invoices'}}>
                            {billInvoicesRoutes}
                        </Route>
                        <Route path="/purchase-orders" handle={{app: 'purchase-orders'}}>
                            {purchaseOrdersRoutes}
                        </Route>

                        <Route path="/onboarding" handle={{app: 'onboarding'}}>
                            {companyOnboardingRoutes}
                        </Route>
                        <Route path="/recurring-vendors" handle={{app: 'recurring-vendors'}}>
                            {recurringVendorsRoutes}
                        </Route>
                        <Route path="/vendor-cards" handle={{app: 'vendor-cards'}}>
                            {vendorCardsRoutes}
                        </Route>
                        <Route path="/company-verification" handle={{app: 'kyc'}}>
                            {kycRoutes}
                        </Route>
                        <Route path="/getstarted" handle={{app: 'employee-onboarding'}}>
                            {employeeOnboardingRoutes}
                        </Route>
                        <Route path="/download" handle={{app: 'employee-onboarding'}}>
                            {downloadAppsRoutes}
                        </Route>
                        <Route path="/suspicious-transaction" handle={{app: 'cards'}}>
                            {suspiciousTransactionRoutes}
                        </Route>
                        <Route path="/register" handle={{app: 'register-user'}}>
                            {registerUserRoutes}
                            <Route path="new-company" handle={{app: 'register-new-company'}}>
                                {registerNewCompanyRoutes}
                            </Route>
                        </Route>
                        <Route path="/settings" handle={{app: 'settings'}}>
                            <Route path="accounting" handle={{app: 'accounting'}}>
                                {accountingSettingsRoutes}
                            </Route>
                            <Route path="guidelines" handle={{app: 'guidelines'}}>
                                {guidelinesSettingsRoutes}
                            </Route>
                            <Route path="mileage" handle={{app: 'mileage'}}>
                                {mileageSettingsRoutes}
                            </Route>
                            <Route path="wellness" handle={{app: 'wellness'}}>
                                {wellnessSettingsRoutes}
                            </Route>
                            <Route path="openapi" handle={{app: 'open-api'}}>
                                {openApiSettingsRoutes}
                            </Route>
                            <Route path="api-keys" handle={{app: 'api-keys'}}>
                                {apiKeysSettingsRoutes}
                            </Route>
                            <Route path="review-overview" handle={{app: 'reviews'}}>
                                {reviewsSettingsRoutes}
                            </Route>
                            <Route path="integrations" handle={{app: 'integrations'}}>
                                {integrationsSettingsRoutes}
                            </Route>
                            <Route path="reimbursements" handle={{app: 'pocket'}}>
                                {reimbursementSettingsRoutes}
                            </Route>
                            <Route path="general">
                                <Route path="billing" handle={{app: 'billing'}}>
                                    {billingSettingsRoutes}
                                </Route>
                                <Route path="company" handle={{app: 'kyc'}}>
                                    {kycSettingsRoutes}
                                </Route>
                                <Route path="card-defaults" handle={{app: 'cards'}}>
                                    {cardsSettingsRoutes}
                                </Route>
                            </Route>
                            {settingsRoutes}
                        </Route>
                        {/* the following line is used by our code-generator to set up new routes
                        new-routes-anchor */}
                    </Route>
                </Route>
                <Route
                    path="/"
                    handle={{auth: true, allowedRoles: roleSets.all}}
                    lazy={async () => import('./index-route')}
                />
                <Route path="*" element={<PageNotFound />} />
            </Route>
            {redirectRoutes}
        </Route>,
    )
