import type {FC} from 'react'
import {useEffect} from 'react'

import {bff} from './bff-hooks'
import {MultiEntityNavigation} from './multi-entity/multi-entity-navigation'
import {Navigation} from './navigation'
import {useInternalNavigationContext} from './navigation-internal-provider'
import {NavigationLoading} from './navigation-loading'

const isMultiEntityNavigation = (type?: string) => {
    return type !== 'default'
}

// This component decides what type of navigation should we render
// based on LD flags and BFF response
export const NavigationTypeChooser: FC = () => {
    const {data, error} = bff.navigation.getNavigationTypeChooserData.useQuery(undefined, {
        keepPreviousData: true,
    })

    const {type, companyId} = data || {}

    const {setIsEntitySwitcherVisible, setSelectedEntityId, setCanChangeEntity} =
        useInternalNavigationContext()

    useEffect(() => {
        if (error || !isMultiEntityNavigation(type)) {
            // Hide the entity switcher if we're going to show the default navigation
            setIsEntitySwitcherVisible(false)
        }
    }, [error, type, setIsEntitySwitcherVisible])

    useEffect(() => {
        setSelectedEntityId(companyId ?? null)
        setCanChangeEntity(true)
    }, [companyId])

    if (error) {
        return <Navigation />
    }

    if (!type) {
        return <NavigationLoading />
    }

    if (!isMultiEntityNavigation(type)) {
        return <Navigation />
    }

    return <MultiEntityNavigation />
}
