import type {SupportedLanguage} from './supported-language'

export const stringToSupportedLanguage = (
    language: string | null,
    supportedLanguageList: SupportedLanguage[],
): SupportedLanguage | null =>
    supportedLanguageList.find(
        (supportedLanguage) =>
            language?.toLowerCase() === supportedLanguage.toString().toLowerCase(),
    ) || null
