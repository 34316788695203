import React from 'react'
import {Route} from 'react-router-dom'

const ResetAuthAppPage = React.lazy(async () => import('./screens/auth-app/auth-app'))
const ResetPasscodePage = React.lazy(async () => import('./screens/passcode/v2/passcode-reset'))
const ResetPhonePage = React.lazy(async () => import('./screens/phone/phone'))
const SelectResetMethodPage = React.lazy(
    async () => import('./screens/select-reset-method/select-reset-method'),
)
export const routes = (
    <>
        <Route path="passcode/:token" element={<ResetPasscodePage />} />
        <Route path="passcode" element={<ResetPasscodePage />} />
        <Route path="tfa/:token" element={<SelectResetMethodPage />} />
        <Route path="phone/:token" element={<ResetPhonePage />} />
        <Route path="authapp/:token" element={<ResetAuthAppPage />} />
    </>
)
