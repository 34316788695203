import {t} from '@lingui/macro'
import useSWR from 'swr'

import {request, RequestScope} from '@product-web/api'
import {getDeimos} from '@product-web/api-deimos/helpers'
import type {
    Application,
    ApplicationResponse,
    AtuValidationRequest,
    PleoReserveApplicationRequest,
    PleoReserveApplicationStatusResponse,
    ValidationResult,
} from '@product-web/bff-moons/generated/kale'
import type {
    PleoReserveEligibility,
    ProductMetadataResponse,
} from '@product-web/bff-moons/generated/narvi'
import config from '@product-web/config'
import {useToaster} from '@product-web/toaster'
import {getIsBookkeeper, getIsCompanyOwner, useLoggedInUser} from '@product-web/user'

import {useWalletBalance} from '../use-wallet-balance'

const baseUrl = config.endpoints.api

export const usePleoReserveEligibility = () => {
    const user = useLoggedInUser()
    const isOwner = getIsCompanyOwner(user)
    const isBookkeeper = getIsBookkeeper(user)
    const isEnabled = user.companyId && (isOwner || isBookkeeper)
    const result = useSWR<PleoReserveEligibility>(
        isEnabled
            ? `rest/v1/credit-portfolio/companies/${user.companyId}/eligibility/product/pleo-reserve`
            : null,
        getDeimos,
    )
    return {
        ...result,
        isLoading: isEnabled && !result.error && !result.data,
    }
}

export const useHasPleoReserveEnabled = () => {
    const {data, isLoading} = usePleoReserveEligibility()
    return {
        hasPleoReserveEnabled: Boolean(data?.enabled),
        isLoading,
    }
}

export const useIsEligibleForPleoReserve = () => {
    const {data, isLoading} = usePleoReserveEligibility()
    return {
        isEligibleForPleoReserve: Boolean(data?.eligibility === 'ELIGIBLE'),
        isLoading,
    }
}

export const usePleoReserveTerms = () => {
    const {data} = usePleoReserveEligibility()
    return data?.terms
}

export const usePleoReserveActiveLimit = () => {
    const {walletBalance, isLoading: isWalletBalanceLoading} = useWalletBalance()
    const {hasPleoReserveEnabled, isLoading: isLoadingReserveEnabled} = useHasPleoReserveEnabled()

    const isLoading = isWalletBalanceLoading || isLoadingReserveEnabled

    const activeReserveLimit = {
        currency: walletBalance?.currency,
        value: walletBalance?.overdraftLimit,
    }

    // Set active credit limit to 0 if it's not enabled by Reserve
    if (activeReserveLimit && !hasPleoReserveEnabled) {
        return {
            isLoading,
            activeReserveLimit: {
                ...activeReserveLimit,
                value: 0,
            },
        }
    }
    return {
        isLoading,
        activeReserveLimit,
    }
}

export const useIsReserveAvailable = () => {
    const {hasPleoReserveEnabled, isLoading: isLoadingHasPleoReserveEnabled} =
        useHasPleoReserveEnabled()
    const {isEligibleForPleoReserve, isLoading: isLoadingIsEligibleForPleoReserve} =
        useIsEligibleForPleoReserve()

    return {
        isReserveAvailable: hasPleoReserveEnabled || isEligibleForPleoReserve,
        isLoading: isLoadingHasPleoReserveEnabled || isLoadingIsEligibleForPleoReserve,
    }
}

export const usePleoReserveApplicationStatus = () => {
    const user = useLoggedInUser()
    const result = useSWR<PleoReserveApplicationStatusResponse>(
        `rest/v1/pleo-reserve/companies/${user.companyId}/apply/status`,
        getDeimos,
    )
    const isLoading = !result.data && !result.error
    return {
        ...result,
        isLoading,
        status: result?.data?.status,
    }
}

export const useActivatePleoReserve = () => {
    const user = useLoggedInUser()
    const pleoReserveEligibility = usePleoReserveEligibility()

    // We use pre-validation check so that user doesn't have to enter pin code in case they fail validation
    const validateActivationPleoReserve = async (payload: AtuValidationRequest) => {
        return request<ValidationResult>(
            `${baseUrl}/rest/v1/pleo-reserve/companies/${user?.companyId!}/validate`,
            {
                auth: 'user',
                method: 'POST',
                body: payload,
            },
        )
    }
    const activatePleoReserve = async (payload: PleoReserveApplicationRequest) => {
        await request<ApplicationResponse>(
            `${baseUrl}/rest/v1/pleo-reserve/companies/${user?.companyId!}/apply`,
            {
                auth: 'elevated',
                method: 'POST',
                body: payload,
                scope: RequestScope.PERMISSION,
            },
        )
        // "Enabled" state of Pleo Reserve changes
        pleoReserveEligibility.mutate()
    }
    // We use pre-validation check so that user doesn't have to enter pin code in case they fail validation
    const validateDeactivationPleoReserve = async (payload: AtuValidationRequest) => {
        return request<ValidationResult>(
            `${baseUrl}/rest/v1/pleo-reserve/companies/${user?.companyId!}/deactivate/validate`,
            {
                auth: 'user',
                method: 'POST',
                body: payload,
            },
        )
    }
    const deactivatePleoReserve = async (payload: {reason: string}) => {
        await request<any>(
            `${baseUrl}/rest/v1/pleo-reserve/companies/${user?.companyId!}/deactivate`,
            {
                auth: 'elevated',
                method: 'POST',
                body: payload,
                scope: RequestScope.PERMISSION,
            },
        )
        // "Enabled" state of Pleo Reserve changes
        pleoReserveEligibility.mutate()
    }
    return {
        activatePleoReserve,
        deactivatePleoReserve,
        validateDeactivationPleoReserve,
        validateActivationPleoReserve,
    }
}

export const useSubmitPleoReserveChanges = () => {
    const {showToast} = useToaster()

    const {
        validateActivationPleoReserve,
        activatePleoReserve,
        deactivatePleoReserve,
        validateDeactivationPleoReserve,
    } = useActivatePleoReserve()

    async function submitPleoReserveChanges({
        pleoReserveEnabled,
        autoTopUpThreshold,
        autoTopUpAmount,
        requestedAmount,
    }: {
        pleoReserveEnabled: boolean
        autoTopUpThreshold: number
        autoTopUpAmount: number
        requestedAmount?: number | undefined
    }) {
        if (pleoReserveEnabled) {
            try {
                const response = await validateActivationPleoReserve({
                    autoTopUpThreshold,
                    autoTopUpAmount,
                    requestedAmount,
                })
                if (response.outcome === 'ERROR') {
                    throw Error(
                        `Pleo Reserve activation validation failed: ${response.messages.join(
                            ', ',
                        )}`,
                    )
                }
                await activatePleoReserve({
                    autoTopUpThreshold,
                    autoTopUpAmount,
                    requestedAmount,
                })
            } catch (error) {
                showToast(t`Failed to activate Pleo Reserve.`, {level: 'error'})
                throw error
            }
        } else {
            try {
                const response = await validateDeactivationPleoReserve({
                    autoTopUpThreshold,
                    autoTopUpAmount,
                })
                if (response.outcome === 'ERROR') {
                    throw Error(
                        `Pleo Reserve deactivation validation failed: ${response.messages.join(
                            ', ',
                        )}`,
                    )
                }
                await deactivatePleoReserve({
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    reason: 'Company admin deactivation via Wallet page.',
                })
            } catch (error) {
                showToast(t`Failed to deactivate Pleo Reserve.`, {level: 'error'})
                throw error
            }
        }
    }

    return {submitPleoReserveChanges}
}

/**
 * Checks whether company is part of the Reserve for Onboarding AB test.
 * These companies are offered Reserve instead of regular Wallet top-up and
 * see slightly different UI in the onboarding Pleo Guide and in the Wallet.
 */
export const usePleoReserveOnboardingFlow = () => {
    const {data, isLoading} = usePleoReserveEligibility()
    return {
        hasReserveOnboardingFlow: Boolean(data?.metadata?.useNewReserveOnboardingFlow),
        isLoading,
    }
}

const useReserveApplication = () => {
    const user = useLoggedInUser()
    const isOwner = getIsCompanyOwner(user)
    const isBookkeeper = getIsBookkeeper(user)
    const isEnabled = user.companyId && (isOwner || isBookkeeper)
    const result = useSWR<Application[]>(
        isEnabled ? `/rest/v2/credit/applications/companies/${user.companyId}/reserve` : null,
        getDeimos,
    )
    const applications = (result.data ?? []).sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    // have to assert type here otherwise TS doesn't think latestApplication could be undefined
    const latestApplication = applications[0] as Application | undefined

    return {
        ...result,
        data: latestApplication,
        isLoading: isEnabled && !result.error && !result.data,
    }
}

export const useReserveMetadata = () => {
    const user = useLoggedInUser()
    const isOwner = getIsCompanyOwner(user)
    const isBookkeeper = getIsBookkeeper(user)
    const isEnabled = user.companyId && (isOwner || isBookkeeper)
    const result = useSWR<ProductMetadataResponse>(
        isEnabled ? `rest/v1/credit-portfolio/product/metadata` : null,
        getDeimos,
    )

    const data = result.data?.productMetadata?.find(
        (productMetadata) => productMetadata.productName === 'RESERVE',
    )

    return {
        ...result,
        data,
        isLoading: isEnabled && !result.error && !result.data,
    }
}

export const useReserveLimits = () => {
    const {reserveLimit, currency} = usePleoReserveTerms() ?? {}
    const {data, isLoading} = useReserveMetadata()
    const {activeReserveLimit} = usePleoReserveActiveLimit()
    const {data: reserveApplication} = useReserveApplication()
    const {walletBalance} = useWalletBalance()
    const currentBalance = walletBalance?.current ?? 0

    const minLimit =
        data?.defaultLimitByCurrency?.find((limit) => limit.currency === currency)?.limit ?? 0
    // actual minimum Reserve limit must be higher than current negative balance
    const minLimitActual = Math.max(minLimit, currentBalance * -1)
    const maxLimit = reserveLimit ?? 0
    const pendingLimit =
        reserveApplication?.status === 'PENDING' ? reserveApplication.amountRequested : undefined
    const activeLimit = activeReserveLimit?.value ?? 0

    const isCustomLimitAvailable = minLimit > 0 && maxLimit > minLimit

    return {
        isLoading,
        minLimit,
        minLimitActual,
        maxLimit,
        activeLimit,
        pendingLimit,
        currency,
        isCustomLimitAvailable,
    }
}

/**
 * Triggers backend eligibility checks to determine whether user will be part of the Onboarding AB test
 */
export const calculateReserveTestEligibility = async (companyId: string) =>
    request(`${baseUrl}/rest/v1/credit-portfolio/abtest/product-test/${companyId}/apply`, {
        auth: 'user',
        method: 'POST',
    })
