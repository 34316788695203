import type {FC} from 'react'
import type React from 'react'

import {theme} from '@product-web/styles/theme'

interface PleoLogoProps extends React.SVGProps<SVGSVGElement> {
    testId?: string
}

export const PleoLogo: FC<PleoLogoProps> = ({testId, width, height, fill, ...props}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-testid={testId}
            viewBox="0 0 656 632"
            width={width}
            height={height}
            fill={fill}
            data-generic-ui="logo-pleo"
            {...props}
        >
            <g>
                <path d="m125.69,74.73c0,17.34 -14.06,31.41 -31.41,31.41l-51.02,0l0,-62.81l51.02,0c17.35,-0.01 31.41,14.06 31.41,31.4m44.78,0.21l0,-0.08c0,-41.1 -33.32,-74.41 -74.41,-74.41l-95.86,0l0,211.44l43.07,0l0,-62.53l52.8,0c41.09,0 74.4,-33.32 74.4,-74.42m113.01,392.06l155.72,0l0,-42.94l-112.79,0l0,-34.81c16.15,-2.46 32.91,-6.78 50.13,-13.04l20.98,-7.63l-15.26,-41.97l-20.98,7.63c-12.53,4.56 -24.12,7.74 -34.87,9.82l0,-45.86l112.79,0l0,-42.94l-155.72,0l0,91.35c-26.22,-2.5 -44.62,-12.83 -57.01,-23.7c-21.88,-19.19 -44.65,-57.73 -28.81,-132.37l4.63,-21.84l-43.68,-9.27l-4.63,21.84c-16.08,75.82 -1.19,136.41 43.06,175.21c18.19,15.95 39.69,26.52 63.7,31.62c7.35,1.56 14.95,2.58 22.75,3.11l0,75.79l-0.01,0zm329.82,53.38c0,9.63 -1.38,18.56 -4.14,26.81c-2.76,8.24 -6.72,15.34 -11.89,21.3c-5.17,5.96 -11.4,10.62 -18.7,13.99c-7.3,3.37 -15.48,5.05 -24.56,5.05c-9.08,0 -17.29,-1.69 -24.64,-5.05c-7.36,-3.37 -13.62,-8.03 -18.79,-13.99c-5.17,-5.95 -9.13,-13.05 -11.89,-21.3c-2.76,-8.24 -4.13,-17.18 -4.13,-26.81c0,-9.62 1.38,-18.56 4.13,-26.8c2.76,-8.24 6.72,-15.37 11.89,-21.39c5.17,-6.01 11.43,-10.71 18.79,-14.08c7.35,-3.37 15.57,-5.06 24.64,-5.06c9.08,0 17.26,1.69 24.56,5.06c7.29,3.37 13.53,8.06 18.7,14.08c5.17,6.02 9.13,13.15 11.89,21.39c2.76,8.24 4.14,17.17 4.14,26.8m42.5,0.05c0,-15.77 -2.36,-30.4 -7.1,-43.91c-4.73,-13.5 -11.53,-25.18 -20.4,-35.04c-8.87,-9.85 -19.57,-17.54 -32.09,-23.07c-12.52,-5.52 -26.57,-8.28 -42.14,-8.28c-15.58,0 -29.67,2.76 -42.29,8.28c-12.62,5.52 -23.36,13.21 -32.24,23.07c-8.87,9.86 -15.67,21.54 -20.4,35.04c-4.73,13.51 -7.1,28.14 -7.1,43.91c0,15.77 2.36,30.41 7.1,43.92c4.73,13.51 11.53,25.13 20.4,34.89c8.87,9.76 19.62,17.4 32.24,22.92c12.61,5.51 26.71,8.28 42.29,8.28c15.57,0 29.62,-2.77 42.14,-8.28c12.51,-5.52 23.21,-13.16 32.09,-22.92c8.87,-9.76 15.67,-21.39 20.4,-34.89c4.74,-13.5 7.1,-28.14 7.1,-43.92" />
            </g>
        </svg>
    )
}

PleoLogo.defaultProps = {
    width: 80,
    height: 77,
    fill: theme.colors.shade900,
}
