import React, {useContext, useState} from 'react'

import tracking from '@product-web/analytics'

const DRAWER_ID = 'adoption-drawer'

type AdoptionCentreContextType = {
    DRAWER_ID: string
    isDrawerOpen: boolean
    setDrawerOpen: (open: boolean) => void
}

const AdoptionCentreContext = React.createContext<AdoptionCentreContextType>({
    DRAWER_ID,
    isDrawerOpen: false,
    setDrawerOpen: () => {},
})

export const AdoptionCentreProvider: React.FC = ({children}) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false)

    return (
        <AdoptionCentreContext.Provider
            value={{
                DRAWER_ID,
                isDrawerOpen,
                setDrawerOpen: (open: boolean) => {
                    setDrawerOpen(open)
                    tracking.adoptionEssentialsCentreActioned({action: open ? 'opened' : 'closed'})
                },
            }}
        >
            {children}
        </AdoptionCentreContext.Provider>
    )
}

export const useAdoptionCentre = () => {
    return useContext(AdoptionCentreContext)
}
