/**
 * Check if the provided parameter is non nullable by checking that it's not `null` and `undefined`.
 *
 * @example
 * ```ts
 * const nullableNumbers = [0, undefined, 1, 2, null, 3] as const
 * const nonNullableNumbers = nullableNumbers.filter(isNonNullable)
 *     // ^? (0 | 1 | 2 | 3)[]
 * ```
 * @param value - The value to check if non-nullable
 * @returns - True if the value is non-nullable, false otherwise
 */
export function isNonNullable<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
}
