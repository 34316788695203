import {t} from '@lingui/macro'
import {useEffect, useState} from 'react'

import {Accordion, Link, List, Stack, Text, tokens} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'

import {LearnMoreBox, StyledListItem} from './styles'

interface AdoptionTasksListProps {
    firstTask: string // triggers first task to display
    children: React.ReactNode // task list component (e.g. <ExportTasksList/>)
}

export const AdoptionTasksList = ({firstTask, children}: AdoptionTasksListProps) => {
    const [accordionValue, setAccordionValue] = useState([firstTask])

    return (
        <Accordion
            type="multiple"
            value={accordionValue}
            onValueChange={(newValues) => setAccordionValue(newValues)}
        >
            <Stack space={8}>{children}</Stack>
        </Accordion>
    )
}

interface taskProps {
    item: number
    task: string
    accordionId: string
    description: string | JSX.Element
    steps: (string | JSX.Element)[]
    listStyle: 'number' | 'bullet' | 'none'
    learnMoreText?: string
    learnMoreHref?: string
    guideName: string
    trackOpen?: boolean
}

export const AdoptionTask = ({
    item,
    task,
    accordionId,
    description,
    steps,
    listStyle,
    learnMoreText,
    learnMoreHref,
    guideName,
    trackOpen,
}: taskProps) => {
    const [isOpen, setIsOpen] = useState(trackOpen)

    useEffect(() => {
        if (trackOpen) {
            tracking.adoptionEssentialsCentreActioned({
                action: 'viewed_guide',
                guide: guideName,
                guide_task: accordionId,
            })
        }
    }, [])

    const onHelpLinkClick = () => {
        tracking.adoptionEssentialsCentreActioned({
            action: 'link_clicked',
            guide: guideName,
            guide_task: accordionId,
            target_url: learnMoreHref,
        })
    }

    const onTaskClick = () => {
        if (!isOpen) {
            tracking.adoptionEssentialsCentreActioned({
                action: 'viewed_guide',
                guide: guideName,
                guide_task: accordionId,
            })
        }
        setIsOpen(!isOpen)
    }

    return (
        <Accordion.Item value={accordionId}>
            <Accordion.Header
                heading={`${item}. ${task}`}
                headingLevel="h2"
                onClick={() => onTaskClick()}
            />
            <Accordion.Content>
                <Text variant="medium-default" align="left">
                    {description}
                </Text>
                <List listStyle={listStyle} css={{marginTop: tokens.spacing20}}>
                    {steps.map((step, index) => {
                        return <StyledListItem key={index}>{step}</StyledListItem>
                    })}
                </List>
                {learnMoreText && (
                    <LearnMoreBox>
                        <Text>
                            {t`Learn more about`}{' '}
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={learnMoreHref}
                                onClick={onHelpLinkClick}
                            >
                                {learnMoreText}
                            </Link>
                        </Text>
                    </LearnMoreBox>
                )}
            </Accordion.Content>
        </Accordion.Item>
    )
}

interface StepLinkProps {
    startText: string
    linkText: string
    endText?: string
    pathname: string
    search?: string
    guideName: string
    taskId: string
}

export const StepLink = ({
    startText,
    linkText,
    endText,
    pathname,
    search,
    guideName,
    taskId,
}: StepLinkProps) => {
    const targetUrl = pathname + (search ? `${search}` : '')

    const handleTracking = () => {
        tracking.adoptionEssentialsCentreActioned({
            action: 'link_clicked',
            guide: guideName,
            guide_task: taskId,
            target_url: targetUrl,
        })
    }

    return (
        <Text>
            {startText}{' '}
            <Link href={targetUrl} onClick={() => handleTracking()}>
                {linkText}{' '}
            </Link>
            {endText}
        </Text>
    )
}
