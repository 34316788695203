// NOTE: this is used by both analytics and error tracking.
export const SEARCH_PARAMS_TO_REDACT = new Set([
    // Gmail/Outlook auth code (readonly) to Segment when redirect back from Gmail/Outlook oAuth page happens
    'code',

    // These used to be part of the signup flow, but are generic enough to be redacted
    'email',
    'first_name',
    'last_name',
    'firstName',
    'lastName',
])

/**
 * Redact sensitive query params from a URL or path.
 *
 * Ideally our query strings should not contain sensitive data or PII, but we have
 * had incidents in the past where this was not the case.
 *
 * @param pathOrUrl - URL or path
 * @returns URL or path with sensitive query params redacted
 */
export function redactUrlParams(pathOrUrl: string): string {
    const url = new URL(pathOrUrl, window.location.origin)

    if (!url.search) {
        return pathOrUrl
    }

    url.searchParams.forEach((_, key) => {
        if (SEARCH_PARAMS_TO_REDACT.has(key)) {
            url.searchParams.set(key, '<redacted>')
        }
    })

    // return a path if pathOrUrl is a path
    const origin = pathOrUrl.startsWith('http') ? url.origin : ''

    return decodeURI(origin + url.pathname + url.search)
}
