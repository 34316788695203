enum Locale {
    de_DE = 'de-DE', // German (Germany)
    de_AT = 'de-AT', // German (Austria)
    da_DK = 'da-DK', // Danish (Denmark)
    es_ES = 'es-ES', // Spanish (Spain)
    en_IE = 'en-IE', // English (Ireland)
    en_GB = 'en-GB', // English (United Kingdom)
    sv_SE = 'sv-SE', // Swedish (Sweden)
    fr_FR = 'fr-FR', // French (France)
    fr_BE = 'fr-BE', // French (Belgium)
    fi_FI = 'fi-FI', // Finnish (Finland)
    nl_NL = 'nl-NL', // Dutch (Netherlands)
    nl_BE = 'nl-BE', // Dutch (Belgium)
    pt_PT = 'pt-PT', // Portuguese (Portugal)
    it_IT = 'it-IT', // Italian (Italy)
    nb_NO = 'nb-NO', // Norwegian Bokmål (Norway)
    nn_NO = 'nn-NO', // Norwegian Nynorsk (Norway)
}

export default Locale
