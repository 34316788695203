import React from 'react'

/**
 * Allows to detect and monitor if a given media query string applies
 * @param query Media query string to check the match for
 * @returns A flag indicating if the document matches the query string
 * @example
 * const isSmallScreen = useMediaQuery('(max-width: 767px')
 */
export const useMediaQuery = (query: string) => {
    const [queryMatches, setQueryMatches] = React.useState(false)

    React.useLayoutEffect(() => {
        let mounted = true
        const mediaQueryList = window.matchMedia(query)

        const onChange = ({matches}: MediaQueryListEvent) => {
            if (mounted) {
                setQueryMatches(matches)
            }
        }
        // needed for backward compatibility with Safari 13
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', onChange)
        } else {
            mediaQueryList.addListener(onChange)
        }
        setQueryMatches(mediaQueryList.matches)

        return () => {
            mounted = false
            // needed for backward compatibility with Safari 13
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener('change', onChange)
            } else {
                mediaQueryList.removeListener(onChange)
            }
        }
    }, [query])

    return queryMatches
}
