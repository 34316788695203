import * as React from 'react'
import {Route} from 'react-router'

import {roleSets} from '@product-web/user'

const OpenAPIPage = React.lazy(async () => import('./screens/open-api/open-api'))
const CreateTokenPage = React.lazy(async () => import('./screens/open-api/token-create'))

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}>
        <Route index element={<OpenAPIPage />} />
        <Route path="create-token" element={<CreateTokenPage />} />
    </Route>
)
