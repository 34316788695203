import React from 'react'
import type {SWRResponse} from 'swr/dist/types'

import {request, RequestAuth} from '@product-web/api'
import * as apiAuthUser from '@product-web/api-auth/user'
import * as apiDeimosEmployee from '@product-web/api-deimos/employee'
import * as apiDeimosUser from '@product-web/api-deimos/user'
import {forceSessionRefresh} from '@product-web/auth--session/operations'
import config from '@product-web/config'
import type {SupportedLanguage} from '@product-web/i18n'
import type {UserData} from '@product-web/user-types'

// We are using a separate context for user mutations, to aid testability
// - you won't have to mock all those methods when you component doesn't use mutations
// It's quite rare to need these, as opposed to the user data that almost every page needs
export const UserMutationsContext = React.createContext<ReturnType<typeof getMutations>>({
    forceRefetchUser: async () => Promise.resolve(),
    refetchUser: async () => Promise.resolve(undefined),
    updateAvatar: async () => Promise.resolve(),
    updateProfile: async () => Promise.resolve(),
    updateLanguage: async () => Promise.resolve(),
    setEmployeeStateKey: async () => Promise.resolve(),
    acceptTerms: async () => Promise.resolve(),
})

// These functions wrap API calls that mutate data that is returned from the user route
// It runs those calls, optimistically updates the cached user data and triggers a refetch
// of the user data from the API.

// We are wrapping these methods in a function that injected a SWR mutate callback to them
// That way we don't have to worry about the caching key, it's already bound to the mutate method
export function getMutations(
    query: {companyId?: string},
    mutate: SWRResponse<UserData, Error>['mutate'],
) {
    /**
     * Forces a refetch of user's information from the server
     * @returns A promise that resolves when the refetch is complete
     */
    async function refetchUser(): Promise<UserData | undefined> {
        return await mutate()
    }

    /**
     * Force fetch with 'X-Auth-Cache' ignore in order to bypass auth cache when fetching on deimos.
     * Deimos has caching auth for 1 minute by default
     */

    async function forceRefetchUser(queryOverrides?: {companyId?: string}) {
        const response = await request(`${config.endpoints.api}/rest/v1/user`, {
            auth: RequestAuth.USER,
            headers: {
                'X-Auth-Cache': 'ignore',
            },
            method: 'GET',
            query: {
                ...(queryOverrides ? queryOverrides : query),
                skipEmployeeCreation: true,
            },
        })

        await mutate(response, false)
    }

    /**
     * Uploads and updates new avatar for a user, updates the cached user object
     * @param file The uploaded avatar image file (as it's received from HTML file input)
     * @param previewSrc Data encoded URL that can be used to display the new image right away
     * @returns A promise that resolves when the mutation is complete
     */
    async function updateAvatar(file: File, previewSrc: string | null) {
        await apiDeimosUser.updateAvatar(file)

        // We temporarily set the `previewSrc` data URI as the avatar, which gets
        // replaced with the remote file location after the user data is re-fetched by SWR
        const avatar = {url: previewSrc || ''}

        await mutate(
            (cachedUser) =>
                cachedUser && {
                    ...cachedUser,
                    employee: cachedUser?.employee && {
                        ...cachedUser?.employee,
                        avatar,
                    },
                    partnerEmployee: cachedUser?.partnerEmployee && {
                        ...cachedUser?.partnerEmployee,
                        avatar,
                    },
                },
        )
    }

    /**
     * Updates the user's profile information
     * @param viewerRole Conveys what role the user was assuming when performing the profile update
     * @param values Set of updatable profile values, all values need to be provided
     * @returns A promise that resolves when the mutation is complete
     */
    async function updateProfile(
        viewerRole: apiDeimosUser.ViewerRole,
        values: Parameters<typeof apiDeimosUser.updateProfile>[1],
    ) {
        await apiDeimosUser.updateProfile(viewerRole, values)

        await mutate(
            (cachedUser) =>
                cachedUser && {
                    ...cachedUser,
                    employee: cachedUser?.employee && {
                        ...cachedUser?.employee,
                        ...values,
                    },
                    partnerEmployee: cachedUser?.partnerEmployee && {
                        ...cachedUser?.partnerEmployee,
                        ...values,
                    },
                },
        )
    }

    /**
     * Changes the preferred user's UI language
     * @param language The selected preferred language code
     * @returns A promise that resolves when the mutation is complete
     */
    async function updateLanguage(language: SupportedLanguage) {
        await apiAuthUser.setLanguage(language)
        await forceSessionRefresh()
        await forceRefetchUser()
    }

    /**
     * Changes the key-value custom state store for company employees
     * @param key The key to save the information under
     * @param value The value of the saved information
     * @returns A promise that resolves when the mutation is complete
     */
    async function setEmployeeStateKey(key: string, value: string | boolean) {
        await mutate(async (cachedUser) => {
            // You cannot edit state of users who are not company employees
            if (!cachedUser?.employee || !cachedUser.employee.id) {
                return cachedUser
            }
            const state = await apiDeimosEmployee.setState(cachedUser?.employee.id, key, value)

            return {
                ...cachedUser,
                employee: {
                    ...cachedUser?.employee,
                    status: {
                        ...cachedUser?.employee.status,
                        state,
                    },
                },
            }
        })
    }

    /**
     * Saves the information that the user has accepted Pleo's T&C
     * @returns A promise that resolves when the mutation is complete
     */
    async function acceptTerms() {
        await apiAuthUser.acceptTerms()
        await forceSessionRefresh()
        await mutate(
            (cachedUser) =>
                cachedUser && {
                    ...cachedUser,
                    status: cachedUser?.status && {
                        ...cachedUser?.status,
                        acceptedTerms: true,
                    },
                },
        )
    }

    return {
        refetchUser,
        forceRefetchUser,
        updateAvatar,
        updateProfile,
        updateLanguage,
        setEmployeeStateKey,
        acceptTerms,
    }
}

/**
 * Custom hook used to retrieve mutation methods that will mutate the user on the server
 * and make sure the cached user available via other custom hooks is updated
 * @returns A dictionary of mutation methods
 */
export function useUserMutations(): ReturnType<typeof getMutations> {
    const mutations = React.useContext(UserMutationsContext)
    return mutations
}
