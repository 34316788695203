import type {ICountryConfiguration} from './types'

import {Export} from '../features/export/types'
import {Feature} from '../types'

const FR: ICountryConfiguration = {
    [Feature.Export]: {
        [Export.CustomFormat]: {
            contraAccountNumber: 51200,
            accountNumber: 62500,
            vatAccountNumber: 4456,
        },
    },
    [Feature.Paywall]: {
        noVATReclaim: true,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleofr.aftership.com/',
    },
}

export default FR
