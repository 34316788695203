import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const ReceiptDownloadPage = React.lazy(async () => import('./screens/receipt-download'))
const PageNotFound = React.lazy(
    async () => import('@product-web/routes/page-not-found/page-not-found'),
)

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route path=":receiptId/file" element={<ReceiptDownloadPage />} />
        <Route index element={<PageNotFound />} />
    </Route>
)
