import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {SessionStatus, useSessionStatus} from '@product-web/auth--session/context'
import {LOGIN_REDIRECT_PATH_STORAGE_KEY} from '@product-web/auth--session/force-login'

/**
 * Handles the redirect logic after successful login. To be used in the global layout component.
 */
export function useHandleLoginRedirect() {
    const navigate = useNavigate()
    const location = useLocation()
    const sessionStatus = useSessionStatus()

    React.useEffect(() => {
        if (sessionStatus !== SessionStatus.LoggedIn) {
            return
        }
        const redirectPath = sessionStorage.getItem(LOGIN_REDIRECT_PATH_STORAGE_KEY)
        sessionStorage.removeItem(LOGIN_REDIRECT_PATH_STORAGE_KEY)
        if (redirectPath) {
            navigate(redirectPath, {replace: true})
        }
    }, [location.pathname, sessionStatus])
}
