import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'
import GeneralSettingsWrapper from '@product-web-features/settings/general-settings-wrapper'

const BusinessExplanationScreen = React.lazy(
    async () =>
        import(
            './screens/company-verification/business-explanation-screen/business-explanation-screen'
        ),
)
const CompanyDetailsScreen = React.lazy(
    async () =>
        import('./screens/company-verification/company-details-screen/company-details-screen'),
)
const CompanyIndustryScreen = React.lazy(
    async () =>
        import('./screens/company-verification/company-industry-screen/company-industry-screen'),
)

const CompanyStructureScreen = React.lazy(
    async () =>
        import('./screens/company-verification/company-structure-screen/company-structure-screen'),
)
const DirectorConfirmationScreen = React.lazy(
    async () =>
        import(
            './screens/company-verification/director-confirmation-screen/director-confirmation-screen'
        ),
)
const DocumentsScreen = React.lazy(
    async () =>
        import('./screens/company-verification/company-documents-screen/company-documents-screen'),
)
const LandingScreen = React.lazy(
    async () => import('./screens/company-verification/company-landing-screen'),
)
const PersonScreen = React.lazy(
    async () => import('./screens/company-verification/person-screen/person-screen'),
)
const SelfVerifyWrapper = React.lazy(
    async () => import('./screens/company-verification/self-verify-wrapper'),
)
const CompanyVerificationWrapper = React.lazy(
    async () => import('./screens/company-verification/company-verification-wrapper'),
)
const StatusScreen = React.lazy(
    async () => import('./screens/company-verification/status-screen/status-screen'),
)
const UbosConfirmationScreen = React.lazy(
    async () =>
        import('./screens/company-verification/ubos-confirmation-screen/ubos-confirmation-screen'),
)
const UsageDetailsScreen = React.lazy(
    async () => import('./screens/company-verification/usage-details-screen/usage-details-screen'),
)
const CompanyInfoScreen = React.lazy(
    async () => import('./screens/settings/company-info-screen/company-info-screen'),
)
const ManufacturingCountriesScreen = React.lazy(
    async () =>
        import(
            './screens/company-verification/manufacturing-countries-screen/manufacturing-countries-screen'
        ),
)
const AdditionalDetailsScreen = React.lazy(
    async () =>
        import(
            './screens/company-verification/additional-details-screen/additional-details-screen'
        ),
)

const VerificationProviderRedirect = React.lazy(
    async () => import('./screens/company-verification/verification-provider-redirect'),
)

export const routes = (
    <>
        <Route path="rfi/:rfiId" element={<SelfVerifyWrapper />} />
        <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}>
            <Route
                path="verification-provider-redirect"
                element={<VerificationProviderRedirect />}
            />
            <Route element={<CompanyVerificationWrapper />}>
                <Route index element={<LandingScreen />} />
                <Route path="company/document/:documentId" element={<DocumentsScreen />} />
                <Route path="company/data/company-details" element={<CompanyDetailsScreen />} />
                <Route path="company/data/industry" element={<CompanyIndustryScreen />} />
                <Route path="company/data/structure" element={<CompanyStructureScreen />} />
                <Route
                    path="company/data/director-confirmation"
                    element={<DirectorConfirmationScreen />}
                />
                <Route
                    path="company/data/business-explanation"
                    element={<BusinessExplanationScreen />}
                />
                <Route path="company/data/usage-details" element={<UsageDetailsScreen />} />
                <Route
                    path="company/data/manufacturing-countries"
                    element={<ManufacturingCountriesScreen />}
                />
                <Route path="company/data/ubos-confirmation" element={<UbosConfirmationScreen />} />
                <Route
                    path="company/data/additional-details"
                    element={<AdditionalDetailsScreen />}
                />
                <Route path="person/:personId" element={<PersonScreen />} />
                <Route path="status" element={<StatusScreen />} />
            </Route>
        </Route>
    </>
)

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}>
        <Route element={<GeneralSettingsWrapper />}>
            <Route index element={<CompanyInfoScreen />} />
        </Route>
    </Route>
)
