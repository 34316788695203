import type {NonNullableValues} from './types'

export function ejectEmptyOrNullPropertyValueFromObject<T>(obj: T): NonNullableValues<T> {
    const result = {} as NonNullableValues<T>
    for (const property in obj) {
        if (obj[property] || (obj[property] as unknown as boolean) === false) {
            result[property] = obj[property] as NonNullableValues<T>[Extract<keyof T, string>]
        }
    }
    return result
}

// Type predicate function for use in `.filter` methods on arrays to filter out
// null/undefined elements, and correctly narrow the result type
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined
}
