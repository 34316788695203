import {callAuth} from '@product-web/api'
import type {SupportedLanguage} from '@product-web/i18n'
import {i18nStorage} from '@product-web/i18n'

export interface CreateCompleteUserRequest {
    email: string
    firstName: string
    language?: string
    lastName: string
    passcode: string
    phone?: string
}

export async function createCompleteUser(body: CreateCompleteUserRequest) {
    return callAuth('/api/v1/users/complete', {
        method: 'POST',
        body,
    })
}

// Accept terms
export async function acceptTerms() {
    return callAuth('/api/v1/user/accept-terms', {
        auth: 'user',
        method: 'POST',
    })
}

export async function setLanguage(language: SupportedLanguage) {
    i18nStorage.set(language)
    return callAuth('/api/v1/user/language', {
        auth: 'user',
        method: 'POST',
        body: {language},
    })
}

// Verify user
export async function verify(token: string) {
    return callAuth(`/api/v1/user/verify/${token}`, {
        method: 'GET',
    })
}
