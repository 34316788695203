import React from 'react'
import {Navigate, Route, useSearchParams} from 'react-router-dom'

import {EmailProvider} from '@pleo-io/deimos'

import {roleSets} from '@product-web/user'

const NotificationsPage = React.lazy(async () => import('./screens/notifications/notifications'))
const MyPowerUpsPage = React.lazy(async () => import('./screens/power-ups/my-power-ups'))
const ReimbursementMethodsPage = React.lazy(
    async () => import('./screens/reimbursement-methods/reimbursement-methods'),
)
const ProfilePage = React.lazy(async () => import('./screens/profile/profile'))
const ResetPinPage = React.lazy(async () => import('./screens/reset-pin/reset-pin'))
const TravelInsurancePage = React.lazy(
    async () => import('./screens/travel-insurance/travel-insurance'),
)
const TrustedDevicesPage = React.lazy(async () => import('./screens/devices/devices'))
const AddAccountPage = React.lazy(async () => import('./screens/add-account/add-account'))
const EmailVerificationPage = React.lazy(async () => import('./screens/login/email-verification'))
const TrustBrowserPage = React.lazy(async () => import('./screens/trust-browser/trust-browser'))
const SessionRefreshPage = React.lazy(
    async () => import('./screens/session-refresh/session-refresh'),
)
const VerifyPage = React.lazy(async () => import('./screens/verify/verify'))
const ScaOnboardingPage = React.lazy(async () => import('./screens/onboarding/onboarding'))
const LoginPage = React.lazy(async () => import('./screens/login/login'))
const VerifyLoginAttemptPage = React.lazy(
    async () => import('./screens/verify-login-attempt/verify-login-attempt'),
)
const VerifyLoginDifferentDevicePage = React.lazy(
    async () => import('./screens/verify-login-different-device/verify-login-different-device'),
)
const VerifyLoginErrorPage = React.lazy(
    async () => import('./screens/verify-login-error/verify-login-error'),
)

const Wrapper = React.lazy(async () => import('./components/profile-page-wrapper'))

export {routes as resetRoutes} from './screens/reset/routes'
export const topLevelRoutes = (
    <>
        <Route path="/trust-browser" element={<TrustBrowserPage />} />
        <Route path="/access" element={<SessionRefreshPage />} />
        <Route path="/verify-login-attempt/:token" element={<VerifyLoginAttemptPage />} />
        <Route path="/verify-login-attempt" element={<VerifyLoginAttemptPage />} />
        <Route path="/verify-login-error/*" element={<VerifyLoginErrorPage />} />
        <Route
            path="/verify-login-different-device/*"
            element={<VerifyLoginDifferentDevicePage />}
        />
    </>
)
export const topLevelLayoutRoutes = (
    <>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/add-account" element={<AddAccountPage />} />
        <Route path="/login-email-verification" element={<EmailVerificationPage />} />

        <Route path="/verify/:token" element={<VerifyPage />} />
        <Route
            path="/secure-your-account"
            element={<ScaOnboardingPage />}
            handle={{auth: true, allowedRoles: roleSets.company}}
        />
    </>
)

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.all}}>
        <Route path="reset/pin/:resetToken" element={<ResetPinPage />} />
        <Route element={<Wrapper />}>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="devices" element={<TrustedDevicesPage />} />
        </Route>

        <Route element={<Wrapper />} handle={{auth: true, allowedRoles: roleSets.company}}>
            <Route path="travel-insurance" element={<TravelInsurancePage />} />
            <Route path="reimbursement-methods" element={<ReimbursementMethodsPage />} />
            {/* For now, a user can configure these powers up for their spending entity
            https://linear.app/pleo/issue/AX-1896/power-ups-showing-up-for-non-spending-entity */}
            <Route path="power-ups" element={<MyPowerUpsPage />} />
        </Route>
    </Route>
)

const MailAuthRedirect: React.FC<{provider: EmailProvider}> = ({provider}) => {
    const [searchParams] = useSearchParams()
    return (
        <Navigate
            to="/account/power-ups"
            state={{
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                integration: 'Fetch',
                provider,
                state: searchParams.get('state'),
                code: searchParams.get('code'),
                error: searchParams.get('error'),
            }}
        />
    )
}

export const oauthRoutes = (
    <Route handle={{allowedRoles: roleSets.companyOnly}}>
        <Route path="email/google" element={<MailAuthRedirect provider={EmailProvider.GMAIL} />} />
        <Route
            path="email/office365"
            element={<MailAuthRedirect provider={EmailProvider.OFFICE365} />}
        />
    </Route>
)
