import type {FC} from 'react'

import {MainSlot} from './main-slot'

import {NavigationSkeleton} from '../navigation-skeleton'
import {NavigationAvatar} from '../navigation-v2/navigation-avatar'

export const MultiEntityNavigation: FC = () => {
    return <NavigationSkeleton userMenu={<NavigationAvatar />} main={<MainSlot />} />
}
