import {Link, useLocation} from 'react-router-dom'

import {Text} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'

import {OnboardingItem} from './onboarding-item'

interface StandardTaskProps {
    name: string
    label: string
    path: string
    checked: boolean
    toggleCheck: () => void
}

export const StandardTask = ({name, label, path, checked, toggleCheck}: StandardTaskProps) => {
    const {pathname} = useLocation()
    const isOnTask = pathname === path
    const status = checked ? 'completed' : 'pending'

    return (
        <OnboardingItem
            as={Link}
            to={path}
            disabled={isOnTask}
            onClick={() => {
                tracking.partnerSelfOnboardingTaskActioned({
                    action: 'open',
                    task: {name, status},
                })
            }}
        >
            <OnboardingItem.Checkbox
                aria-labelledby={name}
                checked={checked}
                disabled={checked}
                onChange={() => toggleCheck()}
                onClick={() => {
                    tracking.partnerSelfOnboardingTaskActioned({
                        action: checked ? 'uncheck' : 'check',
                        task: {name, status},
                    })
                }}
            />
            <OnboardingItem.Content>
                <Text as="label" id={name}>
                    {label}
                </Text>
            </OnboardingItem.Content>
        </OnboardingItem>
    )
}
