import {Trans} from '@lingui/macro'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled, {css, keyframes} from 'styled-components'

import type {EmployeeRole} from '@pleo-io/deimos'
import {focusRing, Inline, Loading, Stack, Tag, Text, tokens} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {useUserNavigation} from '@product-web/api-deimos/user-navigation'
import {OtpType} from '@product-web/api-types/otp'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/auth--accounts'
import {checkEmail} from '@product-web/auth--otp/api'
import {reportError} from '@product-web/error/report'
import {getUniqueConnectedAccountEmails} from '@product-web/navigation/helpers'
import {getIsOrganizationAdmin, useUser} from '@product-web/user'
import type {UserData} from '@product-web/user-types'
import {AddAccountScreenName} from '@product-web-features/multi-entity/add-account-screen-name'
import {AvatarEmployee} from '@product-web-features/ui-avatar-employee/avatar-employee'

import {Dropdown} from './navigation-dropdown'

import {getSwitchAccountRedirectLocation} from '../get-switch-account-redirect-location'

function UserNavigationListItem({
    id,
    isBookkeeper,
    companyName,
    isHomeEntity,
    isOrganizationOwner,
    isActiveAccount,
    hasExtendedAccess,
    switchAccount,
    navigationItem: NavigationItem,
}: {
    isBookkeeper: boolean
    id: string
    companyName: string
    isHomeEntity: boolean
    isOrganizationOwner?: boolean
    hasExtendedAccess: boolean
    isActiveAccount: boolean
    switchAccount: () => void
    navigationItem: NavigationItemType
}) {
    return (
        <NavigationItemWrapper $isActiveAccount={isActiveAccount}>
            <NavigationItem onClick={switchAccount}>
                <Stack stretch>
                    <Inline>
                        <Stack>
                            <AccountName $isActiveAccount={isActiveAccount}>
                                {companyName}
                            </AccountName>
                            {isHomeEntity && isOrganizationOwner && (
                                <Text variant="small-subtle" weight={'regular'}>
                                    <Trans>Your entity</Trans>
                                </Text>
                            )}
                        </Stack>
                        {isActiveAccount && <Icon data-testid={`navigation-item-${id}-active`} />}
                    </Inline>

                    {isBookkeeper && hasExtendedAccess && (
                        <Row>
                            <Text variant="small-subtle" weight={'regular'}>
                                <Trans>extended access</Trans>
                            </Text>
                        </Row>
                    )}
                    {isBookkeeper && !hasExtendedAccess && (
                        <Row>
                            <Text variant="small-subtle" weight={'regular'}>
                                <Trans>limited access</Trans>
                            </Text>
                        </Row>
                    )}
                </Stack>
            </NavigationItem>
        </NavigationItemWrapper>
    )
}

function UserNavigationList({
    currentUser,
    companies,
    email,
    isOrganizationOwner,
    switchAccount,
    navigationItem,
}: {
    email: string
    companies?: {id: string; name: string; role: EmployeeRole | null; isHomeEntity: boolean}[]
    currentUser?: UserData
    isOrganizationOwner?: boolean
    switchAccount: (email: string, companyId?: string | null) => void
    navigationItem: NavigationItemType
}) {
    return (
        <UserNavigationListWrapper>
            {companies?.map(
                (companyAccount: {
                    id: string
                    name: string
                    role: EmployeeRole | null
                    isHomeEntity: boolean
                }) => {
                    const isBookkeeper = Boolean(companyAccount?.role?.startsWith('bookkeeper'))
                    const hasExtendedAccess = Boolean(companyAccount?.role?.includes('extended'))

                    const isActiveAccount =
                        currentUser?.companyId === companyAccount.id && currentUser.email === email

                    const onSwitchAccount = () => {
                        // user cannot click active menu item
                        if (isActiveAccount) {
                            return
                        }
                        switchAccount(email, companyAccount.id)
                    }

                    return (
                        <React.Fragment key={companyAccount?.id}>
                            <UserNavigationListItem
                                id={companyAccount.id}
                                companyName={companyAccount.name}
                                isActiveAccount={isActiveAccount}
                                switchAccount={onSwitchAccount}
                                isBookkeeper={isBookkeeper}
                                isHomeEntity={companyAccount.isHomeEntity}
                                isOrganizationOwner={isOrganizationOwner}
                                hasExtendedAccess={isBookkeeper && hasExtendedAccess}
                                navigationItem={navigationItem}
                            />
                        </React.Fragment>
                    )
                },
            )}
        </UserNavigationListWrapper>
    )
}

const fadeIn = keyframes({
    '0%': {opacity: 0},
    '100%': {opacity: 1},
})

const StyledLoading = styled(Loading).attrs({size: 12})`
    opacity: 0;
    animation: ${fadeIn} 1000ms forwards 1000ms;
`

export const Loader = () => (
    <Inline
        stretchChildren
        p={24}
        alignItems="center"
        css={{background: tokens.colorBackgroundStaticLoud, height: '80px'}}
    >
        <StyledLoading />
    </Inline>
)

function NavigationAccount({
    email,
    accessToken,
    currentUser,
    switchAccount,
    navigationItem: NavigationItem,
}: LoggedInAccount & {
    currentUser?: UserData
    switchAccount: (payload: SwitchAccountPayload) => void
    navigationItem: NavigationItemType
}) {
    const user = useUser()
    const isOrganizationAdmin = getIsOrganizationAdmin(user)
    const location = useLocation()
    const {data, error} = useUserNavigation({accessToken, email})
    const isLoading = !data && !error
    const isBookkeeper = Boolean(
        data?.companies?.some((company) => company?.role?.startsWith('bookkeeper')),
    )

    const homeEntityWithAvatar = data?.companies.find(
        (company) => company.isHomeEntity === true && company.avatar,
    )

    const avatarData = {
        ...homeEntityWithAvatar,
        firstName: data?.firstName,
        lastName: data?.lastName,
    }

    const onSwitchAccount = (accountEmail: string, companyId?: string | null) => {
        tracking.accountSwitchToggleActioned({
            actor_role: user?.role,
        })

        const redirectTo = getSwitchAccountRedirectLocation(location, {
            isSpendingEntity: user?.isSpendingEntity,
            isOrganizationAdmin,
        })
        switchAccount({email: accountEmail, companyId, location: redirectTo})
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <NavigationListWrapper>
            <Stack space={0}>
                <Row pt={12} px={20}>
                    <Inline space={8} wrap alignY="center">
                        <AvatarEmployee size={24} employee={avatarData} />
                        <AccountIdentifier>{data?.email}</AccountIdentifier>
                        {isBookkeeper && (
                            <Tag variant="pink">
                                <Trans>Bookkeeper</Trans>
                            </Tag>
                        )}
                    </Inline>
                </Row>
                {data?.email && (
                    <UserNavigationList
                        currentUser={currentUser}
                        email={data.email}
                        companies={data?.companies}
                        switchAccount={onSwitchAccount}
                        isOrganizationOwner={data?.isOrganizationOwner}
                        navigationItem={NavigationItem}
                    />
                )}
            </Stack>
        </NavigationListWrapper>
    )
}

export function NavigationAccounts({
    items = [],
    switchAccount,
    navigationItem: NavigationItem,
}: {
    items?: LoggedInAccount[]
    switchAccount: (payload: SwitchAccountPayload) => void
    navigationItem: NavigationItemType
}) {
    const user = useUser()
    const isOrganizationAdmin = getIsOrganizationAdmin(user)
    const navigate = useNavigate()
    const location = useLocation()
    const currentUser = useUser()

    const uniqueConnectedAccountEmails = getUniqueConnectedAccountEmails(items)

    const addAccount = async (connectedAccount: {email: string; trusted?: boolean}) => {
        try {
            const response = await checkEmail(connectedAccount.email)
            const otpType = response.googleAuth ? OtpType.GOOGLE_AUTH : OtpType.PHONE
            const addAccountLocation = connectedAccount.trusted ? '/access' : '/add-account'

            navigate(addAccountLocation, {
                state: {
                    ...(connectedAccount.trusted ? {reAuthenticateAccount: true} : {}),
                    otpType,
                    email: connectedAccount.email,
                    currentScreen: AddAccountScreenName.PASSCODE,
                    redirect: getSwitchAccountRedirectLocation(location, {
                        isSpendingEntity: currentUser?.isSpendingEntity,
                        isOrganizationAdmin,
                    }),
                },
            })
        } catch (err) {
            reportError(err)
        }
    }

    return (
        <Wrapper data-testid="navigation-account-items-wrapper">
            {items?.map((item) => (
                <NavigationAccount
                    key={item.email}
                    {...item}
                    currentUser={currentUser}
                    switchAccount={switchAccount}
                    navigationItem={NavigationItem}
                />
            ))}
            {uniqueConnectedAccountEmails.map((connectedAccountEmail) => (
                <React.Fragment key={connectedAccountEmail.email}>
                    <LoggedOutAccountIdentifier>
                        {connectedAccountEmail.email}
                    </LoggedOutAccountIdentifier>
                    <NavigationItem onClick={async () => addAccount(connectedAccountEmail)}>
                        <Text
                            variant="medium-default"
                            color="colorContentInteractiveQuiet"
                            italic
                            align="left"
                        >
                            <Trans>Logged out</Trans>
                        </Text>
                    </NavigationItem>
                </React.Fragment>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-height: 335px;
`

const UserNavigationListWrapper = styled.div`
    padding: ${tokens.spacing8} 0 ${tokens.spacing4} 0;
    width: 100%;
`

const NavigationListWrapper = styled.div`
    border-radius: 0;
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-bottom: ${tokens.borderStatic};

    &:last-child {
        border-bottom: none;
    }
`

const NavigationItemWrapper = styled.div<{$isActiveAccount?: boolean}>`
    width: 100%;
    font-size: 14px;
    line-height: ${tokens.lineHeight3};
    text-align: left;
    box-sizing: border-box;
    background-color: transparent;
    transition: ${tokens.smoothInOut};
    transition-property: background-color;
    cursor: pointer;
    ${(props) =>
        props.$isActiveAccount &&
        css`
            cursor: auto;
        `}
    outline: none;
`

const navigationItemStyles = css`
    ${focusRing('inset')};
    cursor: pointer;
    padding: ${tokens.spacing8} ${tokens.spacing20};

    &:hover {
        border-radius: 0;
        background-color: ${tokens.colorBackgroundInteractiveHover};
    }
`

export const NavigationItemDesktop = styled(Dropdown.Item)`
    ${navigationItemStyles}
`
export const NavigationItemMobile = styled.div`
    ${navigationItemStyles}
`

export type NavigationItemType =
    | React.ComponentType<React.ComponentProps<typeof NavigationItemDesktop>>
    | React.ComponentType<React.ComponentProps<typeof NavigationItemMobile>>

const AccountName = styled(Text).attrs({
    variant: 'medium-default',
    color: 'colorContentStatic',
})<{
    $isActiveAccount?: boolean
}>`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${(props) =>
        props.$isActiveAccount &&
        css`
            font-weight: ${tokens.fontWeightMedium};
        `}
`

const AccountIdentifier = styled(Text).attrs({
    variant: 'small-subtle',
    weight: 'medium',
    truncate: true,
    color: 'colorContentInteractiveQuiet',
    align: 'left',
})`
    max-width: 188px;
`

const LoggedOutAccountIdentifier = styled(AccountIdentifier)`
    padding: ${tokens.spacing12} ${tokens.spacing20} 0;
`

const Row = styled(Inline).attrs({
    alignX: 'space-between',
    alignY: 'flex-end',
})``

const Icon = styled(Check).attrs({
    size: 18,
})`
    color: ${tokens.colorContentInteractive};
    margin: auto 0;
`
