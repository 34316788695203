import React from 'react'

/**
 * Allows to build a simple infinite scroll functionality by detecting efficiently
 * that the user has scrolled down to a certain element
 * The callback passed can be called multiple times, so you need to make sure that
 * it is only provided if the data is not being fetched an if the user has not reached
 * the end of the paginated list
 * @param loadMore The method to call when the user has scrolled to the element (or null to prevent multiple calls)
 * @param goalRef React ref to the element that triggers the fetch, i.e. anchored at the bottom of the list
 * @example
 * useInfiniteScroll(!isFetching && hasMore ? fetchMore : null, goalRef)
 */
export function useInfiniteScroll(
    loadMore: null | (() => void),
    goalRef: React.RefObject<HTMLElement>,
) {
    const [prevYPosition, setPrevYPosition] = React.useState(0)

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entities) => {
                const currentYPosition = entities[0].boundingClientRect.y
                if (
                    loadMore &&
                    (prevYPosition > currentYPosition || entities[0].intersectionRatio)
                ) {
                    loadMore()
                }
                setPrevYPosition(currentYPosition)
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.2,
            },
        )

        if (goalRef.current) {
            observer.observe(goalRef.current)
        }

        const goalRefForCleanup = goalRef

        return () => {
            if (goalRefForCleanup.current) {
                observer.unobserve(goalRefForCleanup.current)
            }
        }
    }, [prevYPosition, loadMore, goalRef])
}
