import {LoadingPage} from '@pleo-io/telescope'

import PageNotFound from '@product-web/routes/page-not-found/page-not-found'

import {useIsPaywalled} from '../hooks/use-is-paywalled'

export const Paywall = ({children}: {children: React.ReactNode}) => {
    const paywall = useIsPaywalled()

    if (paywall.isLoading) {
        return <LoadingPage />
    }
    if (paywall.isOpen) {
        return <>{children}</>
    }
    return <PageNotFound />
}
