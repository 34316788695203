import React, {useEffect} from 'react'

import {ModalSplit} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'
import {useLoggedInAccounts} from '@product-web/auth--accounts'
import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {useGuide} from '@product-web-features/ui-guides/guide'

import {useSteps} from './request-entities-modal-steps'

import {bff} from '../bff'

export const RequestEntitiesModal = ({
    isOrganizationAdmin,
    isOpen,
    setIsOpen,
}: {
    isOrganizationAdmin: boolean
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}) => {
    const steps = useSteps({isOrganizationAdmin})

    const {switchAccount} = useLoggedInAccounts()
    const {data} = bff.featureMultiEntity.requestEntitiesModal.getModalInfo.useQuery(undefined, {
        enabled: isOpen,
    })

    const onCloseGuide = () => {
        tracking.selfExpansionModalClosed({
            step: key,
            hasOrgAccess: isOrganizationAdmin,
        })
        onSetActiveStep(0)
        setIsOpen(false)
    }

    const {active, onSetActiveStep, onNextStep, onPreviousStep, onCompleted, onStart} = useGuide({
        steps,
        onClose: onCloseGuide,
    })

    const activeStep = steps[active]

    const {key, title, sections, illustration, actions, illustrationBgColor} = activeStep

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp}`)

    useEffect(() => {
        if (!isOpen || !data) {
            return
        }

        if (data.companyId !== data.ownerCompanyId) {
            switchAccount({email: data.email, companyId: data.ownerCompanyId, skipRedirect: true})
        }
    }, [isOpen, data])

    return (
        <ModalSplit isOpen={isOpen} onDismiss={onCloseGuide}>
            <ModalSplit.Content>
                <ModalSplit.Body>
                    <ModalSplit.Title>{title}</ModalSplit.Title>

                    {sections?.length &&
                        sections.map(({content, key: stepSectionKey}) => (
                            <div key={`${key}-${stepSectionKey}`}>
                                {typeof content === 'function'
                                    ? content({
                                          onNextStep,
                                          onPreviousStep,
                                          onStart,
                                          onCompleted,
                                          onSetActiveStep,
                                      })
                                    : content}
                            </div>
                        ))}
                </ModalSplit.Body>
                <ModalSplit.Actions>
                    {actions?.map(({action, key: actionKey}, idx) => (
                        <React.Fragment key={`guide-step-action-${actionKey}-${idx}`}>
                            {typeof action === 'function' &&
                                action({
                                    onNextStep,
                                    onPreviousStep,
                                    onStart,
                                    onCompleted,
                                    onSetActiveStep,
                                })}
                        </React.Fragment>
                    ))}
                </ModalSplit.Actions>
            </ModalSplit.Content>
            {!isMobile && (
                <ModalSplit.IllustrationContainer
                    style={{
                        backgroundColor: illustrationBgColor,
                    }}
                >
                    <ModalSplit.Illustration paddingX={32} paddingY={12}>
                        {illustration}
                    </ModalSplit.Illustration>
                </ModalSplit.IllustrationContainer>
            )}
        </ModalSplit>
    )
}
