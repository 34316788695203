import type {LoggedInAccount} from '@product-web/auth--accounts'
import type {UserData} from '@product-web/user-types'

/**
 * Custom hook determine if logged is session has multiple account added or user with multiple companies.
 * @returns boolean
 */
export function useHasAccounts(user?: Readonly<UserData>, accounts?: LoggedInAccount[]) {
    const numberOfcompanies = user?.companies?.length

    const hasAccounts =
        (accounts && accounts.some((account) => !!account?.connectedAccountEmails?.length)) ||
        (accounts && accounts.length > 1) ||
        // partners will see the list if the have at least one company and regular users will see the list of they have more than one
        (user?.partnerId && numberOfcompanies
            ? (numberOfcompanies ?? 0) > 0
            : (numberOfcompanies ?? 0) > 1)
    return hasAccounts
}

/**
 * Custom hook determine if logged is session has multiple accounts/emails added, does not include single accounts with multiple enitites
 * @returns boolean
 */
export function useHasConnectedEmails(accounts?: LoggedInAccount[]) {
    const hasConnectedEmails =
        (accounts && accounts.some((account) => !!account?.connectedAccountEmails?.length)) ||
        (accounts && accounts.length > 1)

    return hasConnectedEmails
}
