import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router'

import type {CompanySpendV1Model} from '@product-web/bff-moons/generated/cordelia'
import {formatCurrency} from '@product-web/locale/currency'
import {isToday, isYesterday, toCalendarFormat} from '@product-web/locale/helpers'
import type {PromptSize} from '@product-web-features/help-centre/adoption-prompt'
import {AdoptionPrompt} from '@product-web-features/help-centre/adoption-prompt'

import {PromptName, usePromptsPersistedState} from './auto-top-up-adoption-prompts-state'
import forkliftMoneyImage from './images/forklift-money.svg'

export const AutoTopUpAdoptionPrompts = ({
    canBeActivated,
    hasDeclinedTransactions,
    hasLowBalance,
    declinedTransactionDetails,
}: {
    canBeActivated: boolean
    hasLowBalance: boolean
    hasDeclinedTransactions: boolean
    declinedTransactionDetails?: CompanySpendV1Model
}) => {
    const declinedTransactionAmount = declinedTransactionDetails
        ? formatCurrency(
              declinedTransactionDetails.amount,
              declinedTransactionDetails.currency as string,
              {
                  format: {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                  },
              },
          )
        : undefined

    const {dismissPrompt, dismissedPrompts} = usePromptsPersistedState()
    const onDismiss = () => {
        dismissPrompt()
    }

    const navigate = useNavigate()
    const isVisible = canBeActivated && !dismissedPrompts?.dismissedDate

    const onComplete = () => {
        dismissPrompt()
        navigate('/wallet/manage/auto-topup')
    }

    const commonProps = {
        size: 'medium' as PromptSize,
        completeText: t`Activate`,
        dismissText: t`Not now`,
        imageSrc: forkliftMoneyImage,
        onComplete,
        onDismiss,
    }

    if (!isVisible) {
        return null
    }

    if (canBeActivated) {
        if (hasLowBalance) {
            return (
                <AdoptionPrompt
                    {...commonProps}
                    name={PromptName.LOW_BALANCE}
                    title={<Trans>Wallet balance running low? Not with Auto Top-up</Trans>}
                    text={
                        <Trans>
                            Activate Auto Top-up to enable automatic transfers from your bank
                            account to your Wallet whenever it hits your chosen threshold.
                        </Trans>
                    }
                />
            )
        }

        if (hasDeclinedTransactions && declinedTransactionDetails) {
            const {createdAt} = declinedTransactionDetails
            const dateLabel = toCalendarFormat(createdAt, {month: 'long'})
            const isDatePrepositionRequired = !isToday(createdAt) && !isYesterday(createdAt)

            return (
                <AdoptionPrompt
                    {...commonProps}
                    name={PromptName.DECLINED_TRANSACTIONS}
                    title={<Trans>Activate Auto Top-up to prevent declined transactions</Trans>}
                    text={
                        isDatePrepositionRequired ? (
                            <Trans>
                                On {dateLabel}, a purchase of {declinedTransactionAmount} was
                                declined due to insufficient funds.
                            </Trans>
                        ) : (
                            <Trans>
                                {dateLabel}, a purchase of {declinedTransactionAmount} was declined
                                due to insufficient funds.
                            </Trans>
                        )
                    }
                />
            )
        }
    }

    return null
}
