import {css} from 'styled-components'

import tokens from '@pleo-io/telescope-tokens'

export const helpCentreLinkButtonStyles = css`
    display: flex;
    gap: ${tokens.spacing10};
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    transition: ${tokens.motionWithinSmallShort};
    transition-property: color;

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`
