/* eslint-disable string-to-lingui/text-restrictions */
import {plural, t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Button, Callout, Modal, Stack, Text} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {PlanTypeName} from '@product-web/api-types/billing'
import {defineClientContext} from '@product-web/bff-client/operational-context'
import {dayjs} from '@product-web/dates/dayjs'
import {reportError} from '@product-web/error/report'

import HighFive from './high-five.svg'

import {bff} from '../../bff-hooks'

interface TrialStartedModalProps {
    closeModal: () => void
    isOpen: boolean
}

export const TrialStartedModal = ({isOpen, closeModal}: TrialStartedModalProps) => {
    const {data, error, isLoading} = bff.companyOnboarding.trialStartedModal.getData.useQuery(
        undefined,
        // Skip batching to avoid conflicts. The trial banner disables its
        // query, and when it is initially disabled, it causes the pre-selected
        // plan to not load correctly in the modal.
        {
            trpc: {
                context: defineClientContext({
                    skipBatch: true,
                }),
            },
        },
    )

    const onClose = () => {
        tracking.companyOnboardingNextStepsModalActioned({cta: 'close-button'})
        closeModal()
    }

    const trialDaysRemaining = data?.trialDaysRemaining
    useEffect(() => {
        // Only trigger when the modal is open and the data is available
        if (isOpen && !isLoading) {
            if (trialDaysRemaining && trialDaysRemaining > 0) {
                tracking.companyOnboardingNextStepsModalViewed()
            } else {
                reportError(`Invalid trialDaysRemaining value: ${trialDaysRemaining}`)
            }
        }
    }, [isOpen, trialDaysRemaining, isLoading])

    if (error || isLoading) {
        return null
    }

    if (
        trialDaysRemaining !== null &&
        trialDaysRemaining !== undefined &&
        trialDaysRemaining <= 0
    ) {
        return null
    }

    const {trialEndDate, preSelectedPlatformPlan} = data
    return (
        <Modal isOpen={isOpen} onDismiss={onClose} aria-label={t`Your Pleo account is live!`}>
            <Modal.Close onClick={onClose} />
            <Modal.Illustration>
                <Illustration src={HighFive} alt="" />
            </Modal.Illustration>
            <Modal.Title>
                <Trans>Your Pleo account is live!</Trans>
            </Modal.Title>
            <Modal.Content>
                <Stack space={24}>
                    <Text>
                        <Trans>
                            Get a head start on expenses with free access to Pleo Essential for the
                            next{' '}
                            {trialDaysRemaining && trialDaysRemaining > 0
                                ? plural(trialDaysRemaining, {
                                      one: '# day',
                                      other: '# days',
                                  })
                                : undefined}
                            .
                        </Trans>
                    </Text>
                    {!!trialEndDate && (
                        <Callout>
                            <Callout.Content>
                                <Callout.ContentHeading align="left">
                                    <Trans>What happens next?</Trans>
                                </Callout.ContentHeading>
                                <Callout.Text>
                                    {getCalloutText({
                                        trialEndDate,
                                        preSelectedPlatformPlan,
                                    })}
                                </Callout.Text>
                            </Callout.Content>
                        </Callout>
                    )}
                </Stack>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    variant="primary"
                    IconRight={ArrowRight}
                    onClick={() => {
                        tracking.companyOnboardingNextStepsModalActioned({
                            cta: 'explore-pleo-button',
                        })
                        closeModal()
                    }}
                >
                    <Trans>Explore Pleo</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const getCalloutText = ({
    trialEndDate,
    preSelectedPlatformPlan,
}: {
    trialEndDate: string
    preSelectedPlatformPlan: string | null
}) => {
    const trialEndDateFull = dayjs(trialEndDate).format('D MMMM YYYY')
    if (
        preSelectedPlatformPlan &&
        (preSelectedPlatformPlan === 'ESSENTIAL' ||
            preSelectedPlatformPlan === 'ADVANCED' ||
            preSelectedPlatformPlan === 'BEYOND')
    ) {
        return (
            <Trans>
                On {trialEndDateFull}, you'll switch over to{' '}
                <strong>Pleo {PlanTypeName[preSelectedPlatformPlan]}</strong>. You can change your
                plan at any time from the Billing page.
            </Trans>
        )
    }

    return (
        <Trans>
            On {trialEndDateFull}, you'll switch over to{' '}
            <strong>Pleo {PlanTypeName.STARTER}</strong> if you have 3 users or less. You can change
            your plan at any time from the Billing page.
        </Trans>
    )
}

const Illustration = styled.img`
    width: 100%;
    max-width: 320px;
`
