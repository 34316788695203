import * as React from 'react'
import {Navigate, Outlet, Route, useLocation, useNavigate, useParams} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {useDeimosStorage} from '@product-web/api-deimos/storage'
import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {roleSets} from '@product-web/user'

import {bff} from './bff-hooks'

const LegacyExportPage = React.lazy(async () => import('./screens/legacy/export'))
const ExportPage = React.lazy(async () => import('./screens/export/export-page'))
const ReportsPage = React.lazy(async () => import('./screens/reports/reports-page'))
const ExportDetailsPane = React.lazy(async () => import('./screens/details/details'))
const Page = React.lazy(async () => import('./components/page'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route element={<ExportWrapper />}>
            <Route path="export-item/:accountingEntryId" />
            <Route element={<Page />}>
                <Route index element={<Navigate to="unexported" replace />} />
                <Route path="reports/:reportLane" element={<ReportsPage />}>
                    <Route path=":expenseId/*" element={<ExportDetailsPane />} />
                </Route>
                <Route path=":exportLane" element={<ExportPage />}>
                    <Route path=":expenseId/*" element={<ExportDetailsPane />} />
                </Route>
            </Route>
        </Route>
    </Route>
)

function ExportWrapper() {
    const flagsLoaded = useFlagsLoaded()
    const {newExportPage} = useFlags()
    const {
        result: {data: exportBetaEnabledData},
        isLoading: exportBetaEnabledLoading,
    } = useDeimosStorage('exportBetaEnabled')

    const location = useLocation()
    const {accountingEntryId} = useParams()
    const navigate = useNavigate()

    const {data: exportJobStatus, isLoading} = bff.exportRoutes.getExportJobStatus.useQuery(
        {accountingEntryId: accountingEntryId ?? ''},
        {
            enabled: Boolean(accountingEntryId),
            onError() {
                navigate('unexported')
            },
            cacheTime: 0, // Do not cache this query to avoid issue in navigation
        },
    )

    if (!flagsLoaded || exportBetaEnabledLoading || (Boolean(accountingEntryId) && isLoading)) {
        return <LoadingPage />
    }

    /**
     * Set by default to new export page
     * If the variation is set to "Enabled", it will use the new export page
     */
    let useNewExport = true

    if (newExportPage === 'disabled') {
        useNewExport = false
    } else if (['default-beta', 'optional-beta'].includes(newExportPage)) {
        if (newExportPage === 'default-beta') {
            useNewExport = true
        }

        /**
         * WHen user explicitly set to old export page, it will override the flag to use old export page
         * It is possible only in the variation "optional-beta" and "default-beta"
         */
        if (exportBetaEnabledData?.value === 'disabled') {
            useNewExport = false
        } else if (exportBetaEnabledData?.value === 'enabled') {
            useNewExport = true
        }
    }

    if (useNewExport) {
        if (exportJobStatus) {
            switch (exportJobStatus) {
                case 'NOT_EXPORTED':
                    return <Navigate to={`/export/unexported/${accountingEntryId}`} replace />
                case 'EXPORTED':
                    return <Navigate to={`/export/exported/${accountingEntryId}`} replace />
                case 'QUEUED':
                    return <Navigate to={`/export/queued/${accountingEntryId}`} replace />
                default:
                    return <Navigate to={'/export/unexported'} replace />
            }
        }

        return <Outlet />
    }

    if (location.pathname !== '/export') {
        return <Navigate to="/export?exportLane=NOT_EXPORTED" replace />
    }

    return <LegacyExportPage />
}
