import React from 'react'

import styles from './browser.module.css'

const NOTIFICATION_ID = 'outdated-browser'

function showWithNavbar() {
    const notificationEl = document.getElementById(NOTIFICATION_ID)
    if (notificationEl) {
        notificationEl.className = `${styles.notification} ${styles.withNav}`
    }
}

function showWithoutNavbar() {
    const notificationEl = document.getElementById(NOTIFICATION_ID)
    if (notificationEl) {
        const reg = new RegExp('(\\s|^)' + styles.withNav + '(\\s|$)')
        notificationEl.className = notificationEl.className.replace(reg, '')
    }
}

/**
 * Triggers showing of the outdated browser notification, if necessary.
 * To be used by the main layout component
 */
export function useOutdatedBrowserNotification() {
    React.useEffect(() => {
        showWithNavbar()
        return () => showWithoutNavbar()
    }, [])
}
