import React from 'react'

import {useRegisterState} from '@product-web/auth--session/register-state'

const INITIAL_NAVIGATION_WIDTH = 260

export type NavigationAPI = {
    width: number
    setWidth: (width: number) => void
    isNavigationVisible: boolean
    setIsNavigationVisible: (show: boolean) => void
    isMobileNavigationVisible: boolean
    setIsMobileNavigationVisible: (visible: boolean) => void
}

export const NavigationContext = React.createContext<NavigationAPI>({
    width: 0,
    setWidth: () => {},
    isNavigationVisible: false,
    setIsNavigationVisible: () => {},
    isMobileNavigationVisible: false,
    setIsMobileNavigationVisible: () => {},
})

// This provider wraps the entire app. It's responsible for maintaining the state of the visibility
// of the global navigation, as well as exposes the methods to modify it. The methods are not meant
// to be used broadly, but is instead via Route component's `hiddenNav` prop
export const NavigationProvider: React.FC = ({children}) => {
    const [isNavigationVisible, setIsNavigationVisible] = React.useState(false)
    const [isMobileNavigationVisible, setIsMobileNavigationVisible] = React.useState(false)
    const [width, setWidth] = React.useState(INITIAL_NAVIGATION_WIDTH)
    const [params] = useRegisterState()

    //if the customer started registration process navigation panel content should not be visible
    const isActiveRegisterType = params.activeRegisterType

    const contextValue: NavigationAPI = {
        width,
        setWidth,
        isNavigationVisible: isNavigationVisible && !isActiveRegisterType,
        setIsNavigationVisible,
        isMobileNavigationVisible,
        setIsMobileNavigationVisible,
    }

    return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>
}

/**
 * Custom React hook retrieving the visibility state of the global navigation
 * @returns Boolean indicating whether the global navigation is currently visible
 */
export function useIsNavigationVisible() {
    return React.useContext(NavigationContext).isNavigationVisible
}

export const useToggleNavigation = ({showNav}: {showNav: boolean}) => {
    const {setIsNavigationVisible} = React.useContext(NavigationContext)
    React.useEffect(() => {
        setIsNavigationVisible(showNav)
    }, [showNav])
}

export const useMobileNavigationVisibility = () => {
    const {isMobileNavigationVisible, setIsMobileNavigationVisible} =
        React.useContext(NavigationContext)

    return {
        isMobileNavigationVisible,
        setIsMobileNavigationVisible,
    }
}

export const useNavigationWidth = () => {
    const {width, setWidth} = React.useContext(NavigationContext)

    return {width, setWidth}
}
