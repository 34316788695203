import {Route} from 'react-router-dom'

import {Invoices} from './invoices'
import {MultiEntities} from './multi-entities'
import {ReimbursementsAndMileage} from './reimbursements-and-mileage'
import {ThePleoProduct} from './the-pleo-product'
import {VendorCards} from './vendor-cards'

export const routes = (
    <>
        <Route>
            <Route index element={<ThePleoProduct />} />
            <Route path="reimbursements-and-mileage" element={<ReimbursementsAndMileage />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="vendor-cards" element={<VendorCards />} />
            <Route path="multi-entities" element={<MultiEntities />} />
        </Route>
    </>
)
