import {t, Trans} from '@lingui/macro'
import {useSearchParams} from 'react-router-dom'

import {Button, Link, Loading, Stack, Text, tokens} from '@pleo-io/telescope'

import {Accordion, SingleItemAccordion} from '@product-web/telescope-lab/accordion'

import {bff} from '../../../../bff-hooks'

export const PartnerAgreement = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const openAcceptPartnerAgreement = () => {
        searchParams.set('acceptTerms', 'true')
        setSearchParams(searchParams)
    }
    const {data, isError, isLoading} = bff.partnerAgreement.getPartnerAgreement.useQuery()

    if (isLoading) {
        return <Loading />
    }

    // We already have a partner agreement in many places, so we don't show too many errors for the same thing
    if (isError) {
        return null
    }

    const {hasSignedAgreement, signedAt, signedBy, url} = data

    if (!url) {
        return null
    }

    return (
        <SingleItemAccordion defaultExpanded={!hasSignedAgreement}>
            <Accordion.Header>
                <Text variant="xlarge-accent">
                    <Trans>Partner agreement</Trans>
                </Text>
                <Accordion.Chevron />
            </Accordion.Header>
            <Accordion.Content css={{borderTop: tokens.borderPrimary}}>
                {hasSignedAgreement ? (
                    <Stack py={24} space={20}>
                        <Text>
                            <Trans>
                                The partner agreement was accepted by {signedBy} on {signedAt}
                            </Trans>
                        </Text>
                        <Button
                            aria-label={t`Download agreement`}
                            as={Link}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="secondary"
                        >
                            <Text color="colorContentInteractiveQuiet">
                                <Trans>Download</Trans>
                            </Text>
                        </Button>
                    </Stack>
                ) : (
                    <Stack py={24} space={20}>
                        <Text>
                            <Trans>
                                The partner agreement needs to be accepted before you and your
                                employees can refer new clients.
                            </Trans>
                        </Text>
                        <Button onClick={openAcceptPartnerAgreement} variant="primary">
                            <Trans>Accept agreement</Trans>
                        </Button>
                    </Stack>
                )}
            </Accordion.Content>
        </SingleItemAccordion>
    )
}
