import type React from 'react'
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3'

import config from '@product-web/config'

// The possible actions used for grouping captcha results
type Action = 'request_2fa_verification' | 'sms_verification' | 'hubspot_update'

/**
 * Make sure that the components that call this are wrapped into GoogleReCaptchaWrapper
 * otherwise the request will fail. This hook can be later expanded into an SWR
 * middleware extending fetcher.
 *
 * @param action The string that identifies the action protected by the captcha.
 * In Google's interface, we can see captcha results grouped by this action
 * string.
 */
export const useRecaptcha = (action: Action) => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    if (!executeRecaptcha) {
        return undefined
    }

    const protectedCall = async function <Response = any>(callback: (token: string) => Response) {
        const token = await executeRecaptcha(action)

        return callback(token)
    }

    return {protectedCall}
}

export const GoogleReCaptchaWrapper = ({children}: {children: React.ReactNode}) => (
    <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaV3.key}>
        {children}
    </GoogleReCaptchaProvider>
)
