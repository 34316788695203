const caseInsensitive = (a: string, b: string) => {
    const A = a || ''
    const B = b || ''

    return A.toLowerCase().localeCompare(B.toLowerCase())
}

const numeric = (a: string | number, b: string | number) => {
    let valA: string | number
    let valB: string | number
    valA = parseFloat((a || '').toString().replace(/[.,]/g, ''))
    valB = parseFloat((b || '').toString().replace(/[.,]/g, ''))

    // Sort non-numeric values alphabetically at the bottom of the list
    if (isNaN(valA) && isNaN(valB)) {
        valA = a
        valB = b
    } else {
        if (isNaN(valA)) {
            return 1
        }
        if (isNaN(valB)) {
            return -1
        }
    }

    if (valA < valB) {
        return -1
    }
    if (valA > valB) {
        return 1
    }

    return 0
}

// Ascending order date sort
const date = (a: string | number, b: string | number) => {
    // Note: this function tries to do a standard javascript string -> date conversion
    // If you need more control over the date string format, consider using a different
    // date library and writing your own function
    const valA = Date.parse(a as string)
    const valB = Date.parse(b as string)

    // Handle non-date values with numeric sort
    // Sort non-numeric values alphabetically at the bottom of the list
    if (isNaN(valA) || isNaN(valB)) {
        return numeric(a as number, b as number)
    }
    if (valA > valB) {
        return 1
    }
    if (valB > valA) {
        return -1
    }

    return 0
}

type SortFn = (a: any, b: any) => 1 | -1 | 0

const sortByField = (sorting: SortFn, field: string) => (a: any, b: any) =>
    sorting(a[field], b[field])

const sortById = <T extends {id: string}>(a: T, b: T) => a.id.localeCompare(b.id)

export const sortByProp =
    (propName: string, options: {reverse?: boolean} = {}) =>
    (a: any, b: any) => {
        let A = a && a[propName]
        let B = b && b[propName]
        A = typeof A === 'string' ? A.toLowerCase() : A
        B = typeof B === 'string' ? B.toLowerCase() : B
        if (A === B) {
            return 0
        }
        if (options.reverse) {
            return A > B ? -1 : 1
        }
        return A < B ? -1 : 1
    }

export const sort = {
    numeric,
    date,
    sortByField,
    caseInsensitive,
    sortById,
}
