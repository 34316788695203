import * as RadixAccordion from '@radix-ui/react-accordion'
import {useState} from 'react'
import type {MarginProps, PaddingProps} from 'styled-system'

import * as Styled from './accordion.styles'

export type AccordionItemProps = RadixAccordion.AccordionItemProps & MarginProps
export type AccordionHeaderProps = RadixAccordion.AccordionHeaderProps
export type AccordionContentProps = RadixAccordion.AccordionContentProps & PaddingProps

export type AccordionProps =
    | RadixAccordion.AccordionSingleProps
    | RadixAccordion.AccordionMultipleProps

/**
 * @deprecated Use '@pleo-io/telescope' Accordion
 */
export const Accordion = ({children, ...props}: AccordionProps) => {
    if (props.type === 'single') {
        // allow collapsing all accordions by default
        props.collapsible = props.collapsible ?? true
    }
    return (
        <RadixAccordion.Root data-generic-ui="accordion" {...props}>
            {children}
        </RadixAccordion.Root>
    )
}

// We group Header and Trigger components as they seem to have a single purpose
// The same is being done in the main code example in Radix docs 🤷‍♂️
const AccordionHeader = ({children, ...props}: AccordionHeaderProps) => (
    <Styled.Header {...props}>
        <Styled.Trigger>{children}</Styled.Trigger>
    </Styled.Header>
)

Accordion.Item = Styled.Item
Accordion.Header = AccordionHeader
Accordion.Trigger = Styled.Trigger
Accordion.Content = Styled.Content
Accordion.Chevron = Styled.Chevron
Accordion.Banner = Styled.Banner
Accordion.Divider = Styled.Divider

/* Add controlled state to accordion */
export const useAccordionState = <T extends AccordionProps['type'] = 'single'>(
    defaultExpanded: T extends 'single' ? string : string[],
) => {
    const [expandedItemId, setExpandedItemId] = useState(defaultExpanded)
    return {
        value: expandedItemId,
        onValueChange: setExpandedItemId,
    }
}
