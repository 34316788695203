import {Trans} from '@lingui/macro'
import {useState} from 'react'
import {Navigate, Outlet} from 'react-router'
import styled from 'styled-components'

import {LoadingPage, tokens} from '@pleo-io/telescope'

import {useCompanyFeatureEntitlement} from '@product-web/entitlement/use-feature-entitlement'
import {Drawer} from '@product-web/telescope-lab/drawer'
import {EmptyStatesBudgets} from '@product-web-features/adoption/empty-states/empty-states-budgets'
import {LandingPagesWrapper} from '@product-web-features/adoption/landing-pages/landing-pages-wrapper'
import {Page} from '@product-web-features/ui-page/page'

import {TagBudgetDetailsDrawer} from './tag-budget-details/tag-budget-details-drawer'

import {bff} from '../bff'
import type {BudgetDrawerTabValues} from '../lib/types'
import {useActiveBudgetDrawerState} from '../lib/use-active-budget-details'
import {ViewDemoButton} from '../screens/tag-budgets-list/components/tag-budgets-empty-state'

export function BudgetsAuthAndRoutes() {
    const {activeBudgetId, isDrawerOpen, openDrawer, closeDrawer} = useActiveBudgetDrawerState()
    const [tabValue, setTabValue] = useState<BudgetDrawerTabValues>('general')

    const {isEntitled: hasBudgetsEntitlements, isLoading: isLoadingEntitlements} =
        useCompanyFeatureEntitlement('BUDGETS')

    const {data: hasAccessToBudgetsResponse, isLoading: isLoadingBudgetAccess} =
        bff.tagBudgetsAuth.getHasAccessToBudgets.useQuery()

    if (isLoadingEntitlements || isLoadingBudgetAccess) {
        return <LoadingPage />
    }

    const hasAccessToBudgets = Boolean(hasAccessToBudgetsResponse)
    if (!hasAccessToBudgets) {
        return <Navigate to="/" />
    }

    if (!hasBudgetsEntitlements) {
        return (
            <>
                <Page.Header>
                    <Page.Title>
                        <Trans>Budgets</Trans>
                    </Page.Title>
                </Page.Header>

                <LandingPagesWrapper
                    pageOrigin="budgets"
                    topAction={<ViewDemoButton onOpenDrawer={openDrawer} alternativeCopy />}
                >
                    <EmptyStatesBudgetsWrapper>
                        <EmptyStatesBudgets
                            secondaryAction={() => <ViewDemoButton onOpenDrawer={openDrawer} />}
                        />
                    </EmptyStatesBudgetsWrapper>
                </LandingPagesWrapper>

                <Drawer isOpen={isDrawerOpen} onDismiss={closeDrawer} size="auto">
                    <TagBudgetDetailsDrawer
                        activeBudgetId={activeBudgetId}
                        onClose={closeDrawer}
                        shouldShowPlanUpgrade={true}
                        setTabValue={setTabValue}
                        tabValue={tabValue}
                    />
                </Drawer>
            </>
        )
    }

    return <Outlet />
}

export const EmptyStatesBudgetsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${tokens.spacing48};
    padding-top: ${tokens.spacing48};
    padding-bottom: ${tokens.spacing56};
`

export default BudgetsAuthAndRoutes
