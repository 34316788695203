import type {EmailProvider} from '@pleo-io/deimos'

import * as tracking from '@product-web/analytics'

export const trackFetchOnboarding = (params: any) => {
    tracking.fetchOnboardingActioned(params)
}

const trackOnboardingStep = (step: string | undefined, action: string) => {
    if (!step) {
        return
    }
    tracking.fetchOnboardingActioned({
        step,
        action,
    })
}

export const trackStepViewed = (stepName: string | undefined) => {
    trackOnboardingStep(stepName, 'viewed')
}

export const trackGuideCompleted = (stepName: string | undefined) => {
    trackOnboardingStep(stepName, 'completed')
}

export const trackGuideDismissed = (stepName: string | undefined) => {
    trackOnboardingStep(stepName, 'dismissed')
}

export const trackProviderSelected = (provider: EmailProvider) => {
    tracking.fetchOnboardingActioned({
        step: 'choose_provider',
        action: 'completed',
        email_provider: provider,
    })
}
