import type {ReactNode} from 'react'
import React from 'react'

import * as s from './guide.styles'

export type Direction = 'left' | 'right'
export interface Step {
    key: string
    name?: string
    title?: ReactNode
    stepIndicatorColor?: string
    color?: string
    illustration?: ReactNode
    illustrationBgColor?: string
    sections?: StepSection[]
    actions?: StepAction[]
    requiredProperty?: string
    indicatorNavigationDisabled?: boolean
}
export interface StepAction {
    key: string
    action: ((props: Partial<StepWithDispatch>) => ReactNode) | ReactNode
}
export interface StepSection {
    key: string
    title?: ReactNode
    content?: ((props: Partial<StepWithDispatch>) => ReactNode) | ReactNode
    actions?: StepSectionAction[]
}

type StepSectionAction = StepAction

interface State {
    active: number
    isStarted: boolean
    isCompleted: boolean
}

type Action = {type: 'start' | 'completed'} | {type: 'active'; payload: number}

export type DispatchActions = {
    onSetActiveStep: (step: number) => void
    onNextStep: () => void
    onPreviousStep: () => void
    onCompleted: () => void
    onStart: () => void
}

type GuideReducer = State & DispatchActions

export function useGuide({
    steps = [],
    onClose,
    initialActiveStep = 0,
}: {
    steps?: Step[]
    onClose: () => void
    initialActiveStep?: number
}): GuideReducer {
    const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(reducer, {
        active: initialActiveStep >= 0 && initialActiveStep < steps.length ? initialActiveStep : 0,
        isStarted: false,
        isCompleted: false,
    })
    const onSetActiveStep = (step: number) => dispatch({type: 'active', payload: step})

    const onPreviousStep = () => {
        if (state.active === 0) {
            return
        }

        onSetActiveStep(state.active - 1)
    }

    const onNextStep = () => {
        if (state.active === steps.length - 1) {
            return
        }

        onSetActiveStep(state.active + 1)
    }
    const onCompleted = async () => {
        dispatch({type: 'completed'})
        onClose()
    }
    const onStart = () => dispatch({type: 'start'})
    return {...state, onSetActiveStep, onNextStep, onPreviousStep, onCompleted, onStart}
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'start':
            return {...state, isStarted: true}
        case 'completed':
            return {...state, isStarted: true, isCompleted: true}
        case 'active':
            return {...state, active: action.payload}
        default:
            throw new Error('Undexpected action in guide reducer')
    }
}

export type StepWithDispatch = Step & DispatchActions

type ScreenProps = {
    children: React.ReactNode
}

export function Screen({children}: ScreenProps) {
    return <s.ScreenWrapper>{children}</s.ScreenWrapper>
}

export const variants = {
    enter: (direction: Direction) => {
        return {
            x: direction === 'right' ? 1 : -1,
            opacity: 0,
        }
    },
    center: {
        x: 0,
        opacity: 1,
    },
    exit: (direction: Direction) => {
        return {
            x: direction === 'left' ? -1 : 1,
            opacity: 0,
        }
    },
}

export const fadeInOnlyVariants = {
    enter: {
        opacity: 0,
    },
    center: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
}
