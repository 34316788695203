import type {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
    Method,
} from 'axios'
import axios from 'axios'

import {
    getRequestErrorPleoMessage,
    getRequestErrorPleoType,
    getRequestErrorStatus,
    PleoErrorMessages,
} from '@product-web/api-error'
import {getScopedToken} from '@product-web/auth--scoped-tokens'
import {
    __getAccessToken,
    __logout,
    memoForceSessionRefresh,
} from '@product-web/auth--session/operations'
import appconfig from '@product-web/config'

import {joinPaths} from './join-paths'
import type {RequestOptions} from './types'

export const request = async <Response = any>(url: string, options: RequestOptions = {}) => {
    let formData: FormData | null = null
    if (options.file) {
        formData = new FormData()
        if (options.form) {
            Object.keys(options.form).forEach(
                (key) => options.form && formData?.append(key, options.form[key]),
            )
        }
        formData.append('file', options.file)
    }

    const data = formData || options.body

    type Data = typeof data

    // These A/B testing headers are set in LaunchDarkly and added to every
    // request. The switch is handled by Istio.
    const launchDarklyABTestingHeaders = window.__launchDarklyClient
        ? window.__launchDarklyClient.variation('ab-testing-headers', {})
        : {}

    const config: AxiosRequestConfig<Data> = {
        url,
        method: (options.method || 'GET').toLowerCase() as Method,
        params: options.query,
        timeout: options.timeout,
        data,
        headers: {
            ...options.headers,
            ...launchDarklyABTestingHeaders,
        },
        withCredentials: options.credentials,
        responseType: options.responseType,
        onUploadProgress:
            options.file && options.onProgress
                ? (e) =>
                      options.onProgress &&
                      e.total &&
                      options.onProgress(Math.round((e.loaded * 100) / e.total))
                : undefined,
    }

    const instance = axios.create()

    instance.interceptors.request.use(requestHandler(options))
    instance.interceptors.response.use(
        (response) => response,
        async (error) => errorHandler(options)(instance, error),
    )

    return instance(url, config).then((response: AxiosResponse<Response, Data>) => response.data)
}

const requestHandler = (options: RequestOptions) => async (config: InternalAxiosRequestConfig) => {
    if (options.bearer) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        config.headers.set('Authorization', `Bearer ${options.bearer}`)
        return config
    }

    if (!options.auth) {
        return config
    }

    let bearer
    switch (options.auth) {
        case 'user':
            // We need to use the non-hook access token retriever here, since this is not yet
            // used as a hook as part of the React render tree
            bearer = __getAccessToken()
            break
        case 'elevated':
            if (options.scope) {
                bearer = await getScopedToken(options.scope, {
                    force: options.forcePIN,
                    elevatedResourceIds: options.elevatedResourceIds,
                })
            } else {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                throw new Error('No scope provided')
            }
            break
    }

    // Counter bug where config.data is {} when it should be FormData
    if (options.file && Object.prototype.toString.call(config.data) !== '[object FormData]') {
        const formData = new FormData()
        formData.append('file', options.file)
        config.data = formData
    }

    if (bearer) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        config.headers.set('Authorization', `Bearer ${bearer}`)
    }

    return config
}

export const callAuth = async <Response = any>(url: string, options: RequestOptions = {}) => {
    return request<Response>(joinPaths(appconfig.endpoints.auth, url), options)
}

export const callApi = async <Response = any>(url: string, options: RequestOptions = {}) => {
    return request<Response>(joinPaths(appconfig.endpoints.api, url), options)
}

const errorHandler =
    ({auth}: RequestOptions) =>
    async (_instance: AxiosInstance, error?: AxiosError) => {
        if (!error) {
            return Promise.reject()
        }

        const status = getRequestErrorStatus(error)
        const message = (getRequestErrorPleoMessage(error) ?? '').toLowerCase()
        const type = (getRequestErrorPleoType(error) ?? '').toLowerCase()

        if (
            status === 401 &&
            [message, type].includes(PleoErrorMessages.TOKEN_EXPIRED) &&
            auth === 'user'
        ) {
            // Instead of logging out, we force a session refresh
            // This will force an app re-render, which will get the app to the right state
            memoForceSessionRefresh()
        } else if (status === 401 && message === PleoErrorMessages.UNAUTHORIZED && auth) {
            __logout()
        }

        return Promise.reject(error)
    }
