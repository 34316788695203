import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, Card, Inline, Link, List, ListItem, Text, tokens} from '@pleo-io/telescope'
import {Download, Link as LinkIcon} from '@pleo-io/telescope-icons'

import {getHelpCentreArticleLink} from '@product-web/locale/helpers'

export interface OverviewStep {
    isActive: boolean
    title: string
}
interface Document {
    url: string
    title: string
}
interface HelpCenterArticle {
    articleId: number
    title: string
}
type UsefulMaterial = Document | HelpCenterArticle

interface SideOverviewProps {
    overviewSteps: OverviewStep[]
    usefulMaterials: UsefulMaterial[]
}

export const SideOverview = ({overviewSteps, usefulMaterials}: SideOverviewProps) => (
    <Card pt={24}>
        <Box px={24}>
            <Card.Title headingLevel="h5">
                <Trans>Overview</Trans>
            </Card.Title>
            <List as="ul">
                {overviewSteps.map(({isActive, title}, index) => {
                    const color = isActive ? 'colorContentStatic' : 'colorContentStaticQuiet'
                    const weight = isActive ? 'semibold' : 'regular'
                    return (
                        <ListItem key={title}>
                            <Text color={color} weight={weight}>
                                <Inline space={12} my={24}>
                                    <NumberedListItem $isActive={isActive} color={color}>
                                        {index + 1}
                                    </NumberedListItem>
                                    {title}
                                </Inline>
                            </Text>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
        <Divider />
        <Box px={24}>
            <Box mt={24} mb={12}>
                <Card.Title headingLevel="h5">
                    <Trans>Useful materials</Trans>
                </Card.Title>
            </Box>
            <List as="ul">
                {usefulMaterials.map((usefulMaterial: UsefulMaterial) => (
                    <ListItem key={usefulMaterial.title}>
                        <Link
                            IconLeft={'url' in usefulMaterial ? Download : LinkIcon}
                            href={
                                'url' in usefulMaterial
                                    ? usefulMaterial.url
                                    : getHelpCentreArticleLink(usefulMaterial.articleId)
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {usefulMaterial.title}
                        </Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    </Card>
)

const Divider = styled.div`
    width: 100%;
    height: ${tokens.sizeBorderDefault};
    background-color: ${tokens.colorBorderStatic};
`

const NumberedListItem = styled.span<{$isActive: boolean}>`
    display: inline-grid;
    justify-items: center;
    width: ${tokens.spacing24};
    border: ${tokens.sizeBorderDefault} solid
        ${({$isActive}) =>
            $isActive ? tokens.colorBorderInteractiveSelected : tokens.colorBorderInteractive};
    border-radius: ${tokens.circle};
`
