import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import paperplaneAccountant from './paperplane-accountant.png'

interface Props {
    variation: 'small' | 'base'
}

const StyledHeadingImage = styled.img<{$variation: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${tokens.shade200};
    width: 100%;
    height: ${({$variation}) => ($variation === 'small' ? '63px' : 'auto')};
    margin-bottom: ${({$variation}) => ($variation === 'small' ? '12px' : '28px')};
`

const HeadingImage = ({variation}: Props) => (
    <StyledHeadingImage $variation={variation} src={paperplaneAccountant} alt="" />
)

export default HeadingImage
