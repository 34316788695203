import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Link, Text, tokens} from '@pleo-io/telescope'

import {useTermsDocuments} from '@product-web/country-configuration/features/terms/terms'
import {useFlags} from '@product-web/flags'
import Country from '@product-web/locale/country'

const StyledLabel = styled(Text).attrs({
    variant: 'small-subtle',
    as: 'div',
})`
    max-width: 324px;
    margin: ${tokens.spacing20} auto;
`

interface TermsProps {
    country?: Country
}

const Terms = ({country}: TermsProps) => {
    const {onboardedVia} = useFlags()
    const {
        masterServiceAgreement,
        dataProcessingAgreement,
        masterServiceAgreementB4B,
        privacyPolicy,
    } = useTermsDocuments(country)
    const isOnboardedViaB4B = country === Country.GB && onboardedVia === 'B4B'

    return (
        <StyledLabel>
            <Trans>
                By continuing you confirm that you have been informed of and accept Pleo's{' '}
                <Link
                    href={isOnboardedViaB4B ? masterServiceAgreementB4B : masterServiceAgreement}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Master Service Agreement
                </Link>
                ,{' '}
                <Link href={dataProcessingAgreement} target="_blank" rel="noopener noreferrer">
                    Data Processing Agreement
                </Link>{' '}
                and{' '}
                <Link href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </Link>
            </Trans>
        </StyledLabel>
    )
}

export default Terms
