import type {ICountryConfiguration} from './types'

import {Feature} from '../types'

const PT: ICountryConfiguration = {
    [Feature.Paywall]: {
        noVATReclaim: true,
        noMobilePay: true,
    },
}

export default PT
