import React from 'react'
import {v4 as uuid} from 'uuid'

import {isJwt} from '@product-web/auth--jwt/utils'
import {OauthContext} from '@product-web/auth--oauth/context'
import {OAUTH_ID_STORAGE_KEY} from '@product-web/auth--oauth/operations'
import {useAccessToken} from '@product-web/auth--session/context'
import {usePrevious} from '@product-web/react-utils'

export const OauthProvider: React.FC = ({children}) => {
    const accessToken = useAccessToken()
    // Initialize oauth ID with either the value saved in session storage
    // or a newly generated oauth ID
    const [oauthId, setOauthId] = React.useState(
        () => sessionStorage.getItem(OAUTH_ID_STORAGE_KEY) ?? getNewOauthId(),
    )

    const previousAccessToken = usePrevious(accessToken)
    React.useEffect(() => {
        // If previous access token is not defined, it's the first render and
        // we should not try to regenerate the oauth token
        if (previousAccessToken === undefined || !isJwt(previousAccessToken ?? '')) {
            return
        }

        // If the token changed, it means that the user changed, logged out,
        // etc. and we should generate a new oauth ID
        setOauthId(getNewOauthId())
    }, [accessToken])

    return <OauthContext.Provider value={{oauthId}}>{children}</OauthContext.Provider>
}

// Generates a new oauth id, saves it in session storage so it can be retrieved
// after the full page refresh (e.g. oauth redirect)
function getNewOauthId() {
    const newOauthId = uuid()
    sessionStorage.setItem(OAUTH_ID_STORAGE_KEY, newOauthId)
    return newOauthId
}
