import qs from 'qs'
import React from 'react'
import useSWR from 'swr'

import {request} from '@product-web/api'
import {RequestScope} from '@product-web/api'
import type {CardOrder} from '@product-web/api-types/card-order'
import {Status} from '@product-web/api-types/card-order'
import config from '@product-web/config'
import {useCompanyUser} from '@product-web/user'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

export interface MaxResponse {
    cards: number
    employees: number
    invites: number
    max: number
}

export async function create(payload: CardOrder) {
    return request(`${baseUrl}/rest/v1/companies/${payload.companyId}/cardOrders`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'POST',
        body: payload,
    })
}

interface MaxCardOrderResponse {
    cards: number
    employees: number
    invites: number
    max: number
}

interface EmbossLineReponse {
    embossLine: string
}

async function createCardOrder(companyId: string, payload: CardOrder) {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/cardOrders`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'POST',
        body: payload,
    })
}

async function destroyCardOrder(id: string) {
    return request(`${baseUrl}/rest/v1/cardOrders/${id}`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'DELETE',
    })
}

export function useCardOrders({filters = {}}: {filters?: {status?: Status[]}} = {}) {
    const {companyId} = useCompanyUser()

    const queryParams = qs.stringify(filters, {arrayFormat: 'brackets', skipNulls: true})
    const query = queryParams ? `?${queryParams}` : ''

    const result = useSWR<CardOrder[], Error>(
        companyId ? `rest/v1/companies/${companyId}/cardOrders${query}` : null,
        getDeimos,
    )

    async function createMutation(payload: CardOrder) {
        if (!companyId) {
            return
        }

        await createCardOrder(companyId, payload)

        result.mutate()
    }

    async function destroyMutation(id: string) {
        if (!companyId) {
            return
        }

        await destroyCardOrder(id)
        result.mutate(result.data?.filter((cardOrder) => cardOrder.id !== id))
    }

    return {
        ...result,
        mutations: {create: createMutation, destroy: destroyMutation},
    }
}

export function useCardOrdersMax() {
    const {companyId} = useCompanyUser()
    return useSWR<MaxCardOrderResponse, Error>(
        companyId ? `rest/v1/companies/${companyId}/cardOrders/max` : null,
        getDeimos,
    )
}

export function useCardOrdersEmbossLine() {
    const {companyId} = useCompanyUser()
    return useSWR<EmbossLineReponse, Error>(
        companyId ? `rest/v1/companies/${companyId}/cardOrders/embossLine` : null,
        getDeimos,
    )
}

export function usePreviousCardOrders() {
    return useCardOrders({filters: {status: [Status.received]}})
}

export function useActiveCardOrders() {
    return useCardOrders({filters: {status: [Status.created, Status.processing, Status.sent]}})
}

export function useEditableCardOrder() {
    const activeCardOrders = useActiveCardOrders()

    return React.useMemo(
        () =>
            activeCardOrders.data?.filter(
                (cardOrder: CardOrder) => cardOrder.status === Status.created,
            )[0],
        [activeCardOrders.data?.length],
    )
}

export function useProcessingCardOrder() {
    const activeCardOrders = useActiveCardOrders()

    return React.useMemo(
        () =>
            activeCardOrders.data?.filter(
                (cardOrder: CardOrder) => cardOrder.status === Status.processing,
            )[0],
        [activeCardOrders.data?.length],
    )
}

function useReadOnlyCardOrders() {
    const activeCardOrders = useActiveCardOrders()

    return React.useMemo(
        () =>
            activeCardOrders.data?.filter(
                (cardOrder: CardOrder) => cardOrder.status !== Status.created,
            ),
        [activeCardOrders.data?.length],
    )
}

export function useLastCardOrder() {
    const cardOrders = useReadOnlyCardOrders()
    const {data: previousCardOrders} = usePreviousCardOrders()
    return cardOrders?.[0] ?? previousCardOrders?.[0]
}
