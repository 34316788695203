export const Keys = {
    ARROW_DOWN: 'ArrowDown',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    BACKSPACE: 'Backspace',
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    SPACE: ' ',
    TAB: 'Tab',
    SHIFT: 'Shift',
    META: 'Meta',
    CONTROL: 'Control',
    ALT: 'Alt',
}

export const isSupportedKey = (key: string) => Object.values(Keys).includes(key)

export function isCharacterKeyPress(key: string) {
    return key.match(/(\w|\s)/g)
}

export const onEnterKey =
    <E extends Element = Element>(handler: (e: React.KeyboardEvent<E>) => void) =>
    (e: React.KeyboardEvent<E>) => {
        if (e.key === Keys.ENTER) {
            handler(e)
        }
    }
