import type {AxiosError} from 'axios'
import useSWR from 'swr'

import {getDeimos} from '@product-web/api-deimos/helpers'
import type {Address} from '@product-web/api-types/address'
import {usePartnerUser} from '@product-web/user'

export enum PartnerTier {
    TRIAL = 'TRIAL',
    BRONZE = 'BRONZE',
    SILVER = 'SILVER',
    GOLD = 'GOLD',
    GOLD_DACH = 'GOLD_DACH',
    PLATINUM = 'PLATINUM',
    PLATINUM_DACH = 'PLATINUM_DACH',
}

export interface PartnerProgramTier {
    type: PartnerTier
    users: {
        from: number
        to?: number
    }
    clients: {
        from: number
        to?: number
    }
}
export interface PartnerProgramInfoResponse {
    id: string
    ownCompanyId: string | null
    registrationNumber: string
    name: string
    tradingName: string
    address: Address
    onboardingPeriod: {
        daysToEnd: number
    }
    currentTier: PartnerProgramTier
    nextTier?: PartnerProgramTier
    calculatedTier?: PartnerProgramTier
    partnershipYear: {
        daysToEnd: number
        freeUsers: number
        paidUsers: number
        newClients: number
    }
}

export function usePartnerProgramInfo(params?: {includeCalculatedTierIfOverride: boolean}) {
    const user = usePartnerUser()
    const partnerId = user.partnerId
    const result = useSWR<PartnerProgramInfoResponse, AxiosError>(
        partnerId
            ? `partner-portal/rpc/partners/${partnerId}/program-info?includeCalculatedTierIfOverride=${!!params?.includeCalculatedTierIfOverride}`
            : null,
        getDeimos,
    )
    return result
}
