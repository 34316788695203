/* eslint-disable string-to-lingui/text-restrictions */
import {Trans} from '@lingui/macro'
import {useFormikContext} from 'formik'

import {Box, Button, ButtonGroup, Inline, Text} from '@pleo-io/telescope'
import {SpeechBubble} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'

import {FeedbackState} from './feedback'
import {StyledTextarea, SubtitleTextarea} from './styles'

type HelpfulReasonProps = {
    helpful: {
        feedback: string
    }
}

type HelpfulProps = {
    setFeedbackStatus: (arg: FeedbackState) => void
    guideName: string
}

export const Helpful = ({setFeedbackStatus, guideName}: HelpfulProps) => {
    const formikProps = useFormikContext<HelpfulReasonProps>()
    const helpful = formikProps.getFieldProps('helpful.feedback').value

    if (formikProps.isSubmitting) {
        return (
            <>
                <Box marginTop={28}>
                    <Text variant="large-accent">
                        <Trans>We got your feedback, appreciate it!</Trans>
                    </Text>
                </Box>
            </>
        )
    }

    return (
        <>
            <Box marginTop={28}>
                <Text align="left" variant="large-accent">
                    <Trans>Great to hear! What did you like about it?</Trans>
                </Text>
            </Box>
            <StyledTextarea
                autoFocus
                value={helpful.feedback}
                onChange={(event) => {
                    formikProps.setFieldValue('helpful.feedback', event.target.value)
                }}
                showCounter
                name="feedback"
                fixedHeight="100px"
                maxLength={1000}
                data-testid="helpful-textarea"
            />
            <Inline marginTop={12} space={10} alignY="center">
                <SpeechBubble size={16} />
                <SubtitleTextarea>
                    <Trans>We value your feedback</Trans>
                </SubtitleTextarea>
            </Inline>

            <Inline css={{width: '100%'}} justifyContent="end" marginTop={16}>
                <ButtonGroup>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setFeedbackStatus(FeedbackState.None)
                        }}
                    >
                        <Trans>Back</Trans>
                    </Button>
                    <Button
                        data-testid="adoption-centre-submit-helpful-feedback"
                        variant="primary"
                        disabled={formikProps.values.helpful.feedback.length === 0}
                        onClick={() => {
                            tracking.adoptionEssentialsFeedbackSubmitted({
                                helpful: 'yes',
                                guide: guideName,
                                other_text: helpful,
                            })
                            formikProps.submitForm()
                        }}
                    >
                        <Trans>Send</Trans>
                    </Button>
                </ButtonGroup>
            </Inline>
        </>
    )
}
