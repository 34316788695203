import {useMatches} from 'react-router'

import type {AppRouteHandle} from './types'

/**
Returns the current sub app based on the routing context. The sub-apps are declared in
core/routes.tsx using the `handle` prop on routes.
@returns The name of the current sub app, or undefined if not found.
*/
export function useCurrentSubApp() {
    const matches = useMatches()
    const handle = matches.findLast((match) => Boolean((match.handle as AppRouteHandle)?.app))
        ?.handle as AppRouteHandle
    const subApp = handle?.app
    return subApp
}
