import type Country from '@product-web/locale/country'

import type {CardOrderConfig} from './features/card-order/types'
import type {ExportConfig} from './features/export/types'
import type {MileageConfig} from './features/mileage/types'
import type {PaywallConfig} from './features/paywall/types'
import type {PerDiemConfig} from './features/per-diem/types'
import type {TermsConfig} from './features/terms/types'
import type {TravelInsuranceConfig} from './features/travel-insurance/types'
import type {VendorCardsConfig} from './features/vendor-cards/types'

export enum Feature {
    CardOrder = 'card-order',
    Export = 'export',
    Mileage = 'mileage',
    Paywall = 'paywall',
    PerDiem = 'per-diem',
    Terms = 'terms',
    TravelInsurance = 'travel-insurance',
    VendorCards = 'vendor-cards',
}

export type Configuration = {
    [Feature.CardOrder]: CardOrderConfig
    [Feature.Export]: ExportConfig
    [Feature.Mileage]: MileageConfig
    [Feature.Paywall]: PaywallConfig
    [Feature.PerDiem]: PerDiemConfig
    [Feature.Terms]: TermsConfig
    [Feature.TravelInsurance]: TravelInsuranceConfig
    [Feature.VendorCards]: VendorCardsConfig
}

export type CountryConfigurations = {
    [key in Country]: Configuration
}
