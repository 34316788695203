import type {Location} from 'react-router'
import {Navigate, Route, useLocation} from 'react-router'

type RedirectDestination = string | ((location: Location) => string)

const redirects: Record<string, RedirectDestination> = {
    '/projects': '/settings/projects',
    '/categories': '/settings/accounting/categories',
    '/billing': '/settings/general/billing',
    '/settings/billing': '/settings/general/billing',
    '/integrations': '/settings/accounting/setup',
    '/settings/tags': '/settings/accounting/tags',
    '/settings/categories': '/settings/accounting/categories',
    '/account/expenses': '/export',
    '/account/expenses/:type': '/export',
    '/team': '/people',
    '/team/company': '/company-verification',
    '/company/*': '/company-verification',
    '/onboarding/company/*': '/company-verification',
    '/pleo-guide': '/onboarding/pleo-guide',
    '/pleo-guide/setup-accounting': '/onboarding/setup-accounting',
    '/pleo-guide/onboard-team': '/onboarding/onboard-team',
    '/onboarding/pleo-guide/topup-wallet': '/onboarding/topup-wallet',
    '/onboarding/pleo-guide/start-spending': '/onboarding/start-spending',
    '/onboarding/pleo-guide/setup-accounting': '/onboarding/setup-accounting',
    '/onboarding/pleo-guide/onboard-team': '/onboarding/onboard-team',
    '/team/departments': '/people/teams',
    '/team/cards': '/people/cards',
    '/team/cards/order': '/people/cards/order',
    '/cards': '/people/cards',
    '/cards/order': '/people/cards/order',
    '/export-beta': '/export',
    '/wallet-v2': '/wallet',
    '/team/wallet/load': '/wallet/direct-debit',
    '/team/wallet/load/oneclick/dk': '/wallet/direct-debit',
    '/wallet/load/oneclick/dk': '/wallet/direct-debit',
    '/chargeback': '/wallet/chargeback',
    '/unload/*': (location) => location.pathname.replace(/^\/unload/, '/wallet/unload'),
    '/bills/*': (location) => location.pathname.replace(/^\/bills/, '/invoices'),
    '/pocket/*': (location) => location.pathname.replace(/^\/pocket/, '/reimbursements'),
    '/settings/account/*': (location) => location.pathname.replace(/^\/settings/, ''),
    '/account/preferences': '/account/notifications',
    '/account/employee': '/account/profile',
    '/welcome': '/register',
    // Not using `/register/user/*` here because other flows are also using
    // routes on `/register/user/` and we want to keep these as is
    '/register/user/welcome': '/register/new-company/user/welcome',
    '/register/user/company-country': '/register/new-company/user/company-country',
    '/register/user/company-size': '/register/new-company/user/company-size',
    '/register/user/passcode': '/register/new-company/user/passcode',
    '/register/user/phone/enter': '/register/new-company/user/phone/enter',
    '/register/user/phone/confirm': '/register/new-company/user/phone/confirm',
    '/register/user/email': '/register/new-company/user/email',
    '/register/company/*': (location) =>
        location.pathname.replace(/^\/register\/company/, '/register/new-company/company'),
    '/register/sole-trader-not-supported': '/register/new-company/sole-trader-not-supported',
    '/register/country-not-supported': '/register/new-company/country-not-supported',
}

function SafeRedirect({to}: {to: RedirectDestination}) {
    const location = useLocation()
    return <Navigate to={{...location, pathname: typeof to === 'function' ? to(location) : to}} />
}

export const redirectRoutes = Object.entries(redirects).map(([from, to]) => (
    <Route path={from} key={from} element={<SafeRedirect to={to} />} />
))
