import type {FC, MouseEventHandler, ReactNode} from 'react'
import type React from 'react'

import {NakedButton, Stack, Text, tokens} from '@pleo-io/telescope'
import type {Variants} from '@pleo-io/telescope/dist/components/text/text.styles'
import {ArrowRight, Close} from '@pleo-io/telescope-icons'

import * as s from './action-banner.styles'

export interface ActionBannerProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        s.BannerStyleProps {
    children: ReactNode
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const ActionBanner: FC<ActionBannerProps> = ({children, onClick, ...props}) => (
    <s.Banner
        as={onClick ? NakedButton : 'div'}
        onClick={onClick}
        data-generic-ui="action-banner"
        {...props}
    >
        {children}
    </s.Banner>
)

interface ActionBannerIconProps extends React.HTMLAttributes<HTMLButtonElement> {
    color?: 'pink' | 'green' | 'yellow' | 'purple'
    custom?: boolean
    className?: string
}

export const ActionBannerIcon: FC<ActionBannerIconProps> = ({
    children,
    color = 'pink',
    custom,
    className,
}) => {
    return custom ? (
        <s.Custom className={className}>{children}</s.Custom>
    ) : (
        <s.Circle color={color} className={className} data-testid={`banner-icon`}>
            {children}
        </s.Circle>
    )
}

export const ActionBannerActionIcon = (props: React.ComponentProps<typeof ArrowRight>) => (
    <ArrowRight
        color={tokens.shade600}
        ml="auto"
        mr={4}
        data-testid="banner-icon-action"
        {...props}
    />
)

export const ActionBannerCloseIcon = (props: React.ComponentProps<typeof Close>) => (
    <Close
        cursor={props.onClick ? 'pointer' : 'initial'}
        color={tokens.shade600}
        ml="auto"
        mr={4}
        data-testid="banner-icon-close"
        {...props}
    />
)

export interface ContentProps {
    title: string | JSX.Element
    titleVariant?: Variants
    titleAccent?: boolean
    subtitle?: string
}

export const ActionBannerContent = ({
    title,
    titleVariant = 'medium-default',
    titleAccent,
    subtitle,
}: ContentProps) => (
    <Stack>
        <Text
            variant={titleVariant}
            weight={titleAccent ? 'medium' : 'regular'}
            data-testid="banner-title"
        >
            {title}
        </Text>
        {subtitle && <Text variant="small-subtle">{subtitle}</Text>}
    </Stack>
)
