import config from '@product-web/config'
import type {UserData} from '@product-web/user-types'
import {loadScript} from '@product-web/web-platform/load-script'

import type {FCWidgetConfig} from './types'

const FRESHCHAT_HOST = 'https://pleohelp.freshchat.com'

const CSAT_QUESTION = 'Did we resolve your issue?'

const load = (onload: () => void) => {
    loadScript({
        src: '//eu.fw-cdn.com/10874830/386147.js',
        asynchronous: true,
        id: 'freshchat-lib',
        onload,
        attributes: {
            widgetId: config.freshchat.widgetId,
            chat: 'false',
        },
    })
}

const isLoaded = () => window.fcWidget?.isLoaded()

const initialise = ({
    user,
    onUserCreated,
    restoreId,
}: {
    user?: UserData
    onUserCreated: ({restoreId}: {restoreId: string}) => void
    restoreId?: string
}) => {
    const externalId = user?.id

    const initConfig: FCWidgetConfig = {
        token: config.freshchat.token,
        host: FRESHCHAT_HOST,
        widgetUuid: config.freshchat.widgetId,
        firstName: user?.employee?.firstName,
        lastName: user?.employee?.lastName,
        email: user?.email,
        phone: user?.phone || undefined,
        restoreId,
        externalId,
        config: {
            headerProperty: {
                hideChatButton: true,
            },
            content: {
                headers: {
                    csat_question: CSAT_QUESTION,
                },
            },
        },
    }

    window.fcWidget?.init(initConfig)

    // Ensure External ID and user properties are set, sometimes they are not set on init
    window.fcWidget?.setExternalId(externalId)
    window.fcWidget?.user.setProperties({
        email: user?.email,
        firstName: user?.employee?.firstName,
        lastName: user?.employee?.lastName,
    })

    window.fcWidget?.on('user:created', (response: any) => {
        if (response?.data?.restoreId) {
            onUserCreated({restoreId: response.data.restoreId})
        }
    })
}

const open = () => window.fcWidget?.open()

const clearUser = async () => {
    if (!window.fcWidget?.isLoaded()) {
        return
    }
    await window.fcWidget?.user.clear()
}

export const Freshchat = {
    load,
    isLoaded,
    initialise,
    open,
    clearUser,
}
