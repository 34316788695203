import {Buffer} from 'buffer'
import initShortUuidTranslator from 'short-uuid'

import type {JwtPayload} from '@pleo-io/jwt-payload-types'

import {isJwt} from './utils'

const shortUuidTranslator = initShortUuidTranslator()

// Defined by https://www.rfc-editor.org/rfc/rfc7519#section-4.1.4
export type TokenPayload = {
    data: JwtPayload
    /**
     *  Issued at claim
     */
    iat: number
    /**
     * Expiration time claim
     */
    exp: number
    /**
     * Issuer claim
     */
    iss: string
}

/**
 * Extracts the payload data from a JWT token.
 *
 * @param token - The JWT token
 * @returns The extracted token payload or null if token is not valid
 *          or cannot be parsed
 */
export function getTokenPayload(token: string) {
    if (!isJwt(token)) {
        return null
    }

    try {
        // We cannot use atob here as JWT uses base64url variant of encoding while
        // atob can only process "normal" base64.
        // @see https://base64.guru/standards/base64url
        // @see https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
        const tokenJSON = Buffer.from(token.split('.')[1], 'base64').toString('utf8')
        const json = JSON.parse(tokenJSON) as TokenPayload
        // Replace companyId represented in short-uuid format with regular uuid
        // The shortened uuid was introduced in https://linear.app/pleo/issue/ME-897
        if (json.data?.user?.cmp) {
            json.data.user.cmp = shortUuidTranslator.toUUID(json.data.user.cmp)
        }
        return json
    } catch (error) {
        return null
    }
}
