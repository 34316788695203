import {createContext, useContext} from 'react'

import {bff} from '../bff-hooks'

const CardsContext = createContext<CardsContext | undefined>(undefined)

interface CardsContext {
    employeeId?: string
    companyId?: string
}

export const useCardsContext = () => {
    const cardsContext = useContext(CardsContext)
    if (!cardsContext) {
        throw new Error('useCardsContext must be used inside CardsContextProvider')
    }
    return cardsContext
}

export const CardsContextProvider = ({children}: {children: React.ReactNode}) => {
    const {data: {employeeId, companyId} = {}} = bff.cardsContext.getData.useQuery()
    return <CardsContext.Provider value={{employeeId, companyId}}>{children}</CardsContext.Provider>
}
