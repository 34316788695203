/**
 * @overview Chip
 * @stage Proposed
 * @author Xenia (@Xenia)
 * @designer TODO
 * @spec https://www.figma.com/file/TBNfdIA6okJSB6P0aIc0qb/%E2%9D%8C-%F0%9F%92%B3-Pocket-Only-(Cardless-users)?node-id=3%3A3410
 */

import {plural} from '@lingui/macro'
import type {FC} from 'react'
import {forwardRef} from 'react'

import {AvatarGroup, Text} from '@pleo-io/telescope'
import {Options} from '@pleo-io/telescope-icons'

import * as s from './styles'

const MAX_LENGTH = 3

export interface Persona {
    /*
     * First name of the mapped person to compose initials on Avatar
     * */
    firstName: string
    /*
     * Last name of the mapped person to compose initials on Avatar
     * */
    lastName: string
    /*
     * The image url of the Avatar
     * */
    src?: string
}

interface P {
    /*
     * Mapped to avatars employees/reviewers/etc.
     * */
    people: Array<Persona>

    /*
     * Callback executed on icon `Options` click, icon is not displayed if no callback passed
     * */
    onClick?: () => void

    /*
     * Text displayed before grouped first names
     * */
    label?: string

    /*
     * Hides list of mapped firstnames after avatar stack
     * @default true
     * */
    withNames?: boolean

    /*
     * data-testid value assigned to component container
     *  */
    testId?: string

    /*
     * max amount of avatars and names to display
     *  */
    maxAmount?: number
}

/**
 * PeopleChip component displaying staked avatars & names(optional) from incoming array of employees
 * @prop {Array<Employee> | undefined} people: Mapped to avatars employees/reviewers/etc.
 * @prop {Boolean | undefined} withNames: Hides list of mapped firstnames after avatar stack
 * @prop {String | undefined} label: Text displayed before grouped first names
 * @prop {Function | undefined} onClick: Callback executed on icon `Options` click, icon is not displayed if no callback passed
 * @prop {string | undefined} testId: data-testid value assigned to component container
 * @prop {number | undefined} maxAmount: max amount of people to display
 *  */
export const PeopleChip: FC<P> = forwardRef(
    ({people, onClick, label, withNames = true, maxAmount = MAX_LENGTH, testId}, ref) => {
        const maxLength = Math.min(people.length, maxAmount)

        const filteredPeople = people.filter((person) => !!person.firstName)
        const peopleToDisplay = filteredPeople.slice(0, maxLength)
        const othersLabel = getOthersLabel(people.length, maxLength)

        return (
            <s.Container
                onClick={onClick}
                alignItems={'center'}
                withHover={!!onClick}
                ref={ref as any}
                data-testid={testId}
                data-generic-ui="people-chip"
            >
                <AvatarGroup stacked size={24} max={maxLength}>
                    {people.map(({lastName, firstName, src}, i) => (
                        <s.Avatar
                            key={i}
                            src={src}
                            name={firstName + ' ' + lastName}
                            index={Math.round(4 / (i + 1))}
                        />
                    ))}
                </AvatarGroup>

                {label && <Text variant={'medium-default'}>&nbsp;{label}&nbsp;</Text>}
                {withNames &&
                    peopleToDisplay.map(({firstName}, i) => (
                        <s.StyledText key={i}>
                            {firstName + (i < maxLength - 1 ? ', ' : '')}
                        </s.StyledText>
                    ))}
                <s.StyledText>{othersLabel}</s.StyledText>

                {onClick && <Options mx={4} cursor={'pointer'} size={16} />}
            </s.Container>
        )
    },
)

const getOthersLabel = (peopleLength: number, maxLength: number) => {
    const gap = peopleLength - maxLength
    if (gap > 0) {
        return plural(gap, {
            one: `and 1 other`,
            other: `and ${gap} others`,
        })
    }
    return null
}

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
PeopleChip.displayName = 'PeopleChip'
