import classnames from 'classnames'
import React from 'react'

import styles from './cell.module.css'

interface CellProps {
    active?: boolean
    border?: boolean
    disabled?: boolean
    borderTop?: boolean
    borderBottom?: boolean
    center?: boolean
    className?: string
    flex?: boolean
    fullHeight?: boolean
    grey?: boolean
    lightGrey?: boolean
    hide?: boolean
    horizontal?: boolean
    hover?: boolean
    margin?: number
    children?: any
    marginTop?: number
    marginBottom?: number
    padding?: any
    paddingTop?: number
    paddingBottom?: number
    right?: boolean
    left?: boolean
    round?: boolean
    separate?: boolean
    spaced?: string
    typo?: string
    testId?: string
    style?: object
    onClick?: any
    ref?: any
}

/**
 * @deprecated Use Telescope Stack or Inline instead
 */
export const Cell = React.forwardRef<HTMLDivElement, CellProps>((props, ref) => {
    const {
        children,
        padding,
        paddingTop,
        paddingBottom,
        margin,
        marginTop,
        marginBottom,
        grey,
        lightGrey,
        border,
        disabled,
        borderTop,
        borderBottom,
        hide,
        hover,
        fullHeight,
        active,
        center,
        right,
        left,
        typo,
        flex,
        spaced,
        horizontal,
        className,
        round,
        separate,
        testId,
        ...restProps
    } = props

    const classes = classnames(styles.cell, {
        [className || '']: !!className,
        [styles['padding' + padding]]: padding,
        [styles['paddingTop' + paddingTop]]: paddingTop,
        [styles['paddingBottom' + paddingBottom]]: paddingBottom,
        [styles['margin' + margin]]: margin,
        [styles['marginTop' + marginTop]]: marginTop,
        [styles['marginBottom' + marginBottom]]: marginBottom,
        [styles.isDisabled]: disabled,
        [styles.grey]: grey,
        [styles.lightGrey]: lightGrey,
        [styles.border]: border,
        [styles.borderTop]: borderTop,
        [styles.borderBottom]: borderBottom,
        [styles.hide]: hide,
        [styles.hover]: hover,
        [styles.active]: active,
        [styles.center]: !flex && center,
        [styles.centerFlex]: flex && center,
        [styles.right]: right,
        [styles.left]: left,
        [styles.flex]: flex || spaced,
        [styles.spaced]: spaced || fullHeight,
        [styles[`spaced-${spaced}`]]: spaced,
        [styles.horizontal]: (spaced || flex) && horizontal,
        [styles.round]: round,
        [styles.separate]: separate,
        [styles['typo-' + typo]]: typo,
    })

    return (
        <div
            ref={ref}
            className={classes}
            data-testid={testId}
            data-generic-ui="cell"
            {...restProps}
        >
            {children}
        </div>
    )
})

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
Cell.displayName = 'Cell'
