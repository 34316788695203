import {lazy, Suspense} from 'react'
import {Route} from 'react-router-dom'
import {Outlet} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {roleSets} from '@product-web/user'

const ReimbursementsPage = lazy(async () => import('./screens/reimbursements'))
const BalanceDetails = lazy(async () => import('./screens/balance-details/balance-details'))
const EntryDetails = lazy(async () => import('./screens/balance-details/entry-details'))
const PocketPage = lazy(async () => import('@product-web-apps/pocket/screens/pocket'))

export const BALANCES_ROUTE = 'reimbursements'

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<ReimbursementsPage />} />
        <Route path=":balanceId" element={<ReimbursementsPage />}>
            <Route
                index
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <BalanceDetails />
                    </Suspense>
                }
            />
            <Route
                path=":entryId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <EntryDetails />
                    </Suspense>
                }
            />
        </Route>
    </Route>
)

export function ReimbursementsRoutesWrapper() {
    const flagsLoaded = useFlagsLoaded()
    const {reimbursementsV2} = useFlags()

    if (!flagsLoaded) {
        return <LoadingPage />
    }

    return <>{reimbursementsV2 ? <Outlet /> : <PocketPage />}</>
}
