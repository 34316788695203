import {t} from '@lingui/macro'

import type {ICountryConfiguration} from './types'

import {TravelInsurance} from '../features/travel-insurance/types'
import {Feature} from '../types'

const SE: ICountryConfiguration = {
    [Feature.TravelInsurance]: {
        [TravelInsurance.ClaimInformation]: {
            policyDocumentUrl: 'terms-pleo-travel-insurance-se.pdf',
            phoneNumber: '+46 8446 895 05',
        },
    },
    [Feature.Paywall]: {
        perDiemEnabled: true,
    },
    [Feature.Mileage]: {
        canUseFuelRates: true,
        fuelRatesTitle: () => t`Company car rates`,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleose.aftership.com/',
    },
}

export default SE
