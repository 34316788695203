import {t} from '@lingui/macro'

import {useHasWalletFeature} from '@product-web/api-deimos/wallet'

import {useActiveBankAccountsWithSameCurrency} from './use-active-bank-accounts-with-same-currency'
import {useMinimumATUTransactionLimit} from './use-minimum-auto-top-up-transaction-limit'

export const useAutoTopUpPermissions = () => {
    const {hasWalletFeature: hasWalletAutoTopUpPermission, isLoading: isWalletFeaturesLoading} =
        useHasWalletFeature('AUTO_TOPUP')
    const {hasWalletFeature: hasVrpFeatureFlag} = useHasWalletFeature('VRP')
    const minimumATUTransactionLimit = useMinimumATUTransactionLimit()

    const {
        data: activeBankAccounts,
        error: activeBankAccountsError,
        isLoading: isActiveBankAccountsLoading,
    } = useActiveBankAccountsWithSameCurrency()
    const hasActiveBankAccounts = Boolean(!activeBankAccountsError && activeBankAccounts?.length)

    const isVisible =
        hasWalletAutoTopUpPermission && typeof minimumATUTransactionLimit !== 'undefined'

    // To have enabled value need to have at least one of:
    //  1. Active Bank Accounts
    //  2. VRP wallet-feature flag
    const isDisabled = !(hasActiveBankAccounts || hasVrpFeatureFlag)

    const disabledReason = (() => {
        if (isDisabled && !hasActiveBankAccounts) {
            return t`You don't have a verified bank account. Please make your first Top-up`
        }

        return undefined
    })()

    const isLoading = isWalletFeaturesLoading || isActiveBankAccountsLoading

    return {
        isVisible,
        isDisabled,
        disabledReason,
        isLoading,
    }
}
