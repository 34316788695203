import {useEffect, useMemo, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useExperiment} from 'statsig-react'

import {generateSystems} from '@product-web/accounting-systems'
import {useCompanySettings} from '@product-web/api-deimos/company-settings'
import {ExportFeature} from '@product-web/api-types/accounting'
import {useTokenData} from '@product-web/auth--session/context'
import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {useReviewManagementMigrationFlag} from '@product-web-features/review/review-feature-flags/review-feature-flags'

import {bff, bffHooks} from './bff-hooks'
import {useInternalNavigationContext} from './navigation-internal-provider'
import {
    ANALYTICS_COUNTRIES_WHITELIST,
    BILL_INVOICES_COUNTRIES_WHITELIST,
    VENDOR_CARDS_COUNTRIES_BLACKLIST,
} from './navigation-items-allowed-countries'
import type {ShowNavigationItemsContext} from './navigation-items-metadata'
import {getNavigationItemsMetadata} from './navigation-items-metadata'

function getSystemsWithoutPocket() {
    return (
        Object.entries(generateSystems())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, system]) => !system.supportedFeatures?.[ExportFeature.POCKET])
            .map(([name]) => name)
            .sort((a, b) => a.localeCompare(b))
    )
}

const navigationStorageBff = bffHooks.navigation.storage

export function useNavigationItems() {
    const location = useLocation()
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const featureFlags = useFlags()
    const isFlagsLoaded = useFlagsLoaded()
    const {isEnabled: isReviewerInfoFlagEnabled} = useReviewManagementMigrationFlag('REVIEWER_INFO')
    const {isEnabled: isCompanyReviewMigrationFlagEnabled} =
        useReviewManagementMigrationFlag('COMPANY_REVIEW')
    const {selectedEntityId} = useInternalNavigationContext()
    const {email: tokenEmail} = useTokenData()?.user || {}
    // Linear ticket: https://linear.app/pleo/issue/ACTIN-3339/optimize-deimos-endpoint-calls-for-accounting-system-settings
    // Using SWR hook to fetch company settings in order to reduce the number of requests sent to Deimos via BFFs
    // This is a temporary solution until we can start fetching accounting settings from Endymion
    const {data: companySettings} = useCompanySettings()
    const experiment = useExperiment('get_started_screen_variants_h1_2024')

    const pathname = location.pathname
    const isWorkingAsPartner = pathname.startsWith('/partner')

    const systemsWithoutPocket = useMemo(() => getSystemsWithoutPocket(), [])

    const {data, isLoading, isFetching, isStale} = bff.navigation.getNavigationData.useQuery(
        {
            isPartnerPortal: isWorkingAsPartner,
            enablePurchaseOrdersFeature: Boolean(featureFlags.enablePurchaseOrdersFeature),
            partnerAcademy: Boolean(featureFlags.partnerAcademy),
            partnerPricingCalculator: Boolean(featureFlags.partnerPricingCalculator),
            systemsWithoutPocket,
            analyticCountries: ANALYTICS_COUNTRIES_WHITELIST,
            billInvoicesCountries: BILL_INVOICES_COUNTRIES_WHITELIST,
            vendorCardBlacklistCountries: VENDOR_CARDS_COUNTRIES_BLACKLIST,
            isB4BNavChangeLive: Boolean(featureFlags.b4BNav),
            reviewPage: Boolean(featureFlags.reviewPage),
            reviewPermissionsMigration: Boolean(isReviewerInfoFlagEnabled),
            companyReviewMigration: Boolean(isCompanyReviewMigrationFlagEnabled),
            onboardingExperimentVariant: experiment.config.get('variant', 'control'),
            newVismaEconomicsMigration: featureFlags.newVismaEconomicsMigration?.split(',') || [],
            requestsPage: featureFlags.requestsPage,
            accountingSystem: companySettings?.accounting.system,
            analyticsPage: featureFlags.analyticsPage,
        },
        {
            enabled: isFlagsLoaded,
        },
    )

    const {items: navigationItems = [], email, companyId} = data || {}

    const showNavigationItemContext: ShowNavigationItemsContext = {
        isTablet,
    }

    const navItemsMetadata = getNavigationItemsMetadata()

    const filteredNavItems = navigationItems
        .filter((item) => {
            if (!navItemsMetadata[item.id]) {
                return false
            }

            if (navItemsMetadata[item.id].showIf) {
                return navItemsMetadata[item.id].showIf?.(showNavigationItemContext)
            }

            return true
        })
        .map((item) => {
            return {
                label: navItemsMetadata[item.id].label,
                match: item.matchPath,
                newFeature: item.newFeature,
                flag: item.flag ? navItemsMetadata[item.id]?.flag?.[item.flag] : undefined,
                to: item.to,
                extraNode: navItemsMetadata[item.id].extraNode,
            }
        })

    // We're using cache for immediate navigation items rendering on entity switch
    const cachedItemsByCompany = useRef<Map<string, typeof filteredNavItems>>(new Map())

    // We use this state to avoid unnecessary navigation items flickering
    // since it's only updated from cache
    const [items, setItems] = useState(filteredNavItems)

    // We need to take into account isFetching and isStale
    const isItemsLoading = isLoading || isFetching || isStale || !isFlagsLoaded

    useEffect(() => {
        if (!selectedEntityId) {
            // if selectedCompanyId is not defined we can not use cache
            // instead we use filteredNavItems directly
            return
        }

        if (!isItemsLoading && companyId === selectedEntityId && email === tokenEmail) {
            // When the final data with refreshed LD flags is loaded
            // we're updating the cache
            cachedItemsByCompany.current.set(selectedEntityId, filteredNavItems)
        }

        // if selectedCompanyId is defined we're always update items from cache for
        // immediate visual change
        setItems(cachedItemsByCompany.current.get(selectedEntityId) ?? [])
    }, [selectedEntityId, isItemsLoading, companyId, tokenEmail, email, isWorkingAsPartner])

    return {
        items: selectedEntityId ? items : filteredNavItems,
        isLoading: selectedEntityId ? items.length === 0 : filteredNavItems.length === 0,
    }
}

export const useReviewPageOnboardingUI = () => {
    const pathname = useLocation().pathname
    const flags = useFlags()
    const ctx = bff.useUtils()
    const isExpensePageOrInvoicePage =
        pathname.includes('/expenses') || pathname.includes('/invoices')
    const hasFeatureFlagChecked = flags.reviewPageOnboardingUi === true

    const {data: reviewPageOnboardingUi, isLoading} =
        navigationStorageBff.getReviewNewHomeOnboarding.useQuery(undefined, {
            enabled: isExpensePageOrInvoicePage && hasFeatureFlagChecked,
        })

    const storageKey = 'reviewPageNewHome'
    const optimisticUpdate = async (updatedValue?: {value: string | null}) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)

        await ctx.navigation.storage.getReviewNewHomeOnboarding.cancel()

        // Snapshot the previous value
        const previousSettings = ctx.navigation.storage.getReviewNewHomeOnboarding.getData()

        // Optimistically update to the new value
        ctx.navigation.storage.getReviewNewHomeOnboarding.setData(undefined, () => ({
            key: storageKey,
            value: updatedValue?.value ?? previousSettings?.value ?? null,
        }))
    }

    const {mutate: setReviewNewHomeOnboarding} =
        navigationStorageBff.setReviewNewHomeOnboarding.useMutation({
            onMutate: async (value) => {
                return optimisticUpdate(value)
            },
        })

    const isReviewPageOnboardingComplete = reviewPageOnboardingUi?.value === 'viewed'

    const showReviewPageOnboarding =
        isExpensePageOrInvoicePage &&
        hasFeatureFlagChecked &&
        !isReviewPageOnboardingComplete &&
        !isLoading

    const markReviewPageOnboardingAsViewed = () => {
        setReviewNewHomeOnboarding({value: 'viewed'})
    }

    return {
        showReviewPageOnboarding,
        markReviewPageOnboardingAsViewed,
    }
}
