import {t} from '@lingui/macro'
import {useMatch} from 'react-router-dom'

import {useAcademyContext} from '../../components/academy-context/academy-context'

export const useSideOverview = () => {
    const {country} = useAcademyContext()
    const matchPath = useMatch('/partner/academy/introduction-to-pleo/:currentPage')
    const currentPage = matchPath?.params.currentPage || ''

    const overviewSteps = [
        {isActive: currentPage === 'introduction', title: t`Introduction (3 mins)`},
        {isActive: currentPage === 'employee-experience', title: t`Employee experience (2 mins)`},
        {
            isActive: currentPage === 'pleo-for-your-clients',
            title: t`Pleo for your clients (2 mins)`,
        },
    ]

    const getUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/5e379cd3-b25b-4573-8034-f73cef2e54be'
            case 'SE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/zfIDiA-z2hCp8Nyr#/content/b2ddb560-c434-405a-ae1c-d968b0d58e6f'
            default:
                // default is GB/English
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/2fb6c6fd-137e-4de8-b26e-57ff57c30db3'
        }
    }

    const usefulMaterials = [
        {
            title: t`What is Pleo?`,
            url: getUrl(),
        },
        {title: t`Pleo price plans`, url: 'https://www.pleo.io/pricing'},
    ]

    return {overviewSteps, usefulMaterials}
}
