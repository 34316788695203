import React, {useCallback} from 'react'

import {reportError} from '@product-web/error/report'

type ActivePrompt = {
    name: string
}

type AdoptionPromptContextValue = {
    activePrompt: ActivePrompt | undefined
    addActivePrompt: (addedPrompt: ActivePrompt) => void
    removeActivePrompt: (removedPrompt: ActivePrompt) => void
}

/**
 * The context controls which of the active adoption prompts is visible to the user
 */
export const AdoptionPromptContext = React.createContext<AdoptionPromptContextValue>({
    activePrompt: undefined,
    addActivePrompt: () => {},
    removeActivePrompt: () => {},
})

export const AdoptionPromptProvider: React.FC = ({children}) => {
    const [activePrompts, setActivePrompts] = React.useState<ActivePrompt[]>([])

    const addActivePrompt = useCallback((addedPrompt: ActivePrompt) => {
        setActivePrompts((prompts) => {
            if (prompts.some((prompt) => prompt.name === addedPrompt.name)) {
                reportError(
                    `Encountered two prompts with the same name "${prompt.name}". Make sure to give unique names to each prompt.`,
                )
                return prompts
            }
            return [...prompts, addedPrompt]
        })
    }, [])

    const removeActivePrompt = useCallback((removedPrompt: ActivePrompt) => {
        setActivePrompts((prompts) =>
            prompts.filter((prompt) => prompt.name !== removedPrompt.name),
        )
    }, [])

    const contextValue: AdoptionPromptContextValue = {
        activePrompt: activePrompts[0],
        addActivePrompt,
        removeActivePrompt,
    }

    return (
        <AdoptionPromptContext.Provider value={contextValue}>
            {children}
        </AdoptionPromptContext.Provider>
    )
}

export const useAdoptionPrompt = () => {
    return React.useContext(AdoptionPromptContext)
}
