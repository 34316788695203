import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Link, Modal, Stack, Text} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import SuspendedImage from '@product-web/images/account/suspended.png'
import {getIsCompanyOwner, getIsPartnerUser, useUser} from '@product-web/user'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import {bff} from './bff-hooks'

interface ShouldShowSubscriptionModalProps {
    goingToExportPage: boolean
    hasUser: boolean
    userPending: boolean
    hasSubscriptionPaywallFlag: boolean
    isOwner: boolean
    isPartner: boolean
    hasNoSubscription: boolean
    isLoading: boolean
    hasCompanyName: boolean
    isFdd: boolean
}

interface SubscriptionNotFoundProps {
    companyName: string
    onClose: () => void
}

const ModalIconWrapper = styled(Modal.Illustration)`
    svg {
        width: 218px;
        height: 145px;
    }
`

export const SubscriptionNotFound: FC<SubscriptionNotFoundProps> = ({companyName, onClose}) => (
    <div data-testid="modal-subscription-not-found">
        <ModalIconWrapper>
            <img src={SuspendedImage} alt="" />
        </ModalIconWrapper>
        <Modal.Title>
            <Trans>{companyName}'s account has been marked as inactive</Trans>
        </Modal.Title>
        <Modal.Content>
            <Stack space={16}>
                <Text as="p">
                    <Trans>
                        Your account has been marked as inactive because it has no subscription.
                    </Trans>
                </Text>
                <Text as="p">
                    <Trans>
                        Don't worry - your cards, funds and expenses are safe until you close your
                        account or resume your subscription.
                    </Trans>
                </Text>
                <Text as="p">
                    <Trans>
                        If you do not know why your account has been marked as inactive, please
                        contact our support team.
                    </Trans>
                </Text>
            </Stack>
        </Modal.Content>
        <Modal.Actions>
            <Inline space={16} align="center">
                <ContactSupport buttonVariant="secondary" chatLabel={t`Close account`} />
                <ContactSupport buttonVariant="primary" chatLabel={t`Contact support`} />
            </Inline>
        </Modal.Actions>
        <Modal.Footer>
            <Stack align="center">
                <Text as="p" color="shade600">
                    <Trans>
                        Just here to export your data?{' '}
                        <Link onClick={() => onClose()} as={RouterLink} to="/export">
                            Export company data
                        </Link>{' '}
                    </Trans>
                </Text>
            </Stack>
        </Modal.Footer>
    </div>
)

export const getShouldShowSubscriptionModal = ({
    goingToExportPage,
    hasUser,
    userPending,
    hasSubscriptionPaywallFlag,
    isOwner,
    isPartner,
    hasNoSubscription,
    isLoading,
    hasCompanyName,
    isFdd,
}: ShouldShowSubscriptionModalProps) => {
    return (
        !goingToExportPage &&
        hasUser &&
        !userPending &&
        hasSubscriptionPaywallFlag &&
        isOwner &&
        !isPartner &&
        hasNoSubscription &&
        !isLoading &&
        hasCompanyName &&
        isFdd
    )
}

function useSubscriptionPaywall() {
    const user = useUser()
    const {subscriptionPaywall} = useFlags()
    const location = useLocation()
    const goingToExportPage = location.pathname.includes('/export')
    const {
        data: hasCompanySubscription,
        isLoading: isHasCompanySubscriptionLoading,
        error: hasCompanySubscriptionError,
    } = bff.paywall.paywallStatus.hasCompanySubscription.useQuery()

    const companyName = user?.company?.name

    const [shouldShow, setShouldShow] = useState(false)

    useEffect(() => {
        setShouldShow(
            getShouldShowSubscriptionModal({
                goingToExportPage,
                hasUser: Boolean(user),
                userPending: Boolean(user?.status?.pending),
                hasSubscriptionPaywallFlag: subscriptionPaywall,
                isOwner: Boolean(user && getIsCompanyOwner(user)),
                isPartner: getIsPartnerUser(user),
                hasNoSubscription:
                    !isHasCompanySubscriptionLoading &&
                    !hasCompanySubscriptionError &&
                    !hasCompanySubscription,
                isLoading: isHasCompanySubscriptionLoading,
                hasCompanyName: Boolean(companyName),
                isFdd: Boolean(user?.company?.status?.fdd),
            }),
        )
    }, [
        companyName,
        goingToExportPage,
        isHasCompanySubscriptionLoading,
        hasCompanySubscription,
        subscriptionPaywall,
        user,
    ])

    return {
        shouldShow,
        companyName,
    }
}

interface InnerSubscriptionModalProps {
    companyName: string
}

const InnerSubscriptionModal: FC<InnerSubscriptionModalProps> = ({companyName}) => {
    const [isOpen, setOpen] = useState(true)
    return (
        <Modal isOpen={isOpen}>
            <SubscriptionNotFound onClose={() => setOpen(false)} companyName={companyName} />
        </Modal>
    )
}

export const SubscriptionModal: FC = () => {
    const {shouldShow, companyName} = useSubscriptionPaywall()

    if (!shouldShow) {
        return null
    }

    return <InnerSubscriptionModal companyName={companyName || ''} />
}
