import * as React from 'react'
import {Navigate, Outlet, Route} from 'react-router-dom'

import {Page} from '@product-web-features/ui-page/page'

import {CompanyTab} from './screens/partner-company'

const PartnerCompany = React.lazy(async () => import('./screens/partner-company'))

const CompanyWrapper = () => (
    <Page layout="wide" showNav>
        <Outlet />
    </Page>
)

export const routes = (
    <>
        <Route index element={<Navigate to={`./${CompanyTab.INFO}`} replace />} />
        <Route element={<CompanyWrapper />}>
            <Route path={`${CompanyTab.INFO}`} element={<PartnerCompany tab={CompanyTab.INFO} />} />
            <Route
                path={`${CompanyTab.REFERRALS}`}
                element={<PartnerCompany tab={CompanyTab.REFERRALS} />}
            />
        </Route>
    </>
)
