import {getCollisions} from '@reach/popover'
import {useRect} from '@reach/rect'
import {useWindowSize} from '@reach/window-size'
import React from 'react'

import {tokens} from '@pleo-io/telescope'

interface PopupAnimationArgs {
    isOpen: boolean
    triggerRef?: React.RefObject<HTMLElement>
    popoverRef: React.RefObject<HTMLDivElement>
    triggerRect?: DOMRect | null
}

export const usePopupAnimation = ({
    isOpen,
    triggerRef,
    triggerRect: supplierTriggerRect,
    popoverRef,
}: PopupAnimationArgs) => {
    const [direction, setDirection] = React.useState<'below' | 'above'>('below')
    const triggerRectFromRef = useRect((triggerRef as React.RefObject<any>) ?? React.createRef())
    const triggerRect = triggerRef ? triggerRectFromRef : supplierTriggerRect
    const popoverRect = useRect(popoverRef as React.RefObject<any>)

    // shouldAnimate is set to false after the animation completes and while the popover is open
    // This is to prevent animation when the popover switches direction while it's open, due
    // to scroll or page resize triggering the edge collision detection
    const [shouldAnimate, setShouldAnimate] = React.useState(true)
    React.useEffect(() => {
        const timeoutId = setTimeout(() => setShouldAnimate(!isOpen), parseInt(tokens.smooth, 10))
        return function cleanup() {
            clearTimeout(timeoutId)
        }
    }, [isOpen])

    // Calculate if the popover can fit below the trigger using `getCollisions`,
    // which is what Reach uses internally for edge collision detection,
    // so that we are guaranteed to get the same direction for arrow as for the popover
    // Recalculate when the trigger moves around the screen or the window is resized
    const {height, width} = useWindowSize()
    const collidesDown = React.useMemo(() => {
        if (triggerRect && popoverRect) {
            return getCollisions(triggerRect, popoverRect).directionUp
        }
        return false
    }, [triggerRect, popoverRect, height, width])

    // Update the popover direction, so we can render the triangle in the correct
    // place and correct rotation
    React.useEffect(() => {
        if (triggerRect && popoverRect) {
            if (collidesDown) {
                setDirection('above')
            } else {
                setDirection('below')
            }
        }
    }, [triggerRect, popoverRect, collidesDown])

    return {isAbove: direction === 'above', shouldAnimate, triggerRect}
}
