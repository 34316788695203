import {generateKeyPair} from '@pleo-io/web-crypto'

type ResolvedValue<T> = T extends PromiseLike<infer U> ? U : T

export type KeyPair = ResolvedValue<ReturnType<typeof generateKeyPair>>

let keyPair: KeyPair

export async function getKeyPair(options?: {force: true}) {
    if (!options?.force && keyPair) {
        return keyPair
    }

    keyPair = await generateKeyPair()
    return keyPair
}
