import {t} from '@lingui/macro'

import type {Account, AccountCategory} from '@pleo-io/deimos'
import {NO_SUITABLE_ACCOUNT_ID} from '@pleo-io/deimos'

export const findAccountItem = (id: string, accountCategories: AccountCategory[]) => {
    const item: {
        accountIndex: number
        account?: Account
        accountCategoryIndex: number
        accountCategory?: AccountCategory
    } = {
        accountIndex: -1,
        account: undefined,
        accountCategoryIndex: -1,
        accountCategory: undefined,
    }
    for (const [accIndex, acc] of accountCategories.entries()) {
        const index = acc?.accounts?.findIndex((account: Account) => account.id === id) ?? -1
        if (acc?.accounts?.length && index !== -1) {
            item.accountIndex = index
            item.account = acc?.accounts[item.accountIndex]
            item.accountCategoryIndex = accIndex
            item.accountCategory = acc
            break
        }
    }

    return item
}

const getAccountCategoryByAccountId = (id: string, categories: AccountCategory[]) =>
    categories.find((category) => category.accounts?.find((account: Account) => account.id === id))

export const getAccountCategoryById = (id: string, categories: AccountCategory[]) =>
    categories.find((category) => category.id === id)

const getAccountCategoryByKey = (typeKey: string, categories: AccountCategory[]) =>
    categories.find((category) => category.typeKey === typeKey)

export const getAccountCategory = ({
    accountId,
    typeKey,
    categories,
}: {
    accountId: string | null
    typeKey: string | null
    categories: AccountCategory[]
}) =>
    accountId
        ? getAccountCategoryByAccountId(accountId, categories)
        : !typeKey
          ? undefined
          : getAccountCategoryByKey(typeKey, categories)

export const getAccounts = (categories: AccountCategory[]) =>
    categories.reduce(
        (arr: Account[], curr: AccountCategory) => [...arr, ...(curr?.accounts ?? [])],
        [],
    )

export const getAccountById = ({
    id,
    categories,
}: {
    id?: string | null
    categories: AccountCategory[]
}) => getAccounts(categories).find((account) => account.id === id)

export const getLabelForAccount = (account: Account) => {
    if (account.id === NO_SUITABLE_ACCOUNT_ID) {
        return generateNoSuitableAccountLabel()
    }

    return account.name
}

export const generateNoSuitableAccountLabel = () =>
    t({
        id: 'no_suitable_category',
        message: "I don't know",
    })
