// Allows to send user to the login page and specify what location they should end up
// on after a successful login.

import React from 'react'
import {useLocation, useNavigate} from 'react-router'

export const LOGIN_REDIRECT_PATH_STORAGE_KEY = 'gotoAfterLogin'
type ForceLoginLocationState = {email?: string; redirect?: string}

/**
 * Handles setting up the redirect on the login page.
 */
export function useHandleLoginState() {
    const location = useLocation()
    const state = (location.state ?? {}) as ForceLoginLocationState

    React.useEffect(() => {
        const redirectAfterLogin = state.redirect
        if (redirectAfterLogin && redirectAfterLogin !== '/') {
            sessionStorage.setItem(LOGIN_REDIRECT_PATH_STORAGE_KEY, redirectAfterLogin)
        }
    }, [])
}

export function useForceLogin() {
    const navigate = useNavigate()
    const location = useLocation()
    /**
     * Navigates the anonymous user the login page, ensuring that the user will be redirected back
     * to the current page after the successful login.
     * @param email The email to pre-fill the login form with
     */
    return function forceLogin(email?: string) {
        const state: ForceLoginLocationState = {
            email,
            redirect: location.pathname + location.search,
        }
        navigate('/login', {state})
    }
}
