/**
 * @overview Chip
 * @stage Proposed
 * @author Xenia (@Xenia)
 * @designer TODO
 * @spec TODO
 */

import type {DataAttributes} from 'styled-components'
import styled from 'styled-components'

import {Inline, tokens} from '@pleo-io/telescope'

export const Chip = styled(Inline).attrs<DataAttributes>({'data-generic-ui': 'chip'})`
    padding: ${tokens.spacing2} ${tokens.spacing8};
    background-color: ${tokens.shade200};
    border-radius: ${tokens.spacing24};
    transition: ${`background-color` + tokens.smoothInOut};
`
