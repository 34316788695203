import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

export const Root = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${tokens.spacing32} 0;
`

export const Content = styled.div<{$maxWidth?: string}>`
    max-width: ${({$maxWidth}) => $maxWidth ?? '480px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const Image = styled.img.attrs({alt: ''})`
    margin-bottom: ${tokens.spacing20};
`

export const Title = styled(Text).attrs({as: 'h2', variant: '2xlarge-accent', space: 20})``

export const Subtitle = styled(Text).attrs({
    variant: 'medium-default',
    space: 32,
})``
