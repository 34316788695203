import {Trans} from '@lingui/macro'
import type {LottieRefCurrentProps} from 'lottie-react'
import Lottie from 'lottie-react'
import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Stack, Tag, Text} from '@pleo-io/telescope'

import animationData from './animations/pleo-product.json'

import {ActionItem} from '../../../../components/action-item/action-item'
import {useAcademyContext} from '../../components/academy-context/academy-context'

export const ThePleoProduct = () => {
    const {hideInvoices} = useAcademyContext()
    const lottieRef = React.useRef<LottieRefCurrentProps>(null)

    React.useEffect(() => {
        // The initial frames of the animation are all the same. To make it start as soon as the user hovers on top of it, we skip to frame 25.
        lottieRef.current?.goToAndStop(25, true)
    }, [lottieRef])

    return (
        <ActionItem
            as={Link}
            onMouseEnter={() => lottieRef.current?.play()}
            onMouseLeave={() => lottieRef.current?.pause()}
            to="/partner/academy/the-pleo-product"
        >
            <Stack>
                <Animation
                    lottieRef={lottieRef}
                    animationData={animationData}
                    autoplay={false}
                    loop
                />
                <ActionItem.Divider />
                <ActionItem.Content>
                    <Stack space={16}>
                        <Text variant="xlarge-accent">
                            <Trans>The Pleo Product</Trans>
                        </Text>
                        <Text color="colorContentStaticQuiet">
                            {hideInvoices ? (
                                <Trans>
                                    Discover some core features to help with expense management (6
                                    mins)
                                </Trans>
                            ) : (
                                <Trans>
                                    Discover some core features to help with expense management (8
                                    mins)
                                </Trans>
                            )}
                        </Text>
                        <Inline space={6}>
                            <Tag variant="pink">
                                <Trans>Admins</Trans>
                            </Tag>
                            <Tag variant="gray">
                                <Trans>Bookkeepers</Trans>
                            </Tag>
                        </Inline>
                    </Stack>
                </ActionItem.Content>
            </Stack>
        </ActionItem>
    )
}

const Animation = styled(Lottie)`
    width: 100%;
    display: flex;
`
