import {useEffect, useMemo, useRef} from 'react'

type FunctionForDebouncePromise<T> = (...args: any[]) => Promise<T>
type WithClear<T> = T & {cancel: () => void}

/**
 * Allows properly debounce functions which returns promise.
 * Optimised to be used inside react and does proper cleanup.
 *
 * Similar to <code>lodash.debounce(func, delay, {leading: false, trailing: true})</code> (with {leading: false} option)
 * and to  <code>lodash.debounce(func, delay, {leading: true, trailing: false})</code> (with {leading: true} option)
 * but works as you would expect it to work with promises.
 *
 * 1) leading == false:
 * The only promise which will be resolved/rejected - is one returned by last invocation of the debounced function.
 * (lodash.debounce with these params will return <code>undefined</code> until delay passes).
 *
 * @example:
 *
 * const debouncedFunction = useDebouncedPromise((result: string) => {
 *     return Promise.resolve(result)
 * }, 5000)
 *
 * useEffect(() => {
 *     [1, 2, 3, 4, 5].forEach((index) => {
 *         debouncedFunction(`invocation ${index}`).then((result) => {
 *            console.log(result)
 *         })
 *     })
 * }, [])
 *
 * // 1. If component was not unmounted during 5 seconds:
 * //   Will log [invocation 5] in 5 seconds:
 * //   Other promises will never be resolved or rejected
 * // 2. If component was unmounted before 5 seconds passed:
 * //   will not log anything: no promises will be resolved
 *
 * 2) leading == true:
 * The very first function invocation will immediately execute original function.
 * All subsequent function invocations that happen in less than <code>delay</code> time:
 * - will return the same promise as the very first function invocation
 * - will delay an ability to invoke original question by <code>delay</code> millis
 *
 * @example:
 *
 * const debouncedFunction = useDebouncedPromise((result: string) => {
 *     return Promise.resolve(result)
 * }, 5000)
 *
 * useEffect(() => {
 *     [1, 2, 3, 4, 5].forEach((index) => {
 *         debouncedFunction(`invocation ${index}`).then((result) => {
 *            console.log(result)
 *         })
 *     })
 *     setTimeout(() => {
 *        debouncedFunction(`invocation 6`).then((result) => {
 *            console.log(result)
 *         })
 *     }, 4999)
 *     setTimeout(() => {
 *        debouncedFunction(`invocation 7`).then((result) => {
 *            console.log(result)
 *         })
 *     }, 10000)
 * }, [])
 *
 * // 1. Will Immediately log [invocation 1]
 * // 2. After 10 seconds from the initial effect invocation [invocation 7] will be logged to the console
 *
 *
 * @see debouncePromise
 * @param func original function that returns promise and should be debounced
 * @param delay delay in milliseconds between possible original function invocations
 * @param {leading} when set to true - will immediately execute original function.
 *  Otherwise, will execute original function only if there will be no function invocations
 *  within <code>delay</code> milliseconds
 *  @return debounced function that calls original passed function in the controlled manner described above.
 *  returned function has property <code>clean</code> which allows
 *  forcibly stop all pending calls to the original function
 */
export const useDebouncePromise = <T>(
    func: FunctionForDebouncePromise<T>,
    delay = 0,
    {leading = false}: {leading?: boolean} = {},
): WithClear<FunctionForDebouncePromise<T>> => {
    const ref = useRef<WithClear<FunctionForDebouncePromise<T>> | null>(null)

    const result = useMemo(() => {
        return debouncePromise(func, delay, {leading})
    }, [func, delay, leading])

    useEffect(() => {
        ref.current?.cancel()
        ref.current = result
        // we have to cancel all pending calls to the original function
        // on component unmount lifecycle. Otherwise, we might get
        // "Can't perform a React state update on an unmounted component" error
        return () => ref.current?.cancel()
    }, [result])

    return result
}

/**
 * Allows to properly debounce functions which returns promise.
 *
 * Similar to <code>lodash.debounce(func, delay, {leading: false, trailing: true})</code> (with {leading: false} option)
 * and to <code>lodash.debounce(func, delay, {leading: true, trailing: false})</code> (with {leading: true} option)
 * but works as you would expect it to work with promises.
 *
 * 1) leading == false (default):
 * The only promise which will be resolved/rejected - is one returned by last invocation of the debounced function.
 * (lodash.debounce with these params will return <code>undefined</code> until delay passes).
 *
 * @example:
 *
 * const debouncedFunction = debouncePromise((result: string) => {
 *     return Promise.resolve(result)
 * }, 5000)
 *
 * [1, 2, 3, 4, 5].forEach((index) => {
 *     debouncedFunction(`invocation ${index}`).then((result) => {
 *        console.log(result)
 *     })
 * })
 *
 * // Will log [invocation 5] in 5 seconds:
 * // Other promises will never be resolved or rejected
 *
 * 2) leading == true:
 * The very first debounced function invocation will immediately invoke original function.
 * All subsequent debounced function invocations that happen in less than <code>delay</code> ms:
 * - will return the same promise as the very first debounced function invocation
 * - will delay an ability to invoke original question by <code>delay</code> millis
 *
 * @example:
 *
 * const debouncedFunction = debouncePromise((result: string) => {
 *     return Promise.resolve(result)
 * }, 5000)
 *
 * [1, 2, 3, 4, 5].forEach((index) => {
 *     debouncedFunction(`invocation ${index}`).then((result) => {
 *        console.log(result)
 *     })
 * })
 *
 * setTimeout(index) => {
 *     debouncedFunction(`invocation 6`).then((result) => {
 *        console.log(result)
 *     })
 * }, 4999)
 *
 * setTimeout(index) => {
 *     debouncedFunction(`invocation 7`).then((result) => {
 *        console.log(result)
 *     })
 * }, 10000)
 *
 * // Will Immediately log [invocation 1]
 * // After 10 seconds from the initial effect invocation [invocation 7] will be logged to the console
 *
 * @param func original function that returns promise and should be debounced
 * @param delay delay in milliseconds between possible original function invocations
 * @param {leading}
 *  When set to true - will immediately execute original function and will not execute it again
 *  unless there will be no debounced function invocations within <code>delay</code> milliseconds.
 *  When set to false (by default) - will execute original function only if there will be no
 *  function invocations within <code>delay</code> milliseconds
 * @return debounced function that calls original passed function in the controlled manner described above.
 *  returned function has property <code>clean</code> which allows
 *  forcibly stop all pending calls to the original function
 */
export function debouncePromise<T>(
    func: FunctionForDebouncePromise<T>,
    delay = 0,
    {leading = false}: {leading?: boolean} = {},
): WithClear<FunctionForDebouncePromise<T>> {
    let timer: number | null = null
    let result: Promise<T> | null = null

    function clearTimer() {
        timer && clearTimeout(timer)
    }

    async function debounce(...args: any) {
        return new Promise<T>((resolve) => {
            clearTimer()

            if (leading) {
                if (!result) {
                    result = func(...args)
                }
                timer = window.setTimeout(() => (result = null), delay)

                resolve(result)
            } else {
                timer = window.setTimeout(() => resolve(func(...args)), delay)
            }
        })
    }

    debounce.cancel = () => {
        clearTimer()
        result = null
    }

    return debounce
}
