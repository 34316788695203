/**
 * `absurd` is a function that can never be called in a well-typed program,
 * because it demands an argument of type `never`, which cannot be obtained.
 *
 * It is generic in the return type, so it can be used in any expression.
 *
 * This is useful for exhaustiveness checking in TypeScript.
 *
 * In cases where `absurd` is called, it will always throw an error. This
 * signifies that the program is not well-typed, perhaps due to lack of
 * validation of IO input.
 *
 * @link https://github.com/gcanti/fp-ts/issues/847
 * @link https://blog.marksauerutley.com/absurd/
 *
 * @example
 * ```typescript
 * type Foo = 'a' | 'b'
 *
 * function bar(x: Foo): number {
 *   switch (x) {
 *     case 'a': return 42
 *     case 'b': return 1337
 *     // The following branch is unreachable, because we've exhausted all
 *     // possible values of `Foo`.
 *     //
 *     // If in the future we add a new member to `Foo`, it will become
 *     // reachable, which will result in a compile-time error.
 *     //
 *     // Because `absurd` returns any type we want, we can use it to satisfy
 *     // the return type of `bar`.
 *     default: return absurd(x)
 *   }
 * }
 * ```
 *
 * @param _ unsatisfiable condition
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function absurd<T>(_: never): T {
    throw new Error('absurd: unreachable code')
}
