import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Inline, Link as TelescopeLink, Loading, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, NewTab} from '@pleo-io/telescope-icons'

import FetchDiggingIllustration from '@product-web/images/fetch-digging.svg'
import FetchDogLookingOverFenceIllustration from '@product-web/images/fetch-dog-looking-over-fence.svg'
import FetchDogSecurityIllustration from '@product-web/images/fetch-dog-security.svg'
import FetchGratefulDogIllustration from '@product-web/images/fetch-grateful-dog.svg'
import FetchLetsGetStartedIllustration from '@product-web/images/fetch-lets-get-started.svg'
import FetchSomethingWentWrongIllustration from '@product-web/images/fetch-something-went-wrong.svg'
import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'
import {theme} from '@product-web/styles/theme'
import type {Step, StepSection} from '@product-web-features/ui-guides/guide'
import * as s from '@product-web-features/ui-guides/guide.styles'

import {BackButton, ContactSupportButton, DoneButton, NextButton, TryAgainButton} from './buttons'
import type {FetchScreenSectionsProps} from './setup-modal'
import {trackFetchOnboarding} from './setup-modal-tracking'

import type {FetchInboxProviderInformation} from '../fetch'

interface FetchStep extends Step {
    sections?: FetchStepSection[]
}

interface FetchStepSection extends StepSection {
    content?: ((props: Partial<FetchScreenSectionsProps>) => ReactNode) | ReactNode
}

export enum FetchGuideStep {
    INTRO,
    SECURITY,
    PROVIDER,
    RESULT,
}

export const IntroSteps = (isMobileScreen: boolean) => [
    {
        key: 'intro',
        name: 'intro',
        color: theme.colors.pink500,
        illustrationBgColor: theme.colors.pink300,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>You make the purchase, we fetch the receipt</Trans>
            </Text>
        ),
        illustration: (
            <s.Illustration $alignX="right" $marginBottom={-150}>
                <img src={FetchDogLookingOverFenceIllustration} alt="" />
            </s.Illustration>
        ),
        sections: [
            {
                key: 'one',
                content: (
                    <s.FlexRow>
                        <Text as="p" align="left">
                            <Trans>
                                With Fetch, Pleo will automatically find the receipt from your email
                                inbox and add them to a Pleo expense.
                                <br /> We support Gmail, Google Workspace, Microsoft 365, Outlook
                                and Hotmail.
                            </Trans>
                        </Text>
                    </s.FlexRow>
                ),
            },
        ],
        actions: [
            {
                key: 'next-step',
                action: NextButton,
            },
        ],
    },
    {
        key: 'security',
        name: 'security',
        color: theme.colors.yellow500,
        illustrationBgColor: theme.colors.yellow400,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Keeping your data secure is our priority</Trans>
            </Text>
        ),
        illustration: (
            <s.Illustration
                $span={isMobileScreen ? 50 : 80}
                $alignX={isMobileScreen ? undefined : 'right'}
                $marginBottom={isMobileScreen ? -10 : -30}
                $marginRight={isMobileScreen ? -50 : 0}
            >
                <img
                    src={FetchDogSecurityIllustration}
                    /* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */
                    alt="fetch dog security"
                />
            </s.Illustration>
        ),
        sections: [
            {
                key: 'one',
                content: (
                    <s.FlexRow>
                        <Text as="p" align="left">
                            <Trans>
                                Fetch only looks for receipts that match your expense. It's designed
                                to avoid conversational or private data. Don't just take our word
                                for it, we've even been audited to ensure this.
                            </Trans>{' '}
                            <Link
                                href={getHelpCentreArticleLinkIntercom(3133170)}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    trackFetchOnboarding({
                                        step: 'security',
                                        action: 'learn_more_clicked',
                                    })
                                }}
                                IconRight={NewTabIcon}
                            >
                                <Trans>Learn more about privacy</Trans>
                            </Link>
                        </Text>
                    </s.FlexRow>
                ),
            },
        ],
        actions: [
            {
                key: 'previous-step',
                action: BackButton,
            },
            {
                key: 'next-step',
                action: NextButton,
            },
        ],
    },
]

const step3CommonProperties = (isMobileScreen: boolean) => ({
    color: theme.colors.purple500,
    illustrationBgColor: theme.colors.purple300,
    illustration: (
        <s.Illustration $span={isMobileScreen ? 80 : 90} $alignX="left" $marginBottom={-100}>
            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
            <img src={FetchLetsGetStartedIllustration} alt="fetch dog get started" />
        </s.Illustration>
    ),
})

export const ProvidersListStep = (isMobileScreen: boolean) =>
    ({
        name: 'choose_provider',
        key: 'choose_provider',
        ...step3CommonProperties(isMobileScreen),
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                {/* eslint-disable-next-line string-to-lingui/text-restrictions */}
                <Trans>Let's get started!</Trans>
            </Text>
        ),
        actions: [
            {
                key: 'previous-step',
                action: BackButton,
            },
        ],
        sections: [
            {
                key: 'one',
                content: (
                    <s.FlexRow>
                        <Text as="p" align="left">
                            <Trans>
                                Please select your email provider below to start using Fetch.
                            </Trans>
                        </Text>
                    </s.FlexRow>
                ),
            },
            {
                key: 'two',
                content: function renderStepContent({receiptsProviders = {}}) {
                    return (
                        <Stack space={12}>
                            {Object.keys(receiptsProviders).map((key) => (
                                <FetchProviderButton key={key} {...receiptsProviders[key]} />
                            ))}
                        </Stack>
                    )
                },
            },
        ],
    }) as FetchStep

export const OtherProviderStep = (isMobileScreen: boolean) =>
    ({
        name: 'other-provider',
        key: 'other-provider',
        ...step3CommonProperties(isMobileScreen),
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Other email provider</Trans>
            </Text>
        ),
        actions: [
            {
                key: 'previous-step',
                action: BackButton,
            },
        ],
        sections: [
            {
                key: 'one',
                content: (
                    <s.FlexRow>
                        <Text as="p" align="left">
                            <Trans>
                                If your email provider isn't supported, you can forward your
                                receipts to Pleo and we'll match them to your expense.
                            </Trans>

                            <Link
                                href={getHelpCentreArticleLinkIntercom(6589242)}
                                target="_blank"
                                rel="noreferrer"
                                IconRight={NewTabIcon}
                            >
                                <Trans>Learn more about Forward to Pleo</Trans>
                            </Link>
                        </Text>
                    </s.FlexRow>
                ),
            },
        ],
    }) as FetchStep

const Link = styled(TelescopeLink)`
    display: block;
    margin-top: ${tokens.spacing8};
`

const NewTabIcon = styled(NewTab)`
    margin-left: ${tokens.spacing4};
    vertical-align: text-top;
`

const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
`

export const ProviderConnectingStep = {
    name: 'connecting',
    key: 'connecting',
    ...step3CommonProperties,
    title: (
        <Text variant="4xlarge-accent" as="h3" align="left">
            <Trans>Connecting your inbox</Trans>
        </Text>
    ),
    sections: [
        {
            key: 'one',
            content: (
                <LoaderWrapper>
                    <Loading size={16} />
                </LoaderWrapper>
            ),
        },
    ],
}

export const ConnectedStep = (isMobileScreen: boolean) => ({
    key: 'success',
    name: 'success',
    color: theme.colors.green600,
    illustrationBgColor: theme.colors.green500,
    title: (
        <Text variant="4xlarge-accent" as="h3" align="left">
            <Trans>Fetch is up and running</Trans>
        </Text>
    ),
    illustration: (
        <s.Illustration
            $span={isMobileScreen ? 60 : undefined}
            $marginBottom={isMobileScreen ? 10 : undefined}
        >
            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
            <img src={FetchGratefulDogIllustration} alt="fetch dog is grateful" />
        </s.Illustration>
    ),
    sections: [
        {
            key: 'one',
            content: (
                <s.FlexRow>
                    <Text as="p" align="left">
                        <Trans>
                            From now on, Fetch will try to match receipts in your inbox to online
                            expenses in Pleo. Look for the <b>From Fetch</b> label on the receipt to
                            see when it's found by Fetch.
                        </Trans>
                    </Text>
                </s.FlexRow>
            ),
        },
    ],
    actions: [
        {
            key: 'all-done',
            action: DoneButton,
        },
    ],
})

export const MissingReceiptStep = (isMobileScreen: boolean) => ({
    key: 'success_going_back_in_time',
    name: 'success_going_back_in_time',
    color: theme.colors.green600,
    illustrationBgColor: theme.colors.green500,
    title: (
        <Text variant="4xlarge-accent" as="h3" align="left">
            <Trans>Fetch will look for past receipts</Trans>
        </Text>
    ),
    illustration: (
        <s.Illustration $marginBottom={isMobileScreen ? -60 : undefined}>
            <img src={FetchDiggingIllustration} alt="" />
        </s.Illustration>
    ),
    sections: [
        {
            key: 'one',
            content: (
                <s.FlexRow>
                    <Text as="p" align="left">
                        <Trans>
                            Now that you've successfully connected Fetch to your inbox, we'll start
                            searching for receipts for your recent expenses. You'll be notified once
                            we're done.
                        </Trans>
                    </Text>
                </s.FlexRow>
            ),
        },
    ],
    actions: [
        {
            key: 'all-done',
            action: DoneButton,
        },
    ],
})

export const ErrorStep = {
    key: 'something_went_wrong',
    name: 'something_went_wrong',
    color: theme.colors.red500,
    title: (
        <Text variant="4xlarge-accent" as="h3" align="left">
            <Trans>Something went wrong</Trans>
        </Text>
    ),
    illustration: (
        <s.Illustration $span={90} $marginBottom={-150}>
            <img src={FetchSomethingWentWrongIllustration} alt="" />
        </s.Illustration>
    ),
    sections: [
        {
            key: 'one',
            content: (
                <s.FlexRow>
                    <Text as="p" align="left">
                        <Trans>
                            Sorry, we were unable to connect your inbox to Pleo, please try again.
                        </Trans>
                    </Text>
                </s.FlexRow>
            ),
        },
    ],
    actions: [
        {
            key: 'contact-support',
            action: ContactSupportButton,
        },
        {
            key: 'try-again',
            action: TryAgainButton,
        },
    ],
}

const FetchProviderButton = ({
    title,
    subtitle,
    logo = null,
    ...otherProps
}: FetchInboxProviderInformation) => (
    <EmailProviderButton
        role={otherProps.href ? 'link' : 'button'}
        as={otherProps.href ? 'a' : 'div'}
        {...otherProps}
    >
        {logo && <LogoWrapper>{logo}</LogoWrapper>}
        <Stack>
            <ProviderTitle color="shade900">{title}</ProviderTitle>
            {subtitle && (
                <Text variant="small-subtle" as="p">
                    {subtitle}
                </Text>
            )}
        </Stack>
        <IconRight />
    </EmailProviderButton>
)

const EmailProviderButton = styled(Inline)`
    width: 100%;
    border: ${tokens.borderPrimary};
    padding: ${tokens.spacing18};
    border-radius: ${tokens.arc8};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    box-shadow: 0 2px 11px rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: ${tokens.smoothInOut};
    transition-property: box-shadow, transform;
    position: relative;

    &:hover {
        box-shadow: ${tokens.shadowElevateQuiet};
        transform: scale(1.02);
    }
`

const LogoWrapper = styled(Inline)`
    margin-right: ${tokens.spacing12};
    margin-top: -${tokens.spacing2};

    svg {
        width: ${tokens.spacing40};
        height: ${tokens.spacing40};
    }
`

const ProviderTitle = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    font-size: ${tokens.spacing16};
    line-height: ${tokens.spacing20};
`

const IconRight = styled(ArrowRight).attrs({size: 16})`
    &&& {
        margin: auto 0 auto auto;
        font-size: ${tokens.fontXLarge};
        color: ${tokens.shade600};
    }
`
