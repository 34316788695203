import type {FC} from 'react'

import {ExpenseViewType} from '@pleo-io/deimos'
import {tokens} from '@pleo-io/telescope'
import type {Props as IconProps} from '@pleo-io/telescope-icons'
import {ArrowCircleLeft, CashSingle, Reimburse, SendMoney} from '@pleo-io/telescope-icons'

interface Props extends IconProps {
    type?: ExpenseViewType | string
}

export const CashIcon: FC<Props> = ({type, color, size}) => {
    switch (type) {
        case ExpenseViewType.EXPENSE:
        case ExpenseViewType.REIMBURSEMENT:
        case 'REIMBURSEMENT':
        case 'EXTERNAL_REIMBURSEMENT':
            return <Reimburse size={size} color={color ?? tokens.purple700} />
        case ExpenseViewType.TRANSFER_REFUND:
        case 'TRANSFER_REFUND':
        case 'MANUAL_REFUND':
            return <SendMoney size={size} color={color ?? tokens.purple700} />
        case ExpenseViewType.CASH_RETURNED:
        case 'CASH_RETURNED':
            return <ArrowCircleLeft size={size} color={color ?? tokens.purple700} />
        default:
            return <CashSingle size={size} color={color ?? tokens.purple700} />
    }
}
