import {t} from '@lingui/macro'
import type {ReactNode} from 'react'

export interface ShowNavigationItemsContext {
    isTablet?: boolean
}

export interface NavigationItemMetadata {
    label: string
    flag?: Record<string, string>
    showIf?: (params: ShowNavigationItemsContext) => boolean
    // The extra node accepts a custom function, so it can render
    // additional components outside the link in Navigation
    // i.e.: Popovers
    extraNode?: () => ReactNode
}

export const getNavigationItemsMetadata = (): Record<string, NavigationItemMetadata> => ({
    analytics: {label: t`Analytics`},
    budgets: {
        label: t`Budgets`,
        flag: {
            BETA: t`BETA`,
        },
    },
    expenses: {label: t`Expenses`},
    review: {
        label: t`Review`,
    },
    requests: {
        label: t`Requests`,
    },
    export: {
        label: t`Export`,
        showIf: ({isTablet}) => !isTablet,
    },
    reimbursements: {
        label: t`Reimbursements`,
    },
    recurringVendors: {
        label: t`Recurring vendors`,
    },
    vendorCards: {
        label: t`Vendor cards`,
    },
    getStarted: {label: t`Get started`},
    pleoGuide: {
        label: t`Get started`,
    },
    verifyCompany: {
        label: t`Company verification`,
    },
    myCards: {label: t`My cards`},
    people: {
        label: t`People`,
    },
    partnerPortal: {label: t`Partner Portal`},
    partnerBackToPleo: {label: t`← Back to Pleo`},
    partnerGetStarted: {label: t`Get started`},
    partnerClients: {label: t`Clients`},
    partnerTeam: {label: t`People`},
    partnerResources: {label: t`Resources`},
    partnerAcademy: {
        label: t`Academy`,
        flag: {
            BETA: t`New`,
        },
    },
    partnerPricingCalculator: {
        label: t`Pricing calculator`,
        flag: {
            BETA: t`New`,
        },
    },
    partnerCompany: {
        label: t`Company`,
    },
    partnerInfo: {
        label: t`Practice info`,
    },
    billInvoices: {
        label: t`Invoices`,
        flag: {
            BETA: t`New`,
        },
    },
    purchaseOrders: {
        label: t`Purchase Orders`,
    },
    wallet: {
        label: t`Wallet`,
    },
    settings: {
        label: t`Settings`,
    },
})
