import 'core-js/stable'
import 'focus-visible'
import '@product-web/styles/global-styles'
import '@product-web/validation/yup'
/**
 * See https://notion.so/pleo/Issues-updating-react-pdf-bfe98b5ba5db476f92512e220333329b
 *
 * TODO: Wrap in conditional to send alert when widely available - https://linear.app/pleo/issue/WEB-1408
 */
import 'core-js/proposals/promise-with-resolvers'

import {render} from 'react-dom'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import {initDataDog} from './datadog'
import {initSentry} from './error/sentry'
import {getRoutes} from './routes'

initDataDog()
initSentry()

const router = createBrowserRouter(getRoutes())
const renderApp = () => render(<RouterProvider router={router} />, document.getElementById('app'))

if (import.meta.env.MODE === 'development') {
    import('./load-mocks-in-local')
        .then(async ({loadMocksInLocal}) => loadMocksInLocal())
        .then(() => {
            renderApp()
        })
        .catch((error) => {
            throw error
        })
} else {
    renderApp()
}
