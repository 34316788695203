import {useState} from 'react'

// typescript 3.8 can not do proper type inference if useHover is used in this way:
// const [hovered, {onMouseEnter, onMouseLeave}] = useHover()
// so we need this type to help it
type UseHoverResult = [boolean, {onMouseEnter: () => void; onMouseLeave: () => void}]

export function useHover(): UseHoverResult {
    const [hovered, setHovered] = useState<boolean>(false)
    const handlers = {
        onMouseEnter: (): void => setHovered(true),
        onMouseLeave: (): void => setHovered(false),
    }
    return [hovered, handlers]
}
