import type {AnchorHTMLAttributes, FC} from 'react'
import type React from 'react'

import {Link} from '@pleo-io/telescope'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
    url: string
    children: React.ReactNode
}

const HelpLink: FC<Props> = ({url, children, ...props}: Props) => {
    return (
        <Link
            rel="noopener noreferrer"
            target="_blank"
            href={url}
            data-generic-ui="helplink"
            {...props}
        >
            {children}
        </Link>
    )
}

export default HelpLink
