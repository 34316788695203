import type {SupportedLanguage} from '@product-web/i18n'

const MINOR_CURRENCY_DIVISOR = 100

type FormatMinorNumberType = {
    locale?: SupportedLanguage
    value: number
    options?: Intl.NumberFormatOptions
}

export function getAmountFromMinorCurrencyUnits(minorCurrencyAmount: number): number {
    return minorCurrencyAmount / MINOR_CURRENCY_DIVISOR
}

export const formatMinorNumberToCurrency = ({locale, value, options}: FormatMinorNumberType) => {
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        ...options,
        style: options?.currency ? 'currency' : 'decimal',
    }).format(getAmountFromMinorCurrencyUnits(value))
}
