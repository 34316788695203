import {t, Trans} from '@lingui/macro'
import {Form, Formik, useFormikContext} from 'formik'
import {type Dispatch, type SetStateAction, useEffect} from 'react'

import type {SelectTypes} from '@pleo-io/telescope'
import {
    Button,
    FormikCheckbox as Checkbox,
    FormikCurrencyInput as CurrencyInput,
    FormikInput as Input,
    FormikSelect as Select,
    HelpPopover,
    Inline,
    RadioButton,
    RadioGroup,
    Stack,
    Text,
} from '@pleo-io/telescope'
import {Warning} from '@pleo-io/telescope-icons'

import {useToaster} from '@product-web/toaster'
import yup from '@product-web/validation/yup'

import {bff} from '../../bff-hooks'
import {usePageModal} from '../../components/page-modals/use-page-modal'
import type {CalculatePricingOutput} from '../../index.bff'
import {partnerTierNamesMap} from '../../lib/partner-tier'

const PlanTypes = ['STARTER', 'ESSENTIAL', 'ADVANCED', 'BEYOND'] as const
type PlanType = (typeof PlanTypes)[number]
interface Props {
    pricingQuote?: CalculatePricingOutput
    setIsDirty: Dispatch<SetStateAction<boolean>>
    setPriceQuote: Dispatch<SetStateAction<CalculatePricingOutput | undefined>>
}

export const PricingDetailsForm = ({setPriceQuote, pricingQuote, setIsDirty}: Props) => {
    const {showToast} = useToaster()
    const {openModal} = usePageModal('showTiersModal')

    const {isLoading, mutateAsync: calculatePricing} =
        bff.pricingDetailsForm.calculatePricing.useMutation({
            onError: () =>
                showToast(t`Please try again, or contact support.`, {
                    title: t`Something went wrong`,
                    level: 'error',
                }),
            onSuccess: (data) => setPriceQuote(data),
        })

    const usersCount =
        (pricingQuote?.plan.userCount || 0) + (pricingQuote?.additionalUsers?.userCount || 0)

    const partnerTierOptions: SelectTypes.Option[] = [
        ...(pricingQuote?.isNewPartner
            ? [{label: partnerTierNamesMap().TRIAL, value: 'TRIAL'}]
            : []),
        {label: partnerTierNamesMap().BRONZE, value: 'BRONZE'},
        {label: partnerTierNamesMap().SILVER, value: 'SILVER'},
        ...(pricingQuote?.isDach
            ? [
                  {label: partnerTierNamesMap().GOLD_DACH, value: 'GOLD_DACH'},
                  {label: partnerTierNamesMap().PLATINUM_DACH, value: 'PLATINUM_DACH'},
              ]
            : [
                  {label: partnerTierNamesMap().GOLD, value: 'GOLD'},
                  {label: partnerTierNamesMap().PLATINUM, value: 'PLATINUM'},
              ]),
    ]

    const planNamesMap: Record<PlanType, string> = {
        STARTER: 'Starter',
        ESSENTIAL: 'Essential',
        ADVANCED: 'Advanced',
        BEYOND: 'Beyond',
    }

    const starter = planNamesMap.STARTER
    const advanced = planNamesMap.ADVANCED
    const beyond = planNamesMap.BEYOND

    const planOptions: SelectTypes.Option[] = [
        {label: planNamesMap.STARTER, value: 'STARTER'},
        {label: planNamesMap.ESSENTIAL, value: 'ESSENTIAL'},
        {label: planNamesMap.ADVANCED, value: 'ADVANCED'},
        {label: planNamesMap.BEYOND, value: 'BEYOND'},
    ]

    const currencyCodeOptions: SelectTypes.Option[] = [
        {label: 'DKK', value: 'DKK'},
        {label: 'EUR', value: 'EUR'},
        {label: 'GBP', value: 'GBP'},
        {label: 'NOK', value: 'NOK'},
        {label: 'SEK', value: 'SEK'},
        {label: 'USD', value: 'USD'},
    ]

    const initialValues = {
        partnerTier:
            partnerTierOptions.find((option) => option.value === pricingQuote?.partnerTier) ||
            partnerTierOptions[0],
        plan:
            planOptions.find((option) => option.label === pricingQuote?.plan.type) ||
            planOptions[1],
        usersCount: usersCount || 3,
        billingFrequency: pricingQuote?.billingFrequency || 'MONTHLY',
        currencyCode:
            currencyCodeOptions.find((option) => option.value === pricingQuote?.currencyCode) ||
            currencyCodeOptions[1],
        cashback: Boolean(pricingQuote?.cashback),
        expectedMonthlySpend: pricingQuote?.expectedMonthlySpend || 0,
    }

    const validationSchema = yup.object().shape({
        partnerTier: yup.object({label: yup.string(), value: yup.string()}).required(),
        plan: yup
            .object({label: yup.string(), value: yup.string()})
            .test(
                'starter-plan-user-count',
                t`${starter} plan is only available for up to 3 users`,
                function (value) {
                    if (value.value === 'STARTER' && this.parent.usersCount > 3) {
                        return false
                    }
                    return true
                },
            )
            .test(
                'starter-plan-billing-frequency',
                t`${starter} plan is only available with a monthly billing frequency`,
                function (value) {
                    if (value.value === 'STARTER' && this.parent.billingFrequency !== 'MONTHLY') {
                        return false
                    }
                    return true
                },
            )
            .required(),
        usersCount: yup
            .number()
            .required(t`Required`)
            .min(1, t`At least 1 user is required`),
        billingFrequency: yup.string().required(),
        currencyCode: yup.object({label: yup.string(), value: yup.string()}).required(),
        cashback: yup
            .boolean()
            .test(
                'cashback-advanced-beyond-annual',
                t`Cashback is only available for "${advanced}" and "${beyond}" plans with an annual billing frequency`,
                function (value) {
                    if (
                        value &&
                        ['ADVANCED', 'BEYOND'].includes(this.parent.plan.value) &&
                        this.parent.billingFrequency === 'ANNUALLY'
                    ) {
                        return true
                    } else if (value === false) {
                        return true
                    }
                    return false
                },
            )
            .required(),
        expectedMonthlySpend: yup.number().when('cashback', {
            is: true,
            then: yup
                .number()
                .required()
                .min(1, t`Please enter an amount to calculate your client's cashback rebate`),
            otherwise: yup.number(),
        }),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const payload = {
                    partnerTier: values.partnerTier.value,
                    plan: values.plan.value,
                    usersCount: Number(values.usersCount),
                    billingFrequency: values.billingFrequency,
                    currencyCode: values.currencyCode.value,
                    cashback: values.cashback,
                    expectedMonthlySpend: Number(values.expectedMonthlySpend),
                    isDach: pricingQuote?.isDach,
                    isNewPartner: pricingQuote?.isNewPartner,
                }
                calculatePricing(payload)
            }}
            validateOnBlur
            validateOnChange
            enableReinitialize
        >
            {({errors, touched, values, handleSubmit, setFieldTouched, setValues}) => (
                <Form onSubmit={handleSubmit}>
                    <SetDirty setIsDirty={setIsDirty} />
                    <Stack data-testid="pricing-details-form" space={24}>
                        <Stack space={8}>
                            <Text>
                                <Inline alignItems="center" space={6}>
                                    <Trans>Your partner tier</Trans>
                                    <HelpPopover
                                        aria-label={t`Learn how your partner tier impacts pricing`}
                                    >
                                        <Trans>
                                            Your tier in the partner programme determines the
                                            discount you can offer to your clients.{' '}
                                            <Button variant="tertiary" onClick={openModal}>
                                                Learn more
                                            </Button>
                                            .
                                        </Trans>
                                    </HelpPopover>
                                </Inline>
                            </Text>
                            <Select
                                aria-label={t`Your partner tier`}
                                name="partnerTier"
                                value={partnerTierOptions.find(
                                    (option) => option === values.partnerTier,
                                )}
                                options={partnerTierOptions}
                                portalled
                            />
                        </Stack>
                        <Select
                            aria-labelledby="label"
                            label={t`Pricing Plan`}
                            name="plan"
                            value={planOptions.find((option) => option === values.plan)}
                            options={planOptions}
                            portalled
                        />
                        <Input
                            label={t`Total number of users`}
                            name="usersCount"
                            type="number"
                            min={1}
                            maxLength={6}
                            value={values.usersCount}
                            autoComplete="off"
                            onChange={(e) => {
                                setFieldTouched('plan')
                                setValues({...values, usersCount: Number(e.target.value)})
                            }}
                        />
                        <Stack space={8}>
                            <Text>
                                <Trans>Billing frequency</Trans>
                            </Text>
                            <RadioGroup
                                label={t`Billing frequency`}
                                defaultValue="MONTHLY"
                                onValueChange={(value) =>
                                    setValues({...values, billingFrequency: value})
                                }
                            >
                                <Inline space={24}>
                                    <RadioButton value="MONTHLY" label={t`Monthly`} />
                                    <RadioButton value="ANNUALLY" label={t`Annually`} />
                                </Inline>
                            </RadioGroup>
                        </Stack>
                        <Select
                            label={t`Currency`}
                            name="currencyCode"
                            value={currencyCodeOptions.find(
                                (option) => option === values.currencyCode,
                            )}
                            options={currencyCodeOptions}
                            portalled
                        />
                        <Stack space={8}>
                            <Text>
                                <Inline alignItems="center" space={6}>
                                    <Trans>Cashback</Trans>
                                    <HelpPopover aria-label={t`About cashback`}>
                                        <Trans>
                                            Cashback is available for "{advanced}" and "{beyond}"
                                            plans with an annual billing frequency. When enabled,
                                            cashback will replace discounts
                                        </Trans>
                                    </HelpPopover>
                                </Inline>
                            </Text>
                            <Checkbox
                                label={t`Cashback`}
                                name="cashback"
                                checked={values.cashback}
                                value={String(values.cashback)}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        cashback: e.target.checked,
                                        expectedMonthlySpend: 0,
                                    })
                                }
                            >
                                <Trans>With cashback</Trans>
                            </Checkbox>
                            {touched.cashback && errors.cashback && (
                                <Text variant="small-subtle" color="colorContentNegative">
                                    <Warning size={16} /> {errors.cashback}
                                </Text>
                            )}
                        </Stack>
                        {values.cashback && (
                            <CurrencyInput
                                label={t`Expected monthly spend`}
                                name="expectedMonthlySpend"
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        expectedMonthlySpend: Number(e.target.value),
                                    })
                                }
                                value={values.expectedMonthlySpend}
                                currency={values.currencyCode.value}
                                postfix={values.currencyCode.value}
                            />
                        )}
                        <Button variant="primary" type="submit" loading={isLoading}>
                            <Trans>Calculate pricing</Trans>
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    )
}

const SetDirty = ({setIsDirty}: {setIsDirty: Dispatch<SetStateAction<boolean>>}) => {
    const {dirty} = useFormikContext()
    useEffect(() => setIsDirty(dirty), [dirty, setIsDirty])
    return null
}
