import Cookies from 'js-cookie'

export function checkStorageSupported(): boolean {
    const value = 'test'
    try {
        localStorage.setItem(value, value)
        if (localStorage.getItem(value) !== value) {
            return false
        }
        localStorage.removeItem(value)
        return true
    } catch (e) {
        return false
    }
}

export function clearCookiesWithPrefix(prefix: string): void {
    document.cookie.split(';').forEach((cookie) => {
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        if (name && name.startsWith(prefix)) {
            Cookies.remove(name, {path: '/'})
        }
    })
}
