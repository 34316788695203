export enum TravelInsurance {
    ClaimInformation = 'claimInformation',
}

export type TravelInsuranceDetails = {
    policyDocumentUrl: string
    phoneNumber: string
    notCoveredInfoUrl: string
}

export type TravelInsuranceConfig = {
    [TravelInsurance.ClaimInformation]: TravelInsuranceDetails
}
