import React from 'react'

export type ColorTheme = 'purple' | 'light'

interface ContextProps {
    colorTheme?: ColorTheme
}

export const PageDrawerContext = React.createContext<ContextProps>({colorTheme: undefined})

export const usePageDrawerContext = () => React.useContext(PageDrawerContext)
