import {css, styled} from 'styled-components'

import {Box, tokens} from '@pleo-io/telescope'

import type {PageOrigin} from '@product-web/paywall/types'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'

import {useLocalisedIllustration} from '../../empty-states/empty-states.helper'
import {useLocalisedAnimation} from '../use-localised-animation'
import {useLocalisedVideo} from '../use-localised-video'

export type MediaWrapperProps = {
    alignItems?: 'center' | 'flex-end'
    padding?: string
    flex?: number
    imageBackgroundColor?: string
    imageBorderRadius?: string
}

const MediaWrapper = styled(Box)<MediaWrapperProps>`
    background-color: ${tokens.colorBackgroundNeutral};
    border-radius: ${tokens.arc8};
    display: flex;
    overflow: hidden;
    align-items: ${({alignItems}) => alignItems ?? 'center'};
    flex: ${({flex}) => flex ?? 1};
`

type LandingPageVideoProps = MediaWrapperProps & {
    videoId: string
    videoThumbnail: string
}

export const LandingPageVideo = (props: LandingPageVideoProps) => {
    return (
        <MediaWrapper alignItems={props.alignItems} padding={props.padding} flex={props.flex}>
            <InlineWistiaVideo
                autoPlay
                startMuted
                thumbnail={{src: props.videoThumbnail}}
                videoId={props.videoId}
            />
        </MediaWrapper>
    )
}

type LandingPageIllustrationProps = MediaWrapperProps & {
    illustrationSrc: string
    illustrationAlt?: string
}

export const LandingPageImg = (props: LandingPageIllustrationProps) => {
    return (
        <MediaWrapper alignItems={props.alignItems} padding={props.padding} flex={props.flex}>
            <img
                src={props.illustrationSrc}
                alt={props.illustrationAlt}
                width={'100%'}
                css={{
                    borderRadius: props.imageBorderRadius ?? 0,
                    backgroundColor: props.imageBackgroundColor ?? 'transparent',
                }}
            />
        </MediaWrapper>
    )
}

const StyledContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing20};

    ${containerQuery(
        {name: 'main', minWidth: pxBreakpoints.tabletMedUp},
        css`
            flex-direction: row;
        `,
    )}
`

export const LandingPageMedia = ({pageOrigin}: {pageOrigin: PageOrigin}) => {
    const {illustrationSrc, illustrationAlt, illustrationStyle} = useLocalisedIllustration({
        type: pageOrigin,
    })
    const {animationSrc, animationAlt, animationStyle} = useLocalisedAnimation({
        type: pageOrigin,
    })
    const {videoId, thumbnail} = useLocalisedVideo({type: pageOrigin})

    return (
        <StyledContainer>
            {videoId ? (
                <LandingPageVideo videoId={videoId} videoThumbnail={thumbnail} />
            ) : (
                <LandingPageImg
                    illustrationSrc={animationSrc}
                    illustrationAlt={animationAlt}
                    padding={animationStyle.padding}
                    flex={animationStyle.flex}
                    alignItems={animationStyle.alignItems}
                />
            )}
            <LandingPageImg
                illustrationSrc={illustrationSrc}
                illustrationAlt={illustrationAlt}
                padding={illustrationStyle.padding}
                flex={illustrationStyle.flex}
                alignItems={illustrationStyle.alignItems}
                imageBackgroundColor={illustrationStyle.imageBackgroundColor}
                imageBorderRadius={illustrationStyle.imageBorderRadius}
            />
        </StyledContainer>
    )
}
