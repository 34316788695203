import * as React from 'react'
import {Route} from 'react-router'

import {roleSets} from '@product-web/user'

const ReimbursementSettingsPage = React.lazy(
    async () => import('./screens/reimbursement-settings/reimbursement-settings'),
)

export const settingsRoutes = (
    <Route
        index
        element={<ReimbursementSettingsPage />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
    />
)
