import * as React from 'react'
import {Navigate, Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const InvoiceActivityFeed = React.lazy(
    async () => import('./screens/details/activity/bill-invoice-activity-feed'),
)
const InvoiceReviewersTimeline = React.lazy(
    async () => import('./screens/details/reviewers/bill-invoice-reviewers-timeline'),
)

const BillInvoiceDetails = React.lazy(async () => import('./screens/details/bill-invoice-details'))
const SupplierManagement = React.lazy(
    async () => import('./screens/details/supplier/bill-invoice-supplier-management'),
)
const BillInvoicesWrapper = React.lazy(async () => import('./components/wrapper'))
const BillInvoicesPage = React.lazy(async () => import('./screens/dashboard/bill-invoices'))
const BillInvoicePage = React.lazy(async () => import('./screens/details/bill-invoice-page'))

export const routes = (
    <Route
        element={<BillInvoicesWrapper />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
    >
        <Route index element={<Navigate to="inbox" replace />} />
        <Route path="inbox" element={<BillInvoicesPage />} />
        <Route path="archived" element={<BillInvoicesPage />} />
        <Route path="paid" element={<BillInvoicesPage />} />
        <Route path="scheduled" element={<BillInvoicesPage />} />
        <Route path=":invoiceId" element={<BillInvoicePage />}>
            <Route index element={<BillInvoiceDetails />} />
            <Route path="activity" element={<InvoiceActivityFeed />} />
            <Route path="supplier" element={<SupplierManagement />} />
            <Route path="reviewers" element={<InvoiceReviewersTimeline />} />
            <Route path="*" element={<Navigate to="." replace />} />
        </Route>
    </Route>
)
