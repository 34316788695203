import styled, {css} from 'styled-components'

import {NakedButton, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'

export interface BannerStyleProps {
    textAlign?: 'center' | 'left'
    small?: boolean
}
export const Banner = styled(NakedButton)<BannerStyleProps>`
    @media (max-width: ${breakpoints.tabletMedUp}) {
        align-items: ${({small}) => (small ? 'flex-start' : 'center')};
    }

    display: flex;
    box-sizing: border-box;
    min-height: ${({small}) => (small ? `${tokens.spacing44}` : 'auto')};
    padding: ${({small}) =>
        small
            ? `${tokens.spacing12} ${tokens.spacing8}`
            : `${tokens.spacing18} ${tokens.spacing24}`};
    line-height: ${tokens.lineHeight3};
    cursor: initial;
    border: ${tokens.borderInteractiveQuiet};
    border-radius: ${tokens.arc8};
    box-shadow: none;
    transition: ${tokens.fastInOut};
    align-items: center;
    flex: 0 0 auto;
    width: 100%;
    text-align: ${({textAlign = 'left'}) => textAlign};

    ${({onClick}) => {
        if (onClick) {
            return css`
                cursor: pointer;

                &:hover {
                    box-shadow: ${tokens.shadowElevateQuiet};
                    transform: scale(1.02);
                }
            `
        }
        return ''
    }}
`

const sharedIconStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Custom = styled.div`
    ${sharedIconStyles}
    margin-top: ${tokens.spacing2};
    margin-left: ${tokens.spacing6};
    margin-right: ${tokens.spacing6};
    align-self: flex-start;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        margin-top: ${tokens.spacing2};
    }
`

const circleBackgroundColors = {
    pink: tokens.colorBackgroundPresentationalPink,
    green: tokens.colorBackgroundPresentationalGreen,
    yellow: tokens.colorBackgroundPresentationalYellow,
    purple: tokens.colorBackgroundPresentationalPurple,
}

export const Circle = styled.div<{
    color?: 'pink' | 'green' | 'yellow' | 'purple'
}>`
    ${sharedIconStyles}
    width: 40px;
    height: 40px;
    margin: ${tokens.spacing18} ${tokens.spacing12} ${tokens.spacing18} ${tokens.spacing4};
    font-size: 14px;
    font-weight: ${tokens.fontWeightBold};
    color: ${tokens.colorContentInteractive};
    border-radius: ${tokens.circle};
    flex: 0 0 ${tokens.spacing40};
    background-color: ${({color = 'pink'}) => circleBackgroundColors[color]};
`
