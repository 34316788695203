import {Trans} from '@lingui/macro'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Callout, Link, Stack, Text} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'

import type {LuxembourgAccountDisclaimerState} from '../bff/top-up-router.bff'
import {featureFundsManagementBff} from '../bff-hooks'

const HELP_ARTICLE_LINK = getHelpCentreArticleLinkIntercom(
    '5673472-initiale-kontonummer-fur-unternehmen-bis-zur-vollstandigen-verifizierung',
)

const CalloutComponent: React.FC = ({children}) => {
    return (
        <Callout variant="neutral">
            <Stack space={8}>
                {children}
                <Link target="_blank" rel="noopener noreferrer" href={HELP_ARTICLE_LINK}>
                    <Trans>Learn more</Trans>
                </Link>
            </Stack>
        </Callout>
    )
}

interface LuxembourgAccountDisclaimerProps {
    /* an optional callback when a link is clicked (needed for some modals) */
    onNavigate?: () => void
}

const DisclaimerBody: React.FC<
    LuxembourgAccountDisclaimerProps & {
        luxembourgAccountDisclaimerState: LuxembourgAccountDisclaimerState
    }
> = ({onNavigate, luxembourgAccountDisclaimerState}) => {
    switch (luxembourgAccountDisclaimerState) {
        case 'not_verified_company':
            return (
                <CalloutComponent>
                    <Text>
                        <Trans>
                            Until you have fully verified your company, you will be using our
                            Luxembourg bank account. To get your German virtual account number
                            simply{' '}
                            <Link
                                inherit
                                as={RouterLink}
                                to="/company-verification"
                                onClick={onNavigate}
                            >
                                verify your company
                            </Link>
                            .
                        </Trans>
                    </Text>
                </CalloutComponent>
            )
        case 'new_account_number_available':
            return (
                <CalloutComponent>
                    <Text>
                        <Trans>
                            You've been assigned a new permanent German account number for top-ups.
                        </Trans>
                    </Text>
                </CalloutComponent>
            )
        case 'no_state_to_show':
        default:
            return null
    }
}

export const LuxembourgAccountDisclaimer = ({onNavigate}: LuxembourgAccountDisclaimerProps) => {
    const {data: luxembourgAccountDisclaimerState, isInitialLoading} =
        featureFundsManagementBff.topUp.getLuxembourgAccountDisclaimer.useQuery()

    const {vibanWarning: LDFlag} = useFlags()
    if (!LDFlag || isInitialLoading || !luxembourgAccountDisclaimerState) {
        return null
    }

    return (
        <DisclaimerBody
            luxembourgAccountDisclaimerState={luxembourgAccountDisclaimerState}
            onNavigate={onNavigate}
        />
    )
}
