import yup from './yup'

export function validator(schema: yup.Schema<any>): (str: string) => yup.ValidationError | true {
    return (str: string) => {
        try {
            schema.validateSync(str)
            return true
        } catch (e) {
            return e as yup.ValidationError
        }
    }
}

export const secureString = validator(
    yup.string().matches(/^(?:[^<>"]+)?$/, {excludeEmptyString: true}),
)

// https://github.com/jquense/yup/issues/507#issuecomment-765799429
const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const email = validator(yup.string().matches(emailRegex).email())

export const required = validator(yup.string().required())

export const address = {
    addressLine1: validator(yup.string().secure().required()),
    addressLine2: validator(yup.string().secure()),
    postalCode: validator(yup.string().secure().required()),
    locality: validator(yup.string().secure().required()),
    region: validator(yup.string().secure().required()),
    country: validator(yup.string().matches(/^[A-Z]{2}$/)),
}
