import intersection from 'lodash.intersection'

import type {Role} from '@product-web/user-types'
import {invariant} from '@product-web/utils'

import {useUser} from './user-context'

export const roleSets = {
    company: ['member', 'owner', 'bookkeeper-basic', 'bookkeeper-extended'],
    companyAndReviewer: ['reviewer', 'member', 'owner', 'bookkeeper-basic', 'bookkeeper-extended'],
    companyOnly: ['member', 'owner'],
    ownerAndBookkeeper: ['owner', 'bookkeeper-basic', 'bookkeeper-extended'],
    ownerAndBookkeeperExtended: ['owner', 'bookkeeper-extended'],
    partner: ['partner-member', 'partner-owner'],
    all: [
        'reviewer',
        'member',
        'owner',
        'bookkeeper-basic',
        'bookkeeper-extended',
        'partner-member',
        'partner-owner',
    ],
} satisfies Record<string, Role[]>

// Checks if the logged in user has any of the allowed roles,
// or a role with broader permission than one of the allowed roles
export const useHasAllowedRole = (allowedRoles?: Role[]) => {
    const user = useUser()
    const userRoles: Role[] = []

    invariant(allowedRoles?.length, `Allowed roles can't be empty under an auth route.`)

    // if no user is logged in, the condition can't be met
    if (!user) {
        return false
    }

    // unfolding hierarchical roles, so e.g. owner has access to routes that require member
    if (user?.role === 'owner') {
        userRoles.push('owner', 'member')
    } else if (user?.role === 'member') {
        userRoles.push('member')
    } else if (user?.role === 'bookkeeper-extended') {
        userRoles.push('bookkeeper-extended', 'bookkeeper-basic')
    } else if (user?.role === 'bookkeeper' || user?.role === 'bookkeeper-basic') {
        userRoles.push('bookkeeper-basic')
    }

    if (user?.partnerEmployee?.role === 'owner') {
        userRoles.push('partner-owner', 'partner-member')
    } else if (user?.partnerEmployee?.role === 'member') {
        userRoles.push('partner-member')
    }

    // treating reviewer as a role here, even tough it's technically not, for convenience
    if (user?.reviewer?.length) {
        userRoles.push('reviewer')
    }

    return !!intersection(allowedRoles, userRoles).length
}
