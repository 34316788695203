import {Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Button, Stack, Text, tokens} from '@pleo-io/telescope'

import * as tracking from '@product-web/analytics'
import {breakpoints} from '@product-web/styles/theme'

import illustration from '../../images/confused-person-scrambled-logo.svg'

export const Error = () => {
    useEffect(() => {
        tracking.companyOnboardingErrorScreenViewed()
    }, [])

    return (
        <ErrorContainer>
            <Stack space={8} justifyItems="center">
                <Illustration src={illustration} alt="" />

                <Stack space={8} justifyItems="center">
                    <Text as="h2" variant="large-accent" weight="medium" align="center">
                        <Trans>Something went wrong</Trans>
                    </Text>

                    <Text align="center">
                        <Trans>
                            Please{' '}
                            {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                            <Button onClick={() => window.location.reload()} variant="tertiary">
                                reload this page
                            </Button>{' '}
                            and try again.
                        </Trans>
                    </Text>
                </Stack>
            </Stack>
        </ErrorContainer>
    )
}

const Illustration = styled.img`
    display: block;
    width: 100%;
    max-width: 268px;
`

const ErrorContainer = styled.div`
    border: ${tokens.borderStaticLoud};
    border-radius: ${tokens.arc8};
    display: grid;
    gap: ${tokens.spacing16};
    padding: ${tokens.spacing60} ${tokens.spacing24};
    box-shadow: ${tokens.shadowElevateQuiet};

    @media (width >= ${breakpoints.mediumTabletUp}) {
        padding: ${tokens.spacing60};
    }
`
