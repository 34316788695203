/* eslint-disable react/display-name */

/**
 * @overview Navigation Arrow Buttons
 * @stage Proposed
 * @author Will (@vilfredsikker)
 * @designer Martin
 * @spec https://www.figma.com/file/w3PQGgCdm7wr9pOT3EdQa2/Bills-web-components?node-id=1%3A9122
 */

import {Trans} from '@lingui/macro'
import type {ButtonHTMLAttributes} from 'react'
import React from 'react'
import styled from 'styled-components'

import {NakedButton, tokens, VisuallyHidden} from '@pleo-io/telescope'
import {ArrowLeft, ChevronDown, ChevronUp, Close} from '@pleo-io/telescope-icons'

import type {ColorTheme} from './context'
import {usePageDrawerContext} from './context'

/**
 * The NavigationArrowButtons can be used to navigate between a list of items.
 * The component is a compound component made of:
 * Root: the root element of the component, which wraps the buttons.
 * ButtonClose: the close button.
 * ButtonUp: button pointing up.
 * ButtonDown: button pointing down.
 * ButtonLeft: button pointing left.
 * All the components can forward a ref
 */

interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        React.RefAttributes<HTMLButtonElement> {}

type NavigationButtonStyleProps = {
    $colorTheme?: ColorTheme
}

const getButtonHoverBackgroundcolor = ({$colorTheme}: NavigationButtonStyleProps) => {
    if ($colorTheme === 'purple') {
        return tokens.purple400
    }

    return tokens.shade300
}

const getButtonDisabledSvgColor = ({$colorTheme}: NavigationButtonStyleProps) => {
    if ($colorTheme === 'purple') {
        return tokens.shade300
    }

    return tokens.shade400
}

const NavigationButtonStyles = styled(NakedButton)<NavigationButtonStyleProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: ${tokens.circle};
    height: ${tokens.spacing32};
    width: ${tokens.spacing32};
    transition: ${tokens.smoothInOut};
    transition-property: background-color;
    color: ${tokens.shade800};
    background-color: transparent;

    &:hover {
        background-color: ${getButtonHoverBackgroundcolor};
    }

    &:disabled {
        cursor: not-allowed;
        color: ${tokens.shade400};

        svg {
            fill: ${getButtonDisabledSvgColor};
        }

        &:hover {
            background-color: transparent;
        }
    }
`

type NavigationButtonsProps = React.HTMLAttributes<HTMLDivElement>

const NavigationButtonsStyles = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const NavigationButtons = ({children, ...props}: NavigationButtonsProps) => {
    return <NavigationButtonsStyles {...props}>{children}</NavigationButtonsStyles>
}

export const NavigationButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {colorTheme} = usePageDrawerContext()
    return (
        <NavigationButtonStyles $colorTheme={colorTheme} {...props} ref={ref}>
            {props.children}
        </NavigationButtonStyles>
    )
})

export const ButtonClose = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <NavigationButton {...props} ref={ref}>
        <VisuallyHidden>
            <Trans>Close</Trans>
        </VisuallyHidden>
        <Close size={16} />
    </NavigationButton>
))

export const ButtonUp = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <NavigationButton {...props} ref={ref}>
        <VisuallyHidden>
            <Trans>Previous</Trans>
        </VisuallyHidden>
        <ChevronUp size={16} />
    </NavigationButton>
))

export const ButtonDown = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <NavigationButton {...props} ref={ref}>
        <VisuallyHidden>
            <Trans>Next</Trans>
        </VisuallyHidden>
        <ChevronDown size={16} />
    </NavigationButton>
))

export const ButtonLeft = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <NavigationButton {...props} ref={ref}>
        <VisuallyHidden>
            <Trans>Back</Trans>
        </VisuallyHidden>
        <ArrowLeft size={16} />
    </NavigationButton>
))
