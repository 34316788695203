import {t, Trans} from '@lingui/macro'
import {ErrorMessage, Form, Formik} from 'formik'
import {Link as RouterLink, useNavigate} from 'react-router-dom'

import {Button, Link, LoadingPage, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {ChoiceTiles} from '@product-web/telescope-lab/choice-tiles'
import {useToaster} from '@product-web/toaster'
import yup from '@product-web/validation/yup'
import {Page} from '@product-web-features/ui-page/page'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import {Layout} from '../../components/layout'
import illustration from '../../images/accountant.svg'

type AdminOnboardingTypes = 'admin' | 'adminBookkeeper' | 'member' | 'soloBookkeeper'

const validationSchema = yup.object().shape({
    type: yup.string().required(() => t`Please select what best describes your responsibilities.`),
})

export const OnboardingType = () => {
    const navigate = useNavigate()
    const {showToast} = useToaster()
    const {data, isLoading, isError} = bff.onboardingType.getOnboardingTypeInfo.useQuery()

    const {mutateAsync: startAdminOnboarding, isLoading: isStartingOnboarding} =
        bff.onboardingType.startAdminOnboarding.useMutation({
            onSuccess: () => navigate('/partner'),
            onError: () => {
                showToast(t`Please try again, or contact support.`, {
                    title: t`Something went wrong`,
                    level: 'error',
                })
            },
        })

    if (isLoading) {
        return <LoadingPage />
    }

    if (isError) {
        return <GenericError />
    }

    const partnerName = data.partnerName

    return (
        <Layout>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Let's get you onboarded, {partnerName}</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
            </Page.Header>
            <Layout.Content mt={36}>
                <Layout.Left>
                    <Link IconLeft={ArrowLeft} as={RouterLink} to="../partner-size">
                        <Trans>Back</Trans>
                    </Link>
                    <Formik<{type?: AdminOnboardingTypes}>
                        initialValues={{type: undefined}}
                        validationSchema={validationSchema}
                        onSubmit={async ({type}) => type && (await startAdminOnboarding({type}))}
                    >
                        {({values, setFieldValue}) => (
                            <Form>
                                <Stack mt={24} mb={40} space={6}>
                                    <Stack space={40}>
                                        <Text>
                                            <Trans>
                                                To help us create the most relevant onboarding
                                                experience for you, please select your
                                                responsibilities.
                                            </Trans>
                                        </Text>
                                        <ChoiceTiles
                                            aria-label={t`Select your responsibilities`}
                                            value={values.type}
                                            onValueChange={(newValue) => {
                                                setFieldValue('type', newValue)
                                            }}
                                        >
                                            <ChoiceTiles.Choice
                                                value="adminBookkeeper"
                                                id="admin-bookkeeper"
                                            >
                                                <ChoiceTiles.Title>
                                                    <Trans>I am involved with bookkeeping.</Trans>
                                                </ChoiceTiles.Title>
                                                <ChoiceTiles.Description>
                                                    <Trans>
                                                        I handle the set up of the portal, manage a
                                                        team and may also manage client accounts.
                                                    </Trans>
                                                </ChoiceTiles.Description>
                                            </ChoiceTiles.Choice>
                                            <ChoiceTiles.Choice value="admin" id="admin">
                                                <ChoiceTiles.Title>
                                                    <Trans>
                                                        I am not involved with bookkeeping.
                                                    </Trans>
                                                </ChoiceTiles.Title>
                                                <ChoiceTiles.Description>
                                                    <Trans>
                                                        I'm only responsible for handling set up of
                                                        the portal and managing my team.
                                                    </Trans>
                                                </ChoiceTiles.Description>
                                            </ChoiceTiles.Choice>
                                        </ChoiceTiles>
                                    </Stack>
                                    <ErrorMessage name="type">
                                        {(errorMessage) => (
                                            <Text color="red700" variant="small-subtle">
                                                {errorMessage}
                                            </Text>
                                        )}
                                    </ErrorMessage>
                                </Stack>
                                <Layout.Actions>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        loading={isStartingOnboarding}
                                    >
                                        <Trans>Start onboarding</Trans>
                                    </Button>
                                </Layout.Actions>
                            </Form>
                        )}
                    </Formik>
                </Layout.Left>
                <Layout.Right>
                    <Layout.Illustration src={illustration} />
                </Layout.Right>
            </Layout.Content>
        </Layout>
    )
}
