import styled from 'styled-components'

import {Text, Textarea, tokens} from '@pleo-io/telescope'

import CallToAction from '@product-web/telescope-lab/call-to-action/call-to-action'

export const StyledCallToAction = styled(CallToAction)`
    width: 100%;
    text-align: left;
    background-color: ${tokens.shade000};
    margin-bottom: ${tokens.spacing16};
`

export const StyledTextarea = styled(Textarea)`
    width: 100%;
    height: 100px;
    margin-top: ${tokens.spacing16};
`

export const SubtitleTextarea = styled(Text).attrs({color: 'colorContentStatic'})`
    font-size: ${tokens.fontSmall};
`
