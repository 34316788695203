import {Trans} from '@lingui/macro'
import {Link, useMatch} from 'react-router-dom'

import {Text} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'

import {bff} from '../../../bff-hooks'
import {OnboardingItem} from '../onboarding-item'

const taskName = 'partner-agreement'

export const PartnerAgreement = () => {
    const {data} = bff.partnerAgreement.getPartnerAgreement.useQuery()
    const hasSignedAgreement = !!data?.hasSignedAgreement
    const taskPath = hasSignedAgreement
        ? '/partner/company/info'
        : '/partner/company/referrals?acceptTerms=true'

    const isOnTask = !!useMatch(taskPath)
    const status = hasSignedAgreement ? 'completed' : 'pending'

    return (
        <OnboardingItem
            as={Link}
            to={taskPath}
            disabled={isOnTask}
            onClick={() => {
                tracking.partnerSelfOnboardingTaskActioned({
                    action: 'open',
                    task: {name: taskName, status},
                })
            }}
        >
            <OnboardingItem.Checkbox
                aria-labelledby={taskName}
                checked={hasSignedAgreement}
                onClick={() => {
                    tracking.partnerSelfOnboardingTaskActioned({
                        action: hasSignedAgreement ? 'uncheck' : 'check',
                        task: {name: taskName, status},
                    })
                }}
                disabled
            />
            <OnboardingItem.Content>
                <Text as="label" id={taskName}>
                    <Trans>Sign your partner agreement</Trans>
                </Text>
            </OnboardingItem.Content>
        </OnboardingItem>
    )
}
