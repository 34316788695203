import type {ICountryConfiguration} from './types'

import {Feature} from '../types'

const NL: ICountryConfiguration = {
    [Feature.Paywall]: {
        noVATReclaim: true,
    },
}

export default NL
