import styled from 'styled-components'

import {ModalContent, tokens} from '@pleo-io/telescope'

export const FormDescription = styled(ModalContent)`
    max-width: 350px;
    margin: 0 auto;
`

export const FieldRow = styled.div`
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-column-gap: ${tokens.spacing16};
    width: 100%;
    margin-top: ${tokens.spacing16};
`

export const AddMemberModalContainer = styled.div`
    min-width: 500px;
`
