import {t} from '@lingui/macro'
import type {AxiosResponse} from 'axios'
import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import type {OtpType} from '@product-web/api-types/otp'
import {request2FAVerification, use2FAEnabled} from '@product-web/auth--otp/api'
import {useShouldRefreshSession} from '@product-web/auth--session/context'
import {getErrorMessage} from '@product-web/error/message'
import {useRecaptcha} from '@product-web/external/use-recaptcha'
import {useToaster} from '@product-web/toaster'

export const ErrorMessageType = {
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    invalid: 'invalid credentials',
    throttled: 'throttled',
    phone_otp_limit_exceeded: 'phone_otp_max_generations_exceeded',
    phone_token_mismatch: 'phone_reset_token_does_not_match',
    phone_token_deleted: 'phone_reset_token_does_not_exist',
    phone_throttled: 'phone_reset_token_max_tries_exceeded',
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    network_error: 'Network Error',
}

export enum OnboardingScreenName {
    WELCOME = 'welcomeScreen',
    PASSCODE = 'passcodeScreen',
    PHONE = 'phoneScreen',
    AUTH_APP = 'authApp',
    VERIFY = 'scaVerifyScreen',
}

const PROLONGED_TOAST_DURATION = 15000
const RESEND_OTP_DISABLED = 10000
const DEFAULT_THROTTLED_MS = 15 * 1000 * 60
export const RESET_ERROR_DELAY = 2000
export const MAX_PHONE_OTP_SUBMITS_BEFORE_EXPIRATION = 6

/**
 * Custom hook to send phone verification One-Time-Password (OTP)
 * @param params.phone Phone number that needs to be verified.
 * @param params.email Email derived from reset email. This is required for non logged in user.
 * @param params.token Token derived from reset email. This is required for non logged in user
 * @param params.accessToken A card-scpoed access token. This is required to request phone change for logged in user.
 * @returns an object with the following properties canRetry, isValidating, hasError, isTokenSent, resend to manage sending, resending and handling errors
 */
export function use2FAVerificationOtpRequest({
    phone,
    email,
    token,
    accessToken,
    authType,
}: {
    phone?: string | null
    email?: string | null
    token?: string | null
    accessToken?: string | null
    authType?: OtpType
}) {
    const notifications = useOtpNotifications()
    /* for critical error (i.e. user is blocked for 24 hours) */
    const [redirectToBeginning, setRedirectToBeginning] = React.useState(false)
    const [hasError, setHasError] = React.useState(false)
    const [isTokenSent, setIsTokenSent] = React.useState(false)
    const [canRetry, setCanRetry] = React.useState(true)
    const [isResending, setIsResending] = React.useState(false)
    const canSendOtp = Boolean(phone && ((email && token) || accessToken))
    const isValidating = isResending && !isTokenSent
    const captcha = useRecaptcha('request_2fa_verification')

    const reset = () => {
        setHasError(false)
        setIsTokenSent(false)
    }

    const sendOtp = async () => {
        if (!phone) {
            return
        }
        setIsResending(true)
        try {
            await captcha?.protectedCall(async (captchaToken) =>
                request2FAVerification({phone, email, token, accessToken, authType, captchaToken}),
            )
            setIsTokenSent(true)
            setIsResending(false)
        } catch (err) {
            const message = getErrorMessage(err)
            setIsResending(false)
            setHasError(true)
            if (message) {
                switch (message) {
                    case ErrorMessageType.phone_otp_limit_exceeded:
                        notifications[ErrorMessageType.phone_otp_limit_exceeded]()
                        setRedirectToBeginning(true)
                        break
                    case ErrorMessageType.phone_throttled:
                    case ErrorMessageType.phone_token_mismatch:
                    case ErrorMessageType.phone_token_deleted:
                        setCanRetry(false)
                        break
                    case ErrorMessageType.invalid:
                    default:
                        reset()
                }
            }
        }
    }

    const resend = async () => {
        if (!phone) {
            return
        }
        reset()
        await sendOtp()
    }

    React.useEffect(() => {
        if (canSendOtp && !isTokenSent && !isResending) {
            sendOtp()
        }
    }, [canSendOtp])

    return {
        canRetry,
        isValidating,

        hasError,
        isTokenSent,
        resend,
        redirectToBeginning,
    }
}

export function useOtpNotifications() {
    const {showToast} = useToaster()

    return {
        [ErrorMessageType.phone_otp_limit_exceeded]: () =>
            showToast(
                t`Too many tries, your account is now locked for 24hours for security reasons. Please contact support@pleo.io to get access`,
                {
                    level: 'error',
                    durationMs: PROLONGED_TOAST_DURATION,
                },
            ),
        ['successful_code_resent']: () => showToast(t`6-digit code resent`, {level: 'success'}),
    }
}

export function useOtpResendTimer() {
    const [isResendDisabled, setIsDisabled] = useState(false)

    const handleDisableResend = () => {
        setIsDisabled(true)
        setTimeout(() => setIsDisabled(false), RESEND_OTP_DISABLED)
    }

    return {
        isResendDisabled,
        handleDisableResend,
    }
}

/**
 * Custom hook to redirect the user to the session-refresh screen
 * This lets the user refresh their session on a trusted device with a passcode
 */
export function useSessionRefreshCheck() {
    const shouldRefreshSession = useShouldRefreshSession()
    const location = useLocation()
    const locationState: {
        addAccount?: boolean
        redirect?: string
    } = location.state ?? {}
    const navigate = useNavigate()
    const allowedLocations = ['/reset/passcode']

    React.useEffect(() => {
        // If the addAccount property is true
        // We assume the user is adding a new session (i.e logging in) to an existing trusted device
        if (
            shouldRefreshSession &&
            !locationState.addAccount &&
            !allowedLocations.some((allowedLocation) =>
                location.pathname.startsWith(allowedLocation),
            )
        ) {
            navigate('/access', {
                state: {
                    redirect: locationState?.redirect ?? location.pathname + location.search,
                },
            })
        }
    }, [shouldRefreshSession, locationState.addAccount])
}

/**
 * Custom hook to indicate whether to show or hide the onboarding screens for 2fA
 * This flow lets us enroll users to Secure Custommer Authentication (SCA)
 * @returns {isLoading: boolean; showOnboarding: boolean}
 */
export function useSCAOnboarding() {
    const navigate = useNavigate()
    const location = useLocation()
    const locationState = (location.state ?? {}) as {fromScaOnboarding?: boolean}
    const twoFactorEnabledResponse = use2FAEnabled()
    const isLoading2FACheck = !twoFactorEnabledResponse.data && !twoFactorEnabledResponse.error

    const is2FAEnabled = Boolean(
        twoFactorEnabledResponse.data && twoFactorEnabledResponse.data?.enabled,
    )

    const showOnboarding =
        !isLoading2FACheck &&
        !is2FAEnabled &&
        !twoFactorEnabledResponse.data?.blocked &&
        !locationState?.fromScaOnboarding

    React.useEffect(() => {
        if (showOnboarding) {
            navigate('/secure-your-account', {
                state: {redirect: location.pathname + location.search},
            })
        }
    }, [showOnboarding])

    return {isLoading: isLoading2FACheck, showOnboarding}
}

export const convertThrottledToMinutes = (timeThrottled: number = DEFAULT_THROTTLED_MS) =>
    Math.ceil(timeThrottled / 1000 / 60)

export const getTimeThrottled = (response: AxiosResponse | undefined) =>
    response?.data?.wait ?? DEFAULT_THROTTLED_MS
