/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {useLocation} from 'react-router-dom'

import config from '@product-web/config'
import {reportError} from '@product-web/error/report'
import type {Role} from '@product-web/user-types'
import {invariant} from '@product-web/utils'

import {useLastHandleParam} from './last-handle-param'

let reportedError = false

// Extension of invariant helper, throws error in dev but only reports error in prod
// TODO: remove once we're confident the new auth checks are reliable in prod
const invariantSafe = (condition: boolean, message: string) => {
    const isLocalOrFeature = ['local', 'feature', 'test'].includes(config.environment)
    if (isLocalOrFeature) {
        invariant(condition, message)
    } else if (!condition && !reportedError) {
        reportedError = true
        reportError(message)
    }
}

const allowedRolesEmptyError = (pathname: string) =>
    `Not allowed to use this hook under route "${pathname}": "allowedRoles" param for this route is empty or undefined.`

const forbiddenRolesError = (pathname: string, allowedRoles: string, forbiddenRoles: string) =>
    `Not allowed to use this hook under route "${pathname}": the hook is only allowed to be used in routes with allowed roles: ${allowedRoles}; but route allows roles: ${forbiddenRoles}.`

const missingRequiredRolesError = (pathname: string, allowedRoles: string) =>
    `Not allowed to use this hook under route "${pathname}": the hook is allowed to be used in routes with one of allowed roles: ${allowedRoles}.`

export const checkAllowedRolesFromRoute = (
    pathname: string,
    allowedRoles: Role[],
    allowedRolesFromRoute: Role[] = [],
) => {
    // Check if allowedRolesFromRoute array is empty
    invariantSafe(Number(allowedRolesFromRoute.length) > 0, allowedRolesEmptyError(pathname))

    // Check that route doesn't allow access to users with roles that are forbidden in the context of this hook
    const forbiddenRoles = allowedRolesFromRoute.filter(
        (allowedRole) => !allowedRoles.includes(allowedRole),
    )
    invariantSafe(
        Number(forbiddenRoles.length) === 0,
        forbiddenRolesError(pathname, allowedRoles.join(','), forbiddenRoles.join(',')),
    )

    // Check that route allows access to users with any role that is required in the context of this hook
    const hasRequiredRole = allowedRoles.some((role) => allowedRolesFromRoute.includes(role))
    invariantSafe(hasRequiredRole, missingRequiredRolesError(pathname, allowedRoles.join(',')))
}

/**
 * Throws error if this hook is used inside a route which
 * - doesn't allow any of the provided roles, or
 * - allows roles which are not in the provided roles
 * @param allowedRoles
 */
export function useAllowedRoles(allowedRoles: Role[]) {
    const {pathname} = useLocation()
    const allowedRolesFromRoute = useLastHandleParam('allowedRoles')

    const auth = useLastHandleParam('auth')
    invariantSafe(auth, `Not allowed to use this hook under public (non-auth) route ${pathname}.`)

    checkAllowedRolesFromRoute(pathname, allowedRoles, allowedRolesFromRoute)
}
