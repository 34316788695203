import {t} from '@lingui/macro'
import {styled} from 'styled-components'

import {
    Button,
    Card,
    cardHorizontalSpacing,
    cardVerticalSpacing,
    Stack,
    Text,
} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {SkeletonLoader} from '@product-web/telescope-lab/skeleton-loader/skeleton-loader'
import {exhaustiveCheck} from '@product-web/utils'

type CardVariant = 'HELP_CENTRE' | 'DEMO_SALES' | 'DEMO_PLAYGROUND'

type FooterCardProps = {
    variant: CardVariant
    headingLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    onClick: () => void
    isLoading?: boolean
}

export const FooterCard = ({
    variant,
    headingLevel,
    onClick,
    isLoading = false,
}: FooterCardProps) => {
    if (isLoading) {
        return (
            <StyledCard>
                <SkeletonLoader width="100%" height="128px">
                    <rect x="0" y="0" rx="3" ry="3" width="140" height="18" />
                    <rect x="0" y="34" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="60" rx="3" ry="3" width="70%" height="14" />
                    <rect x="12" y="88" rx="3" ry="3" width="170" height="40" />
                    <circle cx="20" cy="108" r="20" />
                    <circle cx="180" cy="108" r="20" />
                </SkeletonLoader>
            </StyledCard>
        )
    }

    const {heading, description, ctaText, trackingProps} = getCardContent(variant)

    return (
        <StyledCard>
            <Stack space={4}>
                <Text as={headingLevel} variant="large-accent" weight="semibold">
                    {heading}
                </Text>

                <Text as="p" variant="large-accent">
                    {description}
                </Text>
            </Stack>

            <Button
                variant="secondary"
                onClick={() => {
                    tracking.companyOnboardingFooterCardActioned(trackingProps)
                    onClick()
                }}
                IconRight={ArrowRight}
            >
                {ctaText}
            </Button>
        </StyledCard>
    )
}

const getCardContent = (variant: CardVariant) => {
    switch (variant) {
        case 'HELP_CENTRE':
            return {
                heading: t`Find answers fast`,
                description: t`Got a question about setup? Browse how-to guides in our help centre.`,
                ctaText: t`Go to help centre`,
                trackingProps: {
                    card: 'help-centre',
                },
            }
        case 'DEMO_SALES':
            return {
                heading: t`Talk to sales`,
                description: t`Get help from our team of experts and build the right solution for your company.`,
                ctaText: t`Book a demo`,
                trackingProps: {
                    card: 'book-a-demo',
                },
            }
        case 'DEMO_PLAYGROUND':
            return {
                heading: t`Discover how Pleo works`,
                description: t`Try our interactive demo and see how easy expense management can be.`,
                ctaText: t`Take a tour`,
                trackingProps: {
                    card: 'interactive-demo',
                },
            }
        default:
            return exhaustiveCheck(variant)
    }
}

const StyledCard = styled(Card).attrs({space: 8, forwardedAs: 'aside'})`
    padding-block: ${cardVerticalSpacing};
    padding-inline: ${cardHorizontalSpacing};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
`
