import {t, Trans} from '@lingui/macro'
import * as RadixAccordion from '@radix-ui/react-accordion'
import React from 'react'
import styled, {css} from 'styled-components'

import type {TextProps} from '@pleo-io/telescope'
import {
    Badge,
    Button,
    focusRing,
    Inline,
    ProgressBar,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import type {Props as IconProps} from '@pleo-io/telescope-icons'
import {Check, CheckCircleFilled, ChevronDown, CircleEmpty} from '@pleo-io/telescope-icons'

import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'

import type {MilestoneStatus, TaskStatus} from '../universal/types'

const MOBILE_BREAKPOINT = pxBreakpoints.mediumTabletUp

//#region Components

type OmittedRadixAccordionProps = 'asChild' | 'dir' | 'orientation' | 'type'

type SingleMilestoneAccordionProps = Omit<
    RadixAccordion.AccordionSingleProps,
    OmittedRadixAccordionProps
> & {
    type: 'single'
}
type MultipleMilestoneAccordionProps = Omit<
    RadixAccordion.AccordionMultipleProps,
    OmittedRadixAccordionProps
> & {
    type?: 'multiple'
}

type MilestoneAccordionProps = SingleMilestoneAccordionProps | MultipleMilestoneAccordionProps

export const MilestoneAccordion = ({children, ...props}: MilestoneAccordionProps) => {
    if (props.type === 'single') {
        return (
            <Root {...props} collapsible={props.collapsible ?? true}>
                {children}
            </Root>
        )
    }

    return (
        <Root type="multiple" {...props}>
            {children}
        </Root>
    )
}

type Task = Pick<
    MilestoneAccordionTaskProps,
    'children' | 'heading' | 'status' | 'cta' | 'badgeText' | 'stretch'
>

type MilestoneAccordionItemProps = {
    disabled?: boolean
    value: string
    children: React.ReactNode
    status: MilestoneStatus
}

const MilestoneAccordionItem = ({
    value,
    disabled,
    children,
    status,
}: MilestoneAccordionItemProps) => (
    <MilestoneAccordionContext.Provider value={{status}}>
        <Item
            value={value}
            disabled={disabled}
            $isMilestoneComplete={status === 'COMPLETE'}
            $isMilestoneSkipped={status === 'SKIPPED'}
        >
            {children}
        </Item>
    </MilestoneAccordionContext.Provider>
)

type MilestoneAccordionHeaderProps = {
    headingLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    heading: string
    progress: {
        total: number
        completed: number
    }
    Icon: (props: IconProps) => JSX.Element
    badgeText?: string
}

const MilestoneAccordionHeader = ({
    headingLevel,
    heading,
    progress,
    Icon,
    badgeText,
}: MilestoneAccordionHeaderProps) => {
    const {status} = useMilestoneAccordionContext()

    const isMilestoneComplete = status === 'COMPLETE'
    const isMilestoneSkipped = status === 'SKIPPED'
    const numTotalTasks = progress.total
    const numCompletedTasks = progress.completed
    const minCompletionPercent = 12.5
    const contentColor = getHeaderContentColor({isMilestoneComplete, isMilestoneSkipped})

    return (
        <RadixAccordion.Header asChild>
            <Trigger
                aria-label={heading}
                $isMilestoneComplete={isMilestoneComplete}
                as={isMilestoneComplete ? 'div' : undefined}
            >
                <Inline space={16} as="span">
                    <HeaderLineHeightWrapper color={contentColor}>
                        {isMilestoneComplete ? (
                            <CheckCircleFilled />
                        ) : isMilestoneSkipped ? (
                            <CircleEmpty size={24} />
                        ) : (
                            <Icon size={24} />
                        )}
                    </HeaderLineHeightWrapper>

                    <Inline space={8} alignItems="center" wrap as="span">
                        <HeaderHeading as={headingLevel} color={contentColor}>
                            {heading}
                        </HeaderHeading>

                        {badgeText && !isMilestoneComplete && (
                            <Badge variant="discover" loud={isMilestoneSkipped}>
                                {badgeText}
                            </Badge>
                        )}
                    </Inline>
                </Inline>

                {!isMilestoneComplete && (
                    <HeaderLineHeightWrapper as="span">
                        <Inline alignItems="center" space={24} as="span">
                            {!isMilestoneSkipped && (
                                <StyledProgressBar
                                    variant="tall"
                                    percent={Math.max(
                                        (numCompletedTasks / numTotalTasks) * 100,
                                        minCompletionPercent,
                                    )}
                                    aria-label={t`${numCompletedTasks}/${numTotalTasks} tasks completed`}
                                />
                            )}

                            <Chevron color={tokens[contentColor]} />
                        </Inline>
                    </HeaderLineHeightWrapper>
                )}
            </Trigger>
        </RadixAccordion.Header>
    )
}

type MilestoneAccordionContentProps = {
    children: React.ReactNode
}

const MilestoneAccordionContent = ({children}: MilestoneAccordionContentProps) => {
    const {status} = useMilestoneAccordionContext()

    if (status === 'COMPLETE') {
        return null
    }

    return (
        <Content>
            <Stack p={24} space={32} stretch>
                {children}
            </Stack>
        </Content>
    )
}

type MilestoneAccordionTasksProps = {
    tasks: Task[]
    blocked?: boolean
    banner?: React.ReactNode
}

const MilestoneAccordionTasks = ({
    tasks: allTasks,
    blocked,
    banner,
}: MilestoneAccordionTasksProps) => {
    const completedTasks = allTasks.filter(isComplete)
    const uncompletedTasks = allTasks.filter(not(isComplete))

    type TaskGroupProps = {
        tasks: Task[]
        hideNumber?: boolean
        shouldExpandFirstTask?: boolean
    }

    const TaskGroup = ({tasks, shouldExpandFirstTask = false}: TaskGroupProps) => {
        return (
            <Stack space={32} stretch>
                {tasks.map((task, i) => {
                    return (
                        <MilestoneAccordionTask
                            key={task.heading}
                            heading={task.heading}
                            status={task.status}
                            state={shouldExpandFirstTask && i === 0 ? 'EXPANDED' : 'COLLAPSED'}
                            number={allTasks.indexOf(task) + 1}
                            hideNumber={allTasks.length < 2}
                            cta={task.cta}
                            badgeText={task.badgeText}
                            stretch={task.stretch}
                        >
                            {task.children}
                        </MilestoneAccordionTask>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Stack space={32} stretch>
            {completedTasks.length > 0 && <TaskGroup tasks={completedTasks} />}

            {banner}

            {uncompletedTasks.length > 0 && (
                <TaskGroup tasks={uncompletedTasks} shouldExpandFirstTask={!blocked} />
            )}
        </Stack>
    )
}

type TaskState = 'EXPANDED' | 'COLLAPSED'

type MilestoneAccordionTaskProps = {
    children?: React.ReactNode
    heading: string
    status: TaskStatus
    state: TaskState
    number: number
    hideNumber?: boolean
    cta?: React.ReactNode
    badgeText?: string
    stretch?: boolean
}

const MilestoneAccordionTask = ({
    children,
    heading,
    status,
    state,
    number,
    hideNumber = false,
    cta,
    badgeText,
    stretch = false,
}: MilestoneAccordionTaskProps) => {
    if (status === 'COMPLETE') {
        return (
            <MilestoneAccordionTaskWrapper $hideNumber={hideNumber} $withCta={!!cta}>
                <Check color={tokens.colorBorderPositive} />

                <Text variant="large-accent" weight="regular" color="colorContentPositive">
                    {heading}
                </Text>
            </MilestoneAccordionTaskWrapper>
        )
    }

    return (
        <MilestoneAccordionTaskWrapper $hideNumber={hideNumber} $withCta={!!cta}>
            {!hideNumber && (
                <TaskNumberWrapper>
                    <TaskNumber
                        variant="small-subtle"
                        color="colorContentInteractiveInverse"
                        $isMuted={state === 'COLLAPSED'}
                    >
                        {number}
                    </TaskNumber>
                </TaskNumberWrapper>
            )}

            <TaskHeaderWrapper>
                <Text
                    variant="large-accent"
                    weight={state === 'EXPANDED' ? 'medium' : 'regular'}
                    color={
                        state === 'COLLAPSED'
                            ? 'colorContentInteractiveDisabled'
                            : 'colorContentInteractive'
                    }
                >
                    {heading}
                </Text>

                {state === 'EXPANDED' && badgeText && <Badge variant="discover">{badgeText}</Badge>}
            </TaskHeaderWrapper>

            {state === 'EXPANDED' && !!children && (
                <MilestoneAccordionTaskContentWrapper $hideNumber={hideNumber} $stretch={stretch}>
                    {children}
                </MilestoneAccordionTaskContentWrapper>
            )}

            {state === 'EXPANDED' && !!cta && (
                <CtaWrapper $withChildren={!!children} $hideNumber={hideNumber} $stretch={stretch}>
                    {cta}
                </CtaWrapper>
            )}
        </MilestoneAccordionTaskWrapper>
    )
}

type MilestoneContentItemProps = {
    children: React.ReactNode
}

const MilestoneContentItem = ({children}: MilestoneContentItemProps) => (
    <MilestoneContentItemChildren>{children}</MilestoneContentItemChildren>
)

type MilestoneAccordionFooterProps = {
    description: string
    badgeText?: string
    cta?: React.ReactNode
}

const MilestoneAccordionFooter = ({description, badgeText, cta}: MilestoneAccordionFooterProps) => (
    <MilestoneAccordionFooterWrapper>
        <MilestoneAccordionFooterBody>
            <Text variant="large-accent" weight="medium">
                {description}
            </Text>

            {badgeText && (
                <Badge variant="discover" loud>
                    {badgeText}
                </Badge>
            )}
        </MilestoneAccordionFooterBody>

        {cta}
    </MilestoneAccordionFooterWrapper>
)

type MilestoneAccordionBannerProps = {
    heading: string
    description: string
    cta?: React.ReactNode
}

const MilestoneAccordionBanner = ({heading, description, cta}: MilestoneAccordionBannerProps) => {
    const {status} = useMilestoneAccordionContext()

    return (
        <MilestoneAccordionBannerWrapper $isMilestoneSkipped={status === 'SKIPPED'}>
            <MilestoneAccordionBannerBody>
                <Text variant="large-accent" weight="medium">
                    {heading}
                </Text>

                <Text>{description}</Text>
            </MilestoneAccordionBannerBody>

            {cta}
        </MilestoneAccordionBannerWrapper>
    )
}

type MilestoneAccordionSkipButtonProps = {
    onClick: () => void
    loading?: boolean
}

const MilestoneAccordionSkipButton = ({onClick, loading}: MilestoneAccordionSkipButtonProps) => {
    const {status} = useMilestoneAccordionContext()

    if (status === 'SKIPPED') {
        return null
    }

    return (
        <Stack stretch justifyContent="end">
            <Button variant="tertiary" onClick={onClick} loading={loading}>
                <Trans>Skip this step</Trans>
            </Button>
        </Stack>
    )
}

//#endregion Components

//#region Helpers

// Returns whether or not the provided task is complete
const isComplete = <T extends Pick<Task, 'status'>>(task: T): boolean => task.status === 'COMPLETE'

type Fn = (...args: any[]) => boolean

// Returns a function which negates the result of the provided function
const not = <T,>(fn: Fn) => {
    return (...args: T[]) => !fn(...args)
}

type GetHeaderContentColorArgs = {
    isMilestoneComplete: boolean
    isMilestoneSkipped: boolean
}

const getHeaderContentColor = ({
    isMilestoneComplete,
    isMilestoneSkipped,
}: GetHeaderContentColorArgs): Exclude<TextProps['color'], undefined | 'inherit'> => {
    if (isMilestoneComplete) {
        return 'colorContentPositive'
    }

    if (isMilestoneSkipped) {
        return 'colorContentInteractiveQuiet'
    }

    return 'colorContentInteractive'
}

//#endregion Helpers

//#region Styles

const Root = styled(RadixAccordion.Root)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing16};
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
`

const isMilestoneCompleteItemStyles = css`
    &,
    &[data-state],
    &:hover {
        border-color: transparent;
        background: ${tokens.colorBackgroundPositiveQuiet};
    }
`

const isMilestoneSkippedItemStyles = css`
    &,
    &[data-state],
    &:hover {
        background: ${tokens.colorBackgroundInteractiveLoud};
    }
`

type ItemProps = {
    $isMilestoneComplete: boolean
    $isMilestoneSkipped: boolean
}

const Item = styled(RadixAccordion.Item)<ItemProps>`
    width: 100%;
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc12};
    transition: border-color ${tokens.smoothOut};
    overflow: hidden;

    &[data-state='open'] {
        border-color: ${tokens.colorBorderStaticLoud};
    }

    ${({$isMilestoneSkipped}) => $isMilestoneSkipped && isMilestoneSkippedItemStyles}
    ${({$isMilestoneComplete}) => $isMilestoneComplete && isMilestoneCompleteItemStyles}
`

const buttonReset = css`
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    line-height: inherit;
    text-align: inherit;
    background-color: transparent;
    border-width: 0;
`

const isMilestoneCompleteTriggerStyles = css`
    &,
    &:hover {
        align-items: center;
        cursor: unset;
        background: none;
        border-bottom-color: transparent;
    }
`

type TriggerProps = {
    $isMilestoneComplete: boolean
}

const Trigger = styled(RadixAccordion.Trigger)<TriggerProps>`
    ${focusRing('inset')}
    ${buttonReset}

    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: ${tokens.spacing16};
    align-items: start;
    padding: ${tokens.spacing24};
    color: ${tokens.colorContentInteractive};
    cursor: pointer;
    transition:
        background ${tokens.fastInOut},
        border-radius ${tokens.fastInOut};
    border-top-left-radius: ${tokens.arc12};
    border-top-right-radius: ${tokens.arc12};
    border-bottom: ${tokens.borderPrimary};

    [data-state='closed'] & {
        border-radius: ${tokens.arc12};
        border-bottom-color: transparent;
    }

    [data-disabled] & {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        cursor: not-allowed;

        * {
            color: ${tokens.colorContentInteractiveDisabled};
        }
    }

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
    }

    ${({$isMilestoneComplete}) => $isMilestoneComplete && isMilestoneCompleteTriggerStyles}
`

const HeaderHeading = styled(Text).attrs({
    variant: 'xlarge-accent',
    weight: 'medium',
    maxLines: 2,
})``

// Extends the styling of the heading so we can set the height to 1lh to keep the contents vertically centered to the
// height of 1 line of text
const HeaderLineHeightWrapper = styled(HeaderHeading)`
    height: 1lh;
    display: flex;
    align-items: center;
    flex-shrink: 0;
`

const Content = styled(RadixAccordion.Content)`
    overflow: hidden;

    &[data-state='open'] {
        animation: slideDown ${tokens.smoothOut};
    }

    &[data-state='closed'] {
        animation: slideUp ${tokens.smoothOut};
    }

    @media (prefers-reduced-motion) {
        &[data-state='open'],
        &[data-state='closed'] {
            animation: none;
        }
    }

    @keyframes slideDown {
        from {
            height: 0;
        }

        to {
            /* stylelint-disable-next-line custom-property-pattern */
            height: var(--radix-accordion-content-height);
        }
    }

    @keyframes slideUp {
        from {
            /* stylelint-disable-next-line custom-property-pattern */
            height: var(--radix-accordion-content-height);
        }

        to {
            height: 0;
        }
    }
`

const Chevron = styled(ChevronDown).attrs({size: 24})`
    transition: ${tokens.smooth};

    @media (prefers-reduced-motion) {
        transition: none;
    }

    ${Trigger}[data-state='open'] & {
        transform: rotate(-180deg);
    }
`

const bannerStyles = css`
    padding: ${tokens.spacing24};
    border-radius: ${tokens.arc8};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: ${tokens.spacing16};
    column-gap: ${tokens.spacing8};
    align-items: center;
`

const MilestoneAccordionFooterWrapper = styled.div`
    ${bannerStyles}

    border: ${tokens.borderPrimary};
    background-color: ${tokens.colorBackgroundStaticLoud};
`

const MilestoneAccordionFooterBody = styled(Stack).attrs({space: 8})`
    width: auto;
`

const isMilestoneSkippedBannerStyles = css`
    border: ${tokens.borderDiscover};
`

type MilestoneAccordionBannerWrapperProps = {
    $isMilestoneSkipped?: boolean
}

const MilestoneAccordionBannerWrapper = styled.div<MilestoneAccordionBannerWrapperProps>`
    ${bannerStyles}

    background-color: ${tokens.colorBackgroundDiscoverQuiet};
    ${({$isMilestoneSkipped}) => $isMilestoneSkipped && isMilestoneSkippedBannerStyles}
`

const MilestoneAccordionBannerBody = styled(Stack).attrs({space: 8})`
    width: auto;
`

const TaskNumberWrapper = styled.div`
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

type TaskNumberProps = {
    $isMuted: boolean
}

const TaskNumber = styled(Text)<TaskNumberProps>`
    height: 20px;
    width: 20px;
    min-width: 20px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${tokens.arc99999};
    background: ${({$isMuted}) =>
        $isMuted ? tokens.shade400 : tokens.colorBackgroundInteractiveInverse};
`

type MilestoneAccordionTaskWrapperProps = {
    $hideNumber: boolean
    $withCta: boolean
}

const MilestoneAccordionTaskWrapper = styled.div<MilestoneAccordionTaskWrapperProps>`
    display: grid;
    column-gap: ${tokens.spacing16};
    align-items: center;
    min-height: 40px;

    ${({$hideNumber, $withCta}) => {
        if ($hideNumber && !$withCta) {
            return css`
                grid-template-columns: 1fr;
            `
        }

        if ($hideNumber) {
            return css`
                grid-template-columns: 1fr auto;
            `
        }

        if (!$withCta) {
            return css`
                grid-template-columns: auto 1fr;
            `
        }

        return css`
            grid-template-columns: auto 1fr auto;
        `
    }}

    ${({$hideNumber}) => css`
        ${containerQuery(
            {name: 'main', maxWidth: MOBILE_BREAKPOINT},
            css`
                grid-template-columns: ${$hideNumber ? '1fr' : 'auto 1fr'};
            `,
        )}
    `};
`

const StyledProgressBar = styled(ProgressBar)`
    width: 80px;

    &::before,
    &::after {
        background-color: ${tokens.colorBackgroundPositive};
    }

    ${containerQuery(
        {name: 'main', maxWidth: MOBILE_BREAKPOINT},
        css`
            display: none;
        `,
    )}
`

const TaskHeaderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${tokens.spacing8};
    row-gap: ${tokens.spacing4};
`

const MilestoneContentItemChildren = styled.div`
    width: 100%;
`

type MilestoneAccordionTaskContentWrapperProps = {
    $hideNumber: boolean
    $stretch: boolean
}

const MilestoneAccordionTaskContentWrapper = styled.div<MilestoneAccordionTaskContentWrapperProps>`
    width: 100%;
    grid-column: 2 / span 1;
    grid-row: 2;
    margin-top: ${tokens.spacing4};

    ${containerQuery(
        {name: 'main', maxWidth: MOBILE_BREAKPOINT},
        css`
            flex-direction: column;
            align-items: start;
            grid-column: 2 / -1;
            grid-row: unset;
        `,
    )}

    ${({$hideNumber}) =>
        $hideNumber &&
        css`
            grid-column: unset;
        `};

    ${({$stretch}) =>
        $stretch &&
        css`
            ${containerQuery(
                {name: 'main', maxWidth: MOBILE_BREAKPOINT},
                css`
                    grid-column: 1 / -1;
                `,
            )}
        `};
`

type CtaWrapperProps = {
    $withChildren: boolean
    $hideNumber: boolean
    $stretch: boolean
}

const CtaWrapper = styled.div<CtaWrapperProps>`
    width: auto;
    grid-row: 1 / span 2;
    grid-column: 3;

    ${containerQuery(
        {name: 'main', maxWidth: MOBILE_BREAKPOINT},
        css`
            grid-row: 3;
            grid-column: 2 / -1;
            margin-top: ${tokens.spacing16};
        `,
    )}

    ${({$withChildren}) =>
        !$withChildren &&
        css`
            grid-row: unset;
        `};

    ${({$hideNumber}) =>
        $hideNumber &&
        css`
            grid-column: unset;
        `};

    ${({$stretch}) =>
        $stretch &&
        css`
            ${containerQuery(
                {name: 'main', maxWidth: MOBILE_BREAKPOINT},
                css`
                    grid-column: 1 / -1;
                `,
            )}
        `};
`

//#endregion Styles

//#region Context

type Context = {
    status: MilestoneStatus
}

const MilestoneAccordionContext = React.createContext<Context>({
    status: 'INCOMPLETE',
})

const useMilestoneAccordionContext = () => React.useContext(MilestoneAccordionContext)

//#endregion Context

MilestoneAccordion.Item = MilestoneAccordionItem
MilestoneAccordion.Header = MilestoneAccordionHeader
MilestoneAccordion.Content = MilestoneAccordionContent
MilestoneAccordion.ContentItem = MilestoneContentItem
MilestoneAccordion.Tasks = MilestoneAccordionTasks
MilestoneAccordion.Footer = MilestoneAccordionFooter
MilestoneAccordion.Banner = MilestoneAccordionBanner
MilestoneAccordion.SkipButton = MilestoneAccordionSkipButton
