import merge from 'lodash.merge'

import type Country from '@product-web/locale/country'
import {getCountry, useUser} from '@product-web/user'

import defaultCountryConfiguration from './configurations/default'
import countryConfigurationMap from './configurations/map'
import type {Configuration, CountryConfigurations, Feature} from './types'

export const createCountryConfigurations = (
    countryMap = countryConfigurationMap,
    countryDefault = defaultCountryConfiguration,
): CountryConfigurations =>
    Object.keys(countryMap).reduce((countries: Partial<Configuration>, country: string) => {
        const mergedCountry = merge({}, countryDefault, countryMap[country as Country])
        return {
            ...countries,
            [country]: mergedCountry,
        }
    }, {}) as CountryConfigurations

export const countryConfigurations: CountryConfigurations = createCountryConfigurations()

/**
 * Function for accessing the feature configuration for a country, if no country is provided it will use the default config.
 * @param country Country, override for country
 * @param feature Feature, feature configuration to access
 * @returns featureConfiguration FeatureConfiguration, feature configuration for the requested country
 * @example.
 *      const {documents} = getFeatureCountryConfiguration(Country.GB, Feature.Terms)
 */
export const getFeatureCountryConfiguration = <K extends Feature>(
    feature: K,
    country?: Country,
): Configuration[K] => {
    return country ? countryConfigurations[country][feature] : defaultCountryConfiguration[feature]
}

/**
 * Hook for accessing the feature configuration for a country, will default to the users country unless override provided.
 * @param feature Feature, feature configuration to access
 * @param countryOverride Country, override for country
 * @returns featureConfiguration FeatureConfiguration, feature configuration for the requested country
 * @example.
 *      const {documents} = useFeatureCountryConfig(Feature.Terms)
 */
export function useFeatureCountryConfig<K extends Feature>(
    feature: K,
    countryOverride?: Country,
): Configuration[K] {
    const userCountry = getCountry(useUser())
    const country = countryOverride || userCountry
    return getFeatureCountryConfiguration(feature, country)
}
