import Portal from '@reach/portal'
import type React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

interface OverlayTriangleProps {
    isAbove: boolean
    targetRect: DOMRect | null
    shouldAnimate: boolean
    bgColor?: string
    borderColor?: string
    animationStyles?: any
    size?: number
    className?: string
}

export const OverlayTriangle: React.FC<OverlayTriangleProps> = ({
    isAbove,
    targetRect,
    bgColor = tokens.shade000,
    borderColor = tokens.shade300,
    className,
    size = 10,
}) => {
    // the triangle is rendered in its own portal, absolutely positioned relative to the trigger element
    return targetRect ? (
        <Portal>
            <TriangleWrapper
                className={className}
                $size={size}
                $target={targetRect}
                $isAbove={isAbove}
            >
                <Triangle
                    $isAbove={isAbove}
                    $bgColor={bgColor}
                    $borderColor={borderColor}
                    $size={size}
                />
            </TriangleWrapper>
        </Portal>
    ) : null
}

type TriangleProps = {
    $isAbove: boolean
    $bgColor: string
    $borderColor: string
    $size: number
}
const Triangle = styled.div<TriangleProps>`
    @supports (clip-path: inset(50%)) {
        display: block;
        height: ${(props) => props.$size}px;
        width: ${(props) => props.$size}px;
        background-color: ${(props) => props.$bgColor};
        border: ${tokens.borderPrimary};
        border-color: ${(props) => props.$borderColor};
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        transform: rotate(${(props) => (props.$isAbove ? '-45deg' : '135deg')});
    }
`

type TriangleWrapperProps = {
    $target: DOMRect
    $isAbove: boolean
    $size: number
}
const TriangleWrapper = styled.div<TriangleWrapperProps>`
    position: absolute;
    left: ${(props) => props.$target.left - props.$size / 2 + props.$target.width / 2}px;
    top: ${(props) =>
        props.$isAbove
            ? props.$target.top + window.scrollY - props.$size * 1.5
            : props.$target.bottom + window.scrollY + 3}px;
`
