import {t} from '@lingui/macro'
import type React from 'react'

import {PlanUpgradeSource} from '@product-web/api-types/plan'

import {useLocalisedIllustration} from './empty-states.helper'
import {EmptyStatesBase} from './empty-states-base'

export const EmptyStatesBudgets = ({secondaryAction}: {secondaryAction: () => React.ReactNode}) => {
    const {illustrationSrc: EmptyStatesBudgetsIllustration} = useLocalisedIllustration({
        type: 'budgets',
    })

    return (
        <EmptyStatesBase
            title={t`Stay in control of budgets`}
            description={t`Track expenses for specific projects, clients, or departments.`}
            ctaText={t`Get Budgets`}
            planUpgradeSource={PlanUpgradeSource.BUDGETS_EMPTYSTATE_FLOW}
            pageOrigin={'budgets'}
            illustration={EmptyStatesBudgetsIllustration}
            hasIllustrationAboveCopy
            secondaryAction={secondaryAction}
        />
    )
}
