import {useMemo} from 'react'
import {useGate} from 'statsig-react'

import {dayjs} from '@product-web/dates/dayjs'
import {useUser} from '@product-web/user'
import {useVendorCard} from '@product-web-features/vendor-cards/lib/use-vendor-card'

import {bffHooks} from '../../bff-hooks'

/**
 * @returns {boolean} - Whether the Spend Guide Activation Guide should be visible to the Admin
 *
 * @return {true} if all of the following conditions are met:
 * - if customer is on Advanced plan
 * - if customer has recurring spend on personal cards
 * - if customer is older than 60 days
 * - if customer has no sub-wallets
 * - if feature-gate is enabled
 */

const oneYearBefore = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
const today = dayjs().startOf('day').format('YYYY-MM-DD')

export const useSpendActivationGuide = () => {
    const {value: featureGateEnabled} = useGate('spend_activation_setup_flow')
    const user = useUser()

    const {canUseVendorCards} = useVendorCard()

    const canCheckForSpendActivationGuide =
        featureGateEnabled && canUseVendorCards && user?.role === 'owner'

    const {data: showSpendActivationGuide} =
        bffHooks.recurringVendors.spendActivationGuide.getShouldShowSpendActivationGuide.useQuery(
            {from: [oneYearBefore], to: [today]},
            {enabled: canCheckForSpendActivationGuide},
        )

    const shouldShowSpendGuideActivationGuide =
        featureGateEnabled && canUseVendorCards && showSpendActivationGuide

    return useMemo(
        () => ({
            isEnabled: shouldShowSpendGuideActivationGuide,
        }),
        [shouldShowSpendGuideActivationGuide],
    )
}
