import {t, Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'
import {getQueryKey} from '@trpc/react-query'
import {Formik} from 'formik'
import {useState} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {
    Button,
    FormikInput as Input,
    IconButton,
    Inline,
    Link,
    Loading,
    LoadingPage,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalFooter,
    ModalTitle,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {ArrowRight, Pen} from '@pleo-io/telescope-icons'

import {useFlags} from '@product-web/flags'
import {useSupportChat} from '@product-web/freshchat/use-support-chat'
import {isCountry} from '@product-web/locale/country'
import Address from '@product-web/telescope-lab/address/address'
import {useToaster} from '@product-web/toaster'
import {useActionWithFeedback} from '@product-web/use-action-with-feedback/use-action-with-feedback'
import yup from '@product-web/validation/yup'

import {bff} from '../../../bff-hooks'
import Terms from '../../../components/company-lookup/terms/terms'
import {GenericError} from '../../../components/generic-error'
import {SplitContent} from '../../../components/split-content'
import {usePartnerClientSession} from '../../../lib/use-partner-client-session'
import {PartnerAgreement} from '../components/partner-agreement/partner-agreement'
import {PartnerTierCard} from '../components/partner-tier-card/partner-tier-card'

type FormValues = {tradingName: string}

const validationSchema = yup.object().shape({
    tradingName: yup.string().required(() => t`Please enter the trading name`),
})

export const CompanyInfo = () => {
    const queryClient = useQueryClient()
    const [showTradingNameModal, setShowTradingNameModal] = useState(false)
    const {showToast} = useToaster()
    const {switchClient} = usePartnerClientSession()
    const navigate = useNavigate()
    const {partnerAgreementCountries} = useFlags()
    const {data, isError, isLoading} =
        bff.companyInfo.getCompanyInfo.useQuery(partnerAgreementCountries)

    const queryKey = getQueryKey(bff.companyInfo.getCompanyInfo, partnerAgreementCountries, 'query')

    const {mutateAsync: updatePartnerInfo} = bff.companyInfo.updateCompanyInfo.useMutation({
        onSuccess(_, {tradingName}) {
            queryClient.setQueryData(queryKey, (oldData: typeof data) => {
                if (oldData) {
                    return {...oldData, tradingName}
                }
                return undefined
            })
            setShowTradingNameModal(false)
            showToast(t`Trading name updated successfully.`, {level: 'success'})
        },
        onError() {
            setShowTradingNameModal(false)
            showToast(t`Could not change the trading name. Please try again or contact support.`, {
                level: 'error',
            })
        },
    })

    const {mutateAsync: createOwnPleoCompany, isLoading: isCreatingOwnPleoCompany} =
        bff.companyInfo.createOwnPleoCompany.useMutation()

    const handleOnboardOwnCompany = useActionWithFeedback(
        async () => {
            const {ownCompanyId} = await createOwnPleoCompany()
            await switchClient(ownCompanyId)
            navigate('/company-verification')
        },
        () => ({
            confirmTitle: t`Create company account`,
            confirmDescription: <ConfirmDescription country={data?.address.country || ''} />,
            confirmLabel: t`Create account`,
            successMessage: t`Your own company created, redirecting...`,
            errorMessage: t`An error occurred. Please try again later or contact support.`,
        }),
    )

    if (isError) {
        return <GenericError />
    }

    if (isLoading) {
        return <LoadingPage />
    }

    const pleoOwnCompany = data.ownCompany
    const partnerCompanyName = data.name
    const pleoOwnCompanyId = data.ownCompanyId

    return (
        <Stack mt={32} stretch>
            <SplitContent reverse>
                <SplitContent.Main>
                    <CompaniesGrid>
                        <div>
                            <Text variant="large-accent" weight="semibold" as="h2" space={10}>
                                <Trans>Practice Info</Trans>
                            </Text>
                            <Text variant="small-subtle">
                                <Trans>Legal name</Trans>
                            </Text>
                            <Text variant="large-accent" space={20}>
                                {data.name}
                            </Text>
                            <div>
                                <Text variant="small-subtle">
                                    <Trans>Address</Trans>
                                </Text>
                                <Text variant="large-accent" space={20}>
                                    <Address address={data.address} />
                                </Text>
                            </div>
                            <div>
                                <Text variant="small-subtle">
                                    <Trans>Registration no.</Trans>
                                </Text>
                                <Text variant="large-accent" space={20}>
                                    {data.registrationNumber}
                                </Text>
                            </div>
                            <GetInTouchButton companyName={partnerCompanyName}>
                                <Trans>Get in touch if something has changed</Trans>
                            </GetInTouchButton>
                        </div>
                        <div>
                            {data.isPartnerOwner && !pleoOwnCompany && (
                                <>
                                    <Text
                                        variant="large-accent"
                                        weight="semibold"
                                        as="h2"
                                        space={10}
                                    >
                                        <Trans>Own Pleo Company</Trans>
                                    </Text>
                                    {pleoOwnCompanyId ? (
                                        <Text space={20}>
                                            <Trans>
                                                Your Pleo account has been created successfully.
                                                However, it may take awhile before your Pleo company
                                                is shown on this page. Please check back later.
                                            </Trans>
                                        </Text>
                                    ) : (
                                        <Text>
                                            <Trans>Start using Pleo for your own business.</Trans>{' '}
                                            {isCreatingOwnPleoCompany ? (
                                                <Loading size={8} />
                                            ) : (
                                                <Button
                                                    variant="link"
                                                    onClick={handleOnboardOwnCompany}
                                                    IconRight={ArrowRight}
                                                >
                                                    <Trans>Create account now</Trans>
                                                </Button>
                                            )}
                                        </Text>
                                    )}
                                </>
                            )}
                            {pleoOwnCompany && (
                                <>
                                    <Text
                                        variant="large-accent"
                                        weight="semibold"
                                        as="h2"
                                        space={10}
                                    >
                                        <Trans>Own Pleo Company</Trans>
                                    </Text>
                                    <Text variant="small-subtle">
                                        <Trans>Legal name</Trans>
                                    </Text>
                                    <Text variant="large-accent" space={20}>
                                        {pleoOwnCompany.name}
                                    </Text>
                                    {pleoOwnCompany.address && (
                                        <div>
                                            <Text variant="small-subtle">
                                                <Trans>Address</Trans>
                                            </Text>
                                            <Text variant="large-accent" space={20}>
                                                <Address
                                                    address={{
                                                        addressLine1:
                                                            pleoOwnCompany.address.addressLine1 ||
                                                            '',
                                                        addressLine2:
                                                            pleoOwnCompany.address.addressLine2 ||
                                                            '',
                                                        country:
                                                            pleoOwnCompany.address.country || '',
                                                        locality:
                                                            pleoOwnCompany.address.locality || '',
                                                        postalCode: '',
                                                    }}
                                                />
                                            </Text>
                                        </div>
                                    )}
                                    <div>
                                        <Text variant="small-subtle">
                                            <Trans>Registration number</Trans>
                                        </Text>
                                        <Text variant="large-accent" space={20}>
                                            {pleoOwnCompany.registrationNumber}
                                        </Text>
                                    </div>
                                </>
                            )}
                            {pleoOwnCompanyId && (
                                <Link
                                    as={RouterLink}
                                    to="/expenses"
                                    onClick={async () => {
                                        await switchClient(pleoOwnCompanyId)
                                    }}
                                    IconRight={ArrowRight}
                                >
                                    <Trans>Go to Pleo account</Trans>
                                </Link>
                            )}
                        </div>
                    </CompaniesGrid>
                    <Stack as="section" mb={24} mt={24}>
                        <Text variant="large-accent" weight="semibold" as="h2" space={10}>
                            <Trans>Trading Name</Trans>
                        </Text>
                        <Inline alignItems="center" space={12}>
                            <Text variant="large-accent">{data.tradingName}</Text>
                            {data.isPartnerOwner && (
                                <IconButton
                                    aria-label={t`Edit trading name`}
                                    Icon={Pen}
                                    variant="quiet"
                                    tooltipProps={{content: t`Edit trading name`, side: 'right'}}
                                    onClick={() => setShowTradingNameModal(true)}
                                />
                            )}
                        </Inline>
                        <Modal
                            aria-label={t`Edit trading name`}
                            isOpen={showTradingNameModal}
                            onDismiss={() => setShowTradingNameModal(false)}
                        >
                            <ModalClose onClick={() => setShowTradingNameModal(false)} />
                            <ModalTitle>
                                <Trans>Edit trading name</Trans>
                            </ModalTitle>
                            <Formik<FormValues>
                                initialValues={{tradingName: ''}}
                                validationSchema={validationSchema}
                                onSubmit={async (values) => await updatePartnerInfo(values)}
                            >
                                {(formik) => (
                                    <Stack as="form" onSubmit={formik.handleSubmit} stretch>
                                        <ModalContent>
                                            <Text space={20}>
                                                <Trans>
                                                    What would you like your new trading name to be?
                                                </Trans>
                                            </Text>
                                            <Stack mb={40} stretch>
                                                <Input
                                                    placeholder={t`New trading name`}
                                                    aria-label={t`New trading name`}
                                                    name="tradingName"
                                                    variant="bordered"
                                                />
                                            </Stack>
                                        </ModalContent>
                                        <ModalActions>
                                            <Inline space={16}>
                                                <Button
                                                    type="button"
                                                    variant="secondary"
                                                    onClick={() => setShowTradingNameModal(false)}
                                                >
                                                    <Trans>Cancel</Trans>
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    loading={formik.isSubmitting}
                                                >
                                                    <Trans>Confirm</Trans>
                                                </Button>
                                            </Inline>
                                        </ModalActions>
                                        <ModalFooter>
                                            <Trans>
                                                The trading name is used for communication with your
                                                clients.
                                            </Trans>
                                        </ModalFooter>
                                    </Stack>
                                )}
                            </Formik>
                        </Modal>
                    </Stack>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <Stack space={32} stretch>
                            <PartnerTierCard />
                        </Stack>
                    </SplitContent.Sticky>
                    {data.showPartnerAgreement && (
                        <SplitContent.Sticky>
                            <PartnerAgreement />
                        </SplitContent.Sticky>
                    )}
                </SplitContent.Right>
            </SplitContent>
        </Stack>
    )
}

const ConfirmDescription = ({country}: {country: string}) => {
    const validCountry = isCountry(country) ? country : undefined
    return (
        <Stack align="center" space={16}>
            <Trans>Do you want to start using Pleo for your own business?</Trans>
            <Terms country={validCountry} />
        </Stack>
    )
}

const CompaniesGrid = styled.div`
    display: grid;
    gap: ${tokens.spacing16};
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`

function GetInTouchButton({
    companyName,
    children,
}: {
    companyName: string
    children: React.ReactNode
}) {
    const supportChat = useSupportChat()

    return supportChat.isConnected ? (
        <Button variant="tertiary" onClick={async () => await supportChat.show()}>
            {children}
        </Button>
    ) : (
        <Button
            variant="tertiary"
            href={`mailto:support@pleo.io?subject=${t`(${companyName})'s company information has changed.`}`}
            target="_blank"
        >
            {children}
        </Button>
    )
}
