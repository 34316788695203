/**
 * Checks if the string passed contains the generic domain allowed by
 * Pleo
 * @param email String with the email to check
 * @returns A boolean indicating if the email has the generic domain
 * @example
 * checkGenericEmail('test@gmail.com') // true
 * checkGenericEmail('test@hotmail.com') // false
 * checkGenericEmail('test@test.com') // false
 */

export function checkGenericEmail(email: string) {
    return /@gmail.com$/i.test(email)
}
