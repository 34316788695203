import type {LocaleData} from '@lingui/core'
import {i18n} from '@lingui/core'
import {I18nProvider as LinguiProvider} from '@lingui/react'
import {da, de, en, es, fi, fr, it, nl, no, pt, sv} from 'make-plural/plurals'
import type {FC} from 'react'
import React, {useCallback, useEffect, useState} from 'react'

import {LoadingPage} from '@pleo-io/telescope'

import * as tracking from '@product-web/analytics'
import {dayjs, DefaultLocaleFormat} from '@product-web/dates/dayjs'
import {dayjsLocaleMap, getDayjsLocales} from '@product-web/dates/get-dayjs-locales'
import {reportError} from '@product-web/error/report'
import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {i18nStorage} from '@product-web/i18n'
import {SupportedLanguage} from '@product-web/i18n'
import {
    getLocale,
    isUserAskedToSwitchTheLanguage as getIsUserAskedToSwitchTheLanguage,
    useUser,
    useUserMutations,
} from '@product-web/user'

import {useAppLanguage, useBrowserLanguage} from './get-app-language'
import {ModalLanguageDetection} from './language-detection-modal'
import {loadMessages} from './load-messages'
import {useSetAppLang} from './set-app-lang'

interface Props {
    children: React.ReactNode
}

let MISSING_TRANSLATION_CACHE: Record<string, string> = {}

export const I18nProvider: FC<Props> = ({children}) => {
    const {updateLanguage} = useUserMutations()
    const user = useUser()
    const flagsLoaded = useFlagsLoaded()
    const flags = useFlags()
    const locale = getLocale(user)
    const [modalVisible, setModalVisible] = React.useState(false)
    const isUserAskedToSwitchTheLanguage = getIsUserAskedToSwitchTheLanguage(user)
    const [catalogLoading, setCatalogLoading] = useState(true)
    const userLanguage = user?.language
    const browserLanguage = useBrowserLanguage()
    const userLoaded = Boolean(user?.id)
    const appLanguage = useAppLanguage()
    const isUserWithDefaultLanguage = !userLanguage || userLanguage === SupportedLanguage.EN
    const shouldShowLanguageModal =
        userLoaded &&
        !isUserAskedToSwitchTheLanguage &&
        isUserWithDefaultLanguage &&
        browserLanguage !== SupportedLanguage.EN

    const loadCatalog = useCallback(async () => {
        setCatalogLoading(true)
        const catalog = await loadMessages(appLanguage)

        i18n.load({[appLanguage]: catalog.messages})
        i18n.activate(appLanguage, [locale])

        // track when a translation is missing
        i18n.on('missing', (event) => {
            /*
                Don't track missing 'en' translations as the translation key
                for EN is the actual message, so it will still be displayed.
                This also reduces spam on dev when running local versions
                without the latest @pleo-io/product-web-translations.
            */
            if (event.locale === 'en') {
                return
            }

            const localeKey = `${event.locale}_${event.id}`

            if (typeof MISSING_TRANSLATION_CACHE[localeKey] === typeof undefined) {
                tracking.missingTranslation({
                    key: event.id,
                    locale: event.locale,
                })
                MISSING_TRANSLATION_CACHE = {
                    ...MISSING_TRANSLATION_CACHE,
                    [localeKey]: 'tracked',
                }
            }
        })

        setCatalogLoading(false)
    }, [appLanguage, locale])

    useEffect(() => {
        const localeData: Record<SupportedLanguage, LocaleData> = {
            da: {plurals: da},
            de: {plurals: de},
            'de-AT': {plurals: de},
            en: {plurals: en},
            es: {plurals: es},
            fi: {plurals: fi},
            fr: {plurals: fr},
            'fr-BE': {plurals: fr},
            nl: {plurals: nl},
            'nl-BE': {plurals: nl},
            pt: {plurals: pt},
            sv: {plurals: sv},
            it: {plurals: it},
            no: {plurals: no},
        }
        i18n.loadLocaleData(localeData)
    }, [])

    useEffect(() => {
        loadCatalog()
        getDayjsLocales(appLanguage)
            .then(() => {
                dayjs.locale(dayjsLocaleMap[appLanguage])
                // This next line will enforce the localised dates to be always formatted the same as required by the localisation team
                // the goal is to have more control over the rendered UI.
                // If in the future we want to support formatted dates by local we can simply remove it.
                // (please then also remove it in the following files : 'shared/test--config/setup.js', 'shared/test/helpers/i18n.tsx')
                // the localised date using localizedFormat ('L', 'LL' etc...) will then apply the format of each dayjs locale files
                // current behaviour example for LL format => en-gb: 12 Oct 2022 - de: 12 Okt 2022
                // future behaviour example for LL format => en-gb: 12 October 2022 - de: 12. Oktober 2022
                dayjs.Ls[dayjsLocaleMap[appLanguage]].formats = DefaultLocaleFormat
            })
            .catch((e) => {
                reportError(e, `Couldn't load dayjs locale: ${appLanguage}`)
            })
    }, [appLanguage, loadCatalog])

    useEffect(() => {
        // appLanguage depends on feature flags as certain locales may be turned on/off
        if (flagsLoaded) {
            i18nStorage.set(appLanguage)
        }
        if (flagsLoaded && userLanguage && userLanguage !== appLanguage) {
            updateLanguage(appLanguage)
        }
        // update user language based on the app language if language modal shouldn't appear
        if (
            userLoaded &&
            flagsLoaded &&
            flags.shouldUpdateLocalesWithEmptyUserLanguage &&
            !userLanguage &&
            !shouldShowLanguageModal
        ) {
            updateLanguage(appLanguage)
        }
    }, [
        userLanguage,
        appLanguage,
        flags.shouldUpdateLocalesWithEmptyUserLanguage,
        userLoaded,
        flagsLoaded,
        shouldShowLanguageModal,
    ])

    useEffect(() => {
        if (shouldShowLanguageModal) {
            setModalVisible(true)
        }
    }, [userLoaded])

    useSetAppLang(locale)

    if (catalogLoading) {
        return <LoadingPage />
    }

    return (
        <LinguiProvider i18n={i18n}>
            {children}
            {modalVisible && (
                <ModalLanguageDetection
                    language={browserLanguage}
                    closeModal={() => setModalVisible(false)}
                />
            )}
        </LinguiProvider>
    )
}
