import {t, Trans} from '@lingui/macro'
import {useFormikContext} from 'formik'
import type {Dispatch, SetStateAction} from 'react'

import {Box, Button, Inline, RadioButton, RadioGroup, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft, SpeechBubble} from '@pleo-io/telescope-icons'

import type {AdoptionEssentialsFeedbackSubmitted} from '@product-web/analytics'
import tracking from '@product-web/analytics'
import {useSupportChat} from '@product-web/freshchat/use-support-chat'

import BrowseIcon from './browse.png'
import ChatIcon from './chat.png'
import {FeedbackState} from './feedback'
import {StyledCallToAction, StyledTextarea, SubtitleTextarea} from './styles'

import {AdoptionAccordionView} from '../accordion/adoption-accordion'
import {BackAction, BackButtonWrapper, Container} from '../accordion/components'
import {usePleoLinks} from '../adoption-centre-link-helpers'

type UnhelpfulProps = {
    setFeedbackState: (arg: FeedbackState) => void
    guideName: string
    closeAdoptionCentre: () => void
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
}

type UnhelpfulReasonProps = {
    unhelpful: {
        reason: string
        otherReason: string
    }
}

export enum UnhelpfulReason {
    DidntAnswerMyQuestion = 'didnt-answer-my-question-or-solve-problems',
    ListWasConfusingOrDifficultToRead = 'list-was-confusing-or-difficult-to-read',
    SomeoneElseIsResponsibleForThoseTask = 'someone-else-is-responsible-for-those-tasks',
    Other = 'other',
    None = 'unanswered',
}

export const Unhelpful = ({
    setFeedbackState,
    guideName,
    closeAdoptionCentre,
    setView,
}: UnhelpfulProps) => {
    const formikProps = useFormikContext<UnhelpfulReasonProps>()
    const reason = formikProps.getFieldProps('unhelpful.reason').value
    const otherReasonText = formikProps.getFieldProps('unhelpful.otherReason').value

    const {getHelpCentreLink} = usePleoLinks()
    const supportChat = useSupportChat()

    const updateReason = (value: UnhelpfulReason) => {
        formikProps.setFieldValue('unhelpful.reason', value)

        if (value !== UnhelpfulReason.Other) {
            submitReason(value)
        }
    }

    const submitReason = (value: UnhelpfulReason) => {
        const trackingPayload: AdoptionEssentialsFeedbackSubmitted = {
            helpful: 'no',
            option: value,
            guide: guideName,
        }
        if (value === UnhelpfulReason.Other) {
            trackingPayload.other_text = otherReasonText
        }

        tracking.adoptionEssentialsFeedbackSubmitted(trackingPayload)
        formikProps.submitForm()
    }

    const onShowHelpCenter = () => {
        tracking.adoptionEssentialsCentreActioned({
            action: 'link_clicked',
            guide: guideName,
            page: 'unhelpful_feedback',
            target_url: 'help_centre',
        })

        const helpCenterWindow = window.open(getHelpCentreLink(), '_blank', 'noopener,noreferrer')
        if (helpCenterWindow) {
            helpCenterWindow.opener = null
        }
    }

    const onChatToUs = async () => {
        tracking.adoptionEssentialsCentreActioned({
            action: 'link_clicked',
            guide: guideName,
            page: 'unhelpful_feedback',
            target_url: 'contact_us',
        })
        closeAdoptionCentre()
        await supportChat.show()
    }

    const radioButtons = [
        {
            value: UnhelpfulReason.DidntAnswerMyQuestion,
            label: t`It didn't answer my question or solve my problem`,
        },
        {
            value: UnhelpfulReason.ListWasConfusingOrDifficultToRead,
            label: t`List was confusing or difficult to read`,
        },
        {
            value: UnhelpfulReason.SomeoneElseIsResponsibleForThoseTask,
            label: t`Someone else is responsible for those tasks`,
        },
        {
            value: UnhelpfulReason.Other,
            label: t`Other`,
        },
    ]

    return (
        <Container m={24}>
            <BackButtonWrapper paddingTop={20} paddingBottom={20}>
                <BackAction
                    onClick={() => {
                        setFeedbackState(FeedbackState.None)
                        formikProps.resetForm()
                        setView(AdoptionAccordionView.INITIAL)
                    }}
                >
                    <ArrowLeft marginRight={8} size={16} />
                    <Trans>Back</Trans>
                </BackAction>
            </BackButtonWrapper>
            <Inline
                marginTop={16}
                marginBottom={formikProps.isSubmitting ? 48 : 20}
                alignY="center"
            >
                <Text variant="large-accent">
                    {formikProps.isSubmitting ? (
                        // eslint-disable-next-line string-to-lingui/text-restrictions
                        <Trans>We got your feedback, appreciate it!</Trans>
                    ) : (
                        <Trans>
                            Sorry about that! What did you find the least <br /> helpful?
                        </Trans>
                    )}
                </Text>
            </Inline>

            {formikProps.isSubmitting ? null : (
                <>
                    <RadioGroup
                        label={t`Reason`}
                        value={reason}
                        onValueChange={(value) => updateReason(value)}
                    >
                        <Stack space={12} data-testid="adoption-centre-feedback-reason">
                            {radioButtons.map((radio, index) => {
                                return (
                                    <RadioButton
                                        value={String(radio.value)}
                                        label={radio.label}
                                        key={index}
                                    />
                                )
                            })}
                        </Stack>
                    </RadioGroup>

                    {reason === UnhelpfulReason.Other && (
                        <>
                            <StyledTextarea
                                autoFocus
                                value={otherReasonText}
                                onChange={(event) => {
                                    formikProps.setFieldValue(
                                        'unhelpful.otherReason',
                                        event.target.value,
                                    )
                                }}
                                showCounter
                                name="otherReason"
                                fixedHeight="100px"
                                maxLength={1000}
                                data-testid="unhelpful-textarea"
                            />
                            <Inline marginTop={12} space={10} alignY="center">
                                <SpeechBubble size={16} />
                                <SubtitleTextarea>
                                    <Trans>We value your feedback</Trans>
                                </SubtitleTextarea>
                            </Inline>
                            <Inline align="right" marginTop={16}>
                                <Button
                                    variant="primary"
                                    disabled={otherReasonText?.length === 0}
                                    onClick={() => submitReason(reason)}
                                    data-testid="adoption-centre-submit-unhelpful-feedback"
                                >
                                    <Trans>Send</Trans>
                                </Button>
                            </Inline>
                        </>
                    )}
                </>
            )}

            <Box marginTop={48}>
                <Box marginBottom={20}>
                    <Text variant="large-accent">
                        <Trans>Would you give this a try?</Trans>
                    </Text>
                </Box>

                <StyledCallToAction image={BrowseIcon} onClick={onShowHelpCenter}>
                    <Inline alignY="center" space={8}>
                        <Text variant="large-accent">{t`Browse help articles`}</Text>
                    </Inline>
                    <Text as="p" color="shade600">
                        {t`FAQs and troubleshooting`}
                    </Text>
                </StyledCallToAction>
                {supportChat.isEntitled && (
                    <StyledCallToAction image={ChatIcon} onClick={onChatToUs}>
                        <Inline alignY="center" space={8}>
                            <Text variant="large-accent">{t`Chat to us `}</Text>
                        </Inline>
                        <Text as="p" color="shade600">
                            {t`Get guidance on your question`}
                        </Text>
                    </StyledCallToAction>
                )}
            </Box>
        </Container>
    )
}
