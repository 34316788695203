import axios from 'axios'

export default async function download(
    url: string,
    filename: string,
    timeout = 30000,
): Promise<void | Blob> {
    return axios
        .get(url, {
            responseType: 'blob',
            timeout,
        })
        .then((response) => {
            const blob = new Blob([response.data])
            const windowUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = windowUrl
            // the filename you want
            a.download = filename
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(windowUrl)
        })
}

export const getDownloadFileName = (prefix: string, extension = 'zip') => {
    const date = new Date()
    return `${prefix}_${date.toLocaleDateString()}_${date.toLocaleTimeString()}.${extension}`.replace(
        /:/g,
        '_',
    )
}
