import useSWR from 'swr'

import type {GetUserNotificationsSettings} from '@pleo-io/deimos'

import {request} from '@product-web/api'
import {RequestAuth} from '@product-web/api'
import config from '@product-web/config'
import {useUser} from '@product-web/user'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

async function updateNotificationSettings(
    notifications: GetUserNotificationsSettings,
    employeeId?: string | null,
) {
    return request(
        `${baseUrl}/rest/v1/users/settings/notifications?${
            employeeId ? `employeeId=${employeeId}` : ''
        }`,
        {
            auth: 'user',
            method: 'PUT',
            body: notifications,
        },
    )
}

export function useSettings() {
    const user = useUser()
    const result = useSWR<any>(
        user
            ? `rest/v1/user/settings?${user.employee?.id ? `employeeId=${user.employee.id}` : ''}`
            : null,
        getDeimos,
    )

    const updateNotifications = async (updatedNotifications: GetUserNotificationsSettings) => {
        result.mutate({...result.data, notifications: updatedNotifications}, false)
        try {
            await updateNotificationSettings(updatedNotifications, user?.employee?.id)
        } catch (error) {
            result.mutate()
            throw error
        }
        result.mutate()
    }

    return {...result, mutations: {updateNotifications}}
}

interface UpdateProfilePayload {
    firstName: string
    lastName: string
    phone: string | null
    jobTitle: string | null
}
export type ViewerRole = 'employee' | 'bookkeeper' | 'partner-employee'

export async function updateProfile(viewerRole: ViewerRole, payload: UpdateProfilePayload) {
    return request(`${baseUrl}/rest/v1/user?viewerRole=${viewerRole}`, {
        auth: RequestAuth.USER,
        method: 'PUT',
        body: payload,
    })
}

export async function updateAvatar(file: File, onProgress?: (number: number) => void) {
    return request(`${baseUrl}/rest/v1/user/avatar`, {
        auth: RequestAuth.USER,
        method: 'POST',
        file,
        onProgress,
    })
}
