import type {Ref} from 'react'
import styled from 'styled-components'

import {Avatar as AvatarBase, Text, tokens} from '@pleo-io/telescope'

import {Chip} from '../chip'

export const Avatar = styled(AvatarBase).attrs({outlined: true})<{index?: number}>`
    /* max is 4, according to Avatar group props => it's still less than zIndexOverlay(=5) in @pleo-io/telescope */
    z-index: ${({index}) => index};
`

export const Container = styled(Chip)<{withHover?: boolean; ref?: Ref<any> | null}>`
    white-space: nowrap;
    padding-top: ${tokens.spacing6};
    padding-bottom: ${tokens.spacing6};
    padding-right: ${tokens.spacing12};
    padding-left: ${tokens.spacing8};

    &:hover {
        cursor: pointer;
        transition: ${`background-color` + tokens.smoothInOut};
        background-color: ${({withHover}) => (withHover ? tokens.shade300 : tokens.shade200)};
    }
    &:hover ${Avatar} {
        transition: ${`background-color` + tokens.smoothInOut};
        border-color: ${({withHover}) => (withHover ? tokens.shade300 : tokens.shade200)};
    }
`
export const StyledText = styled(Text)`
    margin-right: ${tokens.spacing4};
`
