import React from 'react'

import {useIsLoggedIn} from '@product-web/auth--session/context'

import {bff} from './bff-hooks'
import type {ComponentWithOutage} from './index.bff'
import {OutageBanner} from './status-page.view'

type StatusPageComponentName = ComponentWithOutage['name']

type StatusPageContextAPI = {
    registerReportedComponents: (id: string, components: StatusPageComponentName[]) => void
    unregisterReportedComponents: (id: string) => void
}

const StatusPageContext = React.createContext<StatusPageContextAPI>({
    registerReportedComponents: () => {},
    unregisterReportedComponents: () => {},
})

// This provider wraps the pages container. It's responsible for displaying a notification banner
// that informs the user of any currently ongoing issues in the Pleo systems. The information comes
// from a 3rd party service called Statuspage (see https://status.pleo.io, http://statuspage.io) which
// gets updated any time a system outage occurs.
export const StatusPageProvider: React.FC = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {default: DEFAULT_REPORTED_COMPONENTS})
    const isLoggedIn = useIsLoggedIn()

    const {data} = bff.componentsWithOutage.useQuery(undefined, {refetchOnWindowFocus: false})
    const componentsWithOutage = data ?? []

    const contextValue: StatusPageContextAPI = React.useMemo(
        () => ({
            registerReportedComponents: (id, components) =>
                dispatch({type: 'register', components, id}),
            unregisterReportedComponents: (id) => dispatch({type: 'unregister', id}),
        }),
        [],
    )

    // In order to register and deregister additional reported components without
    // any interference, we save each call to useStatusPage under a random string key, e.g.
    // {'68c7bad0c4': 'wallet', '3591bf5a0b': 'wallet', default: ['transactions', 'mobileApp', 'webApp']}
    // We then flatten and dedupe the components before passing to the notification banner component.
    const reportedComponents = [...new Set(Object.values(state).flat())]
    const components = componentsWithOutage.map((component) => ({
        ...component,
        isReported: reportedComponents.includes(component.name),
    }))

    return (
        <StatusPageContext.Provider value={contextValue}>
            {isLoggedIn && <OutageBanner components={components} />}
            {children}
        </StatusPageContext.Provider>
    )
}

function reducer(
    reportedComponents: Record<string, StatusPageComponentName[]>,
    action:
        | {type: 'register'; id: string; components: StatusPageComponentName[]}
        | {type: 'unregister'; id: string},
) {
    switch (action.type) {
        case 'register':
            return {...reportedComponents, [action.id]: action.components}
        case 'unregister': {
            const {[action.id]: ignored, ...nextReportedComponents} = reportedComponents
            return nextReportedComponents
        }
        default:
            throw new Error('Unknown action type')
    }
}

// The notification banner displays either a generic message about an outage in some functionality,
// or more details, if the component that has an outage is specifically registered reported for a given
// page. We always report details on outage in the following components:

const DEFAULT_REPORTED_COMPONENTS: StatusPageComponentName[] = [
    'transactions',
    'mobileApp',
    'webApp',
]

// Pages in the app can register other Status Page components using the useStatusPage custom hook.
/**
 * Custom React hook for registering reported Status Page components. Any React component
 * can call this hook with one more component names, and as long as it remains mounted
 * the outage of these components will be explicitly listed in the outage notification banner.
 * @param components A single component name or a list of component names (from config.statuspage)
 */
export function useStatusPage(components: StatusPageComponentName | StatusPageComponentName[]) {
    // Get a "hash" of components to only call useEffect if components change
    const componentsHash = Array.isArray(components) ? components.sort().join(':') : components
    const statusPage = React.useContext(StatusPageContext)

    React.useEffect(() => {
        const id = randomString(10)
        statusPage.registerReportedComponents(
            id,
            Array.isArray(components) ? components : [components],
        )
        return () => statusPage.unregisterReportedComponents(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentsHash, statusPage])
}

const randomString = (length?: number) => Math.random().toString(16).substr(2, length)
