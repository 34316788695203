import React from 'react'

/**
 * Allows to detect clicks outside of a given element or elements
 * Takes a single ref, or an array of refs, and a callback
 * The callback is called when a click is detected anywhere in the document
 * outside of the provided elements
 * @param ref Ref, or array of refs to the elements outside of which the clicks are detected
 * @param callback The function called when a click outside of element/elements was detected
 * @example
 * useOnClickOutside([popoverRef, triggerRef], () => setIsOpen(false))
 */
export function useOnClickOutside(
    ref: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
    callback?: (event: MouseEvent | TouchEvent) => void,
) {
    React.useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const refs = !Array.isArray(ref) ? [ref] : ref
            if (
                refs.some(
                    (currentRef) =>
                        !currentRef.current || currentRef.current.contains(event.target as Node),
                )
            ) {
                return
            }
            if (callback) {
                callback(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, callback])
}
