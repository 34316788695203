import Locale from '@product-web/locale/locale'

import type {ICountryConfiguration} from './types'

import {Terms} from '../features/terms/types'
import {Feature} from '../types'

const GB: ICountryConfiguration = {
    [Feature.Terms]: {
        [Terms.Documents]: {
            masterServiceAgreement: {
                [Locale.en_GB]: 'pleo-master-service-agreement-uk-pfs.pdf',
            },
        },
    },
    [Feature.Paywall]: {
        invoicesEnabled: true,
    },
    [Feature.Mileage]: {
        requirePurposeOfMileage: true,
        canUseFuelRates: true,
    },
    [Feature.PerDiem]: {
        requirePurposeOfPerDiem: true,
    },
}

export default GB
