import {type PropsWithChildren, useMemo} from 'react'

import type {Person} from '@pleo-io/deimos'
import {Avatar, type AvatarProps} from '@pleo-io/telescope'
import {Wallet} from '@pleo-io/telescope-icons'

import type {UserData} from '@product-web/user-types'

import deactivatedIcon from './images/deactivated.svg'

export const getName = (employee?: Partial<Person>) => {
    if (!employee) {
        return '?'
    }

    const initials = [(employee.firstName || '')[0], (employee.lastName || '')[0]]
        .join('')
        .toUpperCase()

    if (initials) {
        return `${employee?.firstName || ''} ${employee?.lastName || ''}`
    }

    return employee.email || '?'
}

const employeeStatusToAvatarState: Record<AvatarStatus, AvatarProps['state']> = {
    invited: 'placeholder',
    deactivated: 'disabled',
    active: 'default',
}

export type AvatarStatus = 'invited' | 'deactivated' | 'active'
export type AvatarWithStatusProps = AvatarProps & {status?: AvatarStatus}
export const AvatarWithStatus = ({
    status = 'active',
    children,
    ...props
}: PropsWithChildren<AvatarWithStatusProps>) => {
    const isDeactivated = status === 'deactivated'

    return (
        <Avatar state={employeeStatusToAvatarState[status]} {...props}>
            {isDeactivated ? (
                <Avatar.Badge icon={<img src={deactivatedIcon} alt="" />} />
            ) : (
                children
            )}
        </Avatar>
    )
}

type AvatarEmployeeProps = AvatarWithStatusProps &
    Partial<Pick<UserData, 'role'>> & {
        employee?: Partial<Person>
    }

export const AvatarEmployee = ({
    employee,
    role,
    status = 'active',
    children,
    ...props
}: PropsWithChildren<AvatarEmployeeProps>) => {
    const isBookkeeper = useMemo(() => role && role.startsWith('bookkeeper'), [role])

    const icon = isBookkeeper ? <Wallet /> : undefined

    return (
        <AvatarWithStatus
            status={status}
            name={getName(employee)}
            src={employee?.avatar?.url}
            icon={icon}
            {...props}
        >
            {children}
        </AvatarWithStatus>
    )
}
