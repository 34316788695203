import {Trans} from '@lingui/macro'

import {Link} from '@pleo-io/telescope'

import {useSupportChat} from '@product-web/freshchat/use-support-chat'

export const ContactSupportLink = ({label}: {label?: string}) => {
    const supportChat = useSupportChat()
    const shouldUseSupportChat = supportChat.isEntitled && supportChat.isConnected

    return (
        <Link
            inherit
            data-testid="support-link"
            href={shouldUseSupportChat ? undefined : 'mailto:support@pleo.io'}
            onClick={shouldUseSupportChat ? supportChat.show : undefined}
        >
            {label ?? <Trans>Contact support</Trans>}
        </Link>
    )
}
