export enum Export {
    CustomFormat = 'customFormat',
}

export type CustomFormatExampleAccountNumbers = {
    contraAccountNumber: number
    accountNumber: number
    vatAccountNumber: number
}

export type ExportConfig = {
    [Export.CustomFormat]: CustomFormatExampleAccountNumbers
}
