import styled, {css} from 'styled-components'

import {List, Text, tokens} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

export const CompactList = styled(List)`
    & > li {
        margin-bottom: 0;
    }
`

const circleStyles = css`
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-radius: ${tokens.circle};
    flex-shrink: 0;
    margin-top: ${tokens.spacing2};
`

const CheckCircleIconWrapper = styled.div`
    ${circleStyles}
    display: grid;
    place-content: center;
    background-color: ${tokens.colorBackgroundPositive};
    color: ${tokens.colorContentStaticInverse};
`
export const CheckCircleIcon = () => (
    <CheckCircleIconWrapper>
        <Check size={16} />
    </CheckCircleIconWrapper>
)

export const EmptyCircleIcon = styled.div`
    ${circleStyles}
    border: ${tokens.borderStaticLoud};
`

export const LinedThroughText = styled(Text)`
    text-decoration: line-through;
`

export const Boldon = styled(Text).attrs({weight: 'bold', as: 'span'})``

export const DaysBreakdownPanel = styled.div`
    width: auto;
    padding: ${tokens.spacing16} ${tokens.spacing10};
    border-radius: ${tokens.arc8};
    border: ${tokens.borderPrimary};
    background-color: ${tokens.shade100};
`
