import {t, Trans} from '@lingui/macro'
import {Form, Formik} from 'formik'

import {
    Accordion,
    Box,
    Button,
    FormikTextarea as Textarea,
    Inline,
    Skeleton,
    Stack,
    Text,
} from '@pleo-io/telescope'
import {MailSent} from '@pleo-io/telescope-pictograms'

import {useToaster} from '@product-web/toaster'
import yup from '@product-web/validation/yup'

import {bff} from '../../../bff-hooks'

interface Props {
    isLoading: boolean
}
export const GiveFeedback = ({isLoading}: Props) => {
    const {showToast} = useToaster()
    const {mutateAsync: submitFeedback} = bff.pricingCalculatorFeedback.submitFeedback.useMutation({
        onError: () => {
            showToast(t`Please try again, or contact support.`, {
                title: t`Something went wrong`,
                level: 'error',
            })
        },
        onSuccess: () => {
            showToast(t`Thank you for sharing your feedback`, {
                title: t`Message sent`,
                level: 'success',
            })
        },
    })
    const initialValues = {feedback: ''}
    const validationSchema = yup.object().shape({
        feedback: yup
            .string()
            .max(5000, t`5000 character limit reached. You can send feedback multiple times`)
            .required(t`Please write your feedback`),
    })

    return (
        <Skeleton borderRadius={8} loading={isLoading}>
            <Box mt={48}>
                <Accordion>
                    <Accordion.Item value="give-feedback">
                        <Accordion.Header
                            headingLevel="h3"
                            heading={t`Give feedback`}
                            Icon={MailSent}
                        />
                        <Accordion.Content>
                            <Text>
                                <Trans>
                                    Tell us what you like about the Pleo pricing calculator, and
                                    what could be improved
                                </Trans>
                            </Text>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={async ({feedback}) => {
                                    await submitFeedback({feedback})
                                }}
                                validationSchema={validationSchema}
                            >
                                {({dirty, handleSubmit, isSubmitting, resetForm, submitCount}) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Stack space={24} mt={24}>
                                            <Textarea
                                                aria-label={t`Feedback`}
                                                name="feedback"
                                                fixedHeight="180px"
                                                showCounter
                                                disabled={isSubmitting}
                                            />
                                            <Inline space={12} justifySelf="flex-end">
                                                {Boolean(dirty && submitCount && !isSubmitting) && (
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => resetForm()}
                                                    >
                                                        <Trans>Clear</Trans>
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                >
                                                    <Trans>Send feedback</Trans>
                                                </Button>
                                            </Inline>
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                        </Accordion.Content>
                    </Accordion.Item>
                </Accordion>
            </Box>
        </Skeleton>
    )
}
