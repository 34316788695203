import {useMatches} from 'react-router-dom'

import {invariant} from '@product-web/utils'

import type {AppRouteHandle} from './types'

type RouteMatch = ReturnType<typeof useMatches>[number]

type AppRouteMatch = RouteMatch & {handle: Required<AppRouteHandle>}

const hasMatchHandleKey =
    <Key extends keyof AppRouteHandle>(key: Key) =>
    (match: RouteMatch): match is AppRouteMatch => {
        return (match.handle as AppRouteHandle)?.[key] !== undefined
    }

// check that none of the route allowedRoles are less restrictive than the parent routes' allowedRoles
const checkAllowedRolesNesting = (matches: RouteMatch[]) => {
    const matchesWithParam = matches.filter(hasMatchHandleKey('allowedRoles'))
    const allowedRoles = matchesWithParam.map((match) => match.handle.allowedRoles)

    allowedRoles.forEach((allowed, index) => {
        const previousRoles = allowedRoles.slice(0, index)
        const forbiddenRoles = allowed.filter((role) =>
            previousRoles.some((previous) => !previous.includes(role)),
        )
        const pathname = matchesWithParam[index].pathname
        invariant(
            Number(forbiddenRoles.length) === 0,
            `Nested route "${pathname}" wants to give access to roles forbidden in parent routes: ${forbiddenRoles.join(
                ',',
            )}.`,
        )
    })
}

export const getLastHandleParam = <Key extends keyof AppRouteHandle>(
    matches: RouteMatch[],
    key: Key,
) => {
    const matchesWithParam = matches.filter(hasMatchHandleKey(key))
    if (key === 'allowedRoles') {
        checkAllowedRolesNesting(matches)
    }
    return matchesWithParam[matchesWithParam.length - 1]?.handle?.[key]
}

export const useLastHandleParam = <Key extends keyof AppRouteHandle>(key: Key) => {
    const matches = useMatches()
    return getLastHandleParam(matches, key)
}
