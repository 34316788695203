import {i18n} from '@lingui/core'
import {t} from '@lingui/macro'
import countries from 'i18n-iso-countries'
import * as R from 'ramda'

import type {CurrencyType} from '@pleo-io/deimos'

import {dayjs} from '@product-web/dates/dayjs'
import {SupportedLanguage} from '@product-web/i18n'
import {capitalize, sortByProp} from '@product-web/utils'

import Country from './country'
import Locale from './locale'

export const countryToLocaleMap: {[key in string]: Locale} = {
    DE: Locale.de_DE,
    AT: Locale.de_AT,
    DK: Locale.da_DK,
    ES: Locale.es_ES,
    GB: Locale.en_GB,
    IE: Locale.en_IE,
    SE: Locale.sv_SE,
    FR: Locale.fr_FR,
    FI: Locale.fi_FI,
    NL: Locale.nl_NL,
    PT: Locale.pt_PT,
    IT: Locale.it_IT,
    NO: Locale.nb_NO,
}

export const getLocaleByLanguage = (language: SupportedLanguage | null) => {
    const languageToLocaleMap: {[key in SupportedLanguage]: Locale} = {
        [SupportedLanguage.DA]: Locale.da_DK,
        [SupportedLanguage.DE]: Locale.de_DE,
        [SupportedLanguage.DE_AT]: Locale.de_AT,
        [SupportedLanguage.EN]: Locale.en_GB,
        [SupportedLanguage.ES]: Locale.es_ES,
        [SupportedLanguage.SV]: Locale.sv_SE,
        [SupportedLanguage.FR]: Locale.fr_FR,
        [SupportedLanguage.FR_BE]: Locale.fr_BE,
        [SupportedLanguage.FI]: Locale.fi_FI,
        [SupportedLanguage.NL]: Locale.nl_NL,
        [SupportedLanguage.NL_BE]: Locale.nl_BE,
        [SupportedLanguage.PT]: Locale.pt_PT,
        [SupportedLanguage.IT]: Locale.it_IT,
        [SupportedLanguage.NO]: Locale.nb_NO,
    }

    return language ? languageToLocaleMap[language] : undefined
}

export const getLanguageByLocale = (locale: Locale) => {
    const localeToLanguageMap: {[key in Locale]: SupportedLanguage} = {
        [Locale.da_DK]: SupportedLanguage.DA,
        [Locale.de_DE]: SupportedLanguage.DE,
        [Locale.de_AT]: SupportedLanguage.DE_AT,
        [Locale.en_GB]: SupportedLanguage.EN,
        [Locale.en_IE]: SupportedLanguage.EN,
        [Locale.es_ES]: SupportedLanguage.ES,
        [Locale.sv_SE]: SupportedLanguage.SV,
        [Locale.fr_FR]: SupportedLanguage.FR,
        [Locale.fr_BE]: SupportedLanguage.FR_BE,
        [Locale.fi_FI]: SupportedLanguage.FI,
        [Locale.nl_NL]: SupportedLanguage.NL,
        [Locale.nl_BE]: SupportedLanguage.NL_BE,
        [Locale.pt_PT]: SupportedLanguage.PT,
        [Locale.it_IT]: SupportedLanguage.IT,
        [Locale.nb_NO]: SupportedLanguage.NO,
        [Locale.nn_NO]: SupportedLanguage.NO,
    }

    return localeToLanguageMap[locale]
}

export const getCountryByLanguage = (language: SupportedLanguage) => {
    const languageToCountryMap: {[key in SupportedLanguage]: Country} = {
        [SupportedLanguage.DA]: Country.DK,
        [SupportedLanguage.DE]: Country.DE,
        [SupportedLanguage.DE_AT]: Country.AT,
        [SupportedLanguage.EN]: Country.GB,
        [SupportedLanguage.ES]: Country.ES,
        [SupportedLanguage.SV]: Country.SE,
        [SupportedLanguage.FR]: Country.FR,
        [SupportedLanguage.FR_BE]: Country.BE,
        [SupportedLanguage.FI]: Country.FI,
        [SupportedLanguage.NL]: Country.NL,
        [SupportedLanguage.NL_BE]: Country.BE,
        [SupportedLanguage.PT]: Country.PT,
        [SupportedLanguage.IT]: Country.IT,
        [SupportedLanguage.NO]: Country.NO,
    }

    return languageToCountryMap[language]
}

export enum CurrencyDisplays {
    Symbol = 'symbol',
    Code = 'code',
    Name = 'name',
}

/**
 * Helper function that will take a currency and pass it through i18n. It will then extract and return the specified
 * display.
 * @param currency The 3-letter ISO 4217 code of the currency that the amount will be formatted in (e.g. 'DKK')
 * @param currencyDisplay, The type of display you would like to extract
 * @returns Formatted string, display for the specified currency
 * @example
 * const currencySymbol = getCurrencyDisplay('DKK', 'symbol')
 */
export const getCurrencyDisplay = (
    currency: CurrencyType | string,
    currencyDisplay: CurrencyDisplays,
) => {
    const value = 0
    // eslint-disable-next-line string-to-lingui/forbid-i18n-calls
    return i18n
        .number(value, {
            style: 'currency',
            currency,
            currencyDisplay,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .split(`${value}`)
        .join('')
        .trim()
}

/**
 * Helper function that will take a currency code and return the localised symbol.
 * @param currency The 3-letter ISO 4217 code of the currency that the amount will be formatted in (e.g. 'DKK')
 * @returns Formatted string, symbol for the specified currency
 * @example
 * const currencySymbol = getCurrencySymbol('DKK')
 */
export const getCurrencySymbol = (currency: CurrencyType | string) => {
    const currencySymbol = getCurrencyDisplay(currency, CurrencyDisplays.Symbol)
    return currencySymbol || 'kr.'
}

export function renderCompanyRegNumber(country: string | undefined): string {
    let res = t`Company Reg. No.`
    switch (country) {
        case 'DK':
            res = 'CVR'
            break
        case 'GB':
            res = 'CRN'
            break
    }
    return res
}

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions) => {
    // eslint-disable-next-line string-to-lingui/forbid-i18n-calls
    return i18n.number(value, options)
}

export const getCountryList = () => {
    const [lang] = (i18n.locale ?? SupportedLanguage.EN).split('-')

    let i18nCountryNames = countries.getNames(lang)

    if (R.isEmpty(i18nCountryNames)) {
        i18nCountryNames = countries.getNames(SupportedLanguage.EN)
    }

    return Object.entries(i18nCountryNames)
        .map(([code, name]) => ({
            code,
            name,
        }))
        .sort(sortByProp('name'))
}

export const getCountryName = (code: string) => {
    const [lang] = i18n.locale.split('-')

    return countries.getName(code, lang) || countries.getName(code, SupportedLanguage.EN)
}

/**
 * @param language - The lang code whose name should be returned
 * @param displayLanguage - The lang code in which to display the provided lang. Defaults to the provided lang code
 * @returns The display name of the provided lang code
 */
export const getLanguageName = (language: string, displayLanguage?: string) => {
    const languageNames = new Intl.DisplayNames(displayLanguage || language, {
        type: 'language',
    })

    // Intl.DisplayNames returns a mix of capitalized and non-capitalized names
    // (e.g. "dansk" and 'Deutsch"). Capitalizing here to standardize them
    return capitalize(languageNames.of(language) || '')
}

/**
 * The help centre doesn't support locale variations (e.g. 'de-AT').
 * If the user's locale is for example 'de-AT', then the language for
 * that locale will be returned as 'de-AT' which does not work for the
 * help centre.
 * @param locale
 * @returns A locale that is not a 'variation' of a main locale (e.g. de-AT -> de-DE)
 */
export const getLocaleForHelpCentre = (locale: Locale) => {
    if (locale === 'de-AT') {
        return Locale.de_DE
    }
    if (locale === 'fr-BE') {
        return Locale.fr_FR
    }
    if (locale === 'nl-BE') {
        return Locale.nl_NL
    }
    return locale
}

export type HelpCentreArticle = number | `${number}-${string}`
export type HelpCentreFolder = number

/**
 * A function that generates a help centre article URL for older articles originally created in Intercom.
 * We're now using Freshdesk and a different URL structure.
 * This helper function will only work for old articles that are mapped to the Freshdesk format in a reverse proxy
 * @param article The Intercom numeric article id (e.g. 123456) or a
 * the 'article part' string (e.g. 412743-how-to-download-the-pleo-mobile-app)
 * that will be used to generate the URL. This is optional in which case
 * the URL generated will just point to the help centre in a particular language.
 * @param languageOverride An optional override to specify the language that the URL
 * will be generated with instead of using the current one.
 * @returns A full URL pointing to a help centre article.
 * @deprecated Use getHelpCentreArticleLink instead
 */
export const getHelpCentreArticleLinkIntercom = (
    article?: HelpCentreArticle,
    languageOverride?: string,
) => {
    const [language] = (i18n.locale ?? SupportedLanguage.EN).split('-')
    // eslint-disable-next-line string-to-lingui/text-restrictions
    return `https://help.pleo.io/${languageOverride || language}${
        typeof article === typeof undefined ? '' : `/articles/${article}`
    }`
}

/**
 * A function that generates a help centre article URL in the current
 * user's locale.
 * @param article The Freshdesk numeric article id (e.g. 123456) or a
 * the 'article part' string (e.g. 412743-how-to-download-the-pleo-mobile-app)
 * that will be used to generate the URL. This is optional in which case
 * the URL generated will just point to the help centre in a particular language.
 * @param languageOverride An optional override to specify the language that the URL
 * will be generated with instead of using the current one.
 * @returns A full URL pointing to a help centre article.
 */
export const getHelpCentreArticleLink = (
    article?: HelpCentreArticle,
    languageOverride?: string,
) => {
    const [language] = (i18n.locale ?? SupportedLanguage.EN).split('-')
    // eslint-disable-next-line string-to-lingui/text-restrictions
    return `https://help.pleo.io/${languageOverride || language}${
        typeof article === typeof undefined ? '' : `/support/solutions/articles/${article}`
    }`
}

/**
 * A function that generates a help centre folders URL in the current
 * user's locale.
 * @param folder The Freshdesk numeric collection id (e.g. 123456)
 * that will be used to generate the URL. This is optional in which case
 * the URL generated will just point to the help centre in a particular language.
 * @param languageOverride An optional override to specify the language that the URL
 * will be generated with instead of using the current one.
 * @returns A full URL pointing to the help centre, or a help centre folder.
 */
export const getHelpCentreFolderLink = (folder?: HelpCentreFolder, languageOverride?: string) => {
    const [language] = (i18n.locale ?? SupportedLanguage.EN).split('-')
    // eslint-disable-next-line string-to-lingui/text-restrictions
    return `https://help.pleo.io/${languageOverride || language}${
        typeof folder === typeof undefined ? '' : `/support/solutions/folders/${folder}`
    }`
}

export const isToday = (date: string | Date) => {
    const dayjsDate = dayjs(date)
    const TODAY = dayjs().clone().startOf('day')
    return dayjsDate.isSame(TODAY, 'd')
}
export const isYesterday = (date: string | Date) => {
    const dayjsDate = dayjs(date)
    const YESTERDAY = dayjs().clone().subtract(1, 'days').startOf('day')
    return dayjsDate.isSame(YESTERDAY, 'd')
}

/**
 * Formats the given date in DD MMM YYYY according to the user's locale. Returns 'Yesterday' or 'Today' if
 * dates are yesterday or today respectively, in the relevant locale.
 * @param {string | Date} date The date to format
 * @param {Intl.DateTimeFormatOptions} options The options for formatting the date
 * @returns Localised, formatted date in DD MMM YYYY. Returns 'Yesterday' or 'Today' if
 * dates are yesterday or today respectively, in the relevant locale.
 */
export const toCalendarFormat = (date: string | Date, options?: Intl.DateTimeFormatOptions) => {
    if (isToday(date)) {
        return t`Today`
    }

    if (isYesterday(date)) {
        return t`Yesterday`
    }

    return new Intl.DateTimeFormat(i18n.locale ?? SupportedLanguage.EN, {
        day: '2-digit',
        month: 'short',
        ...options,
    }).format(new Date(date))
}
