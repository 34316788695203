/**
 * Returns a full name of a person
 * @param person An object that contains firstName and lastName props (both optional)
 * @returns Full name of the person (firstName lastName), gracefully handling missing values
 * @example
 * getFullName({firstName: 'John', lastName: 'Doe'}) // John Doe
 * getFullName({firstName: 'John'}) // John
 */

export function getFullName(person?: {firstName?: string | null; lastName?: string | null}) {
    return `${person?.firstName ?? ''} ${person?.lastName ?? ''}`.trim()
}
