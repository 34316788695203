import {t} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import React from 'react'

import {
    __dummyTelescopeProviderTranslations,
    LoadingPage,
    TelescopeProvider as TelescopeProviderBase,
} from '@pleo-io/telescope'
import type {LocalisationContextType} from '@pleo-io/telescope/dist/utils/localisation'

const TelscopeTranslationsContext = React.createContext<{
    setTranslations: React.Dispatch<React.SetStateAction<LocalisationContextType>>
}>({
    setTranslations: () => {},
})

// We're keeping Telescope translations in React context and setting the translations on
// app init and on locale change.
// This component needs to be rendered "below" the I18nProvider.
// Once we are able to move I18nProvider above TelescopeProvider, we should be able to remove
// this component and define translations directly to the TelescopeProvider.
// https://linear.app/pleo/issue/WEB-1107/move-i18nprovider-above-telescopeprovider-in-product-web
export const TelescopeTranslationsLoader = ({children}: {children: React.ReactNode}) => {
    const {i18n} = useLingui()
    const {setTranslations} = React.useContext(TelscopeTranslationsContext)
    const [isLoaded, setIsLoaded] = React.useState(false)

    React.useEffect(() => {
        setIsLoaded(true)
    }, [])

    React.useEffect(() => {
        setTranslations({
            Callout: {
                CloseButton: t`Dismiss`,
            },
            FormControl: {
                Error: t`Error`,
            },
            Loading: {
                LoadingLabel: t`Loading`,
            },
            Modal: {
                CloseButton: t`Close`,
            },
            ModalSplit: {
                BackButton: t`Back`,
                StepButton: t`Step`,
            },
            statusLabels: {
                neutral: t`Information`,
                info: t`Information`,
                positive: t`Success`,
                warning: t`Warning`,
                negative: t`Error`,
                discover: t`New`,
            },
        })
    }, [i18n.locale, setTranslations])

    if (!isLoaded) {
        return <LoadingPage />
    }

    return <>{children}</>
}

export const TelescopeProvider = ({children}: {children: React.ReactNode}) => {
    const [translations, setTranslations] = React.useState<LocalisationContextType>(
        /* eslint-disable string-to-lingui/missing-lingui-transformation */
        // We are ok with these not being translated,
        // the correct translations will be set with TelescopeTranslationsLoader
        // before rendering any real content.
        __dummyTelescopeProviderTranslations,
        /* eslint-enable string-to-lingui/missing-lingui-transformation */
    )

    return (
        <TelscopeTranslationsContext.Provider value={{setTranslations}}>
            <TelescopeProviderBase translations={translations}>{children}</TelescopeProviderBase>
        </TelscopeTranslationsContext.Provider>
    )
}
