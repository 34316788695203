import {Navigate} from 'react-router'

import {LoadingPage} from '@pleo-io/telescope'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'

export const OnboardingIndex = () => {
    const {data, isLoading, isError} = bff.onboardingIndex.getOnboardingIndexInfo.useQuery()

    if (isLoading) {
        return <LoadingPage />
    }

    if (isError) {
        return <GenericError />
    }

    if (data.isPartnerOwner) {
        if (data.partnerSize) {
            return <Navigate to={{pathname: './onboarding-type'}} replace />
        }
        return <Navigate to={{pathname: './partner-size'}} replace />
    }

    return <Navigate to={{pathname: './start-onboarding'}} replace />
}
