import type {FC} from 'react'
import React from 'react'
import styled from 'styled-components'

import {Stack, Text, tokens} from '@pleo-io/telescope'

import cat from '@product-web/images/cats/yarncat.svg'

import {Back} from '../back'

interface Props {
    className?: string
    action?: JSX.Element
    hasDefaultImage?: boolean
    customImage?: JSX.Element
    hasInverseColourScheme?: boolean
    title: JSX.Element | string
    subtitle?: JSX.Element | string
    back?: () => void
}

const CatIllustration: FC<{}> = () => {
    return <Illustration alt="" src={cat} />
}

export const EmptyState: FC<Props> = ({
    hasDefaultImage,
    action,
    title,
    subtitle,
    back,
    customImage,
    hasInverseColourScheme,
    ...props
}) => {
    return (
        <Wrapper data-generic-ui="empty-state" {...props}>
            {hasDefaultImage ? <CatIllustration /> : undefined}
            {customImage && !hasDefaultImage ? customImage : undefined}
            <TextContainer>
                <Stack space={20} align="center">
                    <Text as="h2" variant="2xlarge-accent" data-testid="blank-state-title">
                        {title}
                    </Text>
                    {subtitle && (
                        <Text
                            as="p"
                            color={hasInverseColourScheme ? 'colorContentStaticInverse' : undefined}
                            weight={hasInverseColourScheme ? 'semibold' : undefined}
                            data-testid="blank-state-subtitle"
                        >
                            {subtitle}
                        </Text>
                    )}
                    {action
                        ? React.cloneElement(action, {
                              color: 'primary',
                          })
                        : null}
                </Stack>
                {back && <BackButton data-testid="blank-state-back" onClick={back} />}
            </TextContainer>
        </Wrapper>
    )
}

const TextContainer = styled.div`
    max-width: 300px;
`

const Illustration = styled.img`
    max-width: 240px;
    margin-bottom: ${tokens.spacing16};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
`

const BackButton = styled(Back)`
    color: ${tokens.colorContentInteractiveLink} !important;
`
