import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Box, ProgressBar, Stack, Text, tokens} from '@pleo-io/telescope'

import {RECURRING_SPEND_CARD_KEY, RecurringSpendCard} from './announcement-modal'

import {useMobileNavigationVisibility} from '../../../../../shared/navigation/navigation-context'
import {useSpendActivationGuide} from '../use-spend-activation-guide'
import useSpendActivationGuideProgress from '../use-spend-activation-guide-progress'

interface ProgressCardProps {
    isTablet: boolean
}

export const ProgressCard = ({isTablet}: ProgressCardProps) => {
    const navigate = useNavigate()

    const {isEnabled = false} = useSpendActivationGuide()
    const {
        isLoading: isProgressLoading,
        progress: {percent, allCompleted},
        isStarted,
        isSkipped,
    } = useSpendActivationGuideProgress({enabled: isEnabled})

    const {setIsMobileNavigationVisible} = useMobileNavigationVisibility()

    if (!isEnabled || isProgressLoading || isSkipped || allCompleted) {
        return null
    }

    if (localStorage.getItem(RECURRING_SPEND_CARD_KEY) === 'dismissed') {
        return null
    }

    if (!isStarted) {
        return <RecurringSpendCard />
    }

    const goToSetupGuide = () => {
        setIsMobileNavigationVisible(false)
        navigate('/recurring-vendors/setup-guide')
    }

    return (
        <Wrapper onClick={goToSetupGuide}>
            <StyledBox
                isTablet={isTablet}
                borderRadius={8}
                paddingTop={12}
                paddingX={16}
                paddingBottom={16}
            >
                <Stack space={8}>
                    <Text>
                        <Trans>Set up recurring spend</Trans>
                    </Text>

                    <StyledProgressBar
                        aria-label={t`progress bar showing percentage of recurring spend setup`}
                        variant="tall"
                        percent={percent}
                    />
                </Stack>
            </StyledBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    cursor: pointer;
    padding: ${tokens.spacing12} ${tokens.spacing16} ${tokens.spacing16};
    justify-self: stretch;
`

interface StyledBoxProps {
    isTablet: boolean
}

const StyledBox = styled(Box)<StyledBoxProps>`
    background-color: ${({isTablet}) =>
        isTablet ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
`

//  Pseudo-class usage should be replaced with the fillColor prop once it supports more colours
const StyledProgressBar = styled(ProgressBar)`
    &::before {
        background-color: ${tokens.colorBackgroundPositive};
    }
`
