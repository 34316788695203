import useSWR from 'swr'

import type {
    CompanyEntitlementsResponse,
    EntitlementResponse,
    FeatureType,
} from '@pleo-io/janus-ts-models'

import {request} from '@product-web/api'
import {RequestAuth} from '@product-web/api'
import config from '@product-web/config'
import {useUser} from '@product-web/user'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

export function useCompanyEntitlements(companyId?: string | null) {
    return useSWR<CompanyEntitlementsResponse, Error>(
        companyId ? `/rest/v1/entitlement/entitlements/${companyId}` : null,
        getDeimos,
        {refreshInterval: 0},
    )
}

const getEntitlementEndpoint = (companyId: string, featureType: FeatureType) =>
    `${baseUrl}/rest/v1/entitlement/entitlements/${companyId}/feature/${featureType}`

export async function checkExternalBookkeepersEntitlement(
    companyId: string,
): Promise<EntitlementResponse> {
    return request(getEntitlementEndpoint(companyId, 'EXTERNAL_BOOKKEEPERS'), {
        auth: RequestAuth.USER,
        method: 'GET',
    })
}

export async function checkInsuranceEntitlement(companyId: string): Promise<EntitlementResponse> {
    return request(getEntitlementEndpoint(companyId, 'INSURANCE'), {
        auth: RequestAuth.USER,
        method: 'GET',
    })
}

export function useUsersCompanyHasInsuranceEntitlement() {
    const user = useUser()

    return useSWR<EntitlementResponse, Error>(user?.companyId, checkInsuranceEntitlement, {
        revalidateOnFocus: false,
    })
}
