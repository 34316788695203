import {t} from '@lingui/macro'
import {useMatch} from 'react-router-dom'

import {useAcademyContext} from '../../components/academy-context/academy-context'
import type {OverviewStep} from '../../components/side-overview'

type Page = 'reimbursements-and-mileage' | 'invoices' | 'vendor-cards' | 'multi-entities'

export const useSideOverview = () => {
    const {country, hideInvoices} = useAcademyContext()
    const matchPath = useMatch('/partner/academy/the-pleo-product/:currentPage')
    const currentPage = matchPath?.params.currentPage || ''

    const overviewSteps: OverviewStep[] = [
        {
            isActive: currentPage === 'reimbursements-and-mileage',
            title: t`Reimbursements & Mileage (2 mins)`,
        },
        {isActive: currentPage === 'invoices', title: t`Invoices (2 mins)`},
        {isActive: currentPage === 'vendor-cards', title: t`Vendor cards (2 mins)`},
        {isActive: currentPage === 'multi-entities', title: t`Multi-entities (2 mins)`},
    ].filter((_, index) => (index === 1 && hideInvoices ? false : true))

    const isRecognisedPage = (page: string): page is Page => {
        switch (page) {
            case 'reimbursements-and-mileage':
            case 'invoices':
            case 'vendor-cards':
            case 'multi-entities':
                return true
            default:
                return false
        }
    }

    if (!isRecognisedPage(currentPage)) {
        return {overviewSteps, usefulMaterials: []}
    }

    const getReimbursementsUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://blog.pleo.io/de/pocket-per-diem'
            default:
                // Default is EN
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/613bec5e-4b65-4fc6-a645-b7c0f66f0487'
        }
    }

    const getInvoicesUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/774e2a20-5455-44db-bb07-7824b66bfe96'
            default:
                // Default is EN
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/492f0c44-68bb-4272-ade5-4eae69c6effb'
        }
    }

    const getMultiEntitiesUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/cf66a505-25b0-4ee8-8832-b96bd3c56513'
            case 'DK':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/x4stBu6d_7KE-2Uq#/content/2683c095-aae5-4612-abf7-1420f922da06'
            case 'SE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/zfIDiA-z2hCp8Nyr#/content/cba48fe8-df92-4fc1-a6cb-f02a3e8152bb'
            default:
                // Default is EN
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/9475e3b6-74d1-482f-87fa-27b171d6299b'
        }
    }

    const getUsefulMaterials = {
        ['reimbursements-and-mileage']: [
            {
                title: t`How do reimbursements work?`,
                url: getReimbursementsUrl(),
            },
            {title: t`Help centre: How reimbursements work?`, articleId: 103000254670},
        ],
        ['invoices']: [
            {
                title: t`How do invoices work?`,
                url: getInvoicesUrl(),
            },
            {title: t`Help centre: Invoices`, articleId: 103000285539},
        ],
        ['vendor-cards']: [{title: t`Help centre: Vendor cards`, articleId: 103000254446}],
        ['multi-entities']: [
            {
                title: t`Multi-entities`,
                url: getMultiEntitiesUrl(),
            },
            {title: t`Help centre: Multi-entity management`, articleId: 103000254598},
        ],
    }

    return {overviewSteps, usefulMaterials: getUsefulMaterials[currentPage]}
}
