import {useLingui} from '@lingui/react'

import {useSupportedLanguageList} from './i18n-storage'
import {stringToSupportedLanguage} from './string-to-supported-language'
import {SupportedLanguage} from './supported-language'

export const useAppLanguage = () => {
    const {i18n} = useLingui()
    const supportedLanguageList = useSupportedLanguageList()

    return stringToSupportedLanguage(i18n.locale, supportedLanguageList) ?? SupportedLanguage.EN
}
