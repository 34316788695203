import {bff} from '../bff-hooks'

export function useCompanySubscription() {
    const {data, isLoading, error} = bff.paywall.subscriptions.getCompanySubscription.useQuery()

    return {
        data,
        isLoading,
        error,
    }
}
