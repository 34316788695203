// @pleo/companyId saved in the local storage determines which company the user
// is currently "working for". This applies only to bookkeepers (independent or via a partner)

export const CLIENT_SESSION_STORAGE_KEY = '@pleo/companyId'

export function openClientSession(companyId: string) {
    localStorage.setItem(CLIENT_SESSION_STORAGE_KEY, companyId)
}

export function clearClientSession() {
    localStorage.removeItem(CLIENT_SESSION_STORAGE_KEY)
}

export function getClientSessionId() {
    return localStorage.getItem(CLIENT_SESSION_STORAGE_KEY)
}
