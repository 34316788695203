const domain = '.pleo.io'

type Cookie = {[key: string]: any}

function set(key: string, value: any, expiresIn?: number) {
    let expires = ''
    if (expiresIn) {
        const date = new Date()
        date.setTime(Date.now() + expiresIn)
        expires = ';expires=' + date.toUTCString()
    }
    window.document.cookie = `${key}=${value}${expires};domain=${domain};path=/;secure`
}

function get(key: string) {
    const cookies: Cookie = toJson()
    return cookies[key] || null
}

function remove(key: string) {
    set(key, '', -1)
}

function toJson() {
    const cookies: Cookie = {}
    window.document.cookie.split(';').forEach((item) => {
        const items = item.split('=')
        if (items.length === 2) {
            cookies[items[0].trim()] = items[1]
        }
    })
    return cookies
}

export default {get, set, remove, toJson}
