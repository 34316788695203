import * as Sentry from '@sentry/react'

import {isRequestError} from '@product-web/api-error'

/**
 * Report handled exception to Sentry.
 * @param originalError Error to report
 * @param errorMessage Custom error message
 * @param context Additional info relevant to the reported error
 */
export const reportError = (
    originalError: unknown,
    errorMessage?: string | null,
    context?: Record<string, any>,
) => {
    if (isRequestError(originalError)) {
        // Ignore request errors as:
        // - we have enough information in Datadog to debug and fix these issues
        // - individual network errors is Sentry are not that actionable
        return
    }

    Sentry.withScope((scope) => {
        scope.setTag('manualNotify', 'true')
        if (context || errorMessage) {
            Sentry.setContext('additionalContext', {...context, errorMessage})
        }
        Sentry.captureException(originalError)
    })
}
