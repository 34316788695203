import type {ReactNode} from 'react'
import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {loadScript} from '@product-web/web-platform/load-script'

export const WistiaVideo = ({
    className,
    video,
    onClick,
    children,
}: {
    children: ReactNode
    className?: string
    onClick?: () => void
    video: string
}) => {
    const [isMounted, setIsMounted] = React.useState(false)
    React.useEffect(() => {
        const initScripts = async () => {
            loadScript({
                src: `https://fast.wistia.com/embed/medias/${video}.jsonp`,
                asynchronous: true,
            })
            loadScript({src: `https://fast.wistia.com/assets/external/E-v1.js`, asynchronous: true})
        }
        initScripts()
        setIsMounted(true)
    }, [])

    if (!isMounted) {
        return null
    }

    return (
        <Wrapper
            className={className}
            $video={video}
            data-generic-ui="wistia-video"
            onClick={() => onClick && onClick()}
        >
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.span.attrs<{$video: string}>((props) => ({
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    className: `${props?.className ?? ''} wistia_embed wistia_async_${
        props.$video
    } popover=true popoverAnimateThumbnail=true popoverContent=link popoverOverlayColor=${
        tokens.shade900
    }
    )}`,
}))<{$video: string}>`
    display: inline;
    position: relative;
    cursor: pointer;
    transition: ${tokens.smoothIn};
    transition-property: all;
    min-width: 100px;
    color: ${tokens.pink800};
    width: 100%;
    text-align: left;
`
