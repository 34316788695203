import type React from 'react'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

import {Text, tokens, Tooltip} from '@pleo-io/telescope'

interface TruncateTextProps {
    text: string
    textProps?: React.ComponentProps<typeof Text>
    tooltip?: boolean
}

function TruncateText({text, textProps, tooltip = false}: TruncateTextProps) {
    const [isLongText, setIsLongText] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const clientWidth = ref.current?.clientWidth ?? 0
        const scrollWidth = ref.current?.scrollWidth ?? 0
        if (scrollWidth > clientWidth) {
            setIsLongText(true)
        }
    }, [])

    if (isLongText && tooltip) {
        return (
            <Tooltip content={text}>
                <Content {...textProps}>{text}</Content>
            </Tooltip>
        )
    }

    return (
        <Content ref={ref} {...textProps}>
            {text}
        </Content>
    )
}

const Content = styled(Text).attrs({truncate: true})`
    width: 100%;
    line-height: ${tokens.lineHeight3};
`

export default TruncateText
