import {entitlementBff} from './bff-hooks'

export const useCompanyEntitlementsLimits = () => {
    const {data: limits, isLoading} =
        entitlementBff.companyEntitlements.getEntitlementsLimits.useQuery()

    return {
        limits,
        isLoading,
    }
}
