import {Trans} from '@lingui/macro'
import React from 'react'
import styled, {css} from 'styled-components'

import {Button, Inline, Loading, Stack, Text, tokens} from '@pleo-io/telescope'

import {dayjs} from '@product-web/dates/dayjs'
import {toCalendarFormat} from '@product-web/locale/helpers'
import {getBreakpoint, pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {EmptyState} from '@product-web/telescope-lab/empty-state'
import type {RenderGroup, RenderItem} from '@product-web/telescope-lab/list/list'
import {List as ListBase, ListItem, useList} from '@product-web/telescope-lab/list/list'

import brokenEggIllustration from './broken-egg.svg'
import {BudgetExpense} from './budget-expense'

import type {BudgetExpensesList} from '../index.bff'
import type {BudgetExpenseData} from '../lib/types'

type Props = {
    onScroll: () => void
    fetching: boolean
    total: number
    itemCount: number
    nextPage: () => void
    budgetExpenses: Array<BudgetExpenseData>
    errorFetchingBudgetExpenses: boolean
    refetchBudgetExpenses: () => void
}

const BudgetExpenses = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            onScroll,
            fetching,
            total,
            itemCount,
            nextPage,
            budgetExpenses,
            errorFetchingBudgetExpenses,
            refetchBudgetExpenses,
        },
        ref,
    ) => {
        const groupedBudgetExpenses = getBudgetExpensesGroupedByDay(budgetExpenses)

        const {...listProps} = useList<BudgetExpenseData>({
            dataFlat: budgetExpenses,
            dataGrouped: groupedBudgetExpenses,
            initialActiveIndex: null,
        })

        const renderBudgetExpensesGroup: RenderGroup<BudgetExpenseData> = ({
            group,
            groupProps,
            listItemProps,
            children,
        }) => {
            return (
                <BudgetExpensesGroup {...groupProps}>
                    <DateHeader {...listItemProps}>{group.title}</DateHeader>
                    {children}
                </BudgetExpensesGroup>
            )
        }

        const renderBudgetExpenseItem: RenderItem<BudgetExpenseData> = ({item, listItemProps}) => {
            const selected = listItemProps['aria-selected']
            const props = {
                id: item.id,
                active: selected,
                expense: item,
            }

            return (
                <StyledListItem {...listItemProps}>
                    <Inline space={6} css={{position: 'relative'}}>
                        <BudgetExpense key={item.id} budgetExpense={item} {...props} />
                    </Inline>
                </StyledListItem>
            )
        }

        return (
            <Wrapper ref={ref} onScroll={onScroll} $split={false}>
                {errorFetchingBudgetExpenses ? (
                    <BudgetExpensesListError reload={refetchBudgetExpenses} />
                ) : (
                    <BudgetExpensesWrapper>
                        {
                            <List<BudgetExpenseData>
                                aria-labelledby="label"
                                renderGroup={renderBudgetExpensesGroup}
                                renderItem={renderBudgetExpenseItem}
                                {...listProps}
                            />
                        }
                        {!fetching && !itemCount && (
                            <BlankStateWrapper>
                                <EmptyState
                                    title={
                                        <Trans>
                                            Expenses will show here as soon as they happen.
                                        </Trans>
                                    }
                                />
                            </BlankStateWrapper>
                        )}
                        {fetching && itemCount > 0 && (
                            <Loading height={100} mb={tokens.spacing60} />
                        )}
                        {itemCount < total && (
                            <div>
                                <Button variant="primary" onClick={nextPage}>
                                    <Trans>More expenses</Trans>
                                </Button>
                            </div>
                        )}
                    </BudgetExpensesWrapper>
                )}
            </Wrapper>
        )
    },
)

export const getBudgetExpensesGroupedByDay = (expenses: BudgetExpensesList[] = []) => {
    const expensesGrouped = expenses.reduce(
        (
            grouped: {
                [index: string]: BudgetExpenseData[]
            },
            item: BudgetExpensesList,
        ) => {
            const time = item.performedAt
            const day = dayjs(time).format('LL')
            grouped[day] = grouped[day] || []
            grouped[day].push(item as BudgetExpenseData)

            return grouped
        },
        {},
    )

    return Object.keys(expensesGrouped).map((key) => {
        const groupDate = expensesGrouped[key][0].performedAt
        const performedInCurrentYear = dayjs(groupDate).isSame(dayjs(), 'year')

        const title = toCalendarFormat(
            groupDate ?? '',
            !performedInCurrentYear ? {year: 'numeric'} : undefined,
        )

        return {
            id: key,
            title,
            children: expensesGrouped[key],
        }
    })
}

const BudgetExpensesListError = ({reload}: {reload: () => void}) => (
    <Stack
        space={8}
        justifyContent="center"
        justifyItems="center"
        css={{margin: 'auto', width: 340}}
    >
        <Illustration src={brokenEggIllustration} />
        <Text as="p" variant="xlarge-accent" color="shade800" weight="medium">
            <Trans>Something went wrong</Trans>
        </Text>
        <Text as="p" color="shade600" align="center">
            <Trans>
                Seems we're experiencing some trouble loading past expenses, try to reload this page
            </Trans>
        </Text>
        <Button css={{marginTop: tokens.spacing8}} variant="secondary" onClick={reload}>
            <Trans>Reload</Trans>
        </Button>
    </Stack>
)

const StyledListItem = styled(ListItem)`
    &:hover {
        cursor: auto;
    }
`

const Illustration = styled.img`
    max-width: 240px;
    margin-bottom: ${tokens.spacing16};
`

const hideWrapper = css`
    max-width: 100%;
    flex: 1 0 100%;
    display: none;
`

const Wrapper = styled.div<{$split: boolean}>`
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: auto;
    box-sizing: border-box;
    border-right: ${tokens.borderLightest};
    transition: all ${tokens.gradual} cubic-bezier(0.165, 0.84, 0.44, 1);

    ${({$split}) =>
        $split &&
        css`
            max-width: 50%;
            flex: 1 0 50%;
            border-color: ${tokens.shade300};

            @media (max-width: ${getBreakpoint('tabletMedUp')}) {
                ${hideWrapper}
            }

            ${containerQuery({maxWidth: pxBreakpoints.tabletUp}, hideWrapper)}
        `}
`

const List = styled(ListBase)`
    width: 100%;
` as typeof ListBase

const BudgetExpensesGroup = styled.ul`
    width: 100%;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: ${tokens.spacing24};
`

const DateHeader = styled.li`
    margin-bottom: ${tokens.spacing12};
    margin-left: ${tokens.spacing24};
    font-size: 18px;
    color: ${tokens.shade600};
    width: 100%;
    text-align: left;
`

const BlankStateWrapper = styled(Inline)`
    margin-top: auto;
    margin-bottom: auto;
    padding: ${tokens.spacing60} 0;
`

const BudgetExpensesWrapper = styled.div`
    width: 100%;
    padding: 0 6%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    box-sizing: border-box;
    align-items: center;
    height: 90%;
`

export default React.memo(BudgetExpenses)

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
BudgetExpenses.displayName = 'BudgetExpenses'
