import {createContext, useContext} from 'react'

export type ModalFlowOrigin = 'onboarding' | 'people'

export interface AddMemberModalContextProps {
    modalFlowOrigin?: ModalFlowOrigin
}

export const AddMemberModalContext = createContext<AddMemberModalContextProps>({
    modalFlowOrigin: 'people',
})

export const useAddMemberModalContext = () => useContext(AddMemberModalContext)
