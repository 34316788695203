import * as React from 'react'
import {Route} from 'react-router-dom'

const PartnerInvite = React.lazy(async () => import('./screens/partner-invite'))
const PartnerInviteAccept = React.lazy(async () => import('./screens/accept-invite'))
const PartnerInviteRegister = React.lazy(async () => import('./screens/register-user'))
const PartnerInviteRegisterSteps = React.lazy(
    async () => import('./screens/register-user-steps/register-user-steps'),
)

export const routes = (
    <>
        <Route index element={<PartnerInvite />} />
        <Route path="accept" element={<PartnerInviteAccept />} />
        <Route path="register" element={<PartnerInviteRegister />} />
        <Route path="register/:step" element={<PartnerInviteRegisterSteps />} />
    </>
)
