import {t} from '@lingui/macro'
import {useCallback, useState} from 'react'

import {callApi, RequestAuth} from '@product-web/api'
import type {APIPartnerInfo} from '@product-web/api-deimos/partner'
import {getRequestErrorPleoMessage, isRequestError} from '@product-web/api-error'
import {useToaster} from '@product-web/toaster'
import {useDebouncePromise} from '@product-web/use-debounce-promise'
import {email as emailValidate} from '@product-web/validation'

const NO_PARTNER_FOUND_ERROR_MESSAGE = 'not_a_partner_employee'
const BAD_REQUEST_ERROR_MESSAGE = 'bad_request'
const DEBOUNCE_DELAY_IN_MILLIS = 300

async function getPartnerInfoFromEmail(companyId: string, email: string): Promise<APIPartnerInfo> {
    return callApi(`partner-portal/rpc/partners/info`, {
        auth: RequestAuth.USER,
        method: 'GET',
        query: {email, companyId},
    })
}

const getPartnerInfo = async (email: string, companyId: string): Promise<APIPartnerInfo | null> => {
    try {
        return await getPartnerInfoFromEmail(companyId, email)
    } catch (e) {
        if (isRequestError(e)) {
            const message = getRequestErrorPleoMessage(e)
            if (
                !!message &&
                [NO_PARTNER_FOUND_ERROR_MESSAGE, BAD_REQUEST_ERROR_MESSAGE].includes(message)
            ) {
                return null
            }
        }

        throw e
    }
}

export const usePartnerInfoForEmail = (companyId: string) => {
    const [isLoading, setIsLoading] = useState(false)
    const [partnerInfo, setPartnerInfo] = useState<APIPartnerInfo | null>(null)
    const {showToast} = useToaster()

    const getPartnerInfoDebounced = useDebouncePromise(getPartnerInfo, DEBOUNCE_DELAY_IN_MILLIS)

    const setEmail = useCallback(
        async (email: string) => {
            const validation = emailValidate(email)
            if (validation === true && email) {
                try {
                    setIsLoading(true)
                    const result = await getPartnerInfoDebounced(email, companyId)
                    setPartnerInfo(result)
                } catch (error) {
                    // don't surface backend validation errors
                    showToast(t`An error occurred while checking email. Please try again later.`, {
                        level: 'error',
                    })
                    setPartnerInfo(null)
                } finally {
                    setIsLoading(false)
                }
            } else {
                setPartnerInfo(null)
            }
        },
        [getPartnerInfoDebounced, companyId, showToast],
    )

    return {
        isLoading,
        partnerInfo,
        setEmail,
    }
}
