export interface TrustBrowserNavigationState {
    addAccount: boolean
    redirectTo?: string
    email?: string | null
    companyId?: string | null
}

export function useEnforceTrustedSessionEntitled() {
    return true
}
