import {t} from '@lingui/macro'
import type {FormikErrors} from 'formik'
import * as R from 'ramda'
import {ValidationError} from 'yup'

export interface Validation {
    name: string
    value: any
    validate: Function
    message: string
}
export interface DateRange {
    minDate: Date
    maxDate: Date
}

export const validation = (
    name: string,
    value: any,
    validate: Function,
    message: string,
): Validation => {
    return {
        name,
        value,
        validate,
        message,
    }
}

export const validationsToFormErrors = (validations: Validation[] = []): FormikErrors<any> => {
    const result = validations.reduce((acc, element) => {
        const yupValidation = element.validate(element.value)
        const isError = yupValidation instanceof ValidationError

        if (isError) {
            return {
                ...acc,
                [element.name]:
                    element.message ||
                    yupValidation.message ||
                    t`No validation for field ${element.name}`,
            }
        }

        return acc
    }, {})

    return result
}

export const fieldArrayValidation = (
    name: string,
    values: {[name: string]: any},
    validator: Function,
) => {
    const validations = (values[name] || []).map(validator)

    if (validations.every((v: any) => R.isEmpty(v))) {
        return {}
    }

    return {
        [name]: validations,
    }
}

export const isValidDateFormat = (date: string | null) => {
    // validate the correct formatting
    const regex = /^\d{4}-\d{2}-\d{2}$/
    if (!date || date.match(regex) === null) {
        return false
    }

    // validate its an actual date
    const newDate = new Date(date)
    const timestamp = newDate.getTime()
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false
    }

    return newDate.toISOString().startsWith(date)
}

export const isInDateRange = (
    newDate: string | null,
    dateRange: DateRange,
    edge?: 'min' | 'max',
) => {
    // validate the correct formatting
    if (!isValidDateFormat(newDate) || newDate === null) {
        return false
    }

    const dateToCheck = new Date(newDate)
    const dateTooEarly = dateToCheck < dateRange.minDate && edge === 'min'
    const dateTooLate = dateToCheck > dateRange.maxDate && edge === 'max'
    const dateOutOfRange = !edge && (dateTooEarly || dateTooLate)

    if (dateTooEarly || dateTooLate || dateOutOfRange) {
        return false
    }

    return true
}

export function luhnCheck(value: string) {
    let sum = 0
    let shouldDouble = false

    for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i), 10)

        if (shouldDouble) {
            if ((digit *= 2) > 9) {
                digit -= 9
            }
        }

        sum += digit
        shouldDouble = !shouldDouble
    }

    return sum % 10 === 0
}
