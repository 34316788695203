import {createContext, useContext, useEffect, useState} from 'react'

import {bff} from '../../../../bff-hooks'

interface AcademyContext {
    country: string
    hideInvoices: boolean
}

const AcademyContext = createContext<AcademyContext | undefined>(undefined)

export const useAcademyContext = () => {
    const academyContext = useContext(AcademyContext)
    if (!academyContext) {
        throw new Error('useAcademyContext must be used inside AcademyContextProvider')
    }
    return academyContext
}

export const AcademyContextProvider = ({children}: {children: React.ReactNode}) => {
    const [country, setCountry] = useState('')
    const [hideInvoices, setHideInvoices] = useState(false)
    const {data} = bff.academyContext.getInfo.useQuery()
    useEffect(() => {
        if (data) {
            setCountry(data.country)
            setHideInvoices(data.hideInvoices)
        }
    }, [data])

    return (
        <AcademyContext.Provider value={{country, hideInvoices}}>
            {children}
        </AcademyContext.Provider>
    )
}
