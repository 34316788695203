enum Country {
    // SEPA countries
    // (please, keep it sorted by the country name, not by country code)
    AT = 'AT', // Austria
    BE = 'BE', // Belgium
    BG = 'BG', // Bulgaria
    HR = 'HR', // Croatia
    CY = 'CY', // Republic of Cyprus
    CZ = 'CZ', // Czech Republic
    DK = 'DK', // Denmark
    EE = 'EE', // Estonia
    FI = 'FI', // Finland
    FR = 'FR', // France
    DE = 'DE', // Germany
    GR = 'GR', // Greece
    HU = 'HU', // Hungary
    IE = 'IE', // Ireland
    IT = 'IT', // Italy
    LV = 'LV', // Latvia
    LT = 'LT', // Lithuania
    LU = 'LU', // Luxembourg
    MT = 'MT', // Malta
    NL = 'NL', // Netherlands
    PL = 'PL', // Poland
    PT = 'PT', // Portugal
    RO = 'RO', // Romania
    SK = 'SK', // Slovakia
    SI = 'SI', // Slovenia
    ES = 'ES', // Spain

    SE = 'SE', // Sweden
    NO = 'NO', // Norway
    GB = 'GB', // United Kingdom
    US = 'US', // United States of America
}

export const isCountry = (maybeCountry: string): maybeCountry is Country => maybeCountry in Country

export default Country
