/**
 * Capitalizes the first letter of each word in a sentence.
 *
 * @param sentence - The sentence to capitalize.
 *
 * @returns The capitalized sentence.
 */
export function capitalize(sentence: string): string {
    // Split the sentence into words
    const words = sentence.split(' ')

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
        const firstChar = word.charAt(0)
        const remainingChars = word.substring(1)

        return firstChar.toUpperCase() + remainingChars
    })

    // Join the capitalized words back into a sentence
    return capitalizedWords.join(' ')
}

/**
 * Checks if a given value is present in a comma-separated string.
 *
 * @template T - The type of the value to check. Must extend string.
 *
 * @param {Object} params - The parameters for the function.
 * @param {T} params.value - The value to check for.
 * @param {string} params.commaSeparatedString - The string to check in.
 *
 * @returns {boolean} - Returns true if the value is present in the string, false otherwise.
 */
export function isValuePresentInCommaSeparatedString<T extends string>({
    value,
    commaSeparatedString,
}: {
    value: T
    commaSeparatedString: string | undefined
}): boolean {
    // Parse the comma-separated string value to an array of strings
    const allValuesArray = (commaSeparatedString ?? '').replace(/\s+/g, '').split(',')

    // Check if the target element is contained in the active elements array
    const isValuePresent = allValuesArray.includes(value)

    return isValuePresent
}

/**
 * Converts a string to a number. Returns undefined if the string is not a number.
 *
 * @param value string
 * @returns number | undefined
 */
export const convertStringToNumber = (value: string) =>
    isNaN(Number(value)) ? undefined : Number(value)
