import {Route} from 'react-router-dom'

import {EmployeeExperience} from './employee-experience'
import {Introduction} from './introduction'
import {IntroductionToPleo} from './introduction-to-pleo'
import {PleoForYourClients} from './pleo-for-your-clients'

export const routes = (
    <Route>
        <Route index element={<IntroductionToPleo />} />
        <Route path="introduction" element={<Introduction />} />
        <Route path="employee-experience" element={<EmployeeExperience />} />
        <Route path="pleo-for-your-clients" element={<PleoForYourClients />} />
    </Route>
)
