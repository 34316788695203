import type {CompanyAdoptionGuideResponse} from '@pleo-io/auxo-ts-models'

export enum AdoptionGuides {
    ExportEssentials = 'EXPORT_ESSENTIALS',
    Automation = 'AUTOMATION_GUIDE',
}

export const getGuideToShow = (
    guides: CompanyAdoptionGuideResponse[],
): CompanyAdoptionGuideResponse | undefined => {
    return guides.find(({adoptionGuide}) => adoptionGuide === AdoptionGuides.ExportEssentials)
}
