import {Trans} from '@lingui/macro'
import type {Dispatch, SetStateAction} from 'react'
import styled from 'styled-components'

import {Box, Button, Inline, Text, tokens} from '@pleo-io/telescope'
import {ThumbDown, ThumbUp} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'

import {Helpful} from './helpful'

import {AdoptionAccordionView} from '../accordion/adoption-accordion'

const FeedbackContainer = styled(Box)`
    width: 100%;
    margin-top: ${tokens.spacing32};
    margin-bottom: ${tokens.spacing16};
`

const ThumbButton = styled(Button)`
    border-color: ${tokens.shade400};
    width: 175px;
`

type FeedbackProps = {
    setFeedbackStatus: (arg: FeedbackState) => void
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
    feedbackStatus: string
    guideName: string
}

export enum FeedbackState {
    Yes = 'yes',
    No = 'no',
    None = 'unanswered',
}

export const Feedback = ({
    setFeedbackStatus,
    setView,
    feedbackStatus,
    guideName,
}: FeedbackProps) => {
    if (feedbackStatus === FeedbackState.Yes) {
        return <Helpful setFeedbackStatus={setFeedbackStatus} guideName={guideName} />
    }

    return (
        <FeedbackContainer>
            <Box marginBottom={16}>
                <Text align="left" variant="large-accent">
                    <Trans>Was this helpful?</Trans>
                </Text>
            </Box>
            <Box>
                <Inline justifyContent="space-between" space={8}>
                    <ThumbButton
                        data-testid="adoption-centre-helpful-button"
                        variant="secondary"
                        onClick={() => {
                            setFeedbackStatus(FeedbackState.Yes)
                            tracking.adoptionEssentialsFeedbackSubmitted({
                                helpful: 'yes',
                                guide: guideName,
                            })
                        }}
                    >
                        <ThumbUp marginRight={4} />
                        <Text variant="medium-default">
                            <Trans>Yes</Trans>
                        </Text>
                    </ThumbButton>
                    <ThumbButton
                        data-testid="adoption-centre-not-helpful-button"
                        variant="secondary"
                        onClick={() => {
                            tracking.adoptionEssentialsFeedbackSubmitted({
                                helpful: 'no',
                                guide: guideName,
                            })
                            setFeedbackStatus(FeedbackState.No)
                            setView(AdoptionAccordionView.UNHELPFUL_FEEDBACK)
                        }}
                    >
                        <ThumbDown marginRight={4} />
                        <Text variant="medium-default">
                            <Trans>No</Trans>
                        </Text>
                    </ThumbButton>
                </Inline>
            </Box>
        </FeedbackContainer>
    )
}
