import {t} from '@lingui/macro'

export const getDemoBudgetMocks = () => {
    return {
        budget: {
            id: '1',
            name: t`Q4 Marketing budget`,
            amount: 25000000,
            sumPending: 500000,
            sumSettled: 9500000,
            remainingAmount: 15000000,
            percentageSpent: 40,
            sumSpent: 10000000,
            isWithinBudget: true,
            currency: 'EUR',
            startDate: '2024-10-01T00:00:00Z',
            endDate: '2024-12-31T23:59:59.999Z',
            isUpcoming: false,
            isClosed: false,
            noEndDate: false,
            reviewerUserIds: [],
        },
        tags: [
            {
                label: t`Performance Marketing`,
                value: '1',
                isArchived: false,
                tagRule: {},
                companyName: '',
            },
            {
                label: t`Digital Ads`,
                value: '2',
                isArchived: false,
                tagRule: {},
                companyName: '',
            },
            {
                label: t`Events`,
                value: '3',
                isArchived: false,
                tagRule: {},
                companyName: '',
            },
        ],
    }
}
