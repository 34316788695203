import styled from 'styled-components'

import {Drawer} from '@pleo-io/telescope'

import {useCompanyGuides} from '@product-web/api-deimos/company-guides'

import AdoptionAccordion from './accordion'
import {useAdoptionCentre} from './adoption-centre-context'
import AdoptionCentreFooter from './adoption-centre-footer'

export const AdoptionDrawer = () => {
    const {data: companyGuides} = useCompanyGuides()
    const {setDrawerOpen, isDrawerOpen, DRAWER_ID} = useAdoptionCentre()

    const onDismiss = () => {
        setDrawerOpen(false)
    }

    return (
        <Drawer isOpen={isDrawerOpen} onDismiss={onDismiss} drawerId={DRAWER_ID}>
            <DrawerWrapper>
                <Drawer.Body>
                    <AdoptionAccordion guides={companyGuides} closeAdoptionCentre={onDismiss} />
                </Drawer.Body>
                <Drawer.Footer css={{justifyContent: 'center'}}>
                    <AdoptionCentreFooter closeAdoptionCentre={onDismiss} />
                </Drawer.Footer>
            </DrawerWrapper>
        </Drawer>
    )
}
const DrawerWrapper = styled(Drawer.Panel)`
    max-width: 416px;
    min-width: 416px;
`
