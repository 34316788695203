import {Trans} from '@lingui/macro'
import classnames from 'classnames'
import type {FC} from 'react'
import type React from 'react'

import styles from './back.module.css'

import {Cell} from '../cell/cell'

interface Props {
    onClick: Function
}

type DivProps = React.HTMLAttributes<HTMLDivElement>

export const Back: FC<Props & DivProps> = ({className, ...props}) => (
    <Cell className={classnames(className, styles.back)} data-generic-ui="back" {...props}>
        <Trans>← back</Trans>
    </Cell>
)
