import {useEffect, useState} from 'react'
import styled from 'styled-components'

import {Modal} from '@pleo-io/telescope'

import {paywallActioned} from '@product-web/analytics'
import {breakpoints} from '@product-web/styles/theme'

import ModalAccountSuspended from './account-suspended'
import PaywallContextProvider, {PaywallView, usePaywallContext} from './paywall-context'
import usePaywallStatus from './use-paywall-status'

export function getPaywallTrackingView(
    modalState: PaywallView,
    isSuspended: boolean,
): string | undefined {
    switch (modalState) {
        case PaywallView.INITIAL:
            return isSuspended ? 'suspended' : 'warning'
        case PaywallView.PENDING_TRANSFER:
            return 'transfer_pending'
        case PaywallView.VIEW_TRANSFER:
            return 'transfer_details'
        case PaywallView.GET_HELP:
            return 'get_help'
        default:
            return undefined
    }
}

const ModalContainer = styled(Modal)<{view: PaywallView}>`
    padding: 0;
    width: ${({view}) => (view === 'VIEW_TRANSFER' ? '500px' : '1000px')};
    overflow: hidden;

    @media (max-width: ${breakpoints.desktopUp}) {
        width: 100%;
    }
`

const Paywall = () => {
    const {hasPaidInvoice, amount, isSuspended, firstPaidInvoiceDate, paywallActive} =
        usePaywallStatus()
    const {view, didViewPendingTransfer} = usePaywallContext()

    // This controls whether or not the user has dismissed the modal. Can only be true when account is not suspended
    const [userDidCloseModal, setUserDidCloseModal] = useState(false)

    const onClose = () => {
        // If the user has reached DAYS_TO_SHOW_SUSPENDED days of non-payment, prevent modal closure
        if (!isSuspended) {
            // Track modal closure
            const trackingView = getPaywallTrackingView(view, false)
            if (trackingView && !didViewPendingTransfer) {
                paywallActioned({
                    action: 'abandoned',
                    is_dismissible: true,
                    view: trackingView,
                })
            }

            setUserDidCloseModal(true)
        }
    }

    const shouldShow = !userDidCloseModal && paywallActive

    useEffect(() => {
        const trackingView = getPaywallTrackingView(view, isSuspended)
        if (shouldShow && trackingView) {
            paywallActioned({
                action: 'viewed',
                is_dismissible: !isSuspended,
                view: trackingView,
            })
        }
    }, [shouldShow, view, isSuspended])

    useEffect(() => {
        if (view === PaywallView.PENDING_TRANSFER && !didViewPendingTransfer) {
            paywallActioned({
                action: 'completed',
                is_dismissible: !isSuspended,
                view: 'transfer_pending',
            })
        }
    }, [view, didViewPendingTransfer, isSuspended])

    return (
        <ModalContainer
            isOpen={Boolean(shouldShow)}
            onDismiss={onClose}
            dangerouslyBypassFocusLock={true}
            dangerouslySetZIndexValue={Number.MAX_SAFE_INTEGER}
            aria-labelledby="modal-account-suspended-title"
            data-testid="paywall-modal"
            view={view}
        >
            {amount && (
                <ModalAccountSuspended
                    amount={amount}
                    firstPaidInvoiceDate={firstPaidInvoiceDate}
                    hasPaidInvoice={hasPaidInvoice}
                    onClose={onClose}
                    isSuspended={isSuspended}
                />
            )}
        </ModalContainer>
    )
}

const PaywallWithProvider = () => {
    return (
        <PaywallContextProvider>
            <Paywall />
        </PaywallContextProvider>
    )
}

export default PaywallWithProvider
