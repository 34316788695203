import {t} from '@lingui/macro'

import {encryptStoreboxCard} from '@pleo-io/web-crypto'

import {request} from '@product-web/api'
import {RequestScope} from '@product-web/api'
import * as apiAuthPin from '@product-web/api-auth/pin'
import {getClientSessionId} from '@product-web/auth--client-session'
import {getKeyPair} from '@product-web/auth--keys/get-key-pair'
import ModalPinLogin from '@product-web/auth--pin-modal/pin-modal'
import config from '@product-web/config'
import {useToaster} from '@product-web/toaster'
import {useCompanyUser} from '@product-web/user'
import {decryptCard} from '@product-web-features/cards/decrypt-card'

import {deleteDeimos} from './helpers'
import type {StorageItemResponse} from './storage'
import {useDeimosStorage} from './storage'

interface LinkOptions {
    token?: string
    pin?: string
}

export const linkCard = async (cardId: string, options: LinkOptions = {}) => {
    const keypair = await getKeyPair()
    const companyId = getClientSessionId()

    let token
    if (options.token) {
        token = options.token
    } else if (options.pin) {
        const response = await apiAuthPin.login(options.pin, companyId, RequestScope.CARD_DETAILS, {
            publicKey: keypair.publicKey,
        })
        token = response.accessToken
    }

    let pan
    let expirationDate
    try {
        const decryptedCard = await decryptCard(cardId, keypair, token)
        pan = decryptedCard.pan
        expirationDate = decryptedCard.expirationDate
    } catch (e) {
        // Could not decrypt card details (could be caused by an OCR issue)
        // Return card ID so user can enter card details manually
        return cardId
    }

    const [month, year] = (expirationDate || '/').split('/')
    const storeboxKey = await getEncryptionKey()

    if (year && month && pan) {
        const encrypted = await encryptStoreboxCard(storeboxKey, {year, month, pan})
        await apiLinkCard(cardId, btoa(encrypted))
        return null
    }
    return cardId
}

export interface CardDetails {
    year: string
    month: string
    pan: string
}

export const linkCardWithDetails = async (cardId: string, {month, pan, year}: CardDetails) => {
    const storeboxKey = await getEncryptionKey()
    const encrypted = await encryptStoreboxCard(storeboxKey, {year, month, pan})
    await apiLinkCard(cardId, btoa(encrypted))
}

export function resolveStoreboxEnabledValue(
    storeboxEnabledJson: StorageItemResponse<string> | null | undefined,
): boolean | undefined {
    if (storeboxEnabledJson === undefined || storeboxEnabledJson === null) {
        // don't hide the fact that the app still doesn't know if this is enabled
        // the loading state ("isFetching") in the Integration component can be used for this
        return undefined
    }

    if (storeboxEnabledJson.value === null) {
        return false
    }

    return JSON.parse(storeboxEnabledJson.value).enabled
}

export function useEnableStorebox() {
    const {showToast} = useToaster()
    const {result} = useDeimosStorage('storebox')
    const user = useCompanyUser()
    const isEnabled = resolveStoreboxEnabledValue(result.data)

    const enable = async (unlinkedCards: {id: string}[]) => {
        try {
            const keypair = await getKeyPair()
            await create(user.companyId)
            const modalResult = await ModalPinLogin.showScoped(
                RequestScope.CARD_DETAILS,
                keypair.publicKey,
            )
            if (modalResult.status === 'cancelled') {
                return
            }

            for (const unlinked of unlinkedCards) {
                await linkCard(unlinked.id, {token: modalResult.value.accessToken})
            }
            await result.mutate()
        } catch (err) {
            reportError(err)
            showToast(t`There was a problem while verifying your company.`, {
                title: t`Company verification error`,
                level: 'error',
            })
            throw err
        }
    }
    return {
        result: {
            isEnabled,
            mutate: result.mutate,
        },
        mutations: {
            enable,
        },
    }
}

const apiBaseUrl = `${config.endpoints.api}/rest/v1`
const authBaseUrl = `${config.endpoints.auth}/api/v1`

async function create(companyId: string) {
    return request(`${apiBaseUrl}/companies/${companyId}/storebox`, {
        auth: 'user',
        method: 'POST',
    })
}

async function getEncryptionKey(): Promise<JsonWebKey> {
    return request(`${authBaseUrl}/keys/storebox`, {
        auth: 'user',
        method: 'GET',
    })
}

async function apiLinkCard(cardId: string, cardData: string) {
    return request(`${apiBaseUrl}/cards/${cardId}/storebox`, {
        auth: 'user',
        method: 'POST',
        body: {
            cardData,
        },
    })
}

export const disableAdminStorebox = async (companyId: string) => {
    return deleteDeimos(`/rest/v1/companies/${companyId}/storebox`)
}
