import * as React from 'react'
import {Navigate, Route} from 'react-router-dom'

import {PeopleTab} from './lib/tabs'

const Accountant = React.lazy(async () => import('./screens/accountant'))
const AddMemberPage = React.lazy(async () => import('./screens/add-member/add-member'))
const AddProvisionedMemberPage = React.lazy(
    async () => import('./screens/add-provisioned-member/add-provisioned-member'),
)
const CardOrders = React.lazy(async () => import('./screens/card-orders/card-orders'))
const Cards = React.lazy(async () => import('./screens/plastic-cards/plastic-cards'))
const Everyone = React.lazy(async () => import('./screens/everyone'))
const TeamEdit = React.lazy(async () => import('./screens/team-edit/team-edit'))
const Teams = React.lazy(async () => import('./screens/teams/teams'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: ['owner', 'reviewer', 'bookkeeper-extended']}}>
        <Route index element={<Navigate to={PeopleTab.EVERYONE} replace />} />
        <Route path={PeopleTab.EVERYONE}>
            <Route index element={<Everyone />} />
            <Route path="add" element={<AddMemberPage />} />
            <Route path="add-provisioned" element={<AddProvisionedMemberPage />} />
            <Route path=":employeeId" element={<Everyone />}>
                <Route index element={<Everyone />} />
                <Route path="bill-payments/:inviteToken" element={<Everyone />} />
            </Route>
            <Route path="access-requests" element={<Everyone />} />
        </Route>
        <Route path={PeopleTab.TEAMS}>
            <Route index element={<Teams />} />
            <Route path=":teamId" element={<TeamEdit />} />
        </Route>
        <Route path={PeopleTab.CARDS}>
            <Route index element={<Cards />} />
            <Route path="order" element={<CardOrders />} />
        </Route>
        <Route handle={{allowedRoles: ['owner']}}>
            <Route path={PeopleTab.ACCOUNTANT} element={<Accountant />} />
        </Route>
    </Route>
)
