import {t} from '@lingui/macro'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import tracking from '@product-web/analytics'
import {
    getOAuthCallbackParams,
    OAUTH_ADD_ACCOUNT,
    setOauthAddAccount,
} from '@product-web/auth--oauth/operations'
import {forceSessionRefresh} from '@product-web/auth--session/operations'
import {useRegisterState} from '@product-web/auth--session/register-state'
import {showAlertModal} from '@product-web/telescope-lab/alert-modal/alert'
import {useToaster} from '@product-web/toaster'

/**
 * Custom hook that reacts to errors returned in Oauth callbacks and clears the URL.
 * Should be used only once in the app in the top level container component
 */
export function useHandleOauthCallback() {
    const {showToast} = useToaster()
    const navigate = useNavigate()
    const location = useLocation()
    const [params, setParams] = useRegisterState()
    const oauthParams = getOAuthCallbackParams(location.hash)

    React.useEffect(() => {
        if (oauthParams?.error && oauthParams?.message) {
            switch (oauthParams.message) {
                case 'user_not_active':
                    showAlertModal({
                        title: t`Account disabled`,
                        message: t`Your account is disabled. Please contact support.`,
                    })
                    break
                case 'throttled':
                    showAlertModal({
                        title: t`Locked`,
                        message: t`Too many wrong password attempts.`,
                    })
                    break
                case 'exists':
                    showToast(t`User already exists`, {
                        level: 'error',
                    })
                    break
                case 'exists_email':
                    showToast(t`You're already a member. Please login`, {
                        level: 'error',
                    })
                    break
                case 'invalid_credentials':
                    showToast(t`Wrong user credentials`, {
                        level: 'error',
                    })
                    break
                case 'not_verified':
                    navigate('/register/user/verification', {replace: true})
                    return
                case 'signup_not_allowed':
                    navigate('/register/user/not-allowed', {replace: true})
                    return
                case 'invalid_state':
                    showAlertModal({
                        title: t`An error occurred`,
                        message: t`An error occurred. Please try again`,
                    })
                    break
                default:
                    break
            }

            navigate(location.pathname, {replace: true})
        }

        // If there's a email and idToken within the URL hash params:
        // These values originates from a SAML callback and we should refresh the session
        // with the provided idToken.
        if (oauthParams?.email && oauthParams?.idToken) {
            forceSessionRefresh(oauthParams?.idToken)
            navigate('/trust-browser', {
                state: {addAccount: false, email: oauthParams?.email},
            })
        }

        // This is condition is made to Google Signup Intergration when it returned `email`, `firstName` and `lastName`
        // If it's the registration flow, it should not have a loginToken nor idToken
        if (oauthParams?.email && !oauthParams.loginToken && !oauthParams?.idToken) {
            setParams({
                ...params,
                email: oauthParams?.email,
                firstName: oauthParams?.firstName || undefined,
                lastName: oauthParams?.lastName || undefined,
                activeRegisterType: 'admin',
            })
            tracking.createAccountActioned({
                action: 'completed',
                signin: 'google',
            })
            navigate(location.pathname, {replace: true})
        }

        if (isOauthAddAccount() && oauthParams?.loginToken) {
            setOauthAddAccount(false)
            navigate('/add-account', {
                state: {
                    ...oauthParams,
                    redirect: window.location.pathname,
                },
                replace: true,
            })
        }

        // If the initial page url contains oAuth params in hash, we should be safe to remove them
        // at this point, since the data is already used for error feedback or initial session state
        // in Session Provider
        if (oauthParams?.accessToken) {
            navigate(location.pathname, {replace: true})
        }

        if (oauthParams?.result === 'verification_email_sent') {
            navigate('/login-email-verification', {
                replace: true,
                state: {email: oauthParams.email},
            })
        }
    }, [location.pathname])
}

function isOauthAddAccount() {
    return !!localStorage.getItem(OAUTH_ADD_ACCOUNT)
}
