import type {FC} from 'react'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import {NakedButton, tokens} from '@pleo-io/telescope'
import {Play} from '@pleo-io/telescope-icons'

import {loadScript} from '@product-web/web-platform/load-script'

import type {Wistia} from './wistia-video-types'
import type {PlayerEvent} from './wistia-video-types/types'

declare global {
    interface Window {
        _wq: Wistia.Item[]
    }
}

const MAX_WIDTH = 1240
const MAX_HEIGHT = 550

enum AspectRatio {
    Ratio169 = (9 / 16) * 100,
    RatioCinemascope = 45,
}

interface Props {
    thumbnail: {src: string}
    videoId: string
    ariaLabel?: string
    aspectRatio?: AspectRatio
    autoPlay?: boolean
    startMuted?: boolean
    maxHeight?: number
    imgMinHeight?: number
    trackingCallback?: (playState: Wistia.PlayState, stats: Wistia.VideoStatsProps) => void
    onSecondChange?: (stats: Wistia.VideoStatsProps) => void
}

export const InlineWistiaVideo: FC<Props> = ({
    ariaLabel,
    thumbnail,
    videoId,
    autoPlay,
    startMuted,
    aspectRatio = AspectRatio.Ratio169,
    maxHeight,
    imgMinHeight,
    trackingCallback,
    onSecondChange,
}) => {
    const [requestedVideoId, setRequestedVideoId] = useState(autoPlay ? videoId : '')
    const [isMounted, setIsMounted] = React.useState(false)
    const [video, setVideo] = useState<Wistia.Video | null>(null)
    const [videoState, setVideoState] = React.useState<Wistia.PlayState>('beforeplay')
    const [videoStats, setVideoStats] = React.useState<Wistia.VideoStatsProps>({
        secondsWatched: 0,
        timestamp: 0,
        duration: 0,
    })

    React.useEffect(() => {
        const initScripts = async () => {
            loadScript({
                src: `https://fast.wistia.com/embed/medias/${video}.jsonp`,
                asynchronous: true,
            })
            loadScript({src: `https://fast.wistia.com/assets/external/E-v1.js`, asynchronous: true})
        }
        initScripts()
        setIsMounted(true)
    }, [])

    useEffect(() => {
        window._wq = window._wq || []

        const events: Wistia.PlayerEvent[] = ['play', 'pause', 'end']
        let secondChangeHandler: () => void
        const stateChangeHandlers: Partial<Record<PlayerEvent, () => void>> = {}

        window._wq.push({
            id: videoId,
            onEmbedded: (embeddedVideo) => {
                if (onSecondChange) {
                    secondChangeHandler = () =>
                        onSecondChange({
                            secondsWatched: embeddedVideo?.secondsWatched(),
                            timestamp: Math.ceil(embeddedVideo?.time()),
                            duration: Math.ceil(embeddedVideo?.duration()),
                        })

                    embeddedVideo?.bind('secondchange', secondChangeHandler)
                }

                setVideo(embeddedVideo)
                events.forEach((event) => {
                    if (startMuted) {
                        embeddedVideo.mute()
                    }
                    if (videoId === requestedVideoId) {
                        embeddedVideo?.play()

                        stateChangeHandlers[event] = () => {
                            setVideoState(embeddedVideo?.state())
                            setVideoStats({
                                secondsWatched: embeddedVideo?.secondsWatched(),
                                timestamp: Math.ceil(embeddedVideo?.time()),
                                duration: Math.ceil(embeddedVideo?.duration()),
                            })
                        }

                        embeddedVideo.bind(event, stateChangeHandlers[event]!)
                    }
                })
            },
        })

        return () => {
            if (secondChangeHandler) {
                video?.unbind('secondchange', secondChangeHandler)
            }

            events.forEach((event) => {
                if (stateChangeHandlers[event]) {
                    video?.unbind(event, stateChangeHandlers[event]!)
                }
            })
        }
    }, [video, videoId, requestedVideoId])

    React.useEffect(() => {
        if (trackingCallback) {
            trackingCallback(videoState, videoStats)
        }
    }, [videoState, videoStats, trackingCallback])

    if (!isMounted) {
        return null
    }

    return (
        <Wrapper
            activeVideo={videoId === requestedVideoId}
            aspectRatio={aspectRatio}
            data-generic-ui="inline-wistia-video"
        >
            {videoId !== requestedVideoId && (
                <>
                    <StyledImage src={thumbnail.src} alt="" minHeight={imgMinHeight} />
                    <PlayVideoWithIcon
                        ariaLabel={ariaLabel}
                        onClick={() => setRequestedVideoId(videoId)}
                    />
                </>
            )}
            {['beforeplay', 'ended'].includes(videoState) && (
                <ImageWrapper>
                    <StyledImage src={thumbnail.src} alt="" minHeight={imgMinHeight} />
                    {!!video && videoState === 'ended' && (
                        <PlayVideoWithIcon ariaLabel={ariaLabel} onClick={() => video.play()} />
                    )}
                </ImageWrapper>
            )}
            <WistiaWrapper
                $videoEnded={videoState === 'ended'}
                $idleVideo={videoId !== requestedVideoId}
                className={`
                        wistia_embed wistia_async_${videoId}
                        videoFoam['maxWidth']=${MAX_WIDTH}
                        videoFoam['maxHeight']=${Math.min(maxHeight ?? 0, MAX_HEIGHT)}
                        playButton=false
                        controlsVisibleOnLoad=false
                        endVideoBehavior=reset
                        wmode=transparent
                    `}
            />
        </Wrapper>
    )
}

const PlayVideoWithIcon: React.FC<{ariaLabel?: string; onClick?: () => void}> = ({
    ariaLabel,
    onClick,
}) => (
    <PlayVideoWithIconWrapper aria-label={ariaLabel} onClick={onClick}>
        <RoundPlayButton />
    </PlayVideoWithIconWrapper>
)

const PlayVideoWithIconWrapper = styled(NakedButton)`
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: ${tokens.zIndexSurface};
`

const RoundPlayButton = () => (
    <RoundPlayButtonWrapper>
        <WhiteIconPlay />
    </RoundPlayButtonWrapper>
)

const RoundPlayButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: ${tokens.circle};
    background-color: ${tokens.shade800};
    color: ${tokens.shade000};
`

const WhiteIconPlay = styled(Play).attrs({size: 64})`
    &&& {
        margin-right: 0;
        color: ${tokens.shade000};
    }
`

const StyledImage = styled.img<{minHeight?: number}>`
    width: 100%;
    height: auto;
    display: block;
    ${({minHeight}) => (minHeight ? `min-height: ${minHeight}px;` : '')}
    border-radius: ${tokens.arc8}
`

const Wrapper = styled.div<{activeVideo: boolean; aspectRatio: number}>`
    position: relative;
    width: 100%;
    padding-top: ${(props) => (props.activeVideo ? `min(${props.aspectRatio}%, 550px) ` : `unset`)};
`

const ImageWrapper = styled.div`
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
`

interface WistiaWrapperProps {
    $idleVideo: boolean
    $videoEnded: boolean
}

const WistiaWrapper = styled.div<WistiaWrapperProps>`
    ${(props) => (props.$idleVideo ? `display: none;` : null)};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    object-fit: contain;
    pointer-events: ${(props) => (props.$videoEnded ? 'none' : 'unset')};
    opacity: ${(props) => (props.$videoEnded ? 0 : 1)};
`
