import {t} from '@lingui/macro'
import type {Dispatch, SetStateAction} from 'react'

import type {CompanyAdoptionGuideResponse} from '@pleo-io/auxo-ts-models'
import {Accordion} from '@pleo-io/telescope'

import type {HelpCentreArticle} from '@product-web/locale/helpers'

import {AdoptionGuides} from './helper'

import {usePleoLinks} from '../../adoption-centre-link-helpers'
import type {FeedbackState} from '../../feedback/feedback'
import {Feedback} from '../../feedback/feedback'
import {AdoptionAccordionView} from '../adoption-accordion'
import HeadingImage from '../heading-image'
import {MoreItem} from '../more-section/more-item'
import AdoptionGuide from '../pro-tips-section/adoption-guide'
import {AdoptionTask, StepLink} from '../pro-tips-section/adoption-tasks-list'
import HowExportWorks from '../pro-tips-section/how-export-works'

export const GUIDE_NAME = AdoptionGuides.ExportEssentials

const ExportGuideTasks = [
    'make-a-purchase-on-your-pleo-card',
    'connect-your-accounting-system',
    'set-up-categories',
    'prepare-your-first-export',
    'export-the-finish-line',
]

const CONNECT_CLIENT_TO_ACCOUTING_SYSTEM: HelpCentreArticle = 3878425
const SET_UP_CATEGORIES: HelpCentreArticle = 1058078
const HOW_TO_EXPORT_EXPENSES: HelpCentreArticle = 412746

interface ExportContentProps {
    guide: CompanyAdoptionGuideResponse
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
    feedbackState: FeedbackState
    setFeedbackState: (state: FeedbackState) => void
}

export const ExportContent = ({
    guide,
    setView,
    feedbackState,
    setFeedbackState,
}: ExportContentProps) => {
    const title = t`It's time to export`
    const description = t`Out of Pleo and into your accounting systems, get your expenses ready for reconciliation`
    const linkText = t`How Export works`

    return (
        <>
            <HeadingImage variation="base" />
            <AdoptionGuide
                title={title}
                description={description}
                linkText={linkText}
                setView={setView}
                guide={guide}
                helperPage={AdoptionAccordionView.GUIDE_DETAILS}
                firstTask={ExportGuideTasks[0]}
            >
                <ExportTasksList />
            </AdoptionGuide>
            <Feedback
                feedbackStatus={feedbackState}
                setView={setView}
                setFeedbackStatus={setFeedbackState}
                guideName={guide.adoptionGuide}
            />
        </>
    )
}

interface ExportGuideProps {
    guide: CompanyAdoptionGuideResponse
    feedbackState: FeedbackState
    setFeedbackState: (state: FeedbackState) => void
    view: AdoptionAccordionView
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
}
export const PRO_TIPS_ACCORDION_ID = 'PRO_TIPS'

export const ExportGuide = ({
    guide,
    feedbackState,
    setFeedbackState,
    view,
    setView,
}: ExportGuideProps) => {
    return (
        <>
            {view === AdoptionAccordionView.INITIAL && (
                <>
                    <MoreItem />
                    <Accordion.Item value={PRO_TIPS_ACCORDION_ID}>
                        <Accordion.Header headingLevel="h2" heading={t`Pro tips`} />
                        <Accordion.Content>
                            <ExportContent
                                guide={guide}
                                setView={setView}
                                feedbackState={feedbackState}
                                setFeedbackState={setFeedbackState}
                            />
                        </Accordion.Content>
                    </Accordion.Item>
                </>
            )}

            {view === AdoptionAccordionView.GUIDE_DETAILS && (
                <HowExportWorks guideName={guide.adoptionGuide} setView={setView} />
            )}
        </>
    )
}

export const ExportTasksList = () => {
    const {getHelpCentreLink} = usePleoLinks()

    return (
        <>
            <AdoptionTask
                key={0}
                item={1}
                task={t`Make a purchase on your Pleo card`}
                accordionId={ExportGuideTasks[0]}
                description={t`Test out your shiny new Pleo card (or the virtual version, we don't mind)`}
                steps={[
                    t`Buy something with your Pleo card, whenever you're ready`,
                    // eslint-disable-next-line string-to-lingui/text-restrictions
                    t`Remember to snap a picture of the receipt!`,
                ]}
                listStyle="bullet"
                guideName={GUIDE_NAME}
                trackOpen={true}
            />
            <AdoptionTask
                key={1}
                item={2}
                task={t`Connect your accounting system`}
                accordionId={ExportGuideTasks[1]}
                description={t`Make sure your expenses end up in the right place`}
                steps={[
                    <StepLink
                        startText={t`Go to`}
                        linkText={`${t`Settings`} > ${t`Accounting`} > ${t`Setup`}`}
                        pathname={'settings/accounting/setup'}
                        key={t`Connect your accounting system`}
                        guideName={GUIDE_NAME}
                        taskId={ExportGuideTasks[1]}
                    />,
                    t`Select your accounting system`,
                    t`Follow the on-screen instructions to complete the setup`,
                ]}
                listStyle="bullet"
                learnMoreText={t`How to connect account system`}
                learnMoreHref={getHelpCentreLink(CONNECT_CLIENT_TO_ACCOUTING_SYSTEM)}
                guideName={GUIDE_NAME}
            />
            <AdoptionTask
                key={2}
                item={3}
                task={t`Set up categories`}
                accordionId={ExportGuideTasks[2]}
                description={t`Link your accounts and let your team help with expense categorisation`}
                steps={[
                    <StepLink
                        startText={t`Go to`}
                        linkText={`${t`Settings`} > ${t`Accounting`} > ${t`Categories`}`}
                        pathname={'settings/accounting/categories'}
                        key={t`Set up categories`}
                        guideName={GUIDE_NAME}
                        taskId={ExportGuideTasks[2]}
                    />,
                    t`Check the default category groups set by Mastercard (these can be changed)`,
                    t`Add your own categories, filling in the account, name, and tax code`,
                ]}
                listStyle="bullet"
                learnMoreText={t`How to set up categories`}
                learnMoreHref={getHelpCentreLink(SET_UP_CATEGORIES)}
                guideName={GUIDE_NAME}
            />
            <AdoptionTask
                key={3}
                item={4}
                task={t`Prepare your first export`}
                accordionId={ExportGuideTasks[3]}
                description={t`Get familiar with the export queue. Organise, filter, and review your expenses.`}
                steps={[
                    <StepLink
                        startText={t`Go to the`}
                        linkText={t`Export`}
                        endText={'page'}
                        pathname={'/export'}
                        search={'?exportLane=NOT_EXPORTED'}
                        key={t`Prepare your first export`}
                        guideName={GUIDE_NAME}
                        taskId={ExportGuideTasks[3]}
                    />,
                    t`Click on an expense to check and edit the details`,
                    t`Add expenses to the Export queue when you're done editing`,
                ]}
                learnMoreText={t`How to Export`}
                learnMoreHref={getHelpCentreLink(HOW_TO_EXPORT_EXPENSES)}
                listStyle="bullet"
                guideName={GUIDE_NAME}
            />
            <AdoptionTask
                key={4}
                item={5}
                task={t`Export: the finish line`}
                accordionId={ExportGuideTasks[4]}
                // eslint-disable-next-line string-to-lingui/text-restrictions
                description={t`Out of Pleo and into your accounting system. The finish line is in sight!`}
                steps={[
                    <StepLink
                        startText={t`Go to`}
                        linkText={`${t`Export`} > ${t`Export Queue`}`}
                        pathname={'/export'}
                        search={`?exportLane=QUEUED`}
                        key={t`Export: the finish line`}
                        guideName={GUIDE_NAME}
                        taskId={ExportGuideTasks[4]}
                    />,
                    t`Select all expenses in the queue`,
                    t`Click Export`,
                ]}
                listStyle="bullet"
                guideName={GUIDE_NAME}
            />
        </>
    )
}
