import styled, {css} from 'styled-components'

import {focusRing, Inline, NakedButton, Text, tokens} from '@pleo-io/telescope'
import {Copy} from '@pleo-io/telescope-icons'

import type {ICopyable} from '../copyable/copyable'

const copyableCommonStyle = ({iconPosition}: Pick<ICopyable, 'iconPosition'>) => css`
    display: flex;
    align-items: center;
    flex-direction: ${iconPosition === 'right' ? 'row-reverse' : 'row'};
    color: ${tokens.colorContentInteractiveLink};
    font-weight: ${tokens.fontWeightMedium};
    font-size: ${tokens.fontMedium};
    cursor: pointer;
    width: fit-content;

    &:hover {
        color: ${tokens.colorContentInteractiveLinkHover};
    }
`

export const StyledCopyableInline = styled(Inline)<Pick<ICopyable, 'iconPosition'>>`
    ${({iconPosition}) => copyableCommonStyle({iconPosition})}
    border-radius: ${tokens.arc4};
    ${focusRing('regular')};
`

export const StyledCopyableButton = styled(NakedButton)<Pick<ICopyable, 'iconPosition'>>`
    ${({iconPosition}) => copyableCommonStyle({iconPosition})}

    height: 32px;
    box-sizing: border-box;
    padding: ${tokens.spacing8};
    border-radius: ${tokens.arc4};

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveHover};
    }
`

export const StyledCopyableIcon = styled(Copy)<Pick<ICopyable, 'iconPosition'>>`
    margin: ${({iconPosition}) =>
        iconPosition === 'right' ? `0 0 0 ${tokens.spacing4}` : `0 ${tokens.spacing4} 0 0`};
`

export const StyledCopyableText = styled(Text).attrs({color: 'inherit'})`
    overflow: hidden;
    white-space: nowrap;
`
