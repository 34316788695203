import {Field} from 'formik'
import styled, {css} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    max-width: 300px;
    margin: ${tokens.spacing40} auto;
    white-space: nowrap;
    flex-direction: row;
    justify-content: space-around;
`

export const PinInputField = styled(Field)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${tokens.zIndexOverlay};
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 ${tokens.spacing36};
    font-family: 'Courier New', monospace, serif;
    font-size: 20px;
    color: ${tokens.shade600};
    letter-spacing: 63px;
    background: transparent;
    border: none;
    outline: none;
    -webkit-text-fill-color: transparent;
    appearance: textfield;

    &&::-ms-clear {
        display: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        margin: 0;
        appearance: none;
    }

    /* Needed to override browser native password managers - https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#the_autocomplete_attribute_and_login_fields */
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        background-clip: text;
    }

    &[type='password']::selection {
        color: inherit;
        background: none;
    }
`

export const Description = styled.div`
    text-align: center;
    color: ${tokens.shade600};
    font-size: 14px;
    height: ${tokens.spacing36};
    margin-bottom: ${tokens.spacing16};
`

export const InputPlaceholder = styled.div<{
    $inputPlaceholderValue: boolean
    $inputPlaceholderNext: boolean
    $focused: boolean
}>`
    position: relative;
    z-index: ${tokens.zIndexSurface};
    display: flex;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    box-sizing: border-box;
    background: ${tokens.shade300};
    border: 4px solid ${tokens.shade300};
    border-radius: ${tokens.circle};
    transition: border ${tokens.fastIn};
    flex: 0 0 60px;

    ${({$inputPlaceholderNext, $focused}) =>
        $inputPlaceholderNext &&
        $focused &&
        css`
            border-color: ${tokens.shade800};
        `}

    ${({$inputPlaceholderValue}) =>
        $inputPlaceholderValue &&
        css`
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                content: '';
                background-color: ${tokens.shade800};
                border-radius: ${tokens.circle};
                transform: translate(-50%, -50%);
            }
        `}
`

export const Circle = styled.div<{
    $isSuccess?: boolean
    $isError?: boolean
}>`
    display: flex;
    width: 63px;
    height: 63px;
    margin: 0 auto ${tokens.spacing32};
    border: 3px solid;
    border-radius: ${tokens.circle};
    align-items: center;
    justify-content: center;

    ${($isSuccess) =>
        $isSuccess &&
        css`
            color: ${tokens.green600};
            border-color: ${tokens.green600};
        `}

    ${($isError) =>
        $isError &&
        css`
            color: ${tokens.red600};
            border-color: ${tokens.red600};
        `}
`
