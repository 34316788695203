import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Box, Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    Attention,
    Car,
    CashSingle,
    Clock,
    Receipt,
    Wallet,
} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'
import {Page} from '@product-web-features/ui-page'

import {useSideOverview} from './use-side-overview'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {SideOverview} from '../../components/side-overview'
import {Snapshot} from '../../components/snapshot'

export const ReimbursementsAndMileage = () => {
    const {country, hideInvoices} = useAcademyContext()
    const {overviewSteps, usefulMaterials} = useSideOverview()
    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    id: 'k7aql5fxj9',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/4ea44058531e68c523927f90a71911ce.jpg',
                }
            case 'DK':
            case 'GL':
                return {
                    id: 'vt1nkhurz5',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/fb5b787deff1c6ef9f2fc66cad84aecd.jpg',
                }
            case 'FR':
                return {
                    id: '2650dgj1hu',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/5e3219f64cbc3773c11aaeb3e3133f3a.jpg',
                }
            case 'SE':
                return {
                    id: 'h04fynm4ca',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/c579e86bb2292c9c47d8739f00fee007.jpg',
                }
            case 'ES':
                return {
                    id: '1bwh4vhmpg',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/dd1d31290a44f9a2dff7602b7096febc.jpg',
                }
            case 'NL':
                return {
                    id: '49m97p7xc0',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/f09eafca22e459df3b9d48de8bd958e6.jpg',
                }
            default:
                // default is GB
                return {
                    id: '8wyh3zqk5f',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/27ed0bc330e58cdfbf43750dcf1a2ee4.jpg',
                }
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`The Pleo Product`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>The Pleo Product</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main>
                        <Text>
                            <Trans>
                                Remove the need for manual reimbursements through payroll. Instead,
                                link employees' personal bank accounts to Pleo to help them get
                                reimbursed in seconds.
                            </Trans>
                        </Text>
                        <Box mb={24} />
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Reimbursements & Mileage</Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video - Reimbursements`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({
                                    action,
                                    video: 'reimbursements_and_mileage',
                                })
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                        <Stack mb={12} />
                        <Text as="h2" variant="2xlarge-accent">
                            <Trans>Reimbursements in a snapshot</Trans>
                        </Text>
                        <Snapshot>
                            <Snapshot.Card
                                icon={<CashSingle />}
                                title={t`Track your employees' expenses easily`}
                                description={t`Keep track of what your employees are owed for business expenses that they have made with their own money or cash withdrawn from a Pleo card.`}
                            />
                            <Snapshot.Card
                                icon={<Car />}
                                title={t`Make use of Mileage`}
                                description={t`With Mileage, we'll calculate the distance and fuel cost of work trips. Simply tell our app where a journey started and ended and we'll work out what's owed for fuel.`}
                            />
                            <Snapshot.Card
                                icon={<Attention />}
                                title={t`Choose who handles reimbursements`}
                                description={t`Admins can settle and reimburse employees, or choose to let employees reimburse themselves for approved expenses.`}
                            />
                            <Snapshot.Card
                                icon={<Wallet />}
                                title={t`Direct Reimbursements from the Pleo Wallet`}
                                description={t`With Direct Reimbursements, admins can reimburse employees with funds from the Pleo Wallet, directly into their employees' bank accounts.`}
                            />
                            <Snapshot.Card
                                icon={<Receipt />}
                                title={t`Manage reimbursements made outside of Pleo`}
                                description={t`Whether you are reimbursing your employees via salary or cash, expenses are marked as settled in Pleo for accurate bookkeeping.`}
                            />
                        </Snapshot>
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to={hideInvoices ? '../vendor-cards' : '../invoices'}
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SideOverview overviewSteps={overviewSteps} usefulMaterials={usefulMaterials} />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
